@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0948b3;
  --bs-indigo: #4c5680;
  --bs-purple: #8965e0;
  --bs-pink: #c96480;
  --bs-red: #fa5252;
  --bs-orange: #ff9f89;
  --bs-yellow: #f5b759;
  --bs-green: #05a677;
  --bs-teal: #1e90ff;
  --bs-cyan: #63b1bd;
  --bs-white: #ffffff;
  --bs-gray: #93a5be;
  --bs-gray-dark: #506690;
  --bs-primary: #262b40;
  --bs-secondary: #61dafb;
  --bs-tertiary: #1b998b;
  --bs-quaternary: #c96480;
  --bs-primary-app: #ebf4f6;
  --bs-secondary-app: #424aa0;
  --bs-light: #eaedf2;
  --bs-lighten: #f0f3f6;
  --bs-success: #05a677;
  --bs-info: #0948b3;
  --bs-warning: #f5b759;
  --bs-danger: #fa5252;
  --bs-white: #ffffff;
  --bs-gray: #4a5073;
  --bs-indigo: #4c5680;
  --bs-dark: #262b40;
  --bs-soft: #f5f8fb;
  --bs-black: #2e3650;
  --bs-blue: #0948b3;
  --bs-pink: #c96480;
  --bs-cyan: #63b1bd;
  --bs-purple: #8965e0;
  --bs-soft-indigo: #f5e8ff;
  --bs-soft-green: #2ca58d;
  --bs-gray-100: #f3f7fa;
  --bs-gray-200: #f5f8fb;
  --bs-gray-300: #f0f3f6;
  --bs-gray-400: #eaedf2;
  --bs-gray-500: #d1d7e0;
  --bs-gray-600: #93a5be;
  --bs-gray-700: #66799e;
  --bs-gray-800: #506690;
  --bs-facebook: #3b5999;
  --bs-twitter: #1da1f2;
  --bs-google: #db4337;
  --bs-instagram: #e4405f;
  --bs-pinterest: #bd081c;
  --bs-youtube: #cd201f;
  --bs-slack: #3aaf85;
  --bs-dribbble: #ea4c89;
  --bs-dropbox: #1e90ff;
  --bs-twitch: #4b367c;
  --bs-paypal: #ecb32c;
  --bs-behance: #0057ff;
  --bs-reddit: #e84422;
  --bs-github: #222222;
  --bs-font-sans-serif: 'Nunito Sans', sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a5073;
  background-color: #f5f8fb;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(46, 54, 80, 0);
}

.alert-box {
  position: absolute;
  z-index: 1000;
  height: 100vh;
  width: 79%;
  background-color: rgba(15, 15, 15, 0.849);
}

.alert-box .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
}

.alert-box .container p {
  color: #fff;
  font-size: 23px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons .yes {
  cursor: pointer;
  font-size: 19px;
  margin-left: 12px;
  margin-right: 12px;
  color: #fff;
}

.buttons .no {
  cursor: pointer;
  font-size: 19px;
  margin-left: 12px;
  margin-right: 12px;
  color: #fff;
}

.buttons .no:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.322);
}

.buttons .yes:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.322);
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 0.0625rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.3;
  color: #262b40;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #262b40;
  text-decoration: none;
}

a:hover {
  color: #0a0b10;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #0948b3;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #4a5073;
  border-radius: 0.1rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #58677d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.5rem;
  }
}

.display-3 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 2.5rem;
  }
}

.display-4 {
  font-size: calc(1.3125rem + 0.75vw);
  font-weight: 600;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 1.875rem;
  }
}

.display-5 {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.3;
}

.display-6 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #93a5be;
}

.blockquote-footer::before {
  content: '\2014\00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f5f8fb;
  border: 0.0625rem solid #f0f3f6;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #93a5be;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 2rem);
  padding-left: var(--bs-gutter-x, 2rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

.row-cols-auto > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 8rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 8rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 10rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 10rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 11rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 11rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 14rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 14rem;
}

.g-11,
.gx-11 {
  --bs-gutter-x: 16rem;
}

.g-11,
.gy-11 {
  --bs-gutter-y: 16rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 20rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 20rem;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 1rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 1rem;
}

.g-md,
.gx-md {
  --bs-gutter-x: 2rem;
}

.g-md,
.gy-md {
  --bs-gutter-y: 2rem;
}

.g-lg,
.gx-lg {
  --bs-gutter-x: 4rem;
}

.g-lg,
.gy-lg {
  --bs-gutter-y: 4rem;
}

.g-xl,
.gx-xl {
  --bs-gutter-x: 8rem;
}

.g-xl,
.gy-xl {
  --bs-gutter-y: 8rem;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 8rem;
  }

  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 8rem;
  }

  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 10rem;
  }

  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 10rem;
  }

  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 11rem;
  }

  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 11rem;
  }

  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 14rem;
  }

  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 14rem;
  }

  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 16rem;
  }

  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 16rem;
  }

  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 20rem;
  }

  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 20rem;
  }

  .g-sm-sm,
  .gx-sm-sm {
    --bs-gutter-x: 1rem;
  }

  .g-sm-sm,
  .gy-sm-sm {
    --bs-gutter-y: 1rem;
  }

  .g-sm-md,
  .gx-sm-md {
    --bs-gutter-x: 2rem;
  }

  .g-sm-md,
  .gy-sm-md {
    --bs-gutter-y: 2rem;
  }

  .g-sm-lg,
  .gx-sm-lg {
    --bs-gutter-x: 4rem;
  }

  .g-sm-lg,
  .gy-sm-lg {
    --bs-gutter-y: 4rem;
  }

  .g-sm-xl,
  .gx-sm-xl {
    --bs-gutter-x: 8rem;
  }

  .g-sm-xl,
  .gy-sm-xl {
    --bs-gutter-y: 8rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }

  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 5rem;
  }

  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 5rem;
  }

  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 8rem;
  }

  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 8rem;
  }

  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 10rem;
  }

  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 10rem;
  }

  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 11rem;
  }

  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 11rem;
  }

  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 14rem;
  }

  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 14rem;
  }

  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 16rem;
  }

  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 16rem;
  }

  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 20rem;
  }

  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 20rem;
  }

  .g-md-sm,
  .gx-md-sm {
    --bs-gutter-x: 1rem;
  }

  .g-md-sm,
  .gy-md-sm {
    --bs-gutter-y: 1rem;
  }

  .g-md-md,
  .gx-md-md {
    --bs-gutter-x: 2rem;
  }

  .g-md-md,
  .gy-md-md {
    --bs-gutter-y: 2rem;
  }

  .g-md-lg,
  .gx-md-lg {
    --bs-gutter-x: 4rem;
  }

  .g-md-lg,
  .gy-md-lg {
    --bs-gutter-y: 4rem;
  }

  .g-md-xl,
  .gx-md-xl {
    --bs-gutter-x: 8rem;
  }

  .g-md-xl,
  .gy-md-xl {
    --bs-gutter-y: 8rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 8rem;
  }

  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 8rem;
  }

  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 10rem;
  }

  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 10rem;
  }

  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 11rem;
  }

  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 11rem;
  }

  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 14rem;
  }

  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 14rem;
  }

  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 16rem;
  }

  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 16rem;
  }

  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 20rem;
  }

  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 20rem;
  }

  .g-lg-sm,
  .gx-lg-sm {
    --bs-gutter-x: 1rem;
  }

  .g-lg-sm,
  .gy-lg-sm {
    --bs-gutter-y: 1rem;
  }

  .g-lg-md,
  .gx-lg-md {
    --bs-gutter-x: 2rem;
  }

  .g-lg-md,
  .gy-lg-md {
    --bs-gutter-y: 2rem;
  }

  .g-lg-lg,
  .gx-lg-lg {
    --bs-gutter-x: 4rem;
  }

  .g-lg-lg,
  .gy-lg-lg {
    --bs-gutter-y: 4rem;
  }

  .g-lg-xl,
  .gx-lg-xl {
    --bs-gutter-x: 8rem;
  }

  .g-lg-xl,
  .gy-lg-xl {
    --bs-gutter-y: 8rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 8rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 8rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 10rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 10rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 11rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 11rem;
  }

  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 14rem;
  }

  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 14rem;
  }

  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 16rem;
  }

  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 16rem;
  }

  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 20rem;
  }

  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 20rem;
  }

  .g-xl-sm,
  .gx-xl-sm {
    --bs-gutter-x: 1rem;
  }

  .g-xl-sm,
  .gy-xl-sm {
    --bs-gutter-y: 1rem;
  }

  .g-xl-md,
  .gx-xl-md {
    --bs-gutter-x: 2rem;
  }

  .g-xl-md,
  .gy-xl-md {
    --bs-gutter-y: 2rem;
  }

  .g-xl-lg,
  .gx-xl-lg {
    --bs-gutter-x: 4rem;
  }

  .g-xl-lg,
  .gy-xl-lg {
    --bs-gutter-y: 4rem;
  }

  .g-xl-xl,
  .gx-xl-xl {
    --bs-gutter-x: 8rem;
  }

  .g-xl-xl,
  .gy-xl-xl {
    --bs-gutter-y: 8rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 8rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 8rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 10rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 10rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 11rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 11rem;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 14rem;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 14rem;
  }

  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 16rem;
  }

  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 16rem;
  }

  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 20rem;
  }

  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 20rem;
  }

  .g-xxl-sm,
  .gx-xxl-sm {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-sm,
  .gy-xxl-sm {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-md,
  .gx-xxl-md {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-md,
  .gy-xxl-md {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-lg,
  .gx-xxl-lg {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-lg,
  .gy-xxl-lg {
    --bs-gutter-y: 4rem;
  }

  .g-xxl-xl,
  .gx-xxl-xl {
    --bs-gutter-x: 8rem;
  }

  .g-xxl-xl,
  .gy-xxl-xl {
    --bs-gutter-y: 8rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #4a5073;
  --bs-table-striped-bg: rgba(46, 54, 80, 0.05);
  --bs-table-active-color: #4a5073;
  --bs-table-active-bg: rgba(46, 54, 80, 0.1);
  --bs-table-hover-color: #4a5073;
  --bs-table-hover-bg: rgba(46, 54, 80, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #4a5073;
  vertical-align: top;
  border-color: #eaedf2;
}

.table > :not(caption) > * > * {
  padding: 0.75rem 0.5rem;
  background-color: var(--bs-table-bg);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--bs-table-accent-bg)),
    to(var(--bs-table-accent-bg))
  );
  background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
  border-bottom-width: 0.0625rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 0.0625rem 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 0.0625rem;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #262b40;
  --bs-table-striped-bg: #31364a;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #3c4053;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #363b4e;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #3c4053;
}

.table-secondary {
  --bs-table-bg: #61dafb;
  --bs-table-striped-bg: #5ed1f2;
  --bs-table-striped-color: #262b40;
  --bs-table-active-bg: #5bc9e8;
  --bs-table-active-color: #262b40;
  --bs-table-hover-bg: #5dcded;
  --bs-table-hover-color: #262b40;
  color: #262b40;
  border-color: #5bc9e8;
}

.table-success {
  --bs-table-bg: #05a677;
  --bs-table-striped-bg: #12aa7e;
  --bs-table-striped-color: #262b40;
  --bs-table-active-bg: #1eaf85;
  --bs-table-active-color: #262b40;
  --bs-table-hover-bg: #18ad81;
  --bs-table-hover-color: #262b40;
  color: #ffffff;
  border-color: #1eaf85;
}

.table-info {
  --bs-table-bg: #0948b3;
  --bs-table-striped-bg: #1551b7;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #225abb;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #1b56b9;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #225abb;
}

.table-warning {
  --bs-table-bg: #f5b759;
  --bs-table-striped-bg: #ebb058;
  --bs-table-striped-color: #262b40;
  --bs-table-active-bg: #e0a957;
  --bs-table-active-color: #262b40;
  --bs-table-hover-bg: #e5ad57;
  --bs-table-hover-color: #262b40;
  color: #262b40;
  border-color: #e0a957;
}

.table-danger {
  --bs-table-bg: #fa5252;
  --bs-table-striped-bg: #fa5b5b;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #fb6363;
  --bs-table-active-color: #262b40;
  --bs-table-hover-bg: #fa5f5f;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #fb6363;
}

.table-light {
  --bs-table-bg: #eaedf2;
  --bs-table-striped-bg: #e0e3e9;
  --bs-table-striped-color: #262b40;
  --bs-table-active-bg: #d6dae0;
  --bs-table-active-color: #262b40;
  --bs-table-hover-bg: #dbdee5;
  --bs-table-hover-color: #262b40;
  color: #262b40;
  border-color: #d6dae0;
}

.table-dark {
  --bs-table-bg: #262b40;
  --bs-table-striped-bg: #31364a;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #3c4053;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #363b4e;
  --bs-table-hover-color: #ffffff;
  color: #ffffff;
  border-color: #3c4053;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: 0.6125rem;
  padding-bottom: 0.6125rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #58677d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid #d1d7e0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  -webkit-box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  -webkit-transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.form-control::placeholder {
  color: #d1d7e0 !important;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control[type='file'] {
  overflow: hidden;
}

.form-control[type='file']:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #66799e;
  background-color: #ffffff;
  border-color: #566190;
  outline: 0;
  -webkit-box-shadow:
    inset 0 1px 2px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow:
    inset 0 1px 2px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-webkit-input-placeholder {
  color: #506690;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #506690;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #506690;
  opacity: 1;
}

.form-control::placeholder {
  color: #506690;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f8fb;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.55rem 0.75rem;
  margin: -0.55rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #66799e;
  background-color: #ffffff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.0625rem;
  border-radius: 0;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}

.form-control::-webkit-file-upload-button {
  padding: 0.55rem 0.75rem;
  margin: -0.55rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #66799e;
  background-color: #ffffff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0.0625rem;
  border-radius: 0;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.55rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #4a5073;
  background-color: transparent;
  border: solid transparent;
  border-width: 0.0625rem 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.625rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.1rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.125rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.225rem);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.625rem);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1.125rem);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.55rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.5rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.5rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.55rem 1.75rem 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  vertical-align: middle;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  -webkit-box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select:focus {
  border-color: #566190;
  outline: 0;
  -webkit-box-shadow:
    inset 0 1px 2px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow:
    inset 0 1px 2px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size='1']) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #93a5be;
  background-color: #f5f8fb;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #66799e;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.625em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.625em;
}

.form-check-input {
  width: 1.125em;
  height: 1.125em;
  margin-top: 0.1875em;
  vertical-align: top;
  background-color: #f5f8fb;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #d1d7e0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  print-color-adjust.: exact;
  -webkit-transition:
    background-color 0.2s ease-in-out,
    background-position 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition:
    background-color 0.2s ease-in-out,
    background-position 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  transition:
    background-color 0.2s ease-in-out,
    background-position 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  transition:
    background-color 0.2s ease-in-out,
    background-position 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}

.form-check-input[type='checkbox'] {
  border-radius: 0.25em;
}

.form-check-input[type='radio'] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #566190;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.form-check-input:checked {
  background-color: #262b40;
  border-color: #262b40;
}

.form-check-input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type='radio'] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-input[type='checkbox']:indeterminate {
  background-color: #262b40;
  border-color: #262b40;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.75em;
}

.form-switch .form-check-input {
  width: 2.25em;
  margin-left: -2.75em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2393a5be'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2.25em;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23566190'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: none;
}

.form-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow:
    0 0 0 1px #f5f8fb,
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow:
    0 0 0 1px #f5f8fb,
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow:
    0 0 0 1px #f5f8fb,
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #262b40;
  border: 0;
  border-radius: 1rem;
  -webkit-box-shadow: 0 0.1rem 0.25rem rgba(46, 54, 80, 0.1);
  box-shadow: 0 0.1rem 0.25rem rgba(46, 54, 80, 0.1);
  -webkit-transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #6f7aaa;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #f0f3f6;
  border-color: transparent;
  border-radius: 1rem;
  -webkit-box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #262b40;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(46, 54, 80, 0.1);
  -webkit-transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #6f7aaa;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #f0f3f6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #d1d7e0;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #d1d7e0;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: 3.625rem;
  padding: 1rem 0.75rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 0.0625rem solid transparent;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition:
    opacity 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
  transition:
    opacity 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
  transition:
    opacity 0.1s ease-in-out,
    transform 0.1s ease-in-out;
  transition:
    opacity 0.1s ease-in-out,
    transform 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    -webkit-transition: none;
    transition: none;
  }
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #93a5be;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.1rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
    .invalid-feedback
  ) {
  margin-left: -0.0625rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #05a677;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 0.55rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(5, 166, 119, 0.99);
  border-radius: 0.5rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #05a677;
  padding-right: calc(1.5em + 1.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2305A677' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.275rem) center;
  background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #05a677;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.1rem);
  background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #05a677;
  padding-right: calc(0.75em + 2.575rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2305A677' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position:
    right 0.75rem center,
    center right 1.75rem;
  background-size:
    16px 12px,
    calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #05a677;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #05a677;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #05a677;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #05a677;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #fa5252;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 0.55rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #ffffff;
  background-color: rgba(250, 82, 82, 0.99);
  border-radius: 0.5rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #fa5252;
  padding-right: calc(1.5em + 1.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FA5252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA5252' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.275rem) center;
  background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #fa5252;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.1rem);
  background-position: top calc(0.375em + 0.275rem) right calc(0.375em + 0.275rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #fa5252;
  padding-right: calc(0.75em + 2.575rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FA5252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FA5252' stroke='none'/%3e%3c/svg%3e");
  background-position:
    right 0.75rem center,
    center right 1.75rem;
  background-size:
    16px 12px,
    calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #fa5252;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #fa5252;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #fa5252;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #fa5252;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.btn {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  color: #4a5073;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover {
  color: #4a5073;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active {
  -webkit-box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
}

.btn-check:checked + .btn:focus,
.btn-check:active + .btn:focus,
.btn:active:focus,
.btn.active:focus {
  -webkit-box-shadow:
    0 0 0 0.2rem rgba(38, 43, 64, 0.25),
    inset 0 3px 5px rgba(46, 54, 80, 0.125);
  box-shadow:
    0 0 0 0.2rem rgba(38, 43, 64, 0.25),
    inset 0 3px 5px rgba(46, 54, 80, 0.125);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-primary:hover {
  color: #ffffff;
  background-color: #202536;
  border-color: #1e2233;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #ffffff;
  background-color: #202536;
  border-color: #1e2233;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(71, 75, 93, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(71, 75, 93, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #1e2233;
  border-color: #1d2030;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(71, 75, 93, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(71, 75, 93, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.btn-secondary {
  color: #262b40;
  background-color: #61dafb;
  border-color: #61dafb;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-secondary:hover {
  color: #262b40;
  background-color: #79e0fc;
  border-color: #71defb;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #262b40;
  background-color: #79e0fc;
  border-color: #71defb;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(88, 192, 223, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(88, 192, 223, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #262b40;
  background-color: #81e1fc;
  border-color: #71defb;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(88, 192, 223, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(88, 192, 223, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #262b40;
  background-color: #61dafb;
  border-color: #61dafb;
}

.btn-tertiary {
  color: #ffffff;
  background-color: #1b998b;
  border-color: #1b998b;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-tertiary:hover {
  color: #ffffff;
  background-color: #178276;
  border-color: #167a6f;
}

.btn-check:focus + .btn-tertiary,
.btn-tertiary:focus {
  color: #ffffff;
  background-color: #178276;
  border-color: #167a6f;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(61, 168, 156, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(61, 168, 156, 0.5);
}

.btn-check:checked + .btn-tertiary,
.btn-check:active + .btn-tertiary,
.btn-tertiary:active,
.btn-tertiary.active,
.show > .btn-tertiary.dropdown-toggle {
  color: #ffffff;
  background-color: #167a6f;
  border-color: #147368;
}

.btn-check:checked + .btn-tertiary:focus,
.btn-check:active + .btn-tertiary:focus,
.btn-tertiary:active:focus,
.btn-tertiary.active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(61, 168, 156, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(61, 168, 156, 0.5);
}

.btn-tertiary:disabled,
.btn-tertiary.disabled {
  color: #ffffff;
  background-color: #1b998b;
  border-color: #1b998b;
}

.btn-quaternary {
  color: #ffffff;
  background-color: #c96480;
  border-color: #c96480;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-quaternary:hover {
  color: #ffffff;
  background-color: #ab556d;
  border-color: #a15066;
}

.btn-check:focus + .btn-quaternary,
.btn-quaternary:focus {
  color: #ffffff;
  background-color: #ab556d;
  border-color: #a15066;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(209, 123, 147, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(209, 123, 147, 0.5);
}

.btn-check:checked + .btn-quaternary,
.btn-check:active + .btn-quaternary,
.btn-quaternary:active,
.btn-quaternary.active,
.show > .btn-quaternary.dropdown-toggle {
  color: #ffffff;
  background-color: #a15066;
  border-color: #974b60;
}

.btn-check:checked + .btn-quaternary:focus,
.btn-check:active + .btn-quaternary:focus,
.btn-quaternary:active:focus,
.btn-quaternary.active:focus,
.show > .btn-quaternary.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(209, 123, 147, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(209, 123, 147, 0.5);
}

.btn-quaternary:disabled,
.btn-quaternary.disabled {
  color: #ffffff;
  background-color: #c96480;
  border-color: #c96480;
}

.btn-primary-app {
  color: #262b40;
  background-color: #ebf4f6;
  border-color: #ebf4f6;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-primary-app:hover {
  color: #262b40;
  background-color: #eef6f7;
  border-color: #edf5f7;
}

.btn-check:focus + .btn-primary-app,
.btn-primary-app:focus {
  color: #262b40;
  background-color: #eef6f7;
  border-color: #edf5f7;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(205, 214, 219, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(205, 214, 219, 0.5);
}

.btn-check:checked + .btn-primary-app,
.btn-check:active + .btn-primary-app,
.btn-primary-app:active,
.btn-primary-app.active,
.show > .btn-primary-app.dropdown-toggle {
  color: #262b40;
  background-color: #eff6f8;
  border-color: #edf5f7;
}

.btn-check:checked + .btn-primary-app:focus,
.btn-check:active + .btn-primary-app:focus,
.btn-primary-app:active:focus,
.btn-primary-app.active:focus,
.show > .btn-primary-app.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(205, 214, 219, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(205, 214, 219, 0.5);
}

.btn-primary-app:disabled,
.btn-primary-app.disabled {
  color: #262b40;
  background-color: #ebf4f6;
  border-color: #ebf4f6;
}

.btn-secondary-app {
  color: #ffffff;
  background-color: #424aa0;
  border-color: #424aa0;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-secondary-app:hover {
  color: #ffffff;
  background-color: #383f88;
  border-color: #353b80;
}

.btn-check:focus + .btn-secondary-app,
.btn-secondary-app:focus {
  color: #ffffff;
  background-color: #383f88;
  border-color: #353b80;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(94, 101, 174, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(94, 101, 174, 0.5);
}

.btn-check:checked + .btn-secondary-app,
.btn-check:active + .btn-secondary-app,
.btn-secondary-app:active,
.btn-secondary-app.active,
.show > .btn-secondary-app.dropdown-toggle {
  color: #ffffff;
  background-color: #353b80;
  border-color: #323878;
}

.btn-check:checked + .btn-secondary-app:focus,
.btn-check:active + .btn-secondary-app:focus,
.btn-secondary-app:active:focus,
.btn-secondary-app.active:focus,
.show > .btn-secondary-app.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(94, 101, 174, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(94, 101, 174, 0.5);
}

.btn-secondary-app:disabled,
.btn-secondary-app.disabled {
  color: #ffffff;
  background-color: #424aa0;
  border-color: #424aa0;
}

.btn-light {
  color: #262b40;
  background-color: #eaedf2;
  border-color: #eaedf2;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-light:hover {
  color: #262b40;
  background-color: #edf0f4;
  border-color: #eceff3;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #262b40;
  background-color: #edf0f4;
  border-color: #eceff3;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(205, 208, 215, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(205, 208, 215, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #262b40;
  background-color: #eef1f5;
  border-color: #eceff3;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(205, 208, 215, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(205, 208, 215, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
  color: #262b40;
  background-color: #eaedf2;
  border-color: #eaedf2;
}

.btn-lighten {
  color: #262b40;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-lighten:hover {
  color: #262b40;
  background-color: #f2f5f7;
  border-color: #f2f4f7;
}

.btn-check:focus + .btn-lighten,
.btn-lighten:focus {
  color: #262b40;
  background-color: #f2f5f7;
  border-color: #f2f4f7;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(210, 213, 219, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(210, 213, 219, 0.5);
}

.btn-check:checked + .btn-lighten,
.btn-check:active + .btn-lighten,
.btn-lighten:active,
.btn-lighten.active,
.show > .btn-lighten.dropdown-toggle {
  color: #262b40;
  background-color: #f3f5f8;
  border-color: #f2f4f7;
}

.btn-check:checked + .btn-lighten:focus,
.btn-check:active + .btn-lighten:focus,
.btn-lighten:active:focus,
.btn-lighten.active:focus,
.show > .btn-lighten.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(210, 213, 219, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(210, 213, 219, 0.5);
}

.btn-lighten:disabled,
.btn-lighten.disabled {
  color: #262b40;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}

.btn-success {
  color: #ffffff;
  background-color: #05a677;
  border-color: #05a677;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-success:hover {
  color: #ffffff;
  background-color: #048d65;
  border-color: #04855f;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #ffffff;
  background-color: #048d65;
  border-color: #04855f;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(43, 179, 139, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(43, 179, 139, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #04855f;
  border-color: #047d59;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(43, 179, 139, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(43, 179, 139, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
  color: #ffffff;
  background-color: #05a677;
  border-color: #05a677;
}

.btn-info {
  color: #ffffff;
  background-color: #0948b3;
  border-color: #0948b3;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-info:hover {
  color: #ffffff;
  background-color: #083d98;
  border-color: #073a8f;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #ffffff;
  background-color: #083d98;
  border-color: #073a8f;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(46, 99, 190, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(46, 99, 190, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #073a8f;
  border-color: #073686;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(46, 99, 190, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(46, 99, 190, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #ffffff;
  background-color: #0948b3;
  border-color: #0948b3;
}

.btn-warning {
  color: #262b40;
  background-color: #f5b759;
  border-color: #f5b759;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-warning:hover {
  color: #262b40;
  background-color: #f7c272;
  border-color: #f6be6a;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #262b40;
  background-color: #f7c272;
  border-color: #f6be6a;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(214, 162, 85, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(214, 162, 85, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #262b40;
  background-color: #f7c57a;
  border-color: #f6be6a;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(214, 162, 85, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(214, 162, 85, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #262b40;
  background-color: #f5b759;
  border-color: #f5b759;
}

.btn-danger {
  color: #ffffff;
  background-color: #fa5252;
  border-color: #fa5252;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-danger:hover {
  color: #ffffff;
  background-color: #d54646;
  border-color: #c84242;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #ffffff;
  background-color: #d54646;
  border-color: #c84242;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(251, 108, 108, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(251, 108, 108, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #c84242;
  border-color: #bc3e3e;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(251, 108, 108, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(251, 108, 108, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #ffffff;
  background-color: #fa5252;
  border-color: #fa5252;
}

.btn-white {
  color: #262b40;
  background-color: #ffffff;
  border-color: #ffffff;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-white:hover {
  color: #262b40;
  background-color: white;
  border-color: white;
}

.btn-check:focus + .btn-white,
.btn-white:focus {
  color: #262b40;
  background-color: white;
  border-color: white;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(222, 223, 226, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(222, 223, 226, 0.5);
}

.btn-check:checked + .btn-white,
.btn-check:active + .btn-white,
.btn-white:active,
.btn-white.active,
.show > .btn-white.dropdown-toggle {
  color: #262b40;
  background-color: white;
  border-color: white;
}

.btn-check:checked + .btn-white:focus,
.btn-check:active + .btn-white:focus,
.btn-white:active:focus,
.btn-white.active:focus,
.show > .btn-white.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(222, 223, 226, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(222, 223, 226, 0.5);
}

.btn-white:disabled,
.btn-white.disabled {
  color: #262b40;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-gray {
  color: #ffffff;
  background-color: #4a5073;
  border-color: #4a5073;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray:hover {
  color: #ffffff;
  background-color: #3f4462;
  border-color: #3b405c;
}

.btn-check:focus + .btn-gray,
.btn-gray:focus {
  color: #ffffff;
  background-color: #3f4462;
  border-color: #3b405c;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(101, 106, 136, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(101, 106, 136, 0.5);
}

.btn-check:checked + .btn-gray,
.btn-check:active + .btn-gray,
.btn-gray:active,
.btn-gray.active,
.show > .btn-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #3b405c;
  border-color: #383c56;
}

.btn-check:checked + .btn-gray:focus,
.btn-check:active + .btn-gray:focus,
.btn-gray:active:focus,
.btn-gray.active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(101, 106, 136, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(101, 106, 136, 0.5);
}

.btn-gray:disabled,
.btn-gray.disabled {
  color: #ffffff;
  background-color: #4a5073;
  border-color: #4a5073;
}

.btn-indigo {
  color: #ffffff;
  background-color: #4c5680;
  border-color: #4c5680;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-indigo:hover {
  color: #ffffff;
  background-color: #41496d;
  border-color: #3d4566;
}

.btn-check:focus + .btn-indigo,
.btn-indigo:focus {
  color: #ffffff;
  background-color: #41496d;
  border-color: #3d4566;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(103, 111, 147, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(103, 111, 147, 0.5);
}

.btn-check:checked + .btn-indigo,
.btn-check:active + .btn-indigo,
.btn-indigo:active,
.btn-indigo.active,
.show > .btn-indigo.dropdown-toggle {
  color: #ffffff;
  background-color: #3d4566;
  border-color: #394160;
}

.btn-check:checked + .btn-indigo:focus,
.btn-check:active + .btn-indigo:focus,
.btn-indigo:active:focus,
.btn-indigo.active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(103, 111, 147, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(103, 111, 147, 0.5);
}

.btn-indigo:disabled,
.btn-indigo.disabled {
  color: #ffffff;
  background-color: #4c5680;
  border-color: #4c5680;
}

.btn-dark {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-dark:hover {
  color: #ffffff;
  background-color: #202536;
  border-color: #1e2233;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #ffffff;
  background-color: #202536;
  border-color: #1e2233;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(71, 75, 93, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(71, 75, 93, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1e2233;
  border-color: #1d2030;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(71, 75, 93, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(71, 75, 93, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.btn-soft {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-soft:hover {
  color: #262b40;
  background-color: #f7f9fc;
  border-color: #f6f9fb;
}

.btn-check:focus + .btn-soft,
.btn-soft:focus {
  color: #262b40;
  background-color: #f7f9fc;
  border-color: #f6f9fb;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(214, 217, 223, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(214, 217, 223, 0.5);
}

.btn-check:checked + .btn-soft,
.btn-check:active + .btn-soft,
.btn-soft:active,
.btn-soft.active,
.show > .btn-soft.dropdown-toggle {
  color: #262b40;
  background-color: #f7f9fc;
  border-color: #f6f9fb;
}

.btn-check:checked + .btn-soft:focus,
.btn-check:active + .btn-soft:focus,
.btn-soft:active:focus,
.btn-soft.active:focus,
.show > .btn-soft.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(214, 217, 223, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(214, 217, 223, 0.5);
}

.btn-soft:disabled,
.btn-soft.disabled {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-black {
  color: #ffffff;
  background-color: #2e3650;
  border-color: #2e3650;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-black:hover {
  color: #ffffff;
  background-color: #272e44;
  border-color: #252b40;
}

.btn-check:focus + .btn-black,
.btn-black:focus {
  color: #ffffff;
  background-color: #272e44;
  border-color: #252b40;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(77, 84, 106, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(77, 84, 106, 0.5);
}

.btn-check:checked + .btn-black,
.btn-check:active + .btn-black,
.btn-black:active,
.btn-black.active,
.show > .btn-black.dropdown-toggle {
  color: #ffffff;
  background-color: #252b40;
  border-color: #23293c;
}

.btn-check:checked + .btn-black:focus,
.btn-check:active + .btn-black:focus,
.btn-black:active:focus,
.btn-black.active:focus,
.show > .btn-black.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(77, 84, 106, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(77, 84, 106, 0.5);
}

.btn-black:disabled,
.btn-black.disabled {
  color: #ffffff;
  background-color: #2e3650;
  border-color: #2e3650;
}

.btn-blue {
  color: #ffffff;
  background-color: #0948b3;
  border-color: #0948b3;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-blue:hover {
  color: #ffffff;
  background-color: #083d98;
  border-color: #073a8f;
}

.btn-check:focus + .btn-blue,
.btn-blue:focus {
  color: #ffffff;
  background-color: #083d98;
  border-color: #073a8f;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(46, 99, 190, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(46, 99, 190, 0.5);
}

.btn-check:checked + .btn-blue,
.btn-check:active + .btn-blue,
.btn-blue:active,
.btn-blue.active,
.show > .btn-blue.dropdown-toggle {
  color: #ffffff;
  background-color: #073a8f;
  border-color: #073686;
}

.btn-check:checked + .btn-blue:focus,
.btn-check:active + .btn-blue:focus,
.btn-blue:active:focus,
.btn-blue.active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(46, 99, 190, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(46, 99, 190, 0.5);
}

.btn-blue:disabled,
.btn-blue.disabled {
  color: #ffffff;
  background-color: #0948b3;
  border-color: #0948b3;
}

.btn-pink {
  color: #ffffff;
  background-color: #c96480;
  border-color: #c96480;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-pink:hover {
  color: #ffffff;
  background-color: #ab556d;
  border-color: #a15066;
}

.btn-check:focus + .btn-pink,
.btn-pink:focus {
  color: #ffffff;
  background-color: #ab556d;
  border-color: #a15066;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(209, 123, 147, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(209, 123, 147, 0.5);
}

.btn-check:checked + .btn-pink,
.btn-check:active + .btn-pink,
.btn-pink:active,
.btn-pink.active,
.show > .btn-pink.dropdown-toggle {
  color: #ffffff;
  background-color: #a15066;
  border-color: #974b60;
}

.btn-check:checked + .btn-pink:focus,
.btn-check:active + .btn-pink:focus,
.btn-pink:active:focus,
.btn-pink.active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(209, 123, 147, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(209, 123, 147, 0.5);
}

.btn-pink:disabled,
.btn-pink.disabled {
  color: #ffffff;
  background-color: #c96480;
  border-color: #c96480;
}

.btn-cyan {
  color: #262b40;
  background-color: #63b1bd;
  border-color: #63b1bd;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-cyan:hover {
  color: #262b40;
  background-color: #7abdc7;
  border-color: #73b9c4;
}

.btn-check:focus + .btn-cyan,
.btn-cyan:focus {
  color: #262b40;
  background-color: #7abdc7;
  border-color: #73b9c4;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(90, 157, 170, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(90, 157, 170, 0.5);
}

.btn-check:checked + .btn-cyan,
.btn-check:active + .btn-cyan,
.btn-cyan:active,
.btn-cyan.active,
.show > .btn-cyan.dropdown-toggle {
  color: #262b40;
  background-color: #82c1ca;
  border-color: #73b9c4;
}

.btn-check:checked + .btn-cyan:focus,
.btn-check:active + .btn-cyan:focus,
.btn-cyan:active:focus,
.btn-cyan.active:focus,
.show > .btn-cyan.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(90, 157, 170, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(90, 157, 170, 0.5);
}

.btn-cyan:disabled,
.btn-cyan.disabled {
  color: #262b40;
  background-color: #63b1bd;
  border-color: #63b1bd;
}

.btn-purple {
  color: #ffffff;
  background-color: #8965e0;
  border-color: #8965e0;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-purple:hover {
  color: #ffffff;
  background-color: #7456be;
  border-color: #6e51b3;
}

.btn-check:focus + .btn-purple,
.btn-purple:focus {
  color: #ffffff;
  background-color: #7456be;
  border-color: #6e51b3;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(155, 124, 229, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(155, 124, 229, 0.5);
}

.btn-check:checked + .btn-purple,
.btn-check:active + .btn-purple,
.btn-purple:active,
.btn-purple.active,
.show > .btn-purple.dropdown-toggle {
  color: #ffffff;
  background-color: #6e51b3;
  border-color: #674ca8;
}

.btn-check:checked + .btn-purple:focus,
.btn-check:active + .btn-purple:focus,
.btn-purple:active:focus,
.btn-purple.active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(155, 124, 229, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(155, 124, 229, 0.5);
}

.btn-purple:disabled,
.btn-purple.disabled {
  color: #ffffff;
  background-color: #8965e0;
  border-color: #8965e0;
}

.btn-soft-indigo {
  color: #262b40;
  background-color: #f5e8ff;
  border-color: #f5e8ff;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-soft-indigo:hover {
  color: #262b40;
  background-color: #f7ebff;
  border-color: #f6eaff;
}

.btn-check:focus + .btn-soft-indigo,
.btn-soft-indigo:focus {
  color: #262b40;
  background-color: #f7ebff;
  border-color: #f6eaff;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(214, 204, 226, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(214, 204, 226, 0.5);
}

.btn-check:checked + .btn-soft-indigo,
.btn-check:active + .btn-soft-indigo,
.btn-soft-indigo:active,
.btn-soft-indigo.active,
.show > .btn-soft-indigo.dropdown-toggle {
  color: #262b40;
  background-color: #f7edff;
  border-color: #f6eaff;
}

.btn-check:checked + .btn-soft-indigo:focus,
.btn-check:active + .btn-soft-indigo:focus,
.btn-soft-indigo:active:focus,
.btn-soft-indigo.active:focus,
.show > .btn-soft-indigo.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(214, 204, 226, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(214, 204, 226, 0.5);
}

.btn-soft-indigo:disabled,
.btn-soft-indigo.disabled {
  color: #262b40;
  background-color: #f5e8ff;
  border-color: #f5e8ff;
}

.btn-soft-green {
  color: #ffffff;
  background-color: #2ca58d;
  border-color: #2ca58d;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-soft-green:hover {
  color: #ffffff;
  background-color: #258c78;
  border-color: #238471;
}

.btn-check:focus + .btn-soft-green,
.btn-soft-green:focus {
  color: #ffffff;
  background-color: #258c78;
  border-color: #238471;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(76, 179, 158, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(76, 179, 158, 0.5);
}

.btn-check:checked + .btn-soft-green,
.btn-check:active + .btn-soft-green,
.btn-soft-green:active,
.btn-soft-green.active,
.show > .btn-soft-green.dropdown-toggle {
  color: #ffffff;
  background-color: #238471;
  border-color: #217c6a;
}

.btn-check:checked + .btn-soft-green:focus,
.btn-check:active + .btn-soft-green:focus,
.btn-soft-green:active:focus,
.btn-soft-green.active:focus,
.show > .btn-soft-green.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(76, 179, 158, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(76, 179, 158, 0.5);
}

.btn-soft-green:disabled,
.btn-soft-green.disabled {
  color: #ffffff;
  background-color: #2ca58d;
  border-color: #2ca58d;
}

.btn-gray-100 {
  color: #262b40;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray-100:hover {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f4f8fb;
}

.btn-check:focus + .btn-gray-100,
.btn-gray-100:focus {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f4f8fb;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(212, 216, 222, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(212, 216, 222, 0.5);
}

.btn-check:checked + .btn-gray-100,
.btn-check:active + .btn-gray-100,
.btn-gray-100:active,
.btn-gray-100.active,
.show > .btn-gray-100.dropdown-toggle {
  color: #262b40;
  background-color: #f5f9fb;
  border-color: #f4f8fb;
}

.btn-check:checked + .btn-gray-100:focus,
.btn-check:active + .btn-gray-100:focus,
.btn-gray-100:active:focus,
.btn-gray-100.active:focus,
.show > .btn-gray-100.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(212, 216, 222, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(212, 216, 222, 0.5);
}

.btn-gray-100:disabled,
.btn-gray-100.disabled {
  color: #262b40;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}

.btn-gray-200 {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray-200:hover {
  color: #262b40;
  background-color: #f7f9fc;
  border-color: #f6f9fb;
}

.btn-check:focus + .btn-gray-200,
.btn-gray-200:focus {
  color: #262b40;
  background-color: #f7f9fc;
  border-color: #f6f9fb;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(214, 217, 223, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(214, 217, 223, 0.5);
}

.btn-check:checked + .btn-gray-200,
.btn-check:active + .btn-gray-200,
.btn-gray-200:active,
.btn-gray-200.active,
.show > .btn-gray-200.dropdown-toggle {
  color: #262b40;
  background-color: #f7f9fc;
  border-color: #f6f9fb;
}

.btn-check:checked + .btn-gray-200:focus,
.btn-check:active + .btn-gray-200:focus,
.btn-gray-200:active:focus,
.btn-gray-200.active:focus,
.show > .btn-gray-200.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(214, 217, 223, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(214, 217, 223, 0.5);
}

.btn-gray-200:disabled,
.btn-gray-200.disabled {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-gray-300 {
  color: #262b40;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray-300:hover {
  color: #262b40;
  background-color: #f2f5f7;
  border-color: #f2f4f7;
}

.btn-check:focus + .btn-gray-300,
.btn-gray-300:focus {
  color: #262b40;
  background-color: #f2f5f7;
  border-color: #f2f4f7;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(210, 213, 219, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(210, 213, 219, 0.5);
}

.btn-check:checked + .btn-gray-300,
.btn-check:active + .btn-gray-300,
.btn-gray-300:active,
.btn-gray-300.active,
.show > .btn-gray-300.dropdown-toggle {
  color: #262b40;
  background-color: #f3f5f8;
  border-color: #f2f4f7;
}

.btn-check:checked + .btn-gray-300:focus,
.btn-check:active + .btn-gray-300:focus,
.btn-gray-300:active:focus,
.btn-gray-300.active:focus,
.show > .btn-gray-300.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(210, 213, 219, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(210, 213, 219, 0.5);
}

.btn-gray-300:disabled,
.btn-gray-300.disabled {
  color: #262b40;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}

.btn-gray-400 {
  color: #262b40;
  background-color: #eaedf2;
  border-color: #eaedf2;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray-400:hover {
  color: #262b40;
  background-color: #edf0f4;
  border-color: #eceff3;
}

.btn-check:focus + .btn-gray-400,
.btn-gray-400:focus {
  color: #262b40;
  background-color: #edf0f4;
  border-color: #eceff3;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(205, 208, 215, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(205, 208, 215, 0.5);
}

.btn-check:checked + .btn-gray-400,
.btn-check:active + .btn-gray-400,
.btn-gray-400:active,
.btn-gray-400.active,
.show > .btn-gray-400.dropdown-toggle {
  color: #262b40;
  background-color: #eef1f5;
  border-color: #eceff3;
}

.btn-check:checked + .btn-gray-400:focus,
.btn-check:active + .btn-gray-400:focus,
.btn-gray-400:active:focus,
.btn-gray-400.active:focus,
.show > .btn-gray-400.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(205, 208, 215, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(205, 208, 215, 0.5);
}

.btn-gray-400:disabled,
.btn-gray-400.disabled {
  color: #262b40;
  background-color: #eaedf2;
  border-color: #eaedf2;
}

.btn-gray-500 {
  color: #262b40;
  background-color: #d1d7e0;
  border-color: #d1d7e0;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray-500:hover {
  color: #262b40;
  background-color: #d8dde5;
  border-color: #d6dbe3;
}

.btn-check:focus + .btn-gray-500,
.btn-gray-500:focus {
  color: #262b40;
  background-color: #d8dde5;
  border-color: #d6dbe3;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(183, 189, 200, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(183, 189, 200, 0.5);
}

.btn-check:checked + .btn-gray-500,
.btn-check:active + .btn-gray-500,
.btn-gray-500:active,
.btn-gray-500.active,
.show > .btn-gray-500.dropdown-toggle {
  color: #262b40;
  background-color: #dadfe6;
  border-color: #d6dbe3;
}

.btn-check:checked + .btn-gray-500:focus,
.btn-check:active + .btn-gray-500:focus,
.btn-gray-500:active:focus,
.btn-gray-500.active:focus,
.show > .btn-gray-500.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(183, 189, 200, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(183, 189, 200, 0.5);
}

.btn-gray-500:disabled,
.btn-gray-500.disabled {
  color: #262b40;
  background-color: #d1d7e0;
  border-color: #d1d7e0;
}

.btn-gray-600 {
  color: #262b40;
  background-color: #93a5be;
  border-color: #93a5be;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray-600:hover {
  color: #262b40;
  background-color: #a3b3c8;
  border-color: #9eaec5;
}

.btn-check:focus + .btn-gray-600,
.btn-gray-600:focus {
  color: #262b40;
  background-color: #a3b3c8;
  border-color: #9eaec5;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(131, 147, 171, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(131, 147, 171, 0.5);
}

.btn-check:checked + .btn-gray-600,
.btn-check:active + .btn-gray-600,
.btn-gray-600:active,
.btn-gray-600.active,
.show > .btn-gray-600.dropdown-toggle {
  color: #262b40;
  background-color: #a9b7cb;
  border-color: #9eaec5;
}

.btn-check:checked + .btn-gray-600:focus,
.btn-check:active + .btn-gray-600:focus,
.btn-gray-600:active:focus,
.btn-gray-600.active:focus,
.show > .btn-gray-600.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(131, 147, 171, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(131, 147, 171, 0.5);
}

.btn-gray-600:disabled,
.btn-gray-600.disabled {
  color: #262b40;
  background-color: #93a5be;
  border-color: #93a5be;
}

.btn-gray-700 {
  color: #ffffff;
  background-color: #66799e;
  border-color: #66799e;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray-700:hover {
  color: #ffffff;
  background-color: #576786;
  border-color: #52617e;
}

.btn-check:focus + .btn-gray-700,
.btn-gray-700:focus {
  color: #ffffff;
  background-color: #576786;
  border-color: #52617e;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(125, 141, 173, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(125, 141, 173, 0.5);
}

.btn-check:checked + .btn-gray-700,
.btn-check:active + .btn-gray-700,
.btn-gray-700:active,
.btn-gray-700.active,
.show > .btn-gray-700.dropdown-toggle {
  color: #ffffff;
  background-color: #52617e;
  border-color: #4d5b77;
}

.btn-check:checked + .btn-gray-700:focus,
.btn-check:active + .btn-gray-700:focus,
.btn-gray-700:active:focus,
.btn-gray-700.active:focus,
.show > .btn-gray-700.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(125, 141, 173, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(125, 141, 173, 0.5);
}

.btn-gray-700:disabled,
.btn-gray-700.disabled {
  color: #ffffff;
  background-color: #66799e;
  border-color: #66799e;
}

.btn-gray-800 {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-gray-800:hover {
  color: #ffffff;
  background-color: #44577a;
  border-color: #405273;
}

.btn-check:focus + .btn-gray-800,
.btn-gray-800:focus {
  color: #ffffff;
  background-color: #44577a;
  border-color: #405273;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(106, 125, 161, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(106, 125, 161, 0.5);
}

.btn-check:checked + .btn-gray-800,
.btn-check:active + .btn-gray-800,
.btn-gray-800:active,
.btn-gray-800.active,
.show > .btn-gray-800.dropdown-toggle {
  color: #ffffff;
  background-color: #405273;
  border-color: #3c4d6c;
}

.btn-check:checked + .btn-gray-800:focus,
.btn-check:active + .btn-gray-800:focus,
.btn-gray-800:active:focus,
.btn-gray-800.active:focus,
.show > .btn-gray-800.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(106, 125, 161, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(106, 125, 161, 0.5);
}

.btn-gray-800:disabled,
.btn-gray-800.disabled {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
}

.btn-facebook {
  color: #ffffff;
  background-color: #3b5999;
  border-color: #3b5999;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-facebook:hover {
  color: #ffffff;
  background-color: #324c82;
  border-color: #2f477a;
}

.btn-check:focus + .btn-facebook,
.btn-facebook:focus {
  color: #ffffff;
  background-color: #324c82;
  border-color: #2f477a;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(88, 114, 168, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(88, 114, 168, 0.5);
}

.btn-check:checked + .btn-facebook,
.btn-check:active + .btn-facebook,
.btn-facebook:active,
.btn-facebook.active,
.show > .btn-facebook.dropdown-toggle {
  color: #ffffff;
  background-color: #2f477a;
  border-color: #2c4373;
}

.btn-check:checked + .btn-facebook:focus,
.btn-check:active + .btn-facebook:focus,
.btn-facebook:active:focus,
.btn-facebook.active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(88, 114, 168, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(88, 114, 168, 0.5);
}

.btn-facebook:disabled,
.btn-facebook.disabled {
  color: #ffffff;
  background-color: #3b5999;
  border-color: #3b5999;
}

.btn-twitter {
  color: #262b40;
  background-color: #1da1f2;
  border-color: #1da1f2;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-twitter:hover {
  color: #262b40;
  background-color: #3faff4;
  border-color: #34aaf3;
}

.btn-check:focus + .btn-twitter,
.btn-twitter:focus {
  color: #262b40;
  background-color: #3faff4;
  border-color: #34aaf3;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(30, 143, 215, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(30, 143, 215, 0.5);
}

.btn-check:checked + .btn-twitter,
.btn-check:active + .btn-twitter,
.btn-twitter:active,
.btn-twitter.active,
.show > .btn-twitter.dropdown-toggle {
  color: #262b40;
  background-color: #4ab4f5;
  border-color: #34aaf3;
}

.btn-check:checked + .btn-twitter:focus,
.btn-check:active + .btn-twitter:focus,
.btn-twitter:active:focus,
.btn-twitter.active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(30, 143, 215, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(30, 143, 215, 0.5);
}

.btn-twitter:disabled,
.btn-twitter.disabled {
  color: #262b40;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-google {
  color: #ffffff;
  background-color: #db4337;
  border-color: #db4337;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-google:hover {
  color: #ffffff;
  background-color: #ba392f;
  border-color: #af362c;
}

.btn-check:focus + .btn-google,
.btn-google:focus {
  color: #ffffff;
  background-color: #ba392f;
  border-color: #af362c;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(224, 95, 85, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(224, 95, 85, 0.5);
}

.btn-check:checked + .btn-google,
.btn-check:active + .btn-google,
.btn-google:active,
.btn-google.active,
.show > .btn-google.dropdown-toggle {
  color: #ffffff;
  background-color: #af362c;
  border-color: #a43229;
}

.btn-check:checked + .btn-google:focus,
.btn-check:active + .btn-google:focus,
.btn-google:active:focus,
.btn-google.active:focus,
.show > .btn-google.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(224, 95, 85, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(224, 95, 85, 0.5);
}

.btn-google:disabled,
.btn-google.disabled {
  color: #ffffff;
  background-color: #db4337;
  border-color: #db4337;
}

.btn-instagram {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-instagram:hover {
  color: #ffffff;
  background-color: #c23651;
  border-color: #b6334c;
}

.btn-check:focus + .btn-instagram,
.btn-instagram:focus {
  color: #ffffff;
  background-color: #c23651;
  border-color: #b6334c;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(232, 93, 119, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(232, 93, 119, 0.5);
}

.btn-check:checked + .btn-instagram,
.btn-check:active + .btn-instagram,
.btn-instagram:active,
.btn-instagram.active,
.show > .btn-instagram.dropdown-toggle {
  color: #ffffff;
  background-color: #b6334c;
  border-color: #ab3047;
}

.btn-check:checked + .btn-instagram:focus,
.btn-check:active + .btn-instagram:focus,
.btn-instagram:active:focus,
.btn-instagram.active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(232, 93, 119, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(232, 93, 119, 0.5);
}

.btn-instagram:disabled,
.btn-instagram.disabled {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f;
}

.btn-pinterest {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-pinterest:hover {
  color: #ffffff;
  background-color: #a10718;
  border-color: #970616;
}

.btn-check:focus + .btn-pinterest,
.btn-pinterest:focus {
  color: #ffffff;
  background-color: #a10718;
  border-color: #970616;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(199, 45, 62, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(199, 45, 62, 0.5);
}

.btn-check:checked + .btn-pinterest,
.btn-check:active + .btn-pinterest,
.btn-pinterest:active,
.btn-pinterest.active,
.show > .btn-pinterest.dropdown-toggle {
  color: #ffffff;
  background-color: #970616;
  border-color: #8e0615;
}

.btn-check:checked + .btn-pinterest:focus,
.btn-check:active + .btn-pinterest:focus,
.btn-pinterest:active:focus,
.btn-pinterest.active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(199, 45, 62, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(199, 45, 62, 0.5);
}

.btn-pinterest:disabled,
.btn-pinterest.disabled {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c;
}

.btn-youtube {
  color: #ffffff;
  background-color: #cd201f;
  border-color: #cd201f;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-youtube:hover {
  color: #ffffff;
  background-color: #ae1b1a;
  border-color: #a41a19;
}

.btn-check:focus + .btn-youtube,
.btn-youtube:focus {
  color: #ffffff;
  background-color: #ae1b1a;
  border-color: #a41a19;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(213, 65, 65, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(213, 65, 65, 0.5);
}

.btn-check:checked + .btn-youtube,
.btn-check:active + .btn-youtube,
.btn-youtube:active,
.btn-youtube.active,
.show > .btn-youtube.dropdown-toggle {
  color: #ffffff;
  background-color: #a41a19;
  border-color: #9a1817;
}

.btn-check:checked + .btn-youtube:focus,
.btn-check:active + .btn-youtube:focus,
.btn-youtube:active:focus,
.btn-youtube.active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(213, 65, 65, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(213, 65, 65, 0.5);
}

.btn-youtube:disabled,
.btn-youtube.disabled {
  color: #ffffff;
  background-color: #cd201f;
  border-color: #cd201f;
}

.btn-slack {
  color: #262b40;
  background-color: #3aaf85;
  border-color: #3aaf85;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-slack:hover {
  color: #262b40;
  background-color: #58bb97;
  border-color: #4eb791;
}

.btn-check:focus + .btn-slack,
.btn-slack:focus {
  color: #262b40;
  background-color: #58bb97;
  border-color: #4eb791;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(55, 155, 123, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(55, 155, 123, 0.5);
}

.btn-check:checked + .btn-slack,
.btn-check:active + .btn-slack,
.btn-slack:active,
.btn-slack.active,
.show > .btn-slack.dropdown-toggle {
  color: #262b40;
  background-color: #61bf9d;
  border-color: #4eb791;
}

.btn-check:checked + .btn-slack:focus,
.btn-check:active + .btn-slack:focus,
.btn-slack:active:focus,
.btn-slack.active:focus,
.show > .btn-slack.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(55, 155, 123, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(55, 155, 123, 0.5);
}

.btn-slack:disabled,
.btn-slack.disabled {
  color: #262b40;
  background-color: #3aaf85;
  border-color: #3aaf85;
}

.btn-dribbble {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-dribbble:hover {
  color: #ffffff;
  background-color: #c74174;
  border-color: #bb3d6e;
}

.btn-check:focus + .btn-dribbble,
.btn-dribbble:focus {
  color: #ffffff;
  background-color: #c74174;
  border-color: #bb3d6e;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(237, 103, 155, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(237, 103, 155, 0.5);
}

.btn-check:checked + .btn-dribbble,
.btn-check:active + .btn-dribbble,
.btn-dribbble:active,
.btn-dribbble.active,
.show > .btn-dribbble.dropdown-toggle {
  color: #ffffff;
  background-color: #bb3d6e;
  border-color: #b03967;
}

.btn-check:checked + .btn-dribbble:focus,
.btn-check:active + .btn-dribbble:focus,
.btn-dribbble:active:focus,
.btn-dribbble.active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(237, 103, 155, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(237, 103, 155, 0.5);
}

.btn-dribbble:disabled,
.btn-dribbble.disabled {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.btn-dropbox {
  color: #ffffff;
  background-color: #1e90ff;
  border-color: #1e90ff;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-dropbox:hover {
  color: #ffffff;
  background-color: #1a7ad9;
  border-color: #1873cc;
}

.btn-check:focus + .btn-dropbox,
.btn-dropbox:focus {
  color: #ffffff;
  background-color: #1a7ad9;
  border-color: #1873cc;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(64, 161, 255, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(64, 161, 255, 0.5);
}

.btn-check:checked + .btn-dropbox,
.btn-check:active + .btn-dropbox,
.btn-dropbox:active,
.btn-dropbox.active,
.show > .btn-dropbox.dropdown-toggle {
  color: #ffffff;
  background-color: #1873cc;
  border-color: #176cbf;
}

.btn-check:checked + .btn-dropbox:focus,
.btn-check:active + .btn-dropbox:focus,
.btn-dropbox:active:focus,
.btn-dropbox.active:focus,
.show > .btn-dropbox.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(64, 161, 255, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(64, 161, 255, 0.5);
}

.btn-dropbox:disabled,
.btn-dropbox.disabled {
  color: #ffffff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}

.btn-twitch {
  color: #ffffff;
  background-color: #4b367c;
  border-color: #4b367c;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-twitch:hover {
  color: #ffffff;
  background-color: #402e69;
  border-color: #3c2b63;
}

.btn-check:focus + .btn-twitch,
.btn-twitch:focus {
  color: #ffffff;
  background-color: #402e69;
  border-color: #3c2b63;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(102, 84, 144, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(102, 84, 144, 0.5);
}

.btn-check:checked + .btn-twitch,
.btn-check:active + .btn-twitch,
.btn-twitch:active,
.btn-twitch.active,
.show > .btn-twitch.dropdown-toggle {
  color: #ffffff;
  background-color: #3c2b63;
  border-color: #38295d;
}

.btn-check:checked + .btn-twitch:focus,
.btn-check:active + .btn-twitch:focus,
.btn-twitch:active:focus,
.btn-twitch.active:focus,
.show > .btn-twitch.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(102, 84, 144, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(102, 84, 144, 0.5);
}

.btn-twitch:disabled,
.btn-twitch.disabled {
  color: #ffffff;
  background-color: #4b367c;
  border-color: #4b367c;
}

.btn-paypal {
  color: #262b40;
  background-color: #ecb32c;
  border-color: #ecb32c;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-paypal:hover {
  color: #262b40;
  background-color: #efbe4c;
  border-color: #eebb41;
}

.btn-check:focus + .btn-paypal,
.btn-paypal:focus {
  color: #262b40;
  background-color: #efbe4c;
  border-color: #eebb41;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(206, 159, 47, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(206, 159, 47, 0.5);
}

.btn-check:checked + .btn-paypal,
.btn-check:active + .btn-paypal,
.btn-paypal:active,
.btn-paypal.active,
.show > .btn-paypal.dropdown-toggle {
  color: #262b40;
  background-color: #f0c256;
  border-color: #eebb41;
}

.btn-check:checked + .btn-paypal:focus,
.btn-check:active + .btn-paypal:focus,
.btn-paypal:active:focus,
.btn-paypal.active:focus,
.show > .btn-paypal.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(206, 159, 47, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(206, 159, 47, 0.5);
}

.btn-paypal:disabled,
.btn-paypal.disabled {
  color: #262b40;
  background-color: #ecb32c;
  border-color: #ecb32c;
}

.btn-behance {
  color: #ffffff;
  background-color: #0057ff;
  border-color: #0057ff;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-behance:hover {
  color: #ffffff;
  background-color: #004ad9;
  border-color: #0046cc;
}

.btn-check:focus + .btn-behance,
.btn-behance:focus {
  color: #ffffff;
  background-color: #004ad9;
  border-color: #0046cc;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 112, 255, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 112, 255, 0.5);
}

.btn-check:checked + .btn-behance,
.btn-check:active + .btn-behance,
.btn-behance:active,
.btn-behance.active,
.show > .btn-behance.dropdown-toggle {
  color: #ffffff;
  background-color: #0046cc;
  border-color: #0041bf;
}

.btn-check:checked + .btn-behance:focus,
.btn-check:active + .btn-behance:focus,
.btn-behance:active:focus,
.btn-behance.active:focus,
.show > .btn-behance.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(38, 112, 255, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(38, 112, 255, 0.5);
}

.btn-behance:disabled,
.btn-behance.disabled {
  color: #ffffff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-reddit {
  color: #ffffff;
  background-color: #e84422;
  border-color: #e84422;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-reddit:hover {
  color: #ffffff;
  background-color: #c53a1d;
  border-color: #ba361b;
}

.btn-check:focus + .btn-reddit,
.btn-reddit:focus {
  color: #ffffff;
  background-color: #c53a1d;
  border-color: #ba361b;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(235, 96, 67, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(235, 96, 67, 0.5);
}

.btn-check:checked + .btn-reddit,
.btn-check:active + .btn-reddit,
.btn-reddit:active,
.btn-reddit.active,
.show > .btn-reddit.dropdown-toggle {
  color: #ffffff;
  background-color: #ba361b;
  border-color: #ae331a;
}

.btn-check:checked + .btn-reddit:focus,
.btn-check:active + .btn-reddit:focus,
.btn-reddit:active:focus,
.btn-reddit.active:focus,
.show > .btn-reddit.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(235, 96, 67, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(235, 96, 67, 0.5);
}

.btn-reddit:disabled,
.btn-reddit.disabled {
  color: #ffffff;
  background-color: #e84422;
  border-color: #e84422;
}

.btn-github {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075);
}

.btn-github:hover {
  color: #ffffff;
  background-color: #1d1d1d;
  border-color: #1b1b1b;
}

.btn-check:focus + .btn-github,
.btn-github:focus {
  color: #ffffff;
  background-color: #1d1d1d;
  border-color: #1b1b1b;
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(67, 67, 67, 0.5);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}

.btn-check:checked + .btn-github,
.btn-check:active + .btn-github,
.btn-github:active,
.btn-github.active,
.show > .btn-github.dropdown-toggle {
  color: #ffffff;
  background-color: #1b1b1b;
  border-color: #1a1a1a;
}

.btn-check:checked + .btn-github:focus,
.btn-check:active + .btn-github:focus,
.btn-github:active:focus,
.btn-github.active:focus,
.show > .btn-github.dropdown-toggle:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(67, 67, 67, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}

.btn-github:disabled,
.btn-github.disabled {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

.btn-outline-primary {
  color: #262b40;
  border-color: #262b40;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(38, 43, 64, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(38, 43, 64, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #262b40;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #61dafb;
  border-color: #61dafb;
}

.btn-outline-secondary:hover {
  color: #262b40;
  background-color: #61dafb;
  border-color: #61dafb;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(97, 218, 251, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(97, 218, 251, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #262b40;
  background-color: #61dafb;
  border-color: #61dafb;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(97, 218, 251, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(97, 218, 251, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #61dafb;
  background-color: transparent;
}

.btn-outline-tertiary {
  color: #1b998b;
  border-color: #1b998b;
}

.btn-outline-tertiary:hover {
  color: #ffffff;
  background-color: #1b998b;
  border-color: #1b998b;
}

.btn-check:focus + .btn-outline-tertiary,
.btn-outline-tertiary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(27, 153, 139, 0.5);
}

.btn-check:checked + .btn-outline-tertiary,
.btn-check:active + .btn-outline-tertiary,
.btn-outline-tertiary:active,
.btn-outline-tertiary.active,
.btn-outline-tertiary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #1b998b;
  border-color: #1b998b;
}

.btn-check:checked + .btn-outline-tertiary:focus,
.btn-check:active + .btn-outline-tertiary:focus,
.btn-outline-tertiary:active:focus,
.btn-outline-tertiary.active:focus,
.btn-outline-tertiary.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(27, 153, 139, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(27, 153, 139, 0.5);
}

.btn-outline-tertiary:disabled,
.btn-outline-tertiary.disabled {
  color: #1b998b;
  background-color: transparent;
}

.btn-outline-quaternary {
  color: #c96480;
  border-color: #c96480;
}

.btn-outline-quaternary:hover {
  color: #ffffff;
  background-color: #c96480;
  border-color: #c96480;
}

.btn-check:focus + .btn-outline-quaternary,
.btn-outline-quaternary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
}

.btn-check:checked + .btn-outline-quaternary,
.btn-check:active + .btn-outline-quaternary,
.btn-outline-quaternary:active,
.btn-outline-quaternary.active,
.btn-outline-quaternary.dropdown-toggle.show {
  color: #ffffff;
  background-color: #c96480;
  border-color: #c96480;
}

.btn-check:checked + .btn-outline-quaternary:focus,
.btn-check:active + .btn-outline-quaternary:focus,
.btn-outline-quaternary:active:focus,
.btn-outline-quaternary.active:focus,
.btn-outline-quaternary.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(201, 100, 128, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(201, 100, 128, 0.5);
}

.btn-outline-quaternary:disabled,
.btn-outline-quaternary.disabled {
  color: #c96480;
  background-color: transparent;
}

.btn-outline-primary-app {
  color: #ebf4f6;
  border-color: #ebf4f6;
}

.btn-outline-primary-app:hover {
  color: #262b40;
  background-color: #ebf4f6;
  border-color: #ebf4f6;
}

.btn-check:focus + .btn-outline-primary-app,
.btn-outline-primary-app:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(235, 244, 246, 0.5);
}

.btn-check:checked + .btn-outline-primary-app,
.btn-check:active + .btn-outline-primary-app,
.btn-outline-primary-app:active,
.btn-outline-primary-app.active,
.btn-outline-primary-app.dropdown-toggle.show {
  color: #262b40;
  background-color: #ebf4f6;
  border-color: #ebf4f6;
}

.btn-check:checked + .btn-outline-primary-app:focus,
.btn-check:active + .btn-outline-primary-app:focus,
.btn-outline-primary-app:active:focus,
.btn-outline-primary-app.active:focus,
.btn-outline-primary-app.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(235, 244, 246, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(235, 244, 246, 0.5);
}

.btn-outline-primary-app:disabled,
.btn-outline-primary-app.disabled {
  color: #ebf4f6;
  background-color: transparent;
}

.btn-outline-secondary-app {
  color: #424aa0;
  border-color: #424aa0;
}

.btn-outline-secondary-app:hover {
  color: #ffffff;
  background-color: #424aa0;
  border-color: #424aa0;
}

.btn-check:focus + .btn-outline-secondary-app,
.btn-outline-secondary-app:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(66, 74, 160, 0.5);
}

.btn-check:checked + .btn-outline-secondary-app,
.btn-check:active + .btn-outline-secondary-app,
.btn-outline-secondary-app:active,
.btn-outline-secondary-app.active,
.btn-outline-secondary-app.dropdown-toggle.show {
  color: #ffffff;
  background-color: #424aa0;
  border-color: #424aa0;
}

.btn-check:checked + .btn-outline-secondary-app:focus,
.btn-check:active + .btn-outline-secondary-app:focus,
.btn-outline-secondary-app:active:focus,
.btn-outline-secondary-app.active:focus,
.btn-outline-secondary-app.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(66, 74, 160, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(66, 74, 160, 0.5);
}

.btn-outline-secondary-app:disabled,
.btn-outline-secondary-app.disabled {
  color: #424aa0;
  background-color: transparent;
}

.btn-outline-light {
  color: #eaedf2;
  border-color: #eaedf2;
}

.btn-outline-light:hover {
  color: #262b40;
  background-color: #eaedf2;
  border-color: #eaedf2;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #262b40;
  background-color: #eaedf2;
  border-color: #eaedf2;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(234, 237, 242, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(234, 237, 242, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #eaedf2;
  background-color: transparent;
}

.btn-outline-lighten {
  color: #f0f3f6;
  border-color: #f0f3f6;
}

.btn-outline-lighten:hover {
  color: #262b40;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}

.btn-check:focus + .btn-outline-lighten,
.btn-outline-lighten:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
}

.btn-check:checked + .btn-outline-lighten,
.btn-check:active + .btn-outline-lighten,
.btn-outline-lighten:active,
.btn-outline-lighten.active,
.btn-outline-lighten.dropdown-toggle.show {
  color: #262b40;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}

.btn-check:checked + .btn-outline-lighten:focus,
.btn-check:active + .btn-outline-lighten:focus,
.btn-outline-lighten:active:focus,
.btn-outline-lighten.active:focus,
.btn-outline-lighten.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(240, 243, 246, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(240, 243, 246, 0.5);
}

.btn-outline-lighten:disabled,
.btn-outline-lighten.disabled {
  color: #f0f3f6;
  background-color: transparent;
}

.btn-outline-success {
  color: #05a677;
  border-color: #05a677;
}

.btn-outline-success:hover {
  color: #ffffff;
  background-color: #05a677;
  border-color: #05a677;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(5, 166, 119, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #ffffff;
  background-color: #05a677;
  border-color: #05a677;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(5, 166, 119, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(5, 166, 119, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #05a677;
  background-color: transparent;
}

.btn-outline-info {
  color: #0948b3;
  border-color: #0948b3;
}

.btn-outline-info:hover {
  color: #ffffff;
  background-color: #0948b3;
  border-color: #0948b3;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #ffffff;
  background-color: #0948b3;
  border-color: #0948b3;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(9, 72, 179, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(9, 72, 179, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #0948b3;
  background-color: transparent;
}

.btn-outline-warning {
  color: #f5b759;
  border-color: #f5b759;
}

.btn-outline-warning:hover {
  color: #262b40;
  background-color: #f5b759;
  border-color: #f5b759;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(245, 183, 89, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #262b40;
  background-color: #f5b759;
  border-color: #f5b759;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(245, 183, 89, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(245, 183, 89, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #f5b759;
  background-color: transparent;
}

.btn-outline-danger {
  color: #fa5252;
  border-color: #fa5252;
}

.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #fa5252;
  border-color: #fa5252;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(250, 82, 82, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #ffffff;
  background-color: #fa5252;
  border-color: #fa5252;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(250, 82, 82, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(250, 82, 82, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #fa5252;
  background-color: transparent;
}

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff;
}

.btn-outline-white:hover {
  color: #262b40;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-check:focus + .btn-outline-white,
.btn-outline-white:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-check:checked + .btn-outline-white,
.btn-check:active + .btn-outline-white,
.btn-outline-white:active,
.btn-outline-white.active,
.btn-outline-white.dropdown-toggle.show {
  color: #262b40;
  background-color: #ffffff;
  border-color: #ffffff;
}

.btn-check:checked + .btn-outline-white:focus,
.btn-check:active + .btn-outline-white:focus,
.btn-outline-white:active:focus,
.btn-outline-white.active:focus,
.btn-outline-white.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white:disabled,
.btn-outline-white.disabled {
  color: #ffffff;
  background-color: transparent;
}

.btn-outline-gray {
  color: #4a5073;
  border-color: #4a5073;
}

.btn-outline-gray:hover {
  color: #ffffff;
  background-color: #4a5073;
  border-color: #4a5073;
}

.btn-check:focus + .btn-outline-gray,
.btn-outline-gray:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(74, 80, 115, 0.5);
}

.btn-check:checked + .btn-outline-gray,
.btn-check:active + .btn-outline-gray,
.btn-outline-gray:active,
.btn-outline-gray.active,
.btn-outline-gray.dropdown-toggle.show {
  color: #ffffff;
  background-color: #4a5073;
  border-color: #4a5073;
}

.btn-check:checked + .btn-outline-gray:focus,
.btn-check:active + .btn-outline-gray:focus,
.btn-outline-gray:active:focus,
.btn-outline-gray.active:focus,
.btn-outline-gray.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(74, 80, 115, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(74, 80, 115, 0.5);
}

.btn-outline-gray:disabled,
.btn-outline-gray.disabled {
  color: #4a5073;
  background-color: transparent;
}

.btn-outline-indigo {
  color: #4c5680;
  border-color: #4c5680;
}

.btn-outline-indigo:hover {
  color: #ffffff;
  background-color: #4c5680;
  border-color: #4c5680;
}

.btn-check:focus + .btn-outline-indigo,
.btn-outline-indigo:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(76, 86, 128, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(76, 86, 128, 0.5);
}

.btn-check:checked + .btn-outline-indigo,
.btn-check:active + .btn-outline-indigo,
.btn-outline-indigo:active,
.btn-outline-indigo.active,
.btn-outline-indigo.dropdown-toggle.show {
  color: #ffffff;
  background-color: #4c5680;
  border-color: #4c5680;
}

.btn-check:checked + .btn-outline-indigo:focus,
.btn-check:active + .btn-outline-indigo:focus,
.btn-outline-indigo:active:focus,
.btn-outline-indigo.active:focus,
.btn-outline-indigo.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(76, 86, 128, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(76, 86, 128, 0.5);
}

.btn-outline-indigo:disabled,
.btn-outline-indigo.disabled {
  color: #4c5680;
  background-color: transparent;
}

.btn-outline-dark {
  color: #262b40;
  border-color: #262b40;
}

.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(38, 43, 64, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(38, 43, 64, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #262b40;
  background-color: transparent;
}

.btn-outline-soft {
  color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-outline-soft:hover {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-check:focus + .btn-outline-soft,
.btn-outline-soft:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
}

.btn-check:checked + .btn-outline-soft,
.btn-check:active + .btn-outline-soft,
.btn-outline-soft:active,
.btn-outline-soft.active,
.btn-outline-soft.dropdown-toggle.show {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-check:checked + .btn-outline-soft:focus,
.btn-check:active + .btn-outline-soft:focus,
.btn-outline-soft:active:focus,
.btn-outline-soft.active:focus,
.btn-outline-soft.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(245, 248, 251, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(245, 248, 251, 0.5);
}

.btn-outline-soft:disabled,
.btn-outline-soft.disabled {
  color: #f5f8fb;
  background-color: transparent;
}

.btn-outline-black {
  color: #2e3650;
  border-color: #2e3650;
}

.btn-outline-black:hover {
  color: #ffffff;
  background-color: #2e3650;
  border-color: #2e3650;
}

.btn-check:focus + .btn-outline-black,
.btn-outline-black:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(46, 54, 80, 0.5);
}

.btn-check:checked + .btn-outline-black,
.btn-check:active + .btn-outline-black,
.btn-outline-black:active,
.btn-outline-black.active,
.btn-outline-black.dropdown-toggle.show {
  color: #ffffff;
  background-color: #2e3650;
  border-color: #2e3650;
}

.btn-check:checked + .btn-outline-black:focus,
.btn-check:active + .btn-outline-black:focus,
.btn-outline-black:active:focus,
.btn-outline-black.active:focus,
.btn-outline-black.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(46, 54, 80, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(46, 54, 80, 0.5);
}

.btn-outline-black:disabled,
.btn-outline-black.disabled {
  color: #2e3650;
  background-color: transparent;
}

.btn-outline-blue {
  color: #0948b3;
  border-color: #0948b3;
}

.btn-outline-blue:hover {
  color: #ffffff;
  background-color: #0948b3;
  border-color: #0948b3;
}

.btn-check:focus + .btn-outline-blue,
.btn-outline-blue:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(9, 72, 179, 0.5);
}

.btn-check:checked + .btn-outline-blue,
.btn-check:active + .btn-outline-blue,
.btn-outline-blue:active,
.btn-outline-blue.active,
.btn-outline-blue.dropdown-toggle.show {
  color: #ffffff;
  background-color: #0948b3;
  border-color: #0948b3;
}

.btn-check:checked + .btn-outline-blue:focus,
.btn-check:active + .btn-outline-blue:focus,
.btn-outline-blue:active:focus,
.btn-outline-blue.active:focus,
.btn-outline-blue.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(9, 72, 179, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(9, 72, 179, 0.5);
}

.btn-outline-blue:disabled,
.btn-outline-blue.disabled {
  color: #0948b3;
  background-color: transparent;
}

.btn-outline-pink {
  color: #c96480;
  border-color: #c96480;
}

.btn-outline-pink:hover {
  color: #ffffff;
  background-color: #c96480;
  border-color: #c96480;
}

.btn-check:focus + .btn-outline-pink,
.btn-outline-pink:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(201, 100, 128, 0.5);
}

.btn-check:checked + .btn-outline-pink,
.btn-check:active + .btn-outline-pink,
.btn-outline-pink:active,
.btn-outline-pink.active,
.btn-outline-pink.dropdown-toggle.show {
  color: #ffffff;
  background-color: #c96480;
  border-color: #c96480;
}

.btn-check:checked + .btn-outline-pink:focus,
.btn-check:active + .btn-outline-pink:focus,
.btn-outline-pink:active:focus,
.btn-outline-pink.active:focus,
.btn-outline-pink.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(201, 100, 128, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(201, 100, 128, 0.5);
}

.btn-outline-pink:disabled,
.btn-outline-pink.disabled {
  color: #c96480;
  background-color: transparent;
}

.btn-outline-cyan {
  color: #63b1bd;
  border-color: #63b1bd;
}

.btn-outline-cyan:hover {
  color: #262b40;
  background-color: #63b1bd;
  border-color: #63b1bd;
}

.btn-check:focus + .btn-outline-cyan,
.btn-outline-cyan:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(99, 177, 189, 0.5);
}

.btn-check:checked + .btn-outline-cyan,
.btn-check:active + .btn-outline-cyan,
.btn-outline-cyan:active,
.btn-outline-cyan.active,
.btn-outline-cyan.dropdown-toggle.show {
  color: #262b40;
  background-color: #63b1bd;
  border-color: #63b1bd;
}

.btn-check:checked + .btn-outline-cyan:focus,
.btn-check:active + .btn-outline-cyan:focus,
.btn-outline-cyan:active:focus,
.btn-outline-cyan.active:focus,
.btn-outline-cyan.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(99, 177, 189, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(99, 177, 189, 0.5);
}

.btn-outline-cyan:disabled,
.btn-outline-cyan.disabled {
  color: #63b1bd;
  background-color: transparent;
}

.btn-outline-purple {
  color: #8965e0;
  border-color: #8965e0;
}

.btn-outline-purple:hover {
  color: #ffffff;
  background-color: #8965e0;
  border-color: #8965e0;
}

.btn-check:focus + .btn-outline-purple,
.btn-outline-purple:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(137, 101, 224, 0.5);
}

.btn-check:checked + .btn-outline-purple,
.btn-check:active + .btn-outline-purple,
.btn-outline-purple:active,
.btn-outline-purple.active,
.btn-outline-purple.dropdown-toggle.show {
  color: #ffffff;
  background-color: #8965e0;
  border-color: #8965e0;
}

.btn-check:checked + .btn-outline-purple:focus,
.btn-check:active + .btn-outline-purple:focus,
.btn-outline-purple:active:focus,
.btn-outline-purple.active:focus,
.btn-outline-purple.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(137, 101, 224, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(137, 101, 224, 0.5);
}

.btn-outline-purple:disabled,
.btn-outline-purple.disabled {
  color: #8965e0;
  background-color: transparent;
}

.btn-outline-soft-indigo {
  color: #f5e8ff;
  border-color: #f5e8ff;
}

.btn-outline-soft-indigo:hover {
  color: #262b40;
  background-color: #f5e8ff;
  border-color: #f5e8ff;
}

.btn-check:focus + .btn-outline-soft-indigo,
.btn-outline-soft-indigo:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(245, 232, 255, 0.5);
}

.btn-check:checked + .btn-outline-soft-indigo,
.btn-check:active + .btn-outline-soft-indigo,
.btn-outline-soft-indigo:active,
.btn-outline-soft-indigo.active,
.btn-outline-soft-indigo.dropdown-toggle.show {
  color: #262b40;
  background-color: #f5e8ff;
  border-color: #f5e8ff;
}

.btn-check:checked + .btn-outline-soft-indigo:focus,
.btn-check:active + .btn-outline-soft-indigo:focus,
.btn-outline-soft-indigo:active:focus,
.btn-outline-soft-indigo.active:focus,
.btn-outline-soft-indigo.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(245, 232, 255, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(245, 232, 255, 0.5);
}

.btn-outline-soft-indigo:disabled,
.btn-outline-soft-indigo.disabled {
  color: #f5e8ff;
  background-color: transparent;
}

.btn-outline-soft-green {
  color: #2ca58d;
  border-color: #2ca58d;
}

.btn-outline-soft-green:hover {
  color: #ffffff;
  background-color: #2ca58d;
  border-color: #2ca58d;
}

.btn-check:focus + .btn-outline-soft-green,
.btn-outline-soft-green:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(44, 165, 141, 0.5);
}

.btn-check:checked + .btn-outline-soft-green,
.btn-check:active + .btn-outline-soft-green,
.btn-outline-soft-green:active,
.btn-outline-soft-green.active,
.btn-outline-soft-green.dropdown-toggle.show {
  color: #ffffff;
  background-color: #2ca58d;
  border-color: #2ca58d;
}

.btn-check:checked + .btn-outline-soft-green:focus,
.btn-check:active + .btn-outline-soft-green:focus,
.btn-outline-soft-green:active:focus,
.btn-outline-soft-green.active:focus,
.btn-outline-soft-green.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(44, 165, 141, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(44, 165, 141, 0.5);
}

.btn-outline-soft-green:disabled,
.btn-outline-soft-green.disabled {
  color: #2ca58d;
  background-color: transparent;
}

.btn-outline-gray-100 {
  color: #f3f7fa;
  border-color: #f3f7fa;
}

.btn-outline-gray-100:hover {
  color: #262b40;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}

.btn-check:focus + .btn-outline-gray-100,
.btn-outline-gray-100:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(243, 247, 250, 0.5);
}

.btn-check:checked + .btn-outline-gray-100,
.btn-check:active + .btn-outline-gray-100,
.btn-outline-gray-100:active,
.btn-outline-gray-100.active,
.btn-outline-gray-100.dropdown-toggle.show {
  color: #262b40;
  background-color: #f3f7fa;
  border-color: #f3f7fa;
}

.btn-check:checked + .btn-outline-gray-100:focus,
.btn-check:active + .btn-outline-gray-100:focus,
.btn-outline-gray-100:active:focus,
.btn-outline-gray-100.active:focus,
.btn-outline-gray-100.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(243, 247, 250, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(243, 247, 250, 0.5);
}

.btn-outline-gray-100:disabled,
.btn-outline-gray-100.disabled {
  color: #f3f7fa;
  background-color: transparent;
}

.btn-outline-gray-200 {
  color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-outline-gray-200:hover {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-check:focus + .btn-outline-gray-200,
.btn-outline-gray-200:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
}

.btn-check:checked + .btn-outline-gray-200,
.btn-check:active + .btn-outline-gray-200,
.btn-outline-gray-200:active,
.btn-outline-gray-200.active,
.btn-outline-gray-200.dropdown-toggle.show {
  color: #262b40;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-check:checked + .btn-outline-gray-200:focus,
.btn-check:active + .btn-outline-gray-200:focus,
.btn-outline-gray-200:active:focus,
.btn-outline-gray-200.active:focus,
.btn-outline-gray-200.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(245, 248, 251, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(245, 248, 251, 0.5);
}

.btn-outline-gray-200:disabled,
.btn-outline-gray-200.disabled {
  color: #f5f8fb;
  background-color: transparent;
}

.btn-outline-gray-300 {
  color: #f0f3f6;
  border-color: #f0f3f6;
}

.btn-outline-gray-300:hover {
  color: #262b40;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}

.btn-check:focus + .btn-outline-gray-300,
.btn-outline-gray-300:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(240, 243, 246, 0.5);
}

.btn-check:checked + .btn-outline-gray-300,
.btn-check:active + .btn-outline-gray-300,
.btn-outline-gray-300:active,
.btn-outline-gray-300.active,
.btn-outline-gray-300.dropdown-toggle.show {
  color: #262b40;
  background-color: #f0f3f6;
  border-color: #f0f3f6;
}

.btn-check:checked + .btn-outline-gray-300:focus,
.btn-check:active + .btn-outline-gray-300:focus,
.btn-outline-gray-300:active:focus,
.btn-outline-gray-300.active:focus,
.btn-outline-gray-300.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(240, 243, 246, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(240, 243, 246, 0.5);
}

.btn-outline-gray-300:disabled,
.btn-outline-gray-300.disabled {
  color: #f0f3f6;
  background-color: transparent;
}

.btn-outline-gray-400 {
  color: #eaedf2;
  border-color: #eaedf2;
}

.btn-outline-gray-400:hover {
  color: #262b40;
  background-color: #eaedf2;
  border-color: #eaedf2;
}

.btn-check:focus + .btn-outline-gray-400,
.btn-outline-gray-400:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(234, 237, 242, 0.5);
}

.btn-check:checked + .btn-outline-gray-400,
.btn-check:active + .btn-outline-gray-400,
.btn-outline-gray-400:active,
.btn-outline-gray-400.active,
.btn-outline-gray-400.dropdown-toggle.show {
  color: #262b40;
  background-color: #eaedf2;
  border-color: #eaedf2;
}

.btn-check:checked + .btn-outline-gray-400:focus,
.btn-check:active + .btn-outline-gray-400:focus,
.btn-outline-gray-400:active:focus,
.btn-outline-gray-400.active:focus,
.btn-outline-gray-400.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(234, 237, 242, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(234, 237, 242, 0.5);
}

.btn-outline-gray-400:disabled,
.btn-outline-gray-400.disabled {
  color: #eaedf2;
  background-color: transparent;
}

.btn-outline-gray-500 {
  color: #d1d7e0;
  border-color: #d1d7e0;
}

.btn-outline-gray-500:hover {
  color: #262b40;
  background-color: #d1d7e0;
  border-color: #d1d7e0;
}

.btn-check:focus + .btn-outline-gray-500,
.btn-outline-gray-500:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(209, 215, 224, 0.5);
}

.btn-check:checked + .btn-outline-gray-500,
.btn-check:active + .btn-outline-gray-500,
.btn-outline-gray-500:active,
.btn-outline-gray-500.active,
.btn-outline-gray-500.dropdown-toggle.show {
  color: #262b40;
  background-color: #d1d7e0;
  border-color: #d1d7e0;
}

.btn-check:checked + .btn-outline-gray-500:focus,
.btn-check:active + .btn-outline-gray-500:focus,
.btn-outline-gray-500:active:focus,
.btn-outline-gray-500.active:focus,
.btn-outline-gray-500.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(209, 215, 224, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(209, 215, 224, 0.5);
}

.btn-outline-gray-500:disabled,
.btn-outline-gray-500.disabled {
  color: #d1d7e0;
  background-color: transparent;
}

.btn-outline-gray-600 {
  color: #93a5be;
  border-color: #93a5be;
}

.btn-outline-gray-600:hover {
  color: #262b40;
  background-color: #93a5be;
  border-color: #93a5be;
}

.btn-check:focus + .btn-outline-gray-600,
.btn-outline-gray-600:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(147, 165, 190, 0.5);
}

.btn-check:checked + .btn-outline-gray-600,
.btn-check:active + .btn-outline-gray-600,
.btn-outline-gray-600:active,
.btn-outline-gray-600.active,
.btn-outline-gray-600.dropdown-toggle.show {
  color: #262b40;
  background-color: #93a5be;
  border-color: #93a5be;
}

.btn-check:checked + .btn-outline-gray-600:focus,
.btn-check:active + .btn-outline-gray-600:focus,
.btn-outline-gray-600:active:focus,
.btn-outline-gray-600.active:focus,
.btn-outline-gray-600.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(147, 165, 190, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(147, 165, 190, 0.5);
}

.btn-outline-gray-600:disabled,
.btn-outline-gray-600.disabled {
  color: #93a5be;
  background-color: transparent;
}

.btn-outline-gray-700 {
  color: #66799e;
  border-color: #66799e;
}

.btn-outline-gray-700:hover {
  color: #ffffff;
  background-color: #66799e;
  border-color: #66799e;
}

.btn-check:focus + .btn-outline-gray-700,
.btn-outline-gray-700:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(102, 121, 158, 0.5);
}

.btn-check:checked + .btn-outline-gray-700,
.btn-check:active + .btn-outline-gray-700,
.btn-outline-gray-700:active,
.btn-outline-gray-700.active,
.btn-outline-gray-700.dropdown-toggle.show {
  color: #ffffff;
  background-color: #66799e;
  border-color: #66799e;
}

.btn-check:checked + .btn-outline-gray-700:focus,
.btn-check:active + .btn-outline-gray-700:focus,
.btn-outline-gray-700:active:focus,
.btn-outline-gray-700.active:focus,
.btn-outline-gray-700.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(102, 121, 158, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(102, 121, 158, 0.5);
}

.btn-outline-gray-700:disabled,
.btn-outline-gray-700.disabled {
  color: #66799e;
  background-color: transparent;
}

.btn-outline-gray-800 {
  color: #506690;
  border-color: #506690;
}

.btn-outline-gray-800:hover {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
}

.btn-check:focus + .btn-outline-gray-800,
.btn-outline-gray-800:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(80, 102, 144, 0.5);
}

.btn-check:checked + .btn-outline-gray-800,
.btn-check:active + .btn-outline-gray-800,
.btn-outline-gray-800:active,
.btn-outline-gray-800.active,
.btn-outline-gray-800.dropdown-toggle.show {
  color: #ffffff;
  background-color: #506690;
  border-color: #506690;
}

.btn-check:checked + .btn-outline-gray-800:focus,
.btn-check:active + .btn-outline-gray-800:focus,
.btn-outline-gray-800:active:focus,
.btn-outline-gray-800.active:focus,
.btn-outline-gray-800.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(80, 102, 144, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(80, 102, 144, 0.5);
}

.btn-outline-gray-800:disabled,
.btn-outline-gray-800.disabled {
  color: #506690;
  background-color: transparent;
}

.btn-outline-facebook {
  color: #3b5999;
  border-color: #3b5999;
}

.btn-outline-facebook:hover {
  color: #ffffff;
  background-color: #3b5999;
  border-color: #3b5999;
}

.btn-check:focus + .btn-outline-facebook,
.btn-outline-facebook:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 153, 0.5);
}

.btn-check:checked + .btn-outline-facebook,
.btn-check:active + .btn-outline-facebook,
.btn-outline-facebook:active,
.btn-outline-facebook.active,
.btn-outline-facebook.dropdown-toggle.show {
  color: #ffffff;
  background-color: #3b5999;
  border-color: #3b5999;
}

.btn-check:checked + .btn-outline-facebook:focus,
.btn-check:active + .btn-outline-facebook:focus,
.btn-outline-facebook:active:focus,
.btn-outline-facebook.active:focus,
.btn-outline-facebook.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(59, 89, 153, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(59, 89, 153, 0.5);
}

.btn-outline-facebook:disabled,
.btn-outline-facebook.disabled {
  color: #3b5999;
  background-color: transparent;
}

.btn-outline-twitter {
  color: #1da1f2;
  border-color: #1da1f2;
}

.btn-outline-twitter:hover {
  color: #262b40;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-check:focus + .btn-outline-twitter,
.btn-outline-twitter:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5);
}

.btn-check:checked + .btn-outline-twitter,
.btn-check:active + .btn-outline-twitter,
.btn-outline-twitter:active,
.btn-outline-twitter.active,
.btn-outline-twitter.dropdown-toggle.show {
  color: #262b40;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-check:checked + .btn-outline-twitter:focus,
.btn-check:active + .btn-outline-twitter:focus,
.btn-outline-twitter:active:focus,
.btn-outline-twitter.active:focus,
.btn-outline-twitter.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(29, 161, 242, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(29, 161, 242, 0.5);
}

.btn-outline-twitter:disabled,
.btn-outline-twitter.disabled {
  color: #1da1f2;
  background-color: transparent;
}

.btn-outline-google {
  color: #db4337;
  border-color: #db4337;
}

.btn-outline-google:hover {
  color: #ffffff;
  background-color: #db4337;
  border-color: #db4337;
}

.btn-check:focus + .btn-outline-google,
.btn-outline-google:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(219, 67, 55, 0.5);
}

.btn-check:checked + .btn-outline-google,
.btn-check:active + .btn-outline-google,
.btn-outline-google:active,
.btn-outline-google.active,
.btn-outline-google.dropdown-toggle.show {
  color: #ffffff;
  background-color: #db4337;
  border-color: #db4337;
}

.btn-check:checked + .btn-outline-google:focus,
.btn-check:active + .btn-outline-google:focus,
.btn-outline-google:active:focus,
.btn-outline-google.active:focus,
.btn-outline-google.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(219, 67, 55, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(219, 67, 55, 0.5);
}

.btn-outline-google:disabled,
.btn-outline-google.disabled {
  color: #db4337;
  background-color: transparent;
}

.btn-outline-instagram {
  color: #e4405f;
  border-color: #e4405f;
}

.btn-outline-instagram:hover {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f;
}

.btn-check:focus + .btn-outline-instagram,
.btn-outline-instagram:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(228, 64, 95, 0.5);
}

.btn-check:checked + .btn-outline-instagram,
.btn-check:active + .btn-outline-instagram,
.btn-outline-instagram:active,
.btn-outline-instagram.active,
.btn-outline-instagram.dropdown-toggle.show {
  color: #ffffff;
  background-color: #e4405f;
  border-color: #e4405f;
}

.btn-check:checked + .btn-outline-instagram:focus,
.btn-check:active + .btn-outline-instagram:focus,
.btn-outline-instagram:active:focus,
.btn-outline-instagram.active:focus,
.btn-outline-instagram.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(228, 64, 95, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(228, 64, 95, 0.5);
}

.btn-outline-instagram:disabled,
.btn-outline-instagram.disabled {
  color: #e4405f;
  background-color: transparent;
}

.btn-outline-pinterest {
  color: #bd081c;
  border-color: #bd081c;
}

.btn-outline-pinterest:hover {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c;
}

.btn-check:focus + .btn-outline-pinterest,
.btn-outline-pinterest:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(189, 8, 28, 0.5);
}

.btn-check:checked + .btn-outline-pinterest,
.btn-check:active + .btn-outline-pinterest,
.btn-outline-pinterest:active,
.btn-outline-pinterest.active,
.btn-outline-pinterest.dropdown-toggle.show {
  color: #ffffff;
  background-color: #bd081c;
  border-color: #bd081c;
}

.btn-check:checked + .btn-outline-pinterest:focus,
.btn-check:active + .btn-outline-pinterest:focus,
.btn-outline-pinterest:active:focus,
.btn-outline-pinterest.active:focus,
.btn-outline-pinterest.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(189, 8, 28, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(189, 8, 28, 0.5);
}

.btn-outline-pinterest:disabled,
.btn-outline-pinterest.disabled {
  color: #bd081c;
  background-color: transparent;
}

.btn-outline-youtube {
  color: #cd201f;
  border-color: #cd201f;
}

.btn-outline-youtube:hover {
  color: #ffffff;
  background-color: #cd201f;
  border-color: #cd201f;
}

.btn-check:focus + .btn-outline-youtube,
.btn-outline-youtube:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.5);
}

.btn-check:checked + .btn-outline-youtube,
.btn-check:active + .btn-outline-youtube,
.btn-outline-youtube:active,
.btn-outline-youtube.active,
.btn-outline-youtube.dropdown-toggle.show {
  color: #ffffff;
  background-color: #cd201f;
  border-color: #cd201f;
}

.btn-check:checked + .btn-outline-youtube:focus,
.btn-check:active + .btn-outline-youtube:focus,
.btn-outline-youtube:active:focus,
.btn-outline-youtube.active:focus,
.btn-outline-youtube.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(205, 32, 31, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(205, 32, 31, 0.5);
}

.btn-outline-youtube:disabled,
.btn-outline-youtube.disabled {
  color: #cd201f;
  background-color: transparent;
}

.btn-outline-slack {
  color: #3aaf85;
  border-color: #3aaf85;
}

.btn-outline-slack:hover {
  color: #262b40;
  background-color: #3aaf85;
  border-color: #3aaf85;
}

.btn-check:focus + .btn-outline-slack,
.btn-outline-slack:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(58, 175, 133, 0.5);
}

.btn-check:checked + .btn-outline-slack,
.btn-check:active + .btn-outline-slack,
.btn-outline-slack:active,
.btn-outline-slack.active,
.btn-outline-slack.dropdown-toggle.show {
  color: #262b40;
  background-color: #3aaf85;
  border-color: #3aaf85;
}

.btn-check:checked + .btn-outline-slack:focus,
.btn-check:active + .btn-outline-slack:focus,
.btn-outline-slack:active:focus,
.btn-outline-slack.active:focus,
.btn-outline-slack.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(58, 175, 133, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(58, 175, 133, 0.5);
}

.btn-outline-slack:disabled,
.btn-outline-slack.disabled {
  color: #3aaf85;
  background-color: transparent;
}

.btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89;
}

.btn-outline-dribbble:hover {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.btn-check:focus + .btn-outline-dribbble,
.btn-outline-dribbble:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
}

.btn-check:checked + .btn-outline-dribbble,
.btn-check:active + .btn-outline-dribbble,
.btn-outline-dribbble:active,
.btn-outline-dribbble.active,
.btn-outline-dribbble.dropdown-toggle.show {
  color: #ffffff;
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.btn-check:checked + .btn-outline-dribbble:focus,
.btn-check:active + .btn-outline-dribbble:focus,
.btn-outline-dribbble:active:focus,
.btn-outline-dribbble.active:focus,
.btn-outline-dribbble.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(234, 76, 137, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(234, 76, 137, 0.5);
}

.btn-outline-dribbble:disabled,
.btn-outline-dribbble.disabled {
  color: #ea4c89;
  background-color: transparent;
}

.btn-outline-dropbox {
  color: #1e90ff;
  border-color: #1e90ff;
}

.btn-outline-dropbox:hover {
  color: #ffffff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}

.btn-check:focus + .btn-outline-dropbox,
.btn-outline-dropbox:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(30, 144, 255, 0.5);
}

.btn-check:checked + .btn-outline-dropbox,
.btn-check:active + .btn-outline-dropbox,
.btn-outline-dropbox:active,
.btn-outline-dropbox.active,
.btn-outline-dropbox.dropdown-toggle.show {
  color: #ffffff;
  background-color: #1e90ff;
  border-color: #1e90ff;
}

.btn-check:checked + .btn-outline-dropbox:focus,
.btn-check:active + .btn-outline-dropbox:focus,
.btn-outline-dropbox:active:focus,
.btn-outline-dropbox.active:focus,
.btn-outline-dropbox.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(30, 144, 255, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(30, 144, 255, 0.5);
}

.btn-outline-dropbox:disabled,
.btn-outline-dropbox.disabled {
  color: #1e90ff;
  background-color: transparent;
}

.btn-outline-twitch {
  color: #4b367c;
  border-color: #4b367c;
}

.btn-outline-twitch:hover {
  color: #ffffff;
  background-color: #4b367c;
  border-color: #4b367c;
}

.btn-check:focus + .btn-outline-twitch,
.btn-outline-twitch:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(75, 54, 124, 0.5);
}

.btn-check:checked + .btn-outline-twitch,
.btn-check:active + .btn-outline-twitch,
.btn-outline-twitch:active,
.btn-outline-twitch.active,
.btn-outline-twitch.dropdown-toggle.show {
  color: #ffffff;
  background-color: #4b367c;
  border-color: #4b367c;
}

.btn-check:checked + .btn-outline-twitch:focus,
.btn-check:active + .btn-outline-twitch:focus,
.btn-outline-twitch:active:focus,
.btn-outline-twitch.active:focus,
.btn-outline-twitch.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(75, 54, 124, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(75, 54, 124, 0.5);
}

.btn-outline-twitch:disabled,
.btn-outline-twitch.disabled {
  color: #4b367c;
  background-color: transparent;
}

.btn-outline-paypal {
  color: #ecb32c;
  border-color: #ecb32c;
}

.btn-outline-paypal:hover {
  color: #262b40;
  background-color: #ecb32c;
  border-color: #ecb32c;
}

.btn-check:focus + .btn-outline-paypal,
.btn-outline-paypal:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(236, 179, 44, 0.5);
}

.btn-check:checked + .btn-outline-paypal,
.btn-check:active + .btn-outline-paypal,
.btn-outline-paypal:active,
.btn-outline-paypal.active,
.btn-outline-paypal.dropdown-toggle.show {
  color: #262b40;
  background-color: #ecb32c;
  border-color: #ecb32c;
}

.btn-check:checked + .btn-outline-paypal:focus,
.btn-check:active + .btn-outline-paypal:focus,
.btn-outline-paypal:active:focus,
.btn-outline-paypal.active:focus,
.btn-outline-paypal.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(236, 179, 44, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(236, 179, 44, 0.5);
}

.btn-outline-paypal:disabled,
.btn-outline-paypal.disabled {
  color: #ecb32c;
  background-color: transparent;
}

.btn-outline-behance {
  color: #0057ff;
  border-color: #0057ff;
}

.btn-outline-behance:hover {
  color: #ffffff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-check:focus + .btn-outline-behance,
.btn-outline-behance:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
}

.btn-check:checked + .btn-outline-behance,
.btn-check:active + .btn-outline-behance,
.btn-outline-behance:active,
.btn-outline-behance.active,
.btn-outline-behance.dropdown-toggle.show {
  color: #ffffff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-check:checked + .btn-outline-behance:focus,
.btn-check:active + .btn-outline-behance:focus,
.btn-outline-behance:active:focus,
.btn-outline-behance.active:focus,
.btn-outline-behance.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(0, 87, 255, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(0, 87, 255, 0.5);
}

.btn-outline-behance:disabled,
.btn-outline-behance.disabled {
  color: #0057ff;
  background-color: transparent;
}

.btn-outline-reddit {
  color: #e84422;
  border-color: #e84422;
}

.btn-outline-reddit:hover {
  color: #ffffff;
  background-color: #e84422;
  border-color: #e84422;
}

.btn-check:focus + .btn-outline-reddit,
.btn-outline-reddit:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(232, 68, 34, 0.5);
}

.btn-check:checked + .btn-outline-reddit,
.btn-check:active + .btn-outline-reddit,
.btn-outline-reddit:active,
.btn-outline-reddit.active,
.btn-outline-reddit.dropdown-toggle.show {
  color: #ffffff;
  background-color: #e84422;
  border-color: #e84422;
}

.btn-check:checked + .btn-outline-reddit:focus,
.btn-check:active + .btn-outline-reddit:focus,
.btn-outline-reddit:active:focus,
.btn-outline-reddit.active:focus,
.btn-outline-reddit.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(232, 68, 34, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(232, 68, 34, 0.5);
}

.btn-outline-reddit:disabled,
.btn-outline-reddit.disabled {
  color: #e84422;
  background-color: transparent;
}

.btn-outline-github {
  color: #222222;
  border-color: #222222;
}

.btn-outline-github:hover {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

.btn-check:focus + .btn-outline-github,
.btn-outline-github:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.btn-check:checked + .btn-outline-github,
.btn-check:active + .btn-outline-github,
.btn-outline-github:active,
.btn-outline-github.active,
.btn-outline-github.dropdown-toggle.show {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

.btn-check:checked + .btn-outline-github:focus,
.btn-check:active + .btn-outline-github:focus,
.btn-outline-github:active:focus,
.btn-outline-github.active:focus,
.btn-outline-github.dropdown-toggle.show:focus {
  -webkit-box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(34, 34, 34, 0.5);
  box-shadow:
    inset 0 3px 5px rgba(46, 54, 80, 0.125),
    0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.btn-outline-github:disabled,
.btn-outline-github.disabled {
  color: #222222;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #262b40;
  text-decoration: none;
}

.btn-link:hover {
  color: #0a0b10;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #93a5be;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #4a5073;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid #eaedf2;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}

.dropdown-menu[style] {
  right: auto !important;
}

.dropdown-menu-start {
  --bs-position: start;
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f5f8fb;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #4a5073;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #404563;
  background-color: #f5f8fb;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #4a5073;
  text-decoration: none;
  background-color: #eaedf2;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #93a5be;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #4a5073;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #4a5073;
}

.dropdown-menu-dark {
  color: #f0f3f6;
  background-color: #506690;
  border-color: #eaedf2;
}

.dropdown-menu-dark .dropdown-item {
  color: #f0f3f6;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #4a5073;
  background-color: #eaedf2;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #d1d7e0;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: #f5f8fb;
}

.dropdown-menu-dark .dropdown-item-text {
  color: #f0f3f6;
}

.dropdown-menu-dark .dropdown-header {
  color: #d1d7e0;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.0625rem;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
  box-shadow: inset 0 3px 5px rgba(46, 54, 80, 0.125);
}

.btn-group.show .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.0625rem;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #506690;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    -webkit-transition: none;
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: #262b40;
}

.nav-link.disabled {
  color: #93a5be;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0.0625rem solid #eaedf2;
}

.nav-tabs .nav-link {
  margin-bottom: -0.0625rem;
  border: 0.0625rem solid transparent;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #f5f8fb #f5f8fb #eaedf2;
}

.nav-tabs .nav-link.disabled {
  color: #93a5be;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #66799e;
  background-color: #eaedf2;
  border-color: #eaedf2;
}

.nav-tabs .dropdown-menu {
  margin-top: -0.0625rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.5rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #262b40;
  background-color: #f8f8f8;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  padding: 0.4rem 0.6rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.5rem;
  -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out;
  transition:
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    -webkit-transition: none;
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem;
  box-shadow: 0 0 0 0.2rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #2e3650;
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: #2e3650;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(46, 54, 80, 0.9);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #2e3650;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(46, 54, 80, 0.9);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #2e3650;
}

.navbar-light .navbar-toggler {
  color: rgba(46, 54, 80, 0.9);
  border-color: rgba(46, 54, 80, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2846, 54, 80, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(46, 54, 80, 0.9);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #2e3650;
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: white;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0.0625rem solid rgba(46, 54, 80, 0.125);
  border-radius: 0.5rem;
  height: 90%;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem 1.5rem;
}

.card-title {
  margin-bottom: 0.875rem;
}

.card-subtitle {
  margin-top: -0.4375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.5rem /* rtl:ignore */;
}

.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(46, 54, 80, 0.03);
  border-bottom: 0.0625rem solid rgba(46, 54, 80, 0.125);
}

.card-header:first-child {
  border-radius: 0.4375rem 0.4375rem 0 0;
}

.card-footer {
  padding: 1.25rem 1.5rem;
  background-color: rgba(46, 54, 80, 0.03);
  border-top: 0.0625rem solid rgba(46, 54, 80, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 0.4375rem 0.4375rem;
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.25rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: #ffffff;
  border-bottom-color: #ffffff;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.4375rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #4a5073;
  background-color: transparent;
  border: 0.0625rem solid rgba(46, 54, 80, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    border-radius 0.15s ease,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    border-radius 0.15s ease,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    -webkit-transition: none;
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
  color: #22273a;
  background-color: #e9eaec;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2322273a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-button::after {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234A5073'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition:
    transform 0.2s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    -webkit-transition: none;
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #566190;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0.0625rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 0.0625rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.accordion-collapse {
  border: solid rgba(46, 54, 80, 0.125);
  border-width: 0 0.0625rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f5f8fb;
  border-radius: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #66799e;
  content: var(--bs-breadcrumb-divider, '/') /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #ffffff;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #262b40;
  background-color: #ffffff;
  border: 0.0625rem solid #eaedf2;
  -webkit-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    -webkit-transition: none;
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #0a0b10;
  background-color: #f5f8fb;
  border-color: #f0f3f6;
}

.page-link:focus {
  z-index: 3;
  color: #0a0b10;
  background-color: #f5f8fb;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -0.0625rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.page-item.disabled .page-link {
  color: #93a5be;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #f0f3f6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1rem;
  border-bottom-right-radius: 0.1rem;
}

.badge {
  display: inline-block;
  padding: 0.1rem 0.4rem;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.5rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.5rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #171a26;
  background-color: #d4d5d9;
  border-color: #bebfc6;
}

.alert-primary .alert-link {
  color: #12151e;
}

.alert-secondary {
  color: #3a8397;
  background-color: #dff8fe;
  border-color: #d0f4fe;
}

.alert-secondary .alert-link {
  color: #2e6979;
}

.alert-tertiary {
  color: #105c53;
  background-color: #d1ebe8;
  border-color: #bbe0dc;
}

.alert-tertiary .alert-link {
  color: #0d4a42;
}

.alert-quaternary {
  color: #793c4d;
  background-color: #f4e0e6;
  border-color: #efd1d9;
}

.alert-quaternary .alert-link {
  color: #61303e;
}

.alert-primary-app {
  color: #8d9294;
  background-color: #fbfdfd;
  border-color: #f9fcfc;
}

.alert-primary-app .alert-link {
  color: #717576;
}

.alert-secondary-app {
  color: #282c60;
  background-color: #d9dbec;
  border-color: #c6c9e3;
}

.alert-secondary-app .alert-link {
  color: #20234d;
}

.alert-light {
  color: #8c8e91;
  background-color: #fbfbfc;
  border-color: #f9fafb;
}

.alert-light .alert-link {
  color: #707274;
}

.alert-lighten {
  color: #909294;
  background-color: #fcfdfd;
  border-color: #fbfbfc;
}

.alert-lighten .alert-link {
  color: #737576;
}

.alert-success {
  color: #036447;
  background-color: #cdede4;
  border-color: #b4e4d6;
}

.alert-success .alert-link {
  color: #025039;
}

.alert-info {
  color: #052b6b;
  background-color: #cedaf0;
  border-color: #b5c8e8;
}

.alert-info .alert-link {
  color: #042256;
}

.alert-warning {
  color: #936e35;
  background-color: #fdf1de;
  border-color: #fce9cd;
}

.alert-warning .alert-link {
  color: #76582a;
}

.alert-danger {
  color: #963131;
  background-color: #fedcdc;
  border-color: #fecbcb;
}

.alert-danger .alert-link {
  color: #782727;
}

.alert-white {
  color: #7d808c;
  background-color: white;
  border-color: white;
}

.alert-white .alert-link {
  color: #646670;
}

.alert-gray {
  color: #2c3045;
  background-color: #dbdce3;
  border-color: #c9cbd5;
}

.alert-gray .alert-link {
  color: #232637;
}

.alert-indigo {
  color: #2e344d;
  background-color: #dbdde6;
  border-color: #c9ccd9;
}

.alert-indigo .alert-link {
  color: #252a3e;
}

.alert-dark {
  color: #171a26;
  background-color: #d4d5d9;
  border-color: #bebfc6;
}

.alert-dark .alert-link {
  color: #12151e;
}

.alert-soft {
  color: #797d8b;
  background-color: #fdfefe;
  border-color: #fcfdfe;
}

.alert-soft .alert-link {
  color: #61646f;
}

.alert-black {
  color: #1c2030;
  background-color: #d5d7dc;
  border-color: #c0c3cb;
}

.alert-black .alert-link {
  color: #161a26;
}

.alert-blue {
  color: #052b6b;
  background-color: #cedaf0;
  border-color: #b5c8e8;
}

.alert-blue .alert-link {
  color: #042256;
}

.alert-pink {
  color: #793c4d;
  background-color: #f4e0e6;
  border-color: #efd1d9;
}

.alert-pink .alert-link {
  color: #61303e;
}

.alert-cyan {
  color: #3b6a71;
  background-color: #e0eff2;
  border-color: #d0e8eb;
}

.alert-cyan .alert-link {
  color: #2f555a;
}

.alert-purple {
  color: #523d86;
  background-color: #e7e0f9;
  border-color: #dcd1f6;
}

.alert-purple .alert-link {
  color: #42316b;
}

.alert-soft-indigo {
  color: #938b99;
  background-color: #fdfaff;
  border-color: #fcf8ff;
}

.alert-soft-indigo .alert-link {
  color: #766f7a;
}

.alert-soft-green {
  color: #1a6355;
  background-color: #d5ede8;
  border-color: #c0e4dd;
}

.alert-soft-green .alert-link {
  color: #154f44;
}

.alert-gray-100 {
  color: #787d8a;
  background-color: #fdfdfe;
  border-color: #fbfdfe;
}

.alert-gray-100 .alert-link {
  color: #60646e;
}

.alert-gray-200 {
  color: #797d8b;
  background-color: #fdfefe;
  border-color: #fcfdfe;
}

.alert-gray-200 .alert-link {
  color: #61646f;
}

.alert-gray-300 {
  color: #909294;
  background-color: #fcfdfd;
  border-color: #fbfbfc;
}

.alert-gray-300 .alert-link {
  color: #737576;
}

.alert-gray-400 {
  color: #8c8e91;
  background-color: #fbfbfc;
  border-color: #f9fafb;
}

.alert-gray-400 .alert-link {
  color: #707274;
}

.alert-gray-500 {
  color: #7d8186;
  background-color: #f6f7f9;
  border-color: #f1f3f6;
}

.alert-gray-500 .alert-link {
  color: #64676b;
}

.alert-gray-600 {
  color: #586372;
  background-color: #e9edf2;
  border-color: #dfe4ec;
}

.alert-gray-600 .alert-link {
  color: #464f5b;
}

.alert-gray-700 {
  color: #3d495f;
  background-color: #e0e4ec;
  border-color: #d1d7e2;
}

.alert-gray-700 .alert-link {
  color: #313a4c;
}

.alert-gray-800 {
  color: #303d56;
  background-color: #dce0e9;
  border-color: #cbd1de;
}

.alert-gray-800 .alert-link {
  color: #263145;
}

.alert-facebook {
  color: #23355c;
  background-color: #d8deeb;
  border-color: #c4cde0;
}

.alert-facebook .alert-link {
  color: #1c2a4a;
}

.alert-twitter {
  color: #116191;
  background-color: #d2ecfc;
  border-color: #bbe3fb;
}

.alert-twitter .alert-link {
  color: #0e4e74;
}

.alert-google {
  color: #832821;
  background-color: #f8d9d7;
  border-color: #f4c7c3;
}

.alert-google .alert-link {
  color: #69201a;
}

.alert-instagram {
  color: #892639;
  background-color: #fad9df;
  border-color: #f7c6cf;
}

.alert-instagram .alert-link {
  color: #6e1e2e;
}

.alert-pinterest {
  color: #710511;
  background-color: #f2ced2;
  border-color: #ebb5bb;
}

.alert-pinterest .alert-link {
  color: #5a040e;
}

.alert-youtube {
  color: #7b1313;
  background-color: #f5d2d2;
  border-color: #f0bcbc;
}

.alert-youtube .alert-link {
  color: #620f0f;
}

.alert-slack {
  color: #236950;
  background-color: #d8efe7;
  border-color: #c4e7da;
}

.alert-slack .alert-link {
  color: #1c5440;
}

.alert-dribbble {
  color: #8c2e52;
  background-color: #fbdbe7;
  border-color: #f9c9dc;
}

.alert-dribbble .alert-link {
  color: #702542;
}

.alert-dropbox {
  color: #125699;
  background-color: #d2e9ff;
  border-color: #bcdeff;
}

.alert-dropbox .alert-link {
  color: #0e457a;
}

.alert-twitch {
  color: #2d204a;
  background-color: #dbd7e5;
  border-color: #c9c3d8;
}

.alert-twitch .alert-link {
  color: #241a3b;
}

.alert-paypal {
  color: #8e6b1a;
  background-color: #fbf0d5;
  border-color: #f9e8c0;
}

.alert-paypal .alert-link {
  color: #725615;
}

.alert-behance {
  color: #003499;
  background-color: #ccddff;
  border-color: #b3cdff;
}

.alert-behance .alert-link {
  color: #002a7a;
}

.alert-reddit {
  color: #8b2914;
  background-color: #fadad3;
  border-color: #f8c7bd;
}

.alert-reddit .alert-link {
  color: #6f2110;
}

.alert-github {
  color: #141414;
  background-color: lightgray;
  border-color: #bdbdbd;
}

.alert-github .alert-link {
  color: #101010;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f5f8fb;
  border-radius: 0.5rem;
  -webkit-box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #262b40;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.5rem;
}

.list-group-item-action {
  width: 100%;
  color: #66799e;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #66799e;
  text-decoration: none;
  background-color: #f3f7fa;
}

.list-group-item-action:active {
  color: #4a5073;
  background-color: #f5f8fb;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  border: 0.0625rem solid rgba(46, 54, 80, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #93a5be;
  pointer-events: none;
  background-color: #ffffff;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -0.0625rem;
  border-top-width: 0.0625rem;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.5rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 0.0625rem;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -0.0625rem;
  border-left-width: 0.0625rem;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 0.0625rem;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -0.0625rem;
    border-left-width: 0.0625rem;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 0.0625rem;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #171a26;
  background-color: #d4d5d9;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #171a26;
  background-color: #bfc0c3;
}

.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #171a26;
  border-color: #171a26;
}

.list-group-item-secondary {
  color: #3a8397;
  background-color: #dff8fe;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #3a8397;
  background-color: #c9dfe5;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #3a8397;
  border-color: #3a8397;
}

.list-group-item-tertiary {
  color: #105c53;
  background-color: #d1ebe8;
}

.list-group-item-tertiary.list-group-item-action:hover,
.list-group-item-tertiary.list-group-item-action:focus {
  color: #105c53;
  background-color: #bcd4d1;
}

.list-group-item-tertiary.list-group-item-action.active {
  color: #ffffff;
  background-color: #105c53;
  border-color: #105c53;
}

.list-group-item-quaternary {
  color: #793c4d;
  background-color: #f4e0e6;
}

.list-group-item-quaternary.list-group-item-action:hover,
.list-group-item-quaternary.list-group-item-action:focus {
  color: #793c4d;
  background-color: #dccacf;
}

.list-group-item-quaternary.list-group-item-action.active {
  color: #ffffff;
  background-color: #793c4d;
  border-color: #793c4d;
}

.list-group-item-primary-app {
  color: #8d9294;
  background-color: #fbfdfd;
}

.list-group-item-primary-app.list-group-item-action:hover,
.list-group-item-primary-app.list-group-item-action:focus {
  color: #8d9294;
  background-color: #e2e4e4;
}

.list-group-item-primary-app.list-group-item-action.active {
  color: #ffffff;
  background-color: #8d9294;
  border-color: #8d9294;
}

.list-group-item-secondary-app {
  color: #282c60;
  background-color: #d9dbec;
}

.list-group-item-secondary-app.list-group-item-action:hover,
.list-group-item-secondary-app.list-group-item-action:focus {
  color: #282c60;
  background-color: #c3c5d4;
}

.list-group-item-secondary-app.list-group-item-action.active {
  color: #ffffff;
  background-color: #282c60;
  border-color: #282c60;
}

.list-group-item-light {
  color: #8c8e91;
  background-color: #fbfbfc;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #8c8e91;
  background-color: #e2e2e3;
}

.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #8c8e91;
  border-color: #8c8e91;
}

.list-group-item-lighten {
  color: #909294;
  background-color: #fcfdfd;
}

.list-group-item-lighten.list-group-item-action:hover,
.list-group-item-lighten.list-group-item-action:focus {
  color: #909294;
  background-color: #e3e4e4;
}

.list-group-item-lighten.list-group-item-action.active {
  color: #ffffff;
  background-color: #909294;
  border-color: #909294;
}

.list-group-item-success {
  color: #036447;
  background-color: #cdede4;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #036447;
  background-color: #b9d5cd;
}

.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #036447;
  border-color: #036447;
}

.list-group-item-info {
  color: #052b6b;
  background-color: #cedaf0;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #052b6b;
  background-color: #b9c4d8;
}

.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #052b6b;
  border-color: #052b6b;
}

.list-group-item-warning {
  color: #936e35;
  background-color: #fdf1de;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #936e35;
  background-color: #e4d9c8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #936e35;
  border-color: #936e35;
}

.list-group-item-danger {
  color: #963131;
  background-color: #fedcdc;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #963131;
  background-color: #e5c6c6;
}

.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #963131;
  border-color: #963131;
}

.list-group-item-white {
  color: #7d808c;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
  color: #7d808c;
  background-color: #e6e6e6;
}

.list-group-item-white.list-group-item-action.active {
  color: #ffffff;
  background-color: #7d808c;
  border-color: #7d808c;
}

.list-group-item-gray {
  color: #2c3045;
  background-color: #dbdce3;
}

.list-group-item-gray.list-group-item-action:hover,
.list-group-item-gray.list-group-item-action:focus {
  color: #2c3045;
  background-color: #c5c6cc;
}

.list-group-item-gray.list-group-item-action.active {
  color: #ffffff;
  background-color: #2c3045;
  border-color: #2c3045;
}

.list-group-item-indigo {
  color: #2e344d;
  background-color: #dbdde6;
}

.list-group-item-indigo.list-group-item-action:hover,
.list-group-item-indigo.list-group-item-action:focus {
  color: #2e344d;
  background-color: #c5c7cf;
}

.list-group-item-indigo.list-group-item-action.active {
  color: #ffffff;
  background-color: #2e344d;
  border-color: #2e344d;
}

.list-group-item-dark {
  color: #171a26;
  background-color: #d4d5d9;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #171a26;
  background-color: #bfc0c3;
}

.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #171a26;
  border-color: #171a26;
}

.list-group-item-soft {
  color: #797d8b;
  background-color: #fdfefe;
}

.list-group-item-soft.list-group-item-action:hover,
.list-group-item-soft.list-group-item-action:focus {
  color: #797d8b;
  background-color: #e4e5e5;
}

.list-group-item-soft.list-group-item-action.active {
  color: #ffffff;
  background-color: #797d8b;
  border-color: #797d8b;
}

.list-group-item-black {
  color: #1c2030;
  background-color: #d5d7dc;
}

.list-group-item-black.list-group-item-action:hover,
.list-group-item-black.list-group-item-action:focus {
  color: #1c2030;
  background-color: #c0c2c6;
}

.list-group-item-black.list-group-item-action.active {
  color: #ffffff;
  background-color: #1c2030;
  border-color: #1c2030;
}

.list-group-item-blue {
  color: #052b6b;
  background-color: #cedaf0;
}

.list-group-item-blue.list-group-item-action:hover,
.list-group-item-blue.list-group-item-action:focus {
  color: #052b6b;
  background-color: #b9c4d8;
}

.list-group-item-blue.list-group-item-action.active {
  color: #ffffff;
  background-color: #052b6b;
  border-color: #052b6b;
}

.list-group-item-pink {
  color: #793c4d;
  background-color: #f4e0e6;
}

.list-group-item-pink.list-group-item-action:hover,
.list-group-item-pink.list-group-item-action:focus {
  color: #793c4d;
  background-color: #dccacf;
}

.list-group-item-pink.list-group-item-action.active {
  color: #ffffff;
  background-color: #793c4d;
  border-color: #793c4d;
}

.list-group-item-cyan {
  color: #3b6a71;
  background-color: #e0eff2;
}

.list-group-item-cyan.list-group-item-action:hover,
.list-group-item-cyan.list-group-item-action:focus {
  color: #3b6a71;
  background-color: #cad7da;
}

.list-group-item-cyan.list-group-item-action.active {
  color: #ffffff;
  background-color: #3b6a71;
  border-color: #3b6a71;
}

.list-group-item-purple {
  color: #523d86;
  background-color: #e7e0f9;
}

.list-group-item-purple.list-group-item-action:hover,
.list-group-item-purple.list-group-item-action:focus {
  color: #523d86;
  background-color: #d0cae0;
}

.list-group-item-purple.list-group-item-action.active {
  color: #ffffff;
  background-color: #523d86;
  border-color: #523d86;
}

.list-group-item-soft-indigo {
  color: #938b99;
  background-color: #fdfaff;
}

.list-group-item-soft-indigo.list-group-item-action:hover,
.list-group-item-soft-indigo.list-group-item-action:focus {
  color: #938b99;
  background-color: #e4e1e6;
}

.list-group-item-soft-indigo.list-group-item-action.active {
  color: #ffffff;
  background-color: #938b99;
  border-color: #938b99;
}

.list-group-item-soft-green {
  color: #1a6355;
  background-color: #d5ede8;
}

.list-group-item-soft-green.list-group-item-action:hover,
.list-group-item-soft-green.list-group-item-action:focus {
  color: #1a6355;
  background-color: #c0d5d1;
}

.list-group-item-soft-green.list-group-item-action.active {
  color: #ffffff;
  background-color: #1a6355;
  border-color: #1a6355;
}

.list-group-item-gray-100 {
  color: #787d8a;
  background-color: #fdfdfe;
}

.list-group-item-gray-100.list-group-item-action:hover,
.list-group-item-gray-100.list-group-item-action:focus {
  color: #787d8a;
  background-color: #e4e4e5;
}

.list-group-item-gray-100.list-group-item-action.active {
  color: #ffffff;
  background-color: #787d8a;
  border-color: #787d8a;
}

.list-group-item-gray-200 {
  color: #797d8b;
  background-color: #fdfefe;
}

.list-group-item-gray-200.list-group-item-action:hover,
.list-group-item-gray-200.list-group-item-action:focus {
  color: #797d8b;
  background-color: #e4e5e5;
}

.list-group-item-gray-200.list-group-item-action.active {
  color: #ffffff;
  background-color: #797d8b;
  border-color: #797d8b;
}

.list-group-item-gray-300 {
  color: #909294;
  background-color: #fcfdfd;
}

.list-group-item-gray-300.list-group-item-action:hover,
.list-group-item-gray-300.list-group-item-action:focus {
  color: #909294;
  background-color: #e3e4e4;
}

.list-group-item-gray-300.list-group-item-action.active {
  color: #ffffff;
  background-color: #909294;
  border-color: #909294;
}

.list-group-item-gray-400 {
  color: #8c8e91;
  background-color: #fbfbfc;
}

.list-group-item-gray-400.list-group-item-action:hover,
.list-group-item-gray-400.list-group-item-action:focus {
  color: #8c8e91;
  background-color: #e2e2e3;
}

.list-group-item-gray-400.list-group-item-action.active {
  color: #ffffff;
  background-color: #8c8e91;
  border-color: #8c8e91;
}

.list-group-item-gray-500 {
  color: #7d8186;
  background-color: #f6f7f9;
}

.list-group-item-gray-500.list-group-item-action:hover,
.list-group-item-gray-500.list-group-item-action:focus {
  color: #7d8186;
  background-color: #dddee0;
}

.list-group-item-gray-500.list-group-item-action.active {
  color: #ffffff;
  background-color: #7d8186;
  border-color: #7d8186;
}

.list-group-item-gray-600 {
  color: #586372;
  background-color: #e9edf2;
}

.list-group-item-gray-600.list-group-item-action:hover,
.list-group-item-gray-600.list-group-item-action:focus {
  color: #586372;
  background-color: #d2d5da;
}

.list-group-item-gray-600.list-group-item-action.active {
  color: #ffffff;
  background-color: #586372;
  border-color: #586372;
}

.list-group-item-gray-700 {
  color: #3d495f;
  background-color: #e0e4ec;
}

.list-group-item-gray-700.list-group-item-action:hover,
.list-group-item-gray-700.list-group-item-action:focus {
  color: #3d495f;
  background-color: #cacdd4;
}

.list-group-item-gray-700.list-group-item-action.active {
  color: #ffffff;
  background-color: #3d495f;
  border-color: #3d495f;
}

.list-group-item-gray-800 {
  color: #303d56;
  background-color: #dce0e9;
}

.list-group-item-gray-800.list-group-item-action:hover,
.list-group-item-gray-800.list-group-item-action:focus {
  color: #303d56;
  background-color: #c6cad2;
}

.list-group-item-gray-800.list-group-item-action.active {
  color: #ffffff;
  background-color: #303d56;
  border-color: #303d56;
}

.list-group-item-facebook {
  color: #23355c;
  background-color: #d8deeb;
}

.list-group-item-facebook.list-group-item-action:hover,
.list-group-item-facebook.list-group-item-action:focus {
  color: #23355c;
  background-color: #c2c8d4;
}

.list-group-item-facebook.list-group-item-action.active {
  color: #ffffff;
  background-color: #23355c;
  border-color: #23355c;
}

.list-group-item-twitter {
  color: #116191;
  background-color: #d2ecfc;
}

.list-group-item-twitter.list-group-item-action:hover,
.list-group-item-twitter.list-group-item-action:focus {
  color: #116191;
  background-color: #bdd4e3;
}

.list-group-item-twitter.list-group-item-action.active {
  color: #ffffff;
  background-color: #116191;
  border-color: #116191;
}

.list-group-item-google {
  color: #832821;
  background-color: #f8d9d7;
}

.list-group-item-google.list-group-item-action:hover,
.list-group-item-google.list-group-item-action:focus {
  color: #832821;
  background-color: #dfc3c2;
}

.list-group-item-google.list-group-item-action.active {
  color: #ffffff;
  background-color: #832821;
  border-color: #832821;
}

.list-group-item-instagram {
  color: #892639;
  background-color: #fad9df;
}

.list-group-item-instagram.list-group-item-action:hover,
.list-group-item-instagram.list-group-item-action:focus {
  color: #892639;
  background-color: #e1c3c9;
}

.list-group-item-instagram.list-group-item-action.active {
  color: #ffffff;
  background-color: #892639;
  border-color: #892639;
}

.list-group-item-pinterest {
  color: #710511;
  background-color: #f2ced2;
}

.list-group-item-pinterest.list-group-item-action:hover,
.list-group-item-pinterest.list-group-item-action:focus {
  color: #710511;
  background-color: #dab9bd;
}

.list-group-item-pinterest.list-group-item-action.active {
  color: #ffffff;
  background-color: #710511;
  border-color: #710511;
}

.list-group-item-youtube {
  color: #7b1313;
  background-color: #f5d2d2;
}

.list-group-item-youtube.list-group-item-action:hover,
.list-group-item-youtube.list-group-item-action:focus {
  color: #7b1313;
  background-color: #ddbdbd;
}

.list-group-item-youtube.list-group-item-action.active {
  color: #ffffff;
  background-color: #7b1313;
  border-color: #7b1313;
}

.list-group-item-slack {
  color: #236950;
  background-color: #d8efe7;
}

.list-group-item-slack.list-group-item-action:hover,
.list-group-item-slack.list-group-item-action:focus {
  color: #236950;
  background-color: #c2d7d0;
}

.list-group-item-slack.list-group-item-action.active {
  color: #ffffff;
  background-color: #236950;
  border-color: #236950;
}

.list-group-item-dribbble {
  color: #8c2e52;
  background-color: #fbdbe7;
}

.list-group-item-dribbble.list-group-item-action:hover,
.list-group-item-dribbble.list-group-item-action:focus {
  color: #8c2e52;
  background-color: #e2c5d0;
}

.list-group-item-dribbble.list-group-item-action.active {
  color: #ffffff;
  background-color: #8c2e52;
  border-color: #8c2e52;
}

.list-group-item-dropbox {
  color: #125699;
  background-color: #d2e9ff;
}

.list-group-item-dropbox.list-group-item-action:hover,
.list-group-item-dropbox.list-group-item-action:focus {
  color: #125699;
  background-color: #bdd2e6;
}

.list-group-item-dropbox.list-group-item-action.active {
  color: #ffffff;
  background-color: #125699;
  border-color: #125699;
}

.list-group-item-twitch {
  color: #2d204a;
  background-color: #dbd7e5;
}

.list-group-item-twitch.list-group-item-action:hover,
.list-group-item-twitch.list-group-item-action:focus {
  color: #2d204a;
  background-color: #c5c2ce;
}

.list-group-item-twitch.list-group-item-action.active {
  color: #ffffff;
  background-color: #2d204a;
  border-color: #2d204a;
}

.list-group-item-paypal {
  color: #8e6b1a;
  background-color: #fbf0d5;
}

.list-group-item-paypal.list-group-item-action:hover,
.list-group-item-paypal.list-group-item-action:focus {
  color: #8e6b1a;
  background-color: #e2d8c0;
}

.list-group-item-paypal.list-group-item-action.active {
  color: #ffffff;
  background-color: #8e6b1a;
  border-color: #8e6b1a;
}

.list-group-item-behance {
  color: #003499;
  background-color: #ccddff;
}

.list-group-item-behance.list-group-item-action:hover,
.list-group-item-behance.list-group-item-action:focus {
  color: #003499;
  background-color: #b8c7e6;
}

.list-group-item-behance.list-group-item-action.active {
  color: #ffffff;
  background-color: #003499;
  border-color: #003499;
}

.list-group-item-reddit {
  color: #8b2914;
  background-color: #fadad3;
}

.list-group-item-reddit.list-group-item-action:hover,
.list-group-item-reddit.list-group-item-action:focus {
  color: #8b2914;
  background-color: #e1c4be;
}

.list-group-item-reddit.list-group-item-action.active {
  color: #ffffff;
  background-color: #8b2914;
  border-color: #8b2914;
}

.list-group-item-github {
  color: #141414;
  background-color: lightgray;
}

.list-group-item-github.list-group-item-action:hover,
.list-group-item-github.list-group-item-action:focus {
  color: #141414;
  background-color: #bebebe;
}

.list-group-item-github.list-group-item-action.active {
  color: #ffffff;
  background-color: #141414;
  border-color: #141414;
}

.btn-close {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #2e3650;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232e3650'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #2e3650;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  border-radius: 0.5rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 2rem;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #93a5be;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition:
    transform 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(46, 54, 80, 0.2);
  border-radius: 0.3rem;
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #2e3650;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0.0625rem solid #eaedf2;
  border-top-left-radius: 0.2375rem;
  border-top-right-radius: 0.2375rem;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0.0625rem solid #eaedf2;
  border-bottom-right-radius: 0.2375rem;
  border-bottom-left-radius: 0.2375rem;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.99;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^='top'] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #262b40;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^='right'] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #262b40;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^='bottom'] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #262b40;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^='left'] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #262b40;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.75rem 0.55rem;
  color: #ffffff;
  text-align: center;
  background-color: #262b40;
  border-radius: 0.5rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid rgba(46, 54, 80, 0.2);
  border-radius: 0.3rem;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[data-popper-placement^='top'] {
  margin-bottom: 0.5rem !important;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow {
  bottom: -0.5625rem;
}

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(46, 54, 80, 0.25);
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after {
  bottom: 0.0625rem;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-end,
.bs-popover-auto[data-popper-placement^='right'] {
  margin-left: 0.5rem !important;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow {
  left: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(46, 54, 80, 0.25);
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after {
  left: 0.0625rem;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^='bottom'] {
  margin-top: 0.5rem !important;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow {
  top: -0.5625rem;
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(46, 54, 80, 0.25);
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after {
  top: 0.0625rem;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 0.0625rem solid #f7f7f7;
}

.bs-popover-start,
.bs-popover-auto[data-popper-placement^='left'] {
  margin-right: 0.5rem !important;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow {
  right: -0.5625rem;
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(46, 54, 80, 0.25);
}

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after {
  right: 0.0625rem;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #262b40;
  background-color: #f7f7f7;
  border-bottom: 0.0625rem solid #dfdfdf;
  border-top-left-radius: 0.2375rem;
  border-top-right-radius: 0.2375rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #4a5073;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition:
    transform 0.6s ease-in-out,
    -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators li {
  background-color: #2e3650;
}

.carousel-dark .carousel-caption {
  color: #2e3650;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1.2rem;
  height: 1.2rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.link-primary {
  color: #262b40;
}

.link-primary:hover,
.link-primary:focus {
  color: #1e2233;
}

.link-secondary {
  color: #61dafb;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #81e1fc;
}

.link-tertiary {
  color: #1b998b;
}

.link-tertiary:hover,
.link-tertiary:focus {
  color: #167a6f;
}

.link-quaternary {
  color: #c96480;
}

.link-quaternary:hover,
.link-quaternary:focus {
  color: #a15066;
}

.link-primary-app {
  color: #ebf4f6;
}

.link-primary-app:hover,
.link-primary-app:focus {
  color: #eff6f8;
}

.link-secondary-app {
  color: #424aa0;
}

.link-secondary-app:hover,
.link-secondary-app:focus {
  color: #353b80;
}

.link-light {
  color: #eaedf2;
}

.link-light:hover,
.link-light:focus {
  color: #eef1f5;
}

.link-lighten {
  color: #f0f3f6;
}

.link-lighten:hover,
.link-lighten:focus {
  color: #f3f5f8;
}

.link-success {
  color: #05a677;
}

.link-success:hover,
.link-success:focus {
  color: #04855f;
}

.link-info {
  color: #0948b3;
}

.link-info:hover,
.link-info:focus {
  color: #073a8f;
}

.link-warning {
  color: #f5b759;
}

.link-warning:hover,
.link-warning:focus {
  color: #f7c57a;
}

.link-danger {
  color: #fa5252;
}

.link-danger:hover,
.link-danger:focus {
  color: #c84242;
}

.link-white {
  color: #ffffff;
}

.link-white:hover,
.link-white:focus {
  color: white;
}

.link-gray {
  color: #4a5073;
}

.link-gray:hover,
.link-gray:focus {
  color: #3b405c;
}

.link-indigo {
  color: #4c5680;
}

.link-indigo:hover,
.link-indigo:focus {
  color: #3d4566;
}

.link-dark {
  color: #262b40;
}

.link-dark:hover,
.link-dark:focus {
  color: #1e2233;
}

.link-soft {
  color: #f5f8fb;
}

.link-soft:hover,
.link-soft:focus {
  color: #f7f9fc;
}

.link-black {
  color: #2e3650;
}

.link-black:hover,
.link-black:focus {
  color: #252b40;
}

.link-blue {
  color: #0948b3;
}

.link-blue:hover,
.link-blue:focus {
  color: #073a8f;
}

.link-pink {
  color: #c96480;
}

.link-pink:hover,
.link-pink:focus {
  color: #a15066;
}

.link-cyan {
  color: #63b1bd;
}

.link-cyan:hover,
.link-cyan:focus {
  color: #82c1ca;
}

.link-purple {
  color: #8965e0;
}

.link-purple:hover,
.link-purple:focus {
  color: #6e51b3;
}

.link-soft-indigo {
  color: #f5e8ff;
}

.link-soft-indigo:hover,
.link-soft-indigo:focus {
  color: #f7edff;
}

.link-soft-green {
  color: #2ca58d;
}

.link-soft-green:hover,
.link-soft-green:focus {
  color: #238471;
}

.link-gray-100 {
  color: #f3f7fa;
}

.link-gray-100:hover,
.link-gray-100:focus {
  color: #f5f9fb;
}

.link-gray-200 {
  color: #f5f8fb;
}

.link-gray-200:hover,
.link-gray-200:focus {
  color: #f7f9fc;
}

.link-gray-300 {
  color: #f0f3f6;
}

.link-gray-300:hover,
.link-gray-300:focus {
  color: #f3f5f8;
}

.link-gray-400 {
  color: #eaedf2;
}

.link-gray-400:hover,
.link-gray-400:focus {
  color: #eef1f5;
}

.link-gray-500 {
  color: #d1d7e0;
}

.link-gray-500:hover,
.link-gray-500:focus {
  color: #dadfe6;
}

.link-gray-600 {
  color: #93a5be;
}

.link-gray-600:hover,
.link-gray-600:focus {
  color: #a9b7cb;
}

.link-gray-700 {
  color: #66799e;
}

.link-gray-700:hover,
.link-gray-700:focus {
  color: #52617e;
}

.link-gray-800 {
  color: #506690;
}

.link-gray-800:hover,
.link-gray-800:focus {
  color: #405273;
}

.link-facebook {
  color: #3b5999;
}

.link-facebook:hover,
.link-facebook:focus {
  color: #2f477a;
}

.link-twitter {
  color: #1da1f2;
}

.link-twitter:hover,
.link-twitter:focus {
  color: #4ab4f5;
}

.link-google {
  color: #db4337;
}

.link-google:hover,
.link-google:focus {
  color: #af362c;
}

.link-instagram {
  color: #e4405f;
}

.link-instagram:hover,
.link-instagram:focus {
  color: #b6334c;
}

.link-pinterest {
  color: #bd081c;
}

.link-pinterest:hover,
.link-pinterest:focus {
  color: #970616;
}

.link-youtube {
  color: #cd201f;
}

.link-youtube:hover,
.link-youtube:focus {
  color: #a41a19;
}

.link-slack {
  color: #3aaf85;
}

.link-slack:hover,
.link-slack:focus {
  color: #61bf9d;
}

.link-dribbble {
  color: #ea4c89;
}

.link-dribbble:hover,
.link-dribbble:focus {
  color: #bb3d6e;
}

.link-dropbox {
  color: #1e90ff;
}

.link-dropbox:hover,
.link-dropbox:focus {
  color: #1873cc;
}

.link-twitch {
  color: #4b367c;
}

.link-twitch:hover,
.link-twitch:focus {
  color: #3c2b63;
}

.link-paypal {
  color: #ecb32c;
}

.link-paypal:hover,
.link-paypal:focus {
  color: #f0c256;
}

.link-behance {
  color: #0057ff;
}

.link-behance:hover,
.link-behance:focus {
  color: #0046cc;
}

.link-reddit {
  color: #e84422;
}

.link-reddit:hover,
.link-reddit:focus {
  color: #ba361b;
}

.link-github {
  color: #222222;
}

.link-github:hover,
.link-github:focus {
  color: #1b1b1b;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: '';
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: -ms-grid !important;
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15) !important;
}

.shadow-sm {
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed,
.headroom--pinned,
.headroom--unpinned {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.border {
  border: 0.0625rem solid #eaedf2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 0.0625rem solid #eaedf2 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 0.0625rem solid #eaedf2 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid #eaedf2 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 0.0625rem solid #eaedf2 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #262b40 !important;
}

.border-secondary {
  border-color: #61dafb !important;
}

.border-tertiary {
  border-color: #1b998b !important;
}

.border-quaternary {
  border-color: #c96480 !important;
}

.border-primary-app {
  border-color: #ebf4f6 !important;
}

.border-secondary-app {
  border-color: #424aa0 !important;
}

.border-light {
  border-color: #eaedf2 !important;
}

.border-lighten {
  border-color: #f0f3f6 !important;
}

.border-success {
  border-color: #05a677 !important;
}

.border-info {
  border-color: #0948b3 !important;
}

.border-warning {
  border-color: #f5b759 !important;
}

.border-danger {
  border-color: #fa5252 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-gray {
  border-color: #4a5073 !important;
}

.border-indigo {
  border-color: #4c5680 !important;
}

.border-dark {
  border-color: #262b40 !important;
}

.border-soft {
  border-color: #f5f8fb !important;
}

.border-black {
  border-color: #2e3650 !important;
}

.border-blue {
  border-color: #0948b3 !important;
}

.border-pink {
  border-color: #c96480 !important;
}

.border-cyan {
  border-color: #63b1bd !important;
}

.border-purple {
  border-color: #8965e0 !important;
}

.border-soft-indigo {
  border-color: #f5e8ff !important;
}

.border-soft-green {
  border-color: #2ca58d !important;
}

.border-gray-100 {
  border-color: #f3f7fa !important;
}

.border-gray-200 {
  border-color: #f5f8fb !important;
}

.border-gray-300 {
  border-color: #f0f3f6 !important;
}

.border-gray-400 {
  border-color: #eaedf2 !important;
}

.border-gray-500 {
  border-color: #d1d7e0 !important;
}

.border-gray-600 {
  border-color: #93a5be !important;
}

.border-gray-700 {
  border-color: #66799e !important;
}

.border-gray-800 {
  border-color: #506690 !important;
}

.border-facebook {
  border-color: #3b5999 !important;
}

.border-twitter {
  border-color: #1da1f2 !important;
}

.border-google {
  border-color: #db4337 !important;
}

.border-instagram {
  border-color: #e4405f !important;
}

.border-pinterest {
  border-color: #bd081c !important;
}

.border-youtube {
  border-color: #cd201f !important;
}

.border-slack {
  border-color: #3aaf85 !important;
}

.border-dribbble {
  border-color: #ea4c89 !important;
}

.border-dropbox {
  border-color: #1e90ff !important;
}

.border-twitch {
  border-color: #4b367c !important;
}

.border-paypal {
  border-color: #ecb32c !important;
}

.border-behance {
  border-color: #0057ff !important;
}

.border-reddit {
  border-color: #e84422 !important;
}

.border-github {
  border-color: #222222 !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 5rem !important;
}

.gap-7 {
  gap: 8rem !important;
}

.gap-8 {
  gap: 10rem !important;
}

.gap-9 {
  gap: 11rem !important;
}

.gap-10 {
  gap: 14rem !important;
}

.gap-11 {
  gap: 16rem !important;
}

.gap-12 {
  gap: 20rem !important;
}

.gap-sm {
  gap: 1rem !important;
}

.gap-md {
  gap: 2rem !important;
}

.gap-lg {
  gap: 4rem !important;
}

.gap-xl {
  gap: 8rem !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.justify-content-evenly {
  -webkit-box-pack: space-evenly !important;
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  -ms-grid-row-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important;
}

.order-first {
  -webkit-box-ordinal-group: 0 !important;
  -ms-flex-order: -1 !important;
  order: -1 !important;
}

.order-0 {
  -webkit-box-ordinal-group: 1 !important;
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

.order-1 {
  -webkit-box-ordinal-group: 2 !important;
  -ms-flex-order: 1 !important;
  order: 1 !important;
}

.order-2 {
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
}

.order-3 {
  -webkit-box-ordinal-group: 4 !important;
  -ms-flex-order: 3 !important;
  order: 3 !important;
}

.order-4 {
  -webkit-box-ordinal-group: 5 !important;
  -ms-flex-order: 4 !important;
  order: 4 !important;
}

.order-5 {
  -webkit-box-ordinal-group: 6 !important;
  -ms-flex-order: 5 !important;
  order: 5 !important;
}

.order-last {
  -webkit-box-ordinal-group: 7 !important;
  -ms-flex-order: 6 !important;
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.m-7 {
  margin: 8rem !important;
}

.m-8 {
  margin: 10rem !important;
}

.m-9 {
  margin: 11rem !important;
}

.m-10 {
  margin: 14rem !important;
}

.m-11 {
  margin: 16rem !important;
}

.m-12 {
  margin: 20rem !important;
}

.m-sm {
  margin: 1rem !important;
}

.m-md {
  margin: 2rem !important;
}

.m-lg {
  margin: 4rem !important;
}

.m-xl {
  margin: 8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-7 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-8 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-9 {
  margin-right: 11rem !important;
  margin-left: 11rem !important;
}

.mx-10 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-11 {
  margin-right: 16rem !important;
  margin-left: 16rem !important;
}

.mx-12 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-sm {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-md {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-lg {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-xl {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-7 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-8 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-9 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.my-10 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-11 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-12 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-sm {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-md {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-lg {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-xl {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

.mt-7 {
  margin-top: 8rem !important;
}

.mt-8 {
  margin-top: 10rem !important;
}

.mt-9 {
  margin-top: 11rem !important;
}

.mt-10 {
  margin-top: 14rem !important;
}

.mt-11 {
  margin-top: 16rem !important;
}

.mt-12 {
  margin-top: 20rem !important;
}

.mt-sm {
  margin-top: 1rem !important;
}

.mt-md {
  margin-top: 2rem !important;
}

.mt-lg {
  margin-top: 4rem !important;
}

.mt-xl {
  margin-top: 8rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 5rem !important;
}

.me-7 {
  margin-right: 8rem !important;
}

.me-8 {
  margin-right: 10rem !important;
}

.me-9 {
  margin-right: 11rem !important;
}

.me-10 {
  margin-right: 14rem !important;
}

.me-11 {
  margin-right: 16rem !important;
}

.me-12 {
  margin-right: 20rem !important;
}

.me-sm {
  margin-right: 1rem !important;
}

.me-md {
  margin-right: 2rem !important;
}

.me-lg {
  margin-right: 4rem !important;
}

.me-xl {
  margin-right: 8rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.mb-7 {
  margin-bottom: 8rem !important;
}

.mb-8 {
  margin-bottom: 10rem !important;
}

.mb-9 {
  margin-bottom: 11rem !important;
}

.mb-10 {
  margin-bottom: 14rem !important;
}

.mb-11 {
  margin-bottom: 16rem !important;
}

.mb-12 {
  margin-bottom: 20rem !important;
}

.mb-sm {
  margin-bottom: 1rem !important;
}

.mb-md {
  margin-bottom: 2rem !important;
}

.mb-lg {
  margin-bottom: 4rem !important;
}

.mb-xl {
  margin-bottom: 8rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 5rem !important;
}

.ms-7 {
  margin-left: 8rem !important;
}

.ms-8 {
  margin-left: 10rem !important;
}

.ms-9 {
  margin-left: 11rem !important;
}

.ms-10 {
  margin-left: 14rem !important;
}

.ms-11 {
  margin-left: 16rem !important;
}

.ms-12 {
  margin-left: 20rem !important;
}

.ms-sm {
  margin-left: 1rem !important;
}

.ms-md {
  margin-left: 2rem !important;
}

.ms-lg {
  margin-left: 4rem !important;
}

.ms-xl {
  margin-left: 8rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -5rem !important;
}

.m-n7 {
  margin: -8rem !important;
}

.m-n8 {
  margin: -10rem !important;
}

.m-n9 {
  margin: -11rem !important;
}

.m-n10 {
  margin: -14rem !important;
}

.m-n11 {
  margin: -16rem !important;
}

.m-n12 {
  margin: -20rem !important;
}

.m-nsm {
  margin: -1rem !important;
}

.m-nmd {
  margin: -2rem !important;
}

.m-nlg {
  margin: -4rem !important;
}

.m-nxl {
  margin: -8rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n7 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n8 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n9 {
  margin-right: -11rem !important;
  margin-left: -11rem !important;
}

.mx-n10 {
  margin-right: -14rem !important;
  margin-left: -14rem !important;
}

.mx-n11 {
  margin-right: -16rem !important;
  margin-left: -16rem !important;
}

.mx-n12 {
  margin-right: -20rem !important;
  margin-left: -20rem !important;
}

.mx-nsm {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-nmd {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-nlg {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-nxl {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n7 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n8 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n9 {
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.my-n10 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.my-n11 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.my-n12 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.my-nsm {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-nmd {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-nlg {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-nxl {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -5rem !important;
}

.mt-n7 {
  margin-top: -8rem !important;
}

.mt-n8 {
  margin-top: -10rem !important;
}

.mt-n9 {
  margin-top: -11rem !important;
}

.mt-n10 {
  margin-top: -14rem !important;
}

.mt-n11 {
  margin-top: -16rem !important;
}

.mt-n12 {
  margin-top: -20rem !important;
}

.mt-nsm {
  margin-top: -1rem !important;
}

.mt-nmd {
  margin-top: -2rem !important;
}

.mt-nlg {
  margin-top: -4rem !important;
}

.mt-nxl {
  margin-top: -8rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -5rem !important;
}

.me-n7 {
  margin-right: -8rem !important;
}

.me-n8 {
  margin-right: -10rem !important;
}

.me-n9 {
  margin-right: -11rem !important;
}

.me-n10 {
  margin-right: -14rem !important;
}

.me-n11 {
  margin-right: -16rem !important;
}

.me-n12 {
  margin-right: -20rem !important;
}

.me-nsm {
  margin-right: -1rem !important;
}

.me-nmd {
  margin-right: -2rem !important;
}

.me-nlg {
  margin-right: -4rem !important;
}

.me-nxl {
  margin-right: -8rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -5rem !important;
}

.mb-n7 {
  margin-bottom: -8rem !important;
}

.mb-n8 {
  margin-bottom: -10rem !important;
}

.mb-n9 {
  margin-bottom: -11rem !important;
}

.mb-n10 {
  margin-bottom: -14rem !important;
}

.mb-n11 {
  margin-bottom: -16rem !important;
}

.mb-n12 {
  margin-bottom: -20rem !important;
}

.mb-nsm {
  margin-bottom: -1rem !important;
}

.mb-nmd {
  margin-bottom: -2rem !important;
}

.mb-nlg {
  margin-bottom: -4rem !important;
}

.mb-nxl {
  margin-bottom: -8rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -5rem !important;
}

.ms-n7 {
  margin-left: -8rem !important;
}

.ms-n8 {
  margin-left: -10rem !important;
}

.ms-n9 {
  margin-left: -11rem !important;
}

.ms-n10 {
  margin-left: -14rem !important;
}

.ms-n11 {
  margin-left: -16rem !important;
}

.ms-n12 {
  margin-left: -20rem !important;
}

.ms-nsm {
  margin-left: -1rem !important;
}

.ms-nmd {
  margin-left: -2rem !important;
}

.ms-nlg {
  margin-left: -4rem !important;
}

.ms-nxl {
  margin-left: -8rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.p-7 {
  padding: 8rem !important;
}

.p-8 {
  padding: 10rem !important;
}

.p-9 {
  padding: 11rem !important;
}

.p-10 {
  padding: 14rem !important;
}

.p-11 {
  padding: 16rem !important;
}

.p-12 {
  padding: 20rem !important;
}

.p-sm {
  padding: 1rem !important;
}

.p-md {
  padding: 2rem !important;
}

.p-lg {
  padding: 4rem !important;
}

.p-xl {
  padding: 8rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-7 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-8 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-9 {
  padding-right: 11rem !important;
  padding-left: 11rem !important;
}

.px-10 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.px-11 {
  padding-right: 16rem !important;
  padding-left: 16rem !important;
}

.px-12 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.px-sm {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-md {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-lg {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-xl {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-7 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-8 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-9 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.py-10 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-11 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-12 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-sm {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-md {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-lg {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-xl {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 5rem !important;
}

.pt-7 {
  padding-top: 8rem !important;
}

.pt-8 {
  padding-top: 10rem !important;
}

.pt-9 {
  padding-top: 11rem !important;
}

.pt-10 {
  padding-top: 14rem !important;
}

.pt-11 {
  padding-top: 16rem !important;
}

.pt-12 {
  padding-top: 20rem !important;
}

.pt-sm {
  padding-top: 1rem !important;
}

.pt-md {
  padding-top: 2rem !important;
}

.pt-lg {
  padding-top: 4rem !important;
}

.pt-xl {
  padding-top: 8rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 5rem !important;
}

.pe-7 {
  padding-right: 8rem !important;
}

.pe-8 {
  padding-right: 10rem !important;
}

.pe-9 {
  padding-right: 11rem !important;
}

.pe-10 {
  padding-right: 14rem !important;
}

.pe-11 {
  padding-right: 16rem !important;
}

.pe-12 {
  padding-right: 20rem !important;
}

.pe-sm {
  padding-right: 1rem !important;
}

.pe-md {
  padding-right: 2rem !important;
}

.pe-lg {
  padding-right: 4rem !important;
}

.pe-xl {
  padding-right: 8rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 5rem !important;
}

.pb-7 {
  padding-bottom: 8rem !important;
}

.pb-8 {
  padding-bottom: 10rem !important;
}

.pb-9 {
  padding-bottom: 11rem !important;
}

.pb-10 {
  padding-bottom: 14rem !important;
}

.pb-11 {
  padding-bottom: 16rem !important;
}

.pb-12 {
  padding-bottom: 20rem !important;
}

.pb-sm {
  padding-bottom: 1rem !important;
}

.pb-md {
  padding-bottom: 2rem !important;
}

.pb-lg {
  padding-bottom: 4rem !important;
}

.pb-xl {
  padding-bottom: 8rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 5rem !important;
}

.ps-7 {
  padding-left: 8rem !important;
}

.ps-8 {
  padding-left: 10rem !important;
}

.ps-9 {
  padding-left: 11rem !important;
}

.ps-10 {
  padding-left: 14rem !important;
}

.ps-11 {
  padding-left: 16rem !important;
}

.ps-12 {
  padding-left: 20rem !important;
}

.ps-sm {
  padding-left: 1rem !important;
}

.ps-md {
  padding-left: 2rem !important;
}

.ps-lg {
  padding-left: 4rem !important;
}

.ps-xl {
  padding-left: 8rem !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-7 {
  font-size: 0.9rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 700 !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #262b40 !important;
}

.text-secondary {
  color: #61dafb !important;
}

.text-tertiary {
  color: #1b998b !important;
}

.text-quaternary {
  color: #c96480 !important;
}

.text-primary-app {
  color: #ebf4f6 !important;
}

.text-secondary-app {
  color: #424aa0 !important;
}

.text-light {
  color: #eaedf2 !important;
}

.text-lighten {
  color: #f0f3f6 !important;
}

.text-success {
  color: #05a677 !important;
}

.text-info {
  color: #0948b3 !important;
}

.text-warning {
  color: #f5b759 !important;
}

.text-danger {
  color: #fa5252 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-gray {
  color: #4a5073 !important;
}

.text-indigo {
  color: #4c5680 !important;
}

.text-dark {
  color: #262b40 !important;
}

.text-soft {
  color: #f5f8fb !important;
}

.text-black {
  color: #2e3650 !important;
}

.text-blue {
  color: #0948b3 !important;
}

.text-pink {
  color: #c96480 !important;
}

.text-cyan {
  color: #63b1bd !important;
}

.text-purple {
  color: #8965e0 !important;
}

.text-soft-indigo {
  color: #f5e8ff !important;
}

.text-soft-green {
  color: #2ca58d !important;
}

.text-gray-100 {
  color: #f3f7fa !important;
}

.text-gray-200 {
  color: #f5f8fb !important;
}

.text-gray-300 {
  color: #f0f3f6 !important;
}

.text-gray-400 {
  color: #eaedf2 !important;
}

.text-gray-500 {
  color: #d1d7e0 !important;
}

.text-gray-600 {
  color: #93a5be !important;
}

.text-gray-700 {
  color: #66799e !important;
}

.text-gray-800 {
  color: #506690 !important;
}

.text-facebook {
  color: #3b5999 !important;
}

.text-twitter {
  color: #1da1f2 !important;
}

.text-google {
  color: #db4337 !important;
}

.text-instagram {
  color: #e4405f !important;
}

.text-pinterest {
  color: #bd081c !important;
}

.text-youtube {
  color: #cd201f !important;
}

.text-slack {
  color: #3aaf85 !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

.text-dropbox {
  color: #1e90ff !important;
}

.text-twitch {
  color: #4b367c !important;
}

.text-paypal {
  color: #ecb32c !important;
}

.text-behance {
  color: #0057ff !important;
}

.text-reddit {
  color: #e84422 !important;
}

.text-github {
  color: #222222 !important;
}

.text-body {
  color: #4a5073 !important;
}

.text-muted {
  color: #58677d !important;
}

.text-black-50 {
  color: rgba(46, 54, 80, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #262b40 !important;
}

.bg-secondary {
  background-color: #61dafb !important;
}

.bg-tertiary {
  background-color: #1b998b !important;
}

.bg-quaternary {
  background-color: #c96480 !important;
}

.bg-primary-app {
  background-color: #ebf4f6 !important;
}

.bg-secondary-app {
  background-color: #424aa0 !important;
}

.bg-light {
  background-color: #eaedf2 !important;
}

.bg-lighten {
  background-color: #f0f3f6 !important;
}

.bg-success {
  background-color: #05a677 !important;
}

.bg-info {
  background-color: #0948b3 !important;
}

.bg-warning {
  background-color: #f5b759 !important;
}

.bg-danger {
  background-color: #fa5252 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-gray {
  background-color: #4a5073 !important;
}

.bg-indigo {
  background-color: #4c5680 !important;
}

.bg-dark {
  background-color: #262b40 !important;
}

.bg-soft {
  background-color: #f5f8fb !important;
}

.bg-black {
  background-color: #2e3650 !important;
}

.bg-blue {
  background-color: #0948b3 !important;
}

.bg-pink {
  background-color: #c96480 !important;
}

.bg-cyan {
  background-color: #63b1bd !important;
}

.bg-purple {
  background-color: #8965e0 !important;
}

.bg-soft-indigo {
  background-color: #f5e8ff !important;
}

.bg-soft-green {
  background-color: #2ca58d !important;
}

.bg-gray-100 {
  background-color: #f3f7fa !important;
}

.bg-gray-200 {
  background-color: #f5f8fb !important;
}

.bg-gray-300 {
  background-color: #f0f3f6 !important;
}

.bg-gray-400 {
  background-color: #eaedf2 !important;
}

.bg-gray-500 {
  background-color: #d1d7e0 !important;
}

.bg-gray-600 {
  background-color: #93a5be !important;
}

.bg-gray-700 {
  background-color: #66799e !important;
}

.bg-gray-800 {
  background-color: #506690 !important;
}

.bg-facebook {
  background-color: #3b5999 !important;
}

.bg-twitter {
  background-color: #1da1f2 !important;
}

.bg-google {
  background-color: #db4337 !important;
}

.bg-instagram {
  background-color: #e4405f !important;
}

.bg-pinterest {
  background-color: #bd081c !important;
}

.bg-youtube {
  background-color: #cd201f !important;
}

.bg-slack {
  background-color: #3aaf85 !important;
}

.bg-dribbble {
  background-color: #ea4c89 !important;
}

.bg-dropbox {
  background-color: #1e90ff !important;
}

.bg-twitch {
  background-color: #4b367c !important;
}

.bg-paypal {
  background-color: #ecb32c !important;
}

.bg-behance {
  background-color: #0057ff !important;
}

.bg-reddit {
  background-color: #e84422 !important;
}

.bg-github {
  background-color: #222222 !important;
}

.bg-body {
  background-color: #f5f8fb !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.5rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.1rem !important;
}

.rounded-2 {
  border-radius: 0.5rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 2rem !important;
}

.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-end {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 5rem !important;
  }

  .gap-sm-7 {
    gap: 8rem !important;
  }

  .gap-sm-8 {
    gap: 10rem !important;
  }

  .gap-sm-9 {
    gap: 11rem !important;
  }

  .gap-sm-10 {
    gap: 14rem !important;
  }

  .gap-sm-11 {
    gap: 16rem !important;
  }

  .gap-sm-12 {
    gap: 20rem !important;
  }

  .gap-sm-sm {
    gap: 1rem !important;
  }

  .gap-sm-md {
    gap: 2rem !important;
  }

  .gap-sm-lg {
    gap: 4rem !important;
  }

  .gap-sm-xl {
    gap: 8rem !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 5rem !important;
  }

  .m-sm-7 {
    margin: 8rem !important;
  }

  .m-sm-8 {
    margin: 10rem !important;
  }

  .m-sm-9 {
    margin: 11rem !important;
  }

  .m-sm-10 {
    margin: 14rem !important;
  }

  .m-sm-11 {
    margin: 16rem !important;
  }

  .m-sm-12 {
    margin: 20rem !important;
  }

  .m-sm-sm {
    margin: 1rem !important;
  }

  .m-sm-md {
    margin: 2rem !important;
  }

  .m-sm-lg {
    margin: 4rem !important;
  }

  .m-sm-xl {
    margin: 8rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-sm-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-sm-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-sm-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-sm-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-sm-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-sm-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-sm-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-sm-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-sm-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-sm-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 5rem !important;
  }

  .mt-sm-7 {
    margin-top: 8rem !important;
  }

  .mt-sm-8 {
    margin-top: 10rem !important;
  }

  .mt-sm-9 {
    margin-top: 11rem !important;
  }

  .mt-sm-10 {
    margin-top: 14rem !important;
  }

  .mt-sm-11 {
    margin-top: 16rem !important;
  }

  .mt-sm-12 {
    margin-top: 20rem !important;
  }

  .mt-sm-sm {
    margin-top: 1rem !important;
  }

  .mt-sm-md {
    margin-top: 2rem !important;
  }

  .mt-sm-lg {
    margin-top: 4rem !important;
  }

  .mt-sm-xl {
    margin-top: 8rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 5rem !important;
  }

  .me-sm-7 {
    margin-right: 8rem !important;
  }

  .me-sm-8 {
    margin-right: 10rem !important;
  }

  .me-sm-9 {
    margin-right: 11rem !important;
  }

  .me-sm-10 {
    margin-right: 14rem !important;
  }

  .me-sm-11 {
    margin-right: 16rem !important;
  }

  .me-sm-12 {
    margin-right: 20rem !important;
  }

  .me-sm-sm {
    margin-right: 1rem !important;
  }

  .me-sm-md {
    margin-right: 2rem !important;
  }

  .me-sm-lg {
    margin-right: 4rem !important;
  }

  .me-sm-xl {
    margin-right: 8rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 8rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 10rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 11rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 14rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 16rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 20rem !important;
  }

  .mb-sm-sm {
    margin-bottom: 1rem !important;
  }

  .mb-sm-md {
    margin-bottom: 2rem !important;
  }

  .mb-sm-lg {
    margin-bottom: 4rem !important;
  }

  .mb-sm-xl {
    margin-bottom: 8rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 5rem !important;
  }

  .ms-sm-7 {
    margin-left: 8rem !important;
  }

  .ms-sm-8 {
    margin-left: 10rem !important;
  }

  .ms-sm-9 {
    margin-left: 11rem !important;
  }

  .ms-sm-10 {
    margin-left: 14rem !important;
  }

  .ms-sm-11 {
    margin-left: 16rem !important;
  }

  .ms-sm-12 {
    margin-left: 20rem !important;
  }

  .ms-sm-sm {
    margin-left: 1rem !important;
  }

  .ms-sm-md {
    margin-left: 2rem !important;
  }

  .ms-sm-lg {
    margin-left: 4rem !important;
  }

  .ms-sm-xl {
    margin-left: 8rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .m-sm-n6 {
    margin: -5rem !important;
  }

  .m-sm-n7 {
    margin: -8rem !important;
  }

  .m-sm-n8 {
    margin: -10rem !important;
  }

  .m-sm-n9 {
    margin: -11rem !important;
  }

  .m-sm-n10 {
    margin: -14rem !important;
  }

  .m-sm-n11 {
    margin: -16rem !important;
  }

  .m-sm-n12 {
    margin: -20rem !important;
  }

  .m-sm-nsm {
    margin: -1rem !important;
  }

  .m-sm-nmd {
    margin: -2rem !important;
  }

  .m-sm-nlg {
    margin: -4rem !important;
  }

  .m-sm-nxl {
    margin: -8rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-sm-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-sm-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-sm-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-sm-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-sm-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-sm-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-sm-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-sm-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-sm-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-sm-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-sm-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-sm-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-sm-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .mt-sm-n6 {
    margin-top: -5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -8rem !important;
  }

  .mt-sm-n8 {
    margin-top: -10rem !important;
  }

  .mt-sm-n9 {
    margin-top: -11rem !important;
  }

  .mt-sm-n10 {
    margin-top: -14rem !important;
  }

  .mt-sm-n11 {
    margin-top: -16rem !important;
  }

  .mt-sm-n12 {
    margin-top: -20rem !important;
  }

  .mt-sm-nsm {
    margin-top: -1rem !important;
  }

  .mt-sm-nmd {
    margin-top: -2rem !important;
  }

  .mt-sm-nlg {
    margin-top: -4rem !important;
  }

  .mt-sm-nxl {
    margin-top: -8rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .me-sm-n6 {
    margin-right: -5rem !important;
  }

  .me-sm-n7 {
    margin-right: -8rem !important;
  }

  .me-sm-n8 {
    margin-right: -10rem !important;
  }

  .me-sm-n9 {
    margin-right: -11rem !important;
  }

  .me-sm-n10 {
    margin-right: -14rem !important;
  }

  .me-sm-n11 {
    margin-right: -16rem !important;
  }

  .me-sm-n12 {
    margin-right: -20rem !important;
  }

  .me-sm-nsm {
    margin-right: -1rem !important;
  }

  .me-sm-nmd {
    margin-right: -2rem !important;
  }

  .me-sm-nlg {
    margin-right: -4rem !important;
  }

  .me-sm-nxl {
    margin-right: -8rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -8rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -10rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -11rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -14rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -16rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -20rem !important;
  }

  .mb-sm-nsm {
    margin-bottom: -1rem !important;
  }

  .mb-sm-nmd {
    margin-bottom: -2rem !important;
  }

  .mb-sm-nlg {
    margin-bottom: -4rem !important;
  }

  .mb-sm-nxl {
    margin-bottom: -8rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .ms-sm-n6 {
    margin-left: -5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -8rem !important;
  }

  .ms-sm-n8 {
    margin-left: -10rem !important;
  }

  .ms-sm-n9 {
    margin-left: -11rem !important;
  }

  .ms-sm-n10 {
    margin-left: -14rem !important;
  }

  .ms-sm-n11 {
    margin-left: -16rem !important;
  }

  .ms-sm-n12 {
    margin-left: -20rem !important;
  }

  .ms-sm-nsm {
    margin-left: -1rem !important;
  }

  .ms-sm-nmd {
    margin-left: -2rem !important;
  }

  .ms-sm-nlg {
    margin-left: -4rem !important;
  }

  .ms-sm-nxl {
    margin-left: -8rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 5rem !important;
  }

  .p-sm-7 {
    padding: 8rem !important;
  }

  .p-sm-8 {
    padding: 10rem !important;
  }

  .p-sm-9 {
    padding: 11rem !important;
  }

  .p-sm-10 {
    padding: 14rem !important;
  }

  .p-sm-11 {
    padding: 16rem !important;
  }

  .p-sm-12 {
    padding: 20rem !important;
  }

  .p-sm-sm {
    padding: 1rem !important;
  }

  .p-sm-md {
    padding: 2rem !important;
  }

  .p-sm-lg {
    padding: 4rem !important;
  }

  .p-sm-xl {
    padding: 8rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-sm-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-sm-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-sm-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-sm-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-sm-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-sm-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-sm-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-sm-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-sm-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-sm-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-sm-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-sm-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-sm-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 5rem !important;
  }

  .pt-sm-7 {
    padding-top: 8rem !important;
  }

  .pt-sm-8 {
    padding-top: 10rem !important;
  }

  .pt-sm-9 {
    padding-top: 11rem !important;
  }

  .pt-sm-10 {
    padding-top: 14rem !important;
  }

  .pt-sm-11 {
    padding-top: 16rem !important;
  }

  .pt-sm-12 {
    padding-top: 20rem !important;
  }

  .pt-sm-sm {
    padding-top: 1rem !important;
  }

  .pt-sm-md {
    padding-top: 2rem !important;
  }

  .pt-sm-lg {
    padding-top: 4rem !important;
  }

  .pt-sm-xl {
    padding-top: 8rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 5rem !important;
  }

  .pe-sm-7 {
    padding-right: 8rem !important;
  }

  .pe-sm-8 {
    padding-right: 10rem !important;
  }

  .pe-sm-9 {
    padding-right: 11rem !important;
  }

  .pe-sm-10 {
    padding-right: 14rem !important;
  }

  .pe-sm-11 {
    padding-right: 16rem !important;
  }

  .pe-sm-12 {
    padding-right: 20rem !important;
  }

  .pe-sm-sm {
    padding-right: 1rem !important;
  }

  .pe-sm-md {
    padding-right: 2rem !important;
  }

  .pe-sm-lg {
    padding-right: 4rem !important;
  }

  .pe-sm-xl {
    padding-right: 8rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 8rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 10rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 11rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 14rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 16rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 20rem !important;
  }

  .pb-sm-sm {
    padding-bottom: 1rem !important;
  }

  .pb-sm-md {
    padding-bottom: 2rem !important;
  }

  .pb-sm-lg {
    padding-bottom: 4rem !important;
  }

  .pb-sm-xl {
    padding-bottom: 8rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 5rem !important;
  }

  .ps-sm-7 {
    padding-left: 8rem !important;
  }

  .ps-sm-8 {
    padding-left: 10rem !important;
  }

  .ps-sm-9 {
    padding-left: 11rem !important;
  }

  .ps-sm-10 {
    padding-left: 14rem !important;
  }

  .ps-sm-11 {
    padding-left: 16rem !important;
  }

  .ps-sm-12 {
    padding-left: 20rem !important;
  }

  .ps-sm-sm {
    padding-left: 1rem !important;
  }

  .ps-sm-md {
    padding-left: 2rem !important;
  }

  .ps-sm-lg {
    padding-left: 4rem !important;
  }

  .ps-sm-xl {
    padding-left: 8rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 5rem !important;
  }

  .gap-md-7 {
    gap: 8rem !important;
  }

  .gap-md-8 {
    gap: 10rem !important;
  }

  .gap-md-9 {
    gap: 11rem !important;
  }

  .gap-md-10 {
    gap: 14rem !important;
  }

  .gap-md-11 {
    gap: 16rem !important;
  }

  .gap-md-12 {
    gap: 20rem !important;
  }

  .gap-md-sm {
    gap: 1rem !important;
  }

  .gap-md-md {
    gap: 2rem !important;
  }

  .gap-md-lg {
    gap: 4rem !important;
  }

  .gap-md-xl {
    gap: 8rem !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 5rem !important;
  }

  .m-md-7 {
    margin: 8rem !important;
  }

  .m-md-8 {
    margin: 10rem !important;
  }

  .m-md-9 {
    margin: 11rem !important;
  }

  .m-md-10 {
    margin: 14rem !important;
  }

  .m-md-11 {
    margin: 16rem !important;
  }

  .m-md-12 {
    margin: 20rem !important;
  }

  .m-md-sm {
    margin: 1rem !important;
  }

  .m-md-md {
    margin: 2rem !important;
  }

  .m-md-lg {
    margin: 4rem !important;
  }

  .m-md-xl {
    margin: 8rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-md-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-md-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-md-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-md-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-md-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-md-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-md-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-md-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-md-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-md-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 5rem !important;
  }

  .mt-md-7 {
    margin-top: 8rem !important;
  }

  .mt-md-8 {
    margin-top: 10rem !important;
  }

  .mt-md-9 {
    margin-top: 11rem !important;
  }

  .mt-md-10 {
    margin-top: 14rem !important;
  }

  .mt-md-11 {
    margin-top: 16rem !important;
  }

  .mt-md-12 {
    margin-top: 20rem !important;
  }

  .mt-md-sm {
    margin-top: 1rem !important;
  }

  .mt-md-md {
    margin-top: 2rem !important;
  }

  .mt-md-lg {
    margin-top: 4rem !important;
  }

  .mt-md-xl {
    margin-top: 8rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 5rem !important;
  }

  .me-md-7 {
    margin-right: 8rem !important;
  }

  .me-md-8 {
    margin-right: 10rem !important;
  }

  .me-md-9 {
    margin-right: 11rem !important;
  }

  .me-md-10 {
    margin-right: 14rem !important;
  }

  .me-md-11 {
    margin-right: 16rem !important;
  }

  .me-md-12 {
    margin-right: 20rem !important;
  }

  .me-md-sm {
    margin-right: 1rem !important;
  }

  .me-md-md {
    margin-right: 2rem !important;
  }

  .me-md-lg {
    margin-right: 4rem !important;
  }

  .me-md-xl {
    margin-right: 8rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 8rem !important;
  }

  .mb-md-8 {
    margin-bottom: 10rem !important;
  }

  .mb-md-9 {
    margin-bottom: 11rem !important;
  }

  .mb-md-10 {
    margin-bottom: 14rem !important;
  }

  .mb-md-11 {
    margin-bottom: 16rem !important;
  }

  .mb-md-12 {
    margin-bottom: 20rem !important;
  }

  .mb-md-sm {
    margin-bottom: 1rem !important;
  }

  .mb-md-md {
    margin-bottom: 2rem !important;
  }

  .mb-md-lg {
    margin-bottom: 4rem !important;
  }

  .mb-md-xl {
    margin-bottom: 8rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 5rem !important;
  }

  .ms-md-7 {
    margin-left: 8rem !important;
  }

  .ms-md-8 {
    margin-left: 10rem !important;
  }

  .ms-md-9 {
    margin-left: 11rem !important;
  }

  .ms-md-10 {
    margin-left: 14rem !important;
  }

  .ms-md-11 {
    margin-left: 16rem !important;
  }

  .ms-md-12 {
    margin-left: 20rem !important;
  }

  .ms-md-sm {
    margin-left: 1rem !important;
  }

  .ms-md-md {
    margin-left: 2rem !important;
  }

  .ms-md-lg {
    margin-left: 4rem !important;
  }

  .ms-md-xl {
    margin-left: 8rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .m-md-n6 {
    margin: -5rem !important;
  }

  .m-md-n7 {
    margin: -8rem !important;
  }

  .m-md-n8 {
    margin: -10rem !important;
  }

  .m-md-n9 {
    margin: -11rem !important;
  }

  .m-md-n10 {
    margin: -14rem !important;
  }

  .m-md-n11 {
    margin: -16rem !important;
  }

  .m-md-n12 {
    margin: -20rem !important;
  }

  .m-md-nsm {
    margin: -1rem !important;
  }

  .m-md-nmd {
    margin: -2rem !important;
  }

  .m-md-nlg {
    margin: -4rem !important;
  }

  .m-md-nxl {
    margin: -8rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-md-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-md-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-md-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-md-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-md-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-md-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-md-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-md-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-md-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-md-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-md-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-md-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-md-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-md-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .mt-md-n6 {
    margin-top: -5rem !important;
  }

  .mt-md-n7 {
    margin-top: -8rem !important;
  }

  .mt-md-n8 {
    margin-top: -10rem !important;
  }

  .mt-md-n9 {
    margin-top: -11rem !important;
  }

  .mt-md-n10 {
    margin-top: -14rem !important;
  }

  .mt-md-n11 {
    margin-top: -16rem !important;
  }

  .mt-md-n12 {
    margin-top: -20rem !important;
  }

  .mt-md-nsm {
    margin-top: -1rem !important;
  }

  .mt-md-nmd {
    margin-top: -2rem !important;
  }

  .mt-md-nlg {
    margin-top: -4rem !important;
  }

  .mt-md-nxl {
    margin-top: -8rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .me-md-n6 {
    margin-right: -5rem !important;
  }

  .me-md-n7 {
    margin-right: -8rem !important;
  }

  .me-md-n8 {
    margin-right: -10rem !important;
  }

  .me-md-n9 {
    margin-right: -11rem !important;
  }

  .me-md-n10 {
    margin-right: -14rem !important;
  }

  .me-md-n11 {
    margin-right: -16rem !important;
  }

  .me-md-n12 {
    margin-right: -20rem !important;
  }

  .me-md-nsm {
    margin-right: -1rem !important;
  }

  .me-md-nmd {
    margin-right: -2rem !important;
  }

  .me-md-nlg {
    margin-right: -4rem !important;
  }

  .me-md-nxl {
    margin-right: -8rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -8rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -10rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -11rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -14rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -16rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -20rem !important;
  }

  .mb-md-nsm {
    margin-bottom: -1rem !important;
  }

  .mb-md-nmd {
    margin-bottom: -2rem !important;
  }

  .mb-md-nlg {
    margin-bottom: -4rem !important;
  }

  .mb-md-nxl {
    margin-bottom: -8rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .ms-md-n6 {
    margin-left: -5rem !important;
  }

  .ms-md-n7 {
    margin-left: -8rem !important;
  }

  .ms-md-n8 {
    margin-left: -10rem !important;
  }

  .ms-md-n9 {
    margin-left: -11rem !important;
  }

  .ms-md-n10 {
    margin-left: -14rem !important;
  }

  .ms-md-n11 {
    margin-left: -16rem !important;
  }

  .ms-md-n12 {
    margin-left: -20rem !important;
  }

  .ms-md-nsm {
    margin-left: -1rem !important;
  }

  .ms-md-nmd {
    margin-left: -2rem !important;
  }

  .ms-md-nlg {
    margin-left: -4rem !important;
  }

  .ms-md-nxl {
    margin-left: -8rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .p-md-7 {
    padding: 8rem !important;
  }

  .p-md-8 {
    padding: 10rem !important;
  }

  .p-md-9 {
    padding: 11rem !important;
  }

  .p-md-10 {
    padding: 14rem !important;
  }

  .p-md-11 {
    padding: 16rem !important;
  }

  .p-md-12 {
    padding: 20rem !important;
  }

  .p-md-sm {
    padding: 1rem !important;
  }

  .p-md-md {
    padding: 2rem !important;
  }

  .p-md-lg {
    padding: 4rem !important;
  }

  .p-md-xl {
    padding: 8rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-md-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-md-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-md-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-md-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-md-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-md-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-md-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-md-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-md-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-md-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-md-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-md-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-md-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 5rem !important;
  }

  .pt-md-7 {
    padding-top: 8rem !important;
  }

  .pt-md-8 {
    padding-top: 10rem !important;
  }

  .pt-md-9 {
    padding-top: 11rem !important;
  }

  .pt-md-10 {
    padding-top: 14rem !important;
  }

  .pt-md-11 {
    padding-top: 16rem !important;
  }

  .pt-md-12 {
    padding-top: 20rem !important;
  }

  .pt-md-sm {
    padding-top: 1rem !important;
  }

  .pt-md-md {
    padding-top: 2rem !important;
  }

  .pt-md-lg {
    padding-top: 4rem !important;
  }

  .pt-md-xl {
    padding-top: 8rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 5rem !important;
  }

  .pe-md-7 {
    padding-right: 8rem !important;
  }

  .pe-md-8 {
    padding-right: 10rem !important;
  }

  .pe-md-9 {
    padding-right: 11rem !important;
  }

  .pe-md-10 {
    padding-right: 14rem !important;
  }

  .pe-md-11 {
    padding-right: 16rem !important;
  }

  .pe-md-12 {
    padding-right: 20rem !important;
  }

  .pe-md-sm {
    padding-right: 1rem !important;
  }

  .pe-md-md {
    padding-right: 2rem !important;
  }

  .pe-md-lg {
    padding-right: 4rem !important;
  }

  .pe-md-xl {
    padding-right: 8rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 8rem !important;
  }

  .pb-md-8 {
    padding-bottom: 10rem !important;
  }

  .pb-md-9 {
    padding-bottom: 11rem !important;
  }

  .pb-md-10 {
    padding-bottom: 14rem !important;
  }

  .pb-md-11 {
    padding-bottom: 16rem !important;
  }

  .pb-md-12 {
    padding-bottom: 20rem !important;
  }

  .pb-md-sm {
    padding-bottom: 1rem !important;
  }

  .pb-md-md {
    padding-bottom: 2rem !important;
  }

  .pb-md-lg {
    padding-bottom: 4rem !important;
  }

  .pb-md-xl {
    padding-bottom: 8rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 5rem !important;
  }

  .ps-md-7 {
    padding-left: 8rem !important;
  }

  .ps-md-8 {
    padding-left: 10rem !important;
  }

  .ps-md-9 {
    padding-left: 11rem !important;
  }

  .ps-md-10 {
    padding-left: 14rem !important;
  }

  .ps-md-11 {
    padding-left: 16rem !important;
  }

  .ps-md-12 {
    padding-left: 20rem !important;
  }

  .ps-md-sm {
    padding-left: 1rem !important;
  }

  .ps-md-md {
    padding-left: 2rem !important;
  }

  .ps-md-lg {
    padding-left: 4rem !important;
  }

  .ps-md-xl {
    padding-left: 8rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 5rem !important;
  }

  .gap-lg-7 {
    gap: 8rem !important;
  }

  .gap-lg-8 {
    gap: 10rem !important;
  }

  .gap-lg-9 {
    gap: 11rem !important;
  }

  .gap-lg-10 {
    gap: 14rem !important;
  }

  .gap-lg-11 {
    gap: 16rem !important;
  }

  .gap-lg-12 {
    gap: 20rem !important;
  }

  .gap-lg-sm {
    gap: 1rem !important;
  }

  .gap-lg-md {
    gap: 2rem !important;
  }

  .gap-lg-lg {
    gap: 4rem !important;
  }

  .gap-lg-xl {
    gap: 8rem !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 5rem !important;
  }

  .m-lg-7 {
    margin: 8rem !important;
  }

  .m-lg-8 {
    margin: 10rem !important;
  }

  .m-lg-9 {
    margin: 11rem !important;
  }

  .m-lg-10 {
    margin: 14rem !important;
  }

  .m-lg-11 {
    margin: 16rem !important;
  }

  .m-lg-12 {
    margin: 20rem !important;
  }

  .m-lg-sm {
    margin: 1rem !important;
  }

  .m-lg-md {
    margin: 2rem !important;
  }

  .m-lg-lg {
    margin: 4rem !important;
  }

  .m-lg-xl {
    margin: 8rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-lg-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-lg-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-lg-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-lg-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-lg-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-lg-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-lg-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-lg-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-lg-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-lg-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 5rem !important;
  }

  .mt-lg-7 {
    margin-top: 8rem !important;
  }

  .mt-lg-8 {
    margin-top: 10rem !important;
  }

  .mt-lg-9 {
    margin-top: 11rem !important;
  }

  .mt-lg-10 {
    margin-top: 14rem !important;
  }

  .mt-lg-11 {
    margin-top: 16rem !important;
  }

  .mt-lg-12 {
    margin-top: 20rem !important;
  }

  .mt-lg-sm {
    margin-top: 1rem !important;
  }

  .mt-lg-md {
    margin-top: 2rem !important;
  }

  .mt-lg-lg {
    margin-top: 4rem !important;
  }

  .mt-lg-xl {
    margin-top: 8rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 5rem !important;
  }

  .me-lg-7 {
    margin-right: 8rem !important;
  }

  .me-lg-8 {
    margin-right: 10rem !important;
  }

  .me-lg-9 {
    margin-right: 11rem !important;
  }

  .me-lg-10 {
    margin-right: 14rem !important;
  }

  .me-lg-11 {
    margin-right: 16rem !important;
  }

  .me-lg-12 {
    margin-right: 20rem !important;
  }

  .me-lg-sm {
    margin-right: 1rem !important;
  }

  .me-lg-md {
    margin-right: 2rem !important;
  }

  .me-lg-lg {
    margin-right: 4rem !important;
  }

  .me-lg-xl {
    margin-right: 8rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 8rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 10rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 11rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 14rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 16rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 20rem !important;
  }

  .mb-lg-sm {
    margin-bottom: 1rem !important;
  }

  .mb-lg-md {
    margin-bottom: 2rem !important;
  }

  .mb-lg-lg {
    margin-bottom: 4rem !important;
  }

  .mb-lg-xl {
    margin-bottom: 8rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 5rem !important;
  }

  .ms-lg-7 {
    margin-left: 8rem !important;
  }

  .ms-lg-8 {
    margin-left: 10rem !important;
  }

  .ms-lg-9 {
    margin-left: 11rem !important;
  }

  .ms-lg-10 {
    margin-left: 14rem !important;
  }

  .ms-lg-11 {
    margin-left: 16rem !important;
  }

  .ms-lg-12 {
    margin-left: 20rem !important;
  }

  .ms-lg-sm {
    margin-left: 1rem !important;
  }

  .ms-lg-md {
    margin-left: 2rem !important;
  }

  .ms-lg-lg {
    margin-left: 4rem !important;
  }

  .ms-lg-xl {
    margin-left: 8rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .m-lg-n6 {
    margin: -5rem !important;
  }

  .m-lg-n7 {
    margin: -8rem !important;
  }

  .m-lg-n8 {
    margin: -10rem !important;
  }

  .m-lg-n9 {
    margin: -11rem !important;
  }

  .m-lg-n10 {
    margin: -14rem !important;
  }

  .m-lg-n11 {
    margin: -16rem !important;
  }

  .m-lg-n12 {
    margin: -20rem !important;
  }

  .m-lg-nsm {
    margin: -1rem !important;
  }

  .m-lg-nmd {
    margin: -2rem !important;
  }

  .m-lg-nlg {
    margin: -4rem !important;
  }

  .m-lg-nxl {
    margin: -8rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-lg-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-lg-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-lg-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-lg-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-lg-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-lg-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-lg-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-lg-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-lg-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-lg-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-lg-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-lg-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-lg-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .mt-lg-n6 {
    margin-top: -5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -8rem !important;
  }

  .mt-lg-n8 {
    margin-top: -10rem !important;
  }

  .mt-lg-n9 {
    margin-top: -11rem !important;
  }

  .mt-lg-n10 {
    margin-top: -14rem !important;
  }

  .mt-lg-n11 {
    margin-top: -16rem !important;
  }

  .mt-lg-n12 {
    margin-top: -20rem !important;
  }

  .mt-lg-nsm {
    margin-top: -1rem !important;
  }

  .mt-lg-nmd {
    margin-top: -2rem !important;
  }

  .mt-lg-nlg {
    margin-top: -4rem !important;
  }

  .mt-lg-nxl {
    margin-top: -8rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .me-lg-n6 {
    margin-right: -5rem !important;
  }

  .me-lg-n7 {
    margin-right: -8rem !important;
  }

  .me-lg-n8 {
    margin-right: -10rem !important;
  }

  .me-lg-n9 {
    margin-right: -11rem !important;
  }

  .me-lg-n10 {
    margin-right: -14rem !important;
  }

  .me-lg-n11 {
    margin-right: -16rem !important;
  }

  .me-lg-n12 {
    margin-right: -20rem !important;
  }

  .me-lg-nsm {
    margin-right: -1rem !important;
  }

  .me-lg-nmd {
    margin-right: -2rem !important;
  }

  .me-lg-nlg {
    margin-right: -4rem !important;
  }

  .me-lg-nxl {
    margin-right: -8rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -8rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -10rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -11rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -14rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -16rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -20rem !important;
  }

  .mb-lg-nsm {
    margin-bottom: -1rem !important;
  }

  .mb-lg-nmd {
    margin-bottom: -2rem !important;
  }

  .mb-lg-nlg {
    margin-bottom: -4rem !important;
  }

  .mb-lg-nxl {
    margin-bottom: -8rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .ms-lg-n6 {
    margin-left: -5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -8rem !important;
  }

  .ms-lg-n8 {
    margin-left: -10rem !important;
  }

  .ms-lg-n9 {
    margin-left: -11rem !important;
  }

  .ms-lg-n10 {
    margin-left: -14rem !important;
  }

  .ms-lg-n11 {
    margin-left: -16rem !important;
  }

  .ms-lg-n12 {
    margin-left: -20rem !important;
  }

  .ms-lg-nsm {
    margin-left: -1rem !important;
  }

  .ms-lg-nmd {
    margin-left: -2rem !important;
  }

  .ms-lg-nlg {
    margin-left: -4rem !important;
  }

  .ms-lg-nxl {
    margin-left: -8rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 5rem !important;
  }

  .p-lg-7 {
    padding: 8rem !important;
  }

  .p-lg-8 {
    padding: 10rem !important;
  }

  .p-lg-9 {
    padding: 11rem !important;
  }

  .p-lg-10 {
    padding: 14rem !important;
  }

  .p-lg-11 {
    padding: 16rem !important;
  }

  .p-lg-12 {
    padding: 20rem !important;
  }

  .p-lg-sm {
    padding: 1rem !important;
  }

  .p-lg-md {
    padding: 2rem !important;
  }

  .p-lg-lg {
    padding: 4rem !important;
  }

  .p-lg-xl {
    padding: 8rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-lg-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-lg-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-lg-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-lg-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-lg-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-lg-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-lg-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-lg-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-lg-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-lg-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-lg-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-lg-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-lg-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 5rem !important;
  }

  .pt-lg-7 {
    padding-top: 8rem !important;
  }

  .pt-lg-8 {
    padding-top: 10rem !important;
  }

  .pt-lg-9 {
    padding-top: 11rem !important;
  }

  .pt-lg-10 {
    padding-top: 14rem !important;
  }

  .pt-lg-11 {
    padding-top: 16rem !important;
  }

  .pt-lg-12 {
    padding-top: 20rem !important;
  }

  .pt-lg-sm {
    padding-top: 1rem !important;
  }

  .pt-lg-md {
    padding-top: 2rem !important;
  }

  .pt-lg-lg {
    padding-top: 4rem !important;
  }

  .pt-lg-xl {
    padding-top: 8rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 5rem !important;
  }

  .pe-lg-7 {
    padding-right: 8rem !important;
  }

  .pe-lg-8 {
    padding-right: 10rem !important;
  }

  .pe-lg-9 {
    padding-right: 11rem !important;
  }

  .pe-lg-10 {
    padding-right: 14rem !important;
  }

  .pe-lg-11 {
    padding-right: 16rem !important;
  }

  .pe-lg-12 {
    padding-right: 20rem !important;
  }

  .pe-lg-sm {
    padding-right: 1rem !important;
  }

  .pe-lg-md {
    padding-right: 2rem !important;
  }

  .pe-lg-lg {
    padding-right: 4rem !important;
  }

  .pe-lg-xl {
    padding-right: 8rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 8rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 10rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 11rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 14rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 16rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 20rem !important;
  }

  .pb-lg-sm {
    padding-bottom: 1rem !important;
  }

  .pb-lg-md {
    padding-bottom: 2rem !important;
  }

  .pb-lg-lg {
    padding-bottom: 4rem !important;
  }

  .pb-lg-xl {
    padding-bottom: 8rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 5rem !important;
  }

  .ps-lg-7 {
    padding-left: 8rem !important;
  }

  .ps-lg-8 {
    padding-left: 10rem !important;
  }

  .ps-lg-9 {
    padding-left: 11rem !important;
  }

  .ps-lg-10 {
    padding-left: 14rem !important;
  }

  .ps-lg-11 {
    padding-left: 16rem !important;
  }

  .ps-lg-12 {
    padding-left: 20rem !important;
  }

  .ps-lg-sm {
    padding-left: 1rem !important;
  }

  .ps-lg-md {
    padding-left: 2rem !important;
  }

  .ps-lg-lg {
    padding-left: 4rem !important;
  }

  .ps-lg-xl {
    padding-left: 8rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 5rem !important;
  }

  .gap-xl-7 {
    gap: 8rem !important;
  }

  .gap-xl-8 {
    gap: 10rem !important;
  }

  .gap-xl-9 {
    gap: 11rem !important;
  }

  .gap-xl-10 {
    gap: 14rem !important;
  }

  .gap-xl-11 {
    gap: 16rem !important;
  }

  .gap-xl-12 {
    gap: 20rem !important;
  }

  .gap-xl-sm {
    gap: 1rem !important;
  }

  .gap-xl-md {
    gap: 2rem !important;
  }

  .gap-xl-lg {
    gap: 4rem !important;
  }

  .gap-xl-xl {
    gap: 8rem !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 5rem !important;
  }

  .m-xl-7 {
    margin: 8rem !important;
  }

  .m-xl-8 {
    margin: 10rem !important;
  }

  .m-xl-9 {
    margin: 11rem !important;
  }

  .m-xl-10 {
    margin: 14rem !important;
  }

  .m-xl-11 {
    margin: 16rem !important;
  }

  .m-xl-12 {
    margin: 20rem !important;
  }

  .m-xl-sm {
    margin: 1rem !important;
  }

  .m-xl-md {
    margin: 2rem !important;
  }

  .m-xl-lg {
    margin: 4rem !important;
  }

  .m-xl-xl {
    margin: 8rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xl-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-xl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-xl-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-xl-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-xl-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xl-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-xl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xl-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xl-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xl-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 5rem !important;
  }

  .mt-xl-7 {
    margin-top: 8rem !important;
  }

  .mt-xl-8 {
    margin-top: 10rem !important;
  }

  .mt-xl-9 {
    margin-top: 11rem !important;
  }

  .mt-xl-10 {
    margin-top: 14rem !important;
  }

  .mt-xl-11 {
    margin-top: 16rem !important;
  }

  .mt-xl-12 {
    margin-top: 20rem !important;
  }

  .mt-xl-sm {
    margin-top: 1rem !important;
  }

  .mt-xl-md {
    margin-top: 2rem !important;
  }

  .mt-xl-lg {
    margin-top: 4rem !important;
  }

  .mt-xl-xl {
    margin-top: 8rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 5rem !important;
  }

  .me-xl-7 {
    margin-right: 8rem !important;
  }

  .me-xl-8 {
    margin-right: 10rem !important;
  }

  .me-xl-9 {
    margin-right: 11rem !important;
  }

  .me-xl-10 {
    margin-right: 14rem !important;
  }

  .me-xl-11 {
    margin-right: 16rem !important;
  }

  .me-xl-12 {
    margin-right: 20rem !important;
  }

  .me-xl-sm {
    margin-right: 1rem !important;
  }

  .me-xl-md {
    margin-right: 2rem !important;
  }

  .me-xl-lg {
    margin-right: 4rem !important;
  }

  .me-xl-xl {
    margin-right: 8rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 8rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 10rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 11rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 14rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 16rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 20rem !important;
  }

  .mb-xl-sm {
    margin-bottom: 1rem !important;
  }

  .mb-xl-md {
    margin-bottom: 2rem !important;
  }

  .mb-xl-lg {
    margin-bottom: 4rem !important;
  }

  .mb-xl-xl {
    margin-bottom: 8rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 5rem !important;
  }

  .ms-xl-7 {
    margin-left: 8rem !important;
  }

  .ms-xl-8 {
    margin-left: 10rem !important;
  }

  .ms-xl-9 {
    margin-left: 11rem !important;
  }

  .ms-xl-10 {
    margin-left: 14rem !important;
  }

  .ms-xl-11 {
    margin-left: 16rem !important;
  }

  .ms-xl-12 {
    margin-left: 20rem !important;
  }

  .ms-xl-sm {
    margin-left: 1rem !important;
  }

  .ms-xl-md {
    margin-left: 2rem !important;
  }

  .ms-xl-lg {
    margin-left: 4rem !important;
  }

  .ms-xl-xl {
    margin-left: 8rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .m-xl-n6 {
    margin: -5rem !important;
  }

  .m-xl-n7 {
    margin: -8rem !important;
  }

  .m-xl-n8 {
    margin: -10rem !important;
  }

  .m-xl-n9 {
    margin: -11rem !important;
  }

  .m-xl-n10 {
    margin: -14rem !important;
  }

  .m-xl-n11 {
    margin: -16rem !important;
  }

  .m-xl-n12 {
    margin: -20rem !important;
  }

  .m-xl-nsm {
    margin: -1rem !important;
  }

  .m-xl-nmd {
    margin: -2rem !important;
  }

  .m-xl-nlg {
    margin: -4rem !important;
  }

  .m-xl-nxl {
    margin: -8rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xl-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xl-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-xl-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-xl-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-xl-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-xl-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xl-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xl-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xl-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-xl-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xl-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-xl-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-xl-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xl-n6 {
    margin-top: -5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -8rem !important;
  }

  .mt-xl-n8 {
    margin-top: -10rem !important;
  }

  .mt-xl-n9 {
    margin-top: -11rem !important;
  }

  .mt-xl-n10 {
    margin-top: -14rem !important;
  }

  .mt-xl-n11 {
    margin-top: -16rem !important;
  }

  .mt-xl-n12 {
    margin-top: -20rem !important;
  }

  .mt-xl-nsm {
    margin-top: -1rem !important;
  }

  .mt-xl-nmd {
    margin-top: -2rem !important;
  }

  .mt-xl-nlg {
    margin-top: -4rem !important;
  }

  .mt-xl-nxl {
    margin-top: -8rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .me-xl-n6 {
    margin-right: -5rem !important;
  }

  .me-xl-n7 {
    margin-right: -8rem !important;
  }

  .me-xl-n8 {
    margin-right: -10rem !important;
  }

  .me-xl-n9 {
    margin-right: -11rem !important;
  }

  .me-xl-n10 {
    margin-right: -14rem !important;
  }

  .me-xl-n11 {
    margin-right: -16rem !important;
  }

  .me-xl-n12 {
    margin-right: -20rem !important;
  }

  .me-xl-nsm {
    margin-right: -1rem !important;
  }

  .me-xl-nmd {
    margin-right: -2rem !important;
  }

  .me-xl-nlg {
    margin-right: -4rem !important;
  }

  .me-xl-nxl {
    margin-right: -8rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -8rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -10rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -11rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -14rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -16rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -20rem !important;
  }

  .mb-xl-nsm {
    margin-bottom: -1rem !important;
  }

  .mb-xl-nmd {
    margin-bottom: -2rem !important;
  }

  .mb-xl-nlg {
    margin-bottom: -4rem !important;
  }

  .mb-xl-nxl {
    margin-bottom: -8rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .ms-xl-n6 {
    margin-left: -5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -8rem !important;
  }

  .ms-xl-n8 {
    margin-left: -10rem !important;
  }

  .ms-xl-n9 {
    margin-left: -11rem !important;
  }

  .ms-xl-n10 {
    margin-left: -14rem !important;
  }

  .ms-xl-n11 {
    margin-left: -16rem !important;
  }

  .ms-xl-n12 {
    margin-left: -20rem !important;
  }

  .ms-xl-nsm {
    margin-left: -1rem !important;
  }

  .ms-xl-nmd {
    margin-left: -2rem !important;
  }

  .ms-xl-nlg {
    margin-left: -4rem !important;
  }

  .ms-xl-nxl {
    margin-left: -8rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 5rem !important;
  }

  .p-xl-7 {
    padding: 8rem !important;
  }

  .p-xl-8 {
    padding: 10rem !important;
  }

  .p-xl-9 {
    padding: 11rem !important;
  }

  .p-xl-10 {
    padding: 14rem !important;
  }

  .p-xl-11 {
    padding: 16rem !important;
  }

  .p-xl-12 {
    padding: 20rem !important;
  }

  .p-xl-sm {
    padding: 1rem !important;
  }

  .p-xl-md {
    padding: 2rem !important;
  }

  .p-xl-lg {
    padding: 4rem !important;
  }

  .p-xl-xl {
    padding: 8rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xl-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xl-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xl-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-xl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-xl-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-xl-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-xl-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xl-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xl-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-xl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xl-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-xl-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-xl-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 5rem !important;
  }

  .pt-xl-7 {
    padding-top: 8rem !important;
  }

  .pt-xl-8 {
    padding-top: 10rem !important;
  }

  .pt-xl-9 {
    padding-top: 11rem !important;
  }

  .pt-xl-10 {
    padding-top: 14rem !important;
  }

  .pt-xl-11 {
    padding-top: 16rem !important;
  }

  .pt-xl-12 {
    padding-top: 20rem !important;
  }

  .pt-xl-sm {
    padding-top: 1rem !important;
  }

  .pt-xl-md {
    padding-top: 2rem !important;
  }

  .pt-xl-lg {
    padding-top: 4rem !important;
  }

  .pt-xl-xl {
    padding-top: 8rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 5rem !important;
  }

  .pe-xl-7 {
    padding-right: 8rem !important;
  }

  .pe-xl-8 {
    padding-right: 10rem !important;
  }

  .pe-xl-9 {
    padding-right: 11rem !important;
  }

  .pe-xl-10 {
    padding-right: 14rem !important;
  }

  .pe-xl-11 {
    padding-right: 16rem !important;
  }

  .pe-xl-12 {
    padding-right: 20rem !important;
  }

  .pe-xl-sm {
    padding-right: 1rem !important;
  }

  .pe-xl-md {
    padding-right: 2rem !important;
  }

  .pe-xl-lg {
    padding-right: 4rem !important;
  }

  .pe-xl-xl {
    padding-right: 8rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 8rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 10rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 11rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 14rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 16rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 20rem !important;
  }

  .pb-xl-sm {
    padding-bottom: 1rem !important;
  }

  .pb-xl-md {
    padding-bottom: 2rem !important;
  }

  .pb-xl-lg {
    padding-bottom: 4rem !important;
  }

  .pb-xl-xl {
    padding-bottom: 8rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 5rem !important;
  }

  .ps-xl-7 {
    padding-left: 8rem !important;
  }

  .ps-xl-8 {
    padding-left: 10rem !important;
  }

  .ps-xl-9 {
    padding-left: 11rem !important;
  }

  .ps-xl-10 {
    padding-left: 14rem !important;
  }

  .ps-xl-11 {
    padding-left: 16rem !important;
  }

  .ps-xl-12 {
    padding-left: 20rem !important;
  }

  .ps-xl-sm {
    padding-left: 1rem !important;
  }

  .ps-xl-md {
    padding-left: 2rem !important;
  }

  .ps-xl-lg {
    padding-left: 4rem !important;
  }

  .ps-xl-xl {
    padding-left: 8rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-6 {
    gap: 5rem !important;
  }

  .gap-xxl-7 {
    gap: 8rem !important;
  }

  .gap-xxl-8 {
    gap: 10rem !important;
  }

  .gap-xxl-9 {
    gap: 11rem !important;
  }

  .gap-xxl-10 {
    gap: 14rem !important;
  }

  .gap-xxl-11 {
    gap: 16rem !important;
  }

  .gap-xxl-12 {
    gap: 20rem !important;
  }

  .gap-xxl-sm {
    gap: 1rem !important;
  }

  .gap-xxl-md {
    gap: 2rem !important;
  }

  .gap-xxl-lg {
    gap: 4rem !important;
  }

  .gap-xxl-xl {
    gap: 8rem !important;
  }

  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }

  .order-xxl-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .order-xxl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .order-xxl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }

  .order-xxl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }

  .order-xxl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }

  .order-xxl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }

  .order-xxl-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 5rem !important;
  }

  .m-xxl-7 {
    margin: 8rem !important;
  }

  .m-xxl-8 {
    margin: 10rem !important;
  }

  .m-xxl-9 {
    margin: 11rem !important;
  }

  .m-xxl-10 {
    margin: 14rem !important;
  }

  .m-xxl-11 {
    margin: 16rem !important;
  }

  .m-xxl-12 {
    margin: 20rem !important;
  }

  .m-xxl-sm {
    margin: 1rem !important;
  }

  .m-xxl-md {
    margin: 2rem !important;
  }

  .m-xxl-lg {
    margin: 4rem !important;
  }

  .m-xxl-xl {
    margin: 8rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-8 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }

  .mx-xxl-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }

  .mx-xxl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }

  .mx-xxl-11 {
    margin-right: 16rem !important;
    margin-left: 16rem !important;
  }

  .mx-xxl-12 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }

  .mx-xxl-sm {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-md {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-lg {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-xl {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-7 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-8 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .my-xxl-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .my-xxl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .my-xxl-11 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .my-xxl-12 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .my-xxl-sm {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-md {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-lg {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-xl {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 8rem !important;
  }

  .mt-xxl-8 {
    margin-top: 10rem !important;
  }

  .mt-xxl-9 {
    margin-top: 11rem !important;
  }

  .mt-xxl-10 {
    margin-top: 14rem !important;
  }

  .mt-xxl-11 {
    margin-top: 16rem !important;
  }

  .mt-xxl-12 {
    margin-top: 20rem !important;
  }

  .mt-xxl-sm {
    margin-top: 1rem !important;
  }

  .mt-xxl-md {
    margin-top: 2rem !important;
  }

  .mt-xxl-lg {
    margin-top: 4rem !important;
  }

  .mt-xxl-xl {
    margin-top: 8rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-6 {
    margin-right: 5rem !important;
  }

  .me-xxl-7 {
    margin-right: 8rem !important;
  }

  .me-xxl-8 {
    margin-right: 10rem !important;
  }

  .me-xxl-9 {
    margin-right: 11rem !important;
  }

  .me-xxl-10 {
    margin-right: 14rem !important;
  }

  .me-xxl-11 {
    margin-right: 16rem !important;
  }

  .me-xxl-12 {
    margin-right: 20rem !important;
  }

  .me-xxl-sm {
    margin-right: 1rem !important;
  }

  .me-xxl-md {
    margin-right: 2rem !important;
  }

  .me-xxl-lg {
    margin-right: 4rem !important;
  }

  .me-xxl-xl {
    margin-right: 8rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 10rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 11rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 14rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 16rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 20rem !important;
  }

  .mb-xxl-sm {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-md {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-lg {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-xl {
    margin-bottom: 8rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-6 {
    margin-left: 5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 8rem !important;
  }

  .ms-xxl-8 {
    margin-left: 10rem !important;
  }

  .ms-xxl-9 {
    margin-left: 11rem !important;
  }

  .ms-xxl-10 {
    margin-left: 14rem !important;
  }

  .ms-xxl-11 {
    margin-left: 16rem !important;
  }

  .ms-xxl-12 {
    margin-left: 20rem !important;
  }

  .ms-xxl-sm {
    margin-left: 1rem !important;
  }

  .ms-xxl-md {
    margin-left: 2rem !important;
  }

  .ms-xxl-lg {
    margin-left: 4rem !important;
  }

  .ms-xxl-xl {
    margin-left: 8rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .m-xxl-n6 {
    margin: -5rem !important;
  }

  .m-xxl-n7 {
    margin: -8rem !important;
  }

  .m-xxl-n8 {
    margin: -10rem !important;
  }

  .m-xxl-n9 {
    margin: -11rem !important;
  }

  .m-xxl-n10 {
    margin: -14rem !important;
  }

  .m-xxl-n11 {
    margin: -16rem !important;
  }

  .m-xxl-n12 {
    margin: -20rem !important;
  }

  .m-xxl-nsm {
    margin: -1rem !important;
  }

  .m-xxl-nmd {
    margin: -2rem !important;
  }

  .m-xxl-nlg {
    margin: -4rem !important;
  }

  .m-xxl-nxl {
    margin: -8rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -16rem !important;
    margin-left: -16rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }

  .mx-xxl-nsm {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-nmd {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-nlg {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-nxl {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n6 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .my-xxl-n8 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .my-xxl-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .my-xxl-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .my-xxl-n11 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .my-xxl-n12 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .my-xxl-nsm {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-nmd {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-nlg {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-nxl {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -8rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -10rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -11rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -14rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -16rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -20rem !important;
  }

  .mt-xxl-nsm {
    margin-top: -1rem !important;
  }

  .mt-xxl-nmd {
    margin-top: -2rem !important;
  }

  .mt-xxl-nlg {
    margin-top: -4rem !important;
  }

  .mt-xxl-nxl {
    margin-top: -8rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .me-xxl-n6 {
    margin-right: -5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -8rem !important;
  }

  .me-xxl-n8 {
    margin-right: -10rem !important;
  }

  .me-xxl-n9 {
    margin-right: -11rem !important;
  }

  .me-xxl-n10 {
    margin-right: -14rem !important;
  }

  .me-xxl-n11 {
    margin-right: -16rem !important;
  }

  .me-xxl-n12 {
    margin-right: -20rem !important;
  }

  .me-xxl-nsm {
    margin-right: -1rem !important;
  }

  .me-xxl-nmd {
    margin-right: -2rem !important;
  }

  .me-xxl-nlg {
    margin-right: -4rem !important;
  }

  .me-xxl-nxl {
    margin-right: -8rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -8rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -10rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -11rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -14rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -16rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -20rem !important;
  }

  .mb-xxl-nsm {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-nmd {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-nlg {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-nxl {
    margin-bottom: -8rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -8rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -10rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -11rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -14rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -16rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -20rem !important;
  }

  .ms-xxl-nsm {
    margin-left: -1rem !important;
  }

  .ms-xxl-nmd {
    margin-left: -2rem !important;
  }

  .ms-xxl-nlg {
    margin-left: -4rem !important;
  }

  .ms-xxl-nxl {
    margin-left: -8rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 5rem !important;
  }

  .p-xxl-7 {
    padding: 8rem !important;
  }

  .p-xxl-8 {
    padding: 10rem !important;
  }

  .p-xxl-9 {
    padding: 11rem !important;
  }

  .p-xxl-10 {
    padding: 14rem !important;
  }

  .p-xxl-11 {
    padding: 16rem !important;
  }

  .p-xxl-12 {
    padding: 20rem !important;
  }

  .p-xxl-sm {
    padding: 1rem !important;
  }

  .p-xxl-md {
    padding: 2rem !important;
  }

  .p-xxl-lg {
    padding: 4rem !important;
  }

  .p-xxl-xl {
    padding: 8rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .px-xxl-7 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .px-xxl-8 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }

  .px-xxl-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }

  .px-xxl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }

  .px-xxl-11 {
    padding-right: 16rem !important;
    padding-left: 16rem !important;
  }

  .px-xxl-12 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }

  .px-xxl-sm {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-md {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-lg {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-xl {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xxl-7 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .py-xxl-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .py-xxl-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .py-xxl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .py-xxl-11 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .py-xxl-12 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .py-xxl-sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-md {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-lg {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-xl {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 8rem !important;
  }

  .pt-xxl-8 {
    padding-top: 10rem !important;
  }

  .pt-xxl-9 {
    padding-top: 11rem !important;
  }

  .pt-xxl-10 {
    padding-top: 14rem !important;
  }

  .pt-xxl-11 {
    padding-top: 16rem !important;
  }

  .pt-xxl-12 {
    padding-top: 20rem !important;
  }

  .pt-xxl-sm {
    padding-top: 1rem !important;
  }

  .pt-xxl-md {
    padding-top: 2rem !important;
  }

  .pt-xxl-lg {
    padding-top: 4rem !important;
  }

  .pt-xxl-xl {
    padding-top: 8rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 8rem !important;
  }

  .pe-xxl-8 {
    padding-right: 10rem !important;
  }

  .pe-xxl-9 {
    padding-right: 11rem !important;
  }

  .pe-xxl-10 {
    padding-right: 14rem !important;
  }

  .pe-xxl-11 {
    padding-right: 16rem !important;
  }

  .pe-xxl-12 {
    padding-right: 20rem !important;
  }

  .pe-xxl-sm {
    padding-right: 1rem !important;
  }

  .pe-xxl-md {
    padding-right: 2rem !important;
  }

  .pe-xxl-lg {
    padding-right: 4rem !important;
  }

  .pe-xxl-xl {
    padding-right: 8rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 8rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 10rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 11rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 14rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 16rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 20rem !important;
  }

  .pb-xxl-sm {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-md {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-lg {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-xl {
    padding-bottom: 8rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 8rem !important;
  }

  .ps-xxl-8 {
    padding-left: 10rem !important;
  }

  .ps-xxl-9 {
    padding-left: 11rem !important;
  }

  .ps-xxl-10 {
    padding-left: 14rem !important;
  }

  .ps-xxl-11 {
    padding-left: 16rem !important;
  }

  .ps-xxl-12 {
    padding-left: 20rem !important;
  }

  .ps-xxl-sm {
    padding-left: 1rem !important;
  }

  .ps-xxl-md {
    padding-left: 2rem !important;
  }

  .ps-xxl-lg {
    padding-left: 4rem !important;
  }

  .ps-xxl-xl {
    padding-left: 8rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }

  .fs-sm-1 {
    font-size: 2.5rem !important;
  }

  .fs-sm-2 {
    font-size: 2rem !important;
  }

  .fs-sm-3 {
    font-size: 1.75rem !important;
  }

  .fs-sm-4 {
    font-size: 1.5rem !important;
  }

  .fs-md-1 {
    font-size: 2.5rem !important;
  }

  .fs-md-2 {
    font-size: 2rem !important;
  }

  .fs-md-3 {
    font-size: 1.75rem !important;
  }

  .fs-md-4 {
    font-size: 1.5rem !important;
  }

  .fs-lg-1 {
    font-size: 2.5rem !important;
  }

  .fs-lg-2 {
    font-size: 2rem !important;
  }

  .fs-lg-3 {
    font-size: 1.75rem !important;
  }

  .fs-lg-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: -ms-grid !important;
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.headroom {
  will-change: transform;
  background-color: inherit;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .headroom {
    -webkit-transition: none;
    transition: none;
  }
}

.headroom--pinned {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.headroom--unpinned {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.headroom--not-top {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.headroom--not-top.navbar-theme-primary {
  background-color: #262b40;
}

.headroom--not-top.navbar-theme-primary .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-primary .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-primary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-primary .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-secondary {
  background-color: #61dafb;
}

.headroom--not-top.navbar-theme-secondary .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-secondary .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-secondary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-secondary .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-tertiary {
  background-color: #1b998b;
}

.headroom--not-top.navbar-theme-tertiary .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-tertiary .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-tertiary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-tertiary .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-quaternary {
  background-color: #c96480;
}

.headroom--not-top.navbar-theme-quaternary .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-quaternary .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-quaternary .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-quaternary .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-primary-app {
  background-color: #ebf4f6;
}

.headroom--not-top.navbar-theme-primary-app .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-primary-app .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-primary-app .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-primary-app .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-secondary-app {
  background-color: #424aa0;
}

.headroom--not-top.navbar-theme-secondary-app .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-secondary-app .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-secondary-app .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-secondary-app .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-light {
  background-color: #eaedf2;
}

.headroom--not-top.navbar-theme-light .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-light .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-light .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-light .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-lighten {
  background-color: #f0f3f6;
}

.headroom--not-top.navbar-theme-lighten .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-lighten .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-lighten .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-lighten .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-success {
  background-color: #05a677;
}

.headroom--not-top.navbar-theme-success .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-success .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-success .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-success .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-info {
  background-color: #0948b3;
}

.headroom--not-top.navbar-theme-info .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-info .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-info .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-info .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-warning {
  background-color: #f5b759;
}

.headroom--not-top.navbar-theme-warning .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-warning .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-warning .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-warning .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-danger {
  background-color: #fa5252;
}

.headroom--not-top.navbar-theme-danger .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-danger .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-danger .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-danger .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-white {
  background-color: #ffffff;
}

.headroom--not-top.navbar-theme-white .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-white .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-white .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-white .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray {
  background-color: #4a5073;
}

.headroom--not-top.navbar-theme-gray .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-indigo {
  background-color: #4c5680;
}

.headroom--not-top.navbar-theme-indigo .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-indigo .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-indigo .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-indigo .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-dark {
  background-color: #262b40;
}

.headroom--not-top.navbar-theme-dark .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-dark .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-dark .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-dark .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-soft {
  background-color: #f5f8fb;
}

.headroom--not-top.navbar-theme-soft .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-soft .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-soft .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-soft .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-black {
  background-color: #2e3650;
}

.headroom--not-top.navbar-theme-black .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-black .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-black .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-black .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-blue {
  background-color: #0948b3;
}

.headroom--not-top.navbar-theme-blue .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-blue .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-blue .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-blue .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-pink {
  background-color: #c96480;
}

.headroom--not-top.navbar-theme-pink .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-pink .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-pink .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-pink .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-cyan {
  background-color: #63b1bd;
}

.headroom--not-top.navbar-theme-cyan .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-cyan .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-cyan .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-cyan .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-purple {
  background-color: #8965e0;
}

.headroom--not-top.navbar-theme-purple .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-purple .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-purple .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-purple .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-soft-indigo {
  background-color: #f5e8ff;
}

.headroom--not-top.navbar-theme-soft-indigo .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-soft-indigo .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-soft-indigo .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-soft-indigo .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-soft-green {
  background-color: #2ca58d;
}

.headroom--not-top.navbar-theme-soft-green .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-soft-green .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-soft-green .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-soft-green .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray-100 {
  background-color: #f3f7fa;
}

.headroom--not-top.navbar-theme-gray-100 .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray-100 .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray-100 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray-100 .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray-200 {
  background-color: #f5f8fb;
}

.headroom--not-top.navbar-theme-gray-200 .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray-200 .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray-200 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray-200 .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray-300 {
  background-color: #f0f3f6;
}

.headroom--not-top.navbar-theme-gray-300 .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray-300 .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray-300 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray-300 .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray-400 {
  background-color: #eaedf2;
}

.headroom--not-top.navbar-theme-gray-400 .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray-400 .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray-400 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray-400 .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray-500 {
  background-color: #d1d7e0;
}

.headroom--not-top.navbar-theme-gray-500 .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray-500 .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray-500 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray-500 .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray-600 {
  background-color: #93a5be;
}

.headroom--not-top.navbar-theme-gray-600 .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray-600 .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray-600 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray-600 .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray-700 {
  background-color: #66799e;
}

.headroom--not-top.navbar-theme-gray-700 .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray-700 .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray-700 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray-700 .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-gray-800 {
  background-color: #506690;
}

.headroom--not-top.navbar-theme-gray-800 .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-gray-800 .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-gray-800 .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-gray-800 .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-facebook {
  background-color: #3b5999;
}

.headroom--not-top.navbar-theme-facebook .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-facebook .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-facebook .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-facebook .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-twitter {
  background-color: #1da1f2;
}

.headroom--not-top.navbar-theme-twitter .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-twitter .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-twitter .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-twitter .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-google {
  background-color: #db4337;
}

.headroom--not-top.navbar-theme-google .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-google .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-google .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-google .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-instagram {
  background-color: #e4405f;
}

.headroom--not-top.navbar-theme-instagram .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-instagram .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-instagram .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-instagram .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-pinterest {
  background-color: #bd081c;
}

.headroom--not-top.navbar-theme-pinterest .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-pinterest .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-pinterest .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-pinterest .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-youtube {
  background-color: #cd201f;
}

.headroom--not-top.navbar-theme-youtube .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-youtube .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-youtube .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-youtube .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-slack {
  background-color: #3aaf85;
}

.headroom--not-top.navbar-theme-slack .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-slack .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-slack .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-slack .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-dribbble {
  background-color: #ea4c89;
}

.headroom--not-top.navbar-theme-dribbble .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-dribbble .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-dribbble .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-dribbble .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-dropbox {
  background-color: #1e90ff;
}

.headroom--not-top.navbar-theme-dropbox .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-dropbox .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-dropbox .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-dropbox .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-twitch {
  background-color: #4b367c;
}

.headroom--not-top.navbar-theme-twitch .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-twitch .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-twitch .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-twitch .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-paypal {
  background-color: #ecb32c;
}

.headroom--not-top.navbar-theme-paypal .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-paypal .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-paypal .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-paypal .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-behance {
  background-color: #0057ff;
}

.headroom--not-top.navbar-theme-behance .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-behance .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-behance .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-behance .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-reddit {
  background-color: #e84422;
}

.headroom--not-top.navbar-theme-reddit .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-reddit .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-reddit .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-reddit .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-theme-github {
  background-color: #222222;
}

.headroom--not-top.navbar-theme-github .navbar-brand-light {
  display: none;
}

.headroom--not-top.navbar-theme-github .navbar-brand-dark {
  display: block;
}

.headroom--not-top.navbar-theme-github .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.headroom--not-top.navbar-theme-github .nav-link:hover {
  color: white;
}

.headroom--not-top.navbar-light .navbar-nav > .nav-item.show > .nav-link,
.headroom--not-top.navbar-light .navbar-nav > .nav-item > .nav-link:focus,
.headroom--not-top.navbar-light .nav-item .nav-link > span {
  color: #ffffff;
}

.headroom--not-top.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*='language-'],
pre[class*='language-'] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*='language-']::-moz-selection,
pre[class*='language-'] ::-moz-selection,
code[class*='language-']::-moz-selection,
code[class*='language-'] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*='language-'],
  pre[class*='language-'] {
    text-shadow: none;
  }
}

/* Code blocks */
pre[class*='language-'] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: rgba(255, 255, 255, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function,
.token.class-name {
  color: #dd4a68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

@media (max-width: 767.98px) {
  .prism-code {
    padding-top: 2.5rem !important;
  }
}

.ct-label {
  fill: #66799e;
  color: #66799e;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 400;
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  dominant-baseline: central;
}

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}

.ct-grid {
  stroke: #eaedf2;
  stroke-width: 1px;
  stroke-dasharray: 1px;
}

.ct-grid-background {
  fill: none;
}

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round;
}

.ct-line {
  fill: none;
  stroke-width: 3px;
}

.ct-area {
  stroke: none;
  fill-opacity: 0.1;
}

.ct-bar {
  fill: none;
  stroke-width: 7px;
}

.ct-slice-donut {
  fill: none;
  stroke-width: 50px;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #1b998b;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-area {
  fill: #1b998b;
}

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #17a5ce;
}

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-area {
  fill: #17a5ce;
}

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #262b40;
}

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-area {
  fill: #262b40;
}

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
  stroke: #f5b759;
}

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-area {
  fill: #f5b759;
}

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
  stroke: #c96480;
}

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-area {
  fill: #c96480;
}

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
  stroke: #ffffff;
}

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-area {
  fill: #ffffff;
}

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
  stroke: #17a5ce;
}

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-area {
  fill: #17a5ce;
}

.ct-square {
  display: block;
  position: relative;
  width: 100%;
}

.ct-square:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 100%;
}

.ct-square:after {
  content: '';
  display: table;
  clear: both;
}

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
}

.ct-minor-second:after {
  content: '';
  display: table;
  clear: both;
}

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 88.88889%;
}

.ct-major-second:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 83.33333%;
}

.ct-minor-third:after {
  content: '';
  display: table;
  clear: both;
}

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 80%;
}

.ct-major-third:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 75%;
}

.ct-perfect-fourth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 66.66667%;
}

.ct-perfect-fifth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
}

.ct-minor-sixth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}

.ct-golden-section:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 61.8047%;
}

.ct-golden-section:after {
  content: '';
  display: table;
  clear: both;
}

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 60%;
}

.ct-major-sixth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
}

.ct-minor-seventh:after {
  content: '';
  display: table;
  clear: both;
}

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 53.33333%;
}

.ct-major-seventh:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 50%;
}

.ct-octave:after {
  content: '';
  display: table;
  clear: both;
}

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-tenth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 40%;
}

.ct-major-tenth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
}

.ct-major-eleventh:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 33.33333%;
}

.ct-major-twelfth:after {
  content: '';
  display: table;
  clear: both;
}

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-double-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 25%;
}

.ct-double-octave:after {
  content: '';
  display: table;
  clear: both;
}

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}

.rdtPicker .dow {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.rdtPicker td {
  height: 35px !important;
}

.rdtPicker td.rdtActive {
  background-color: #61dafb !important;
  color: #262b40 !important;
  border-radius: 0.5rem;
  text-shadow: none !important;
}

.rdtPicker td.rdtDay:hover {
  border-radius: 0.5rem;
}

.rdtPicker td.rdtToday {
  background-color: #eaedf2;
  border-radius: 0.5rem;
}

.rdtPicker td.rdtToday::before {
  border-bottom: 7px solid #61dafb;
}

.rdtPicker th {
  border-bottom: 0 !important;
}

iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
  margin: 0;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 600;
}

button:focus {
  outline: 0;
}

label {
  font-weight: 600;
}

/**
 * = Backgrounds
*/
.bg-gradient-primary {
  background: linear-gradient(87deg, #262b40 0, #212845 100%) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(87deg, #61dafb 0, #5ddcff 100%) !important;
}

.bg-gradient-tertiary {
  background: linear-gradient(87deg, #1b998b 0, #12a292 100%) !important;
}

.bg-gradient-quaternary {
  background: linear-gradient(87deg, #c96480 0, #d35a7b 100%) !important;
}

.bg-gradient-primary-app {
  background: linear-gradient(87deg, #ebf4f6 0, #eaf5f7 100%) !important;
}

.bg-gradient-secondary-app {
  background: linear-gradient(87deg, #424aa0 0, #3741ab 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #eaedf2 0, #e8edf4 100%) !important;
}

.bg-gradient-lighten {
  background: linear-gradient(87deg, #f0f3f6 0, #eff3f7 100%) !important;
}

.bg-gradient-success {
  background: linear-gradient(87deg, #05a677 0, #00ab79 100%) !important;
}

.bg-gradient-info {
  background: linear-gradient(87deg, #0948b3 0, #0046bc 100%) !important;
}

.bg-gradient-warning {
  background: linear-gradient(87deg, #f5b759 0, #feb950 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(87deg, #fa5252 0, #ff4d4d 100%) !important;
}

.bg-gradient-white {
  background: linear-gradient(87deg, #ffffff 0, white 100%) !important;
}

.bg-gradient-gray {
  background: linear-gradient(87deg, #4a5073 0, #41497c 100%) !important;
}

.bg-gradient-indigo {
  background: linear-gradient(87deg, #4c5680 0, #42508a 100%) !important;
}

.bg-gradient-dark {
  background: linear-gradient(87deg, #262b40 0, #212845 100%) !important;
}

.bg-gradient-soft {
  background: linear-gradient(87deg, #f5f8fb 0, #f4f8fc 100%) !important;
}

.bg-gradient-black {
  background: linear-gradient(87deg, #2e3650 0, #283356 100%) !important;
}

.bg-gradient-blue {
  background: linear-gradient(87deg, #0948b3 0, #0046bc 100%) !important;
}

.bg-gradient-pink {
  background: linear-gradient(87deg, #c96480 0, #d35a7b 100%) !important;
}

.bg-gradient-cyan {
  background: linear-gradient(87deg, #63b1bd 0, #58b9c8 100%) !important;
}

.bg-gradient-purple {
  background: linear-gradient(87deg, #8965e0 0, #855ce9 100%) !important;
}

.bg-gradient-soft-indigo {
  background: linear-gradient(87deg, #f5e8ff 0, #f5e8ff 100%) !important;
}

.bg-gradient-soft-green {
  background: linear-gradient(87deg, #2ca58d 0, #22af93 100%) !important;
}

.bg-gradient-gray-100 {
  background: linear-gradient(87deg, #f3f7fa 0, #f2f7fb 100%) !important;
}

.bg-gradient-gray-200 {
  background: linear-gradient(87deg, #f5f8fb 0, #f4f8fc 100%) !important;
}

.bg-gradient-gray-300 {
  background: linear-gradient(87deg, #f0f3f6 0, #eff3f7 100%) !important;
}

.bg-gradient-gray-400 {
  background: linear-gradient(87deg, #eaedf2 0, #e8edf4 100%) !important;
}

.bg-gradient-gray-500 {
  background: linear-gradient(87deg, #d1d7e0 0, #cdd6e4 100%) !important;
}

.bg-gradient-gray-600 {
  background: linear-gradient(87deg, #93a5be 0, #8aa4c7 100%) !important;
}

.bg-gradient-gray-700 {
  background: linear-gradient(87deg, #66799e 0, #5a75ab 100%) !important;
}

.bg-gradient-gray-800 {
  background: linear-gradient(87deg, #506690 0, #45639b 100%) !important;
}

.bg-gradient-facebook {
  background: linear-gradient(87deg, #3b5999 0, #3055a4 100%) !important;
}

.bg-gradient-twitter {
  background: linear-gradient(87deg, #1da1f2 0, #11a4fe 100%) !important;
}

.bg-gradient-google {
  background: linear-gradient(87deg, #db4337 0, #e7392b 100%) !important;
}

.bg-gradient-instagram {
  background: linear-gradient(87deg, #e4405f 0, #ef3558 100%) !important;
}

.bg-gradient-pinterest {
  background: linear-gradient(87deg, #bd081c 0, #c50016 100%) !important;
}

.bg-gradient-youtube {
  background: linear-gradient(87deg, #cd201f 0, #d91413 100%) !important;
}

.bg-gradient-slack {
  background: linear-gradient(87deg, #3aaf85 0, #2ebb88 100%) !important;
}

.bg-gradient-dribbble {
  background: linear-gradient(87deg, #ea4c89 0, #f44287 100%) !important;
}

.bg-gradient-dropbox {
  background: linear-gradient(87deg, #1e90ff 0, dodgerblue 100%) !important;
}

.bg-gradient-twitch {
  background: linear-gradient(87deg, #4b367c 0, #472d85 100%) !important;
}

.bg-gradient-paypal {
  background: linear-gradient(87deg, #ecb32c 0, #f8b821 100%) !important;
}

.bg-gradient-behance {
  background: linear-gradient(87deg, #0057ff 0, #0057ff 100%) !important;
}

.bg-gradient-reddit {
  background: linear-gradient(87deg, #e84422 0, #f43c16 100%) !important;
}

.bg-gradient-github {
  background: linear-gradient(87deg, #222222 0, #251f1f 100%) !important;
}

.overlay-primary:before {
  position: absolute;
  content: '';
  background: #262b40;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-secondary:before {
  position: absolute;
  content: '';
  background: #61dafb;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-tertiary:before {
  position: absolute;
  content: '';
  background: #1b998b;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-quaternary:before {
  position: absolute;
  content: '';
  background: #c96480;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-primary-app:before {
  position: absolute;
  content: '';
  background: #ebf4f6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-secondary-app:before {
  position: absolute;
  content: '';
  background: #424aa0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-light:before {
  position: absolute;
  content: '';
  background: #eaedf2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-lighten:before {
  position: absolute;
  content: '';
  background: #f0f3f6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-success:before {
  position: absolute;
  content: '';
  background: #05a677;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-info:before {
  position: absolute;
  content: '';
  background: #0948b3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-warning:before {
  position: absolute;
  content: '';
  background: #f5b759;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-danger:before {
  position: absolute;
  content: '';
  background: #fa5252;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-white:before {
  position: absolute;
  content: '';
  background: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray:before {
  position: absolute;
  content: '';
  background: #4a5073;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-indigo:before {
  position: absolute;
  content: '';
  background: #4c5680;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-dark:before {
  position: absolute;
  content: '';
  background: #262b40;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-soft:before {
  position: absolute;
  content: '';
  background: #f5f8fb;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-black:before {
  position: absolute;
  content: '';
  background: #2e3650;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-blue:before {
  position: absolute;
  content: '';
  background: #0948b3;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-pink:before {
  position: absolute;
  content: '';
  background: #c96480;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-cyan:before {
  position: absolute;
  content: '';
  background: #63b1bd;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-purple:before {
  position: absolute;
  content: '';
  background: #8965e0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-soft-indigo:before {
  position: absolute;
  content: '';
  background: #f5e8ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-soft-green:before {
  position: absolute;
  content: '';
  background: #2ca58d;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-100:before {
  position: absolute;
  content: '';
  background: #f3f7fa;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-200:before {
  position: absolute;
  content: '';
  background: #f5f8fb;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-300:before {
  position: absolute;
  content: '';
  background: #f0f3f6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-400:before {
  position: absolute;
  content: '';
  background: #eaedf2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-500:before {
  position: absolute;
  content: '';
  background: #d1d7e0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-600:before {
  position: absolute;
  content: '';
  background: #93a5be;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-700:before {
  position: absolute;
  content: '';
  background: #66799e;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-gray-800:before {
  position: absolute;
  content: '';
  background: #506690;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-facebook:before {
  position: absolute;
  content: '';
  background: #3b5999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-twitter:before {
  position: absolute;
  content: '';
  background: #1da1f2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-google:before {
  position: absolute;
  content: '';
  background: #db4337;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-instagram:before {
  position: absolute;
  content: '';
  background: #e4405f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-pinterest:before {
  position: absolute;
  content: '';
  background: #bd081c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-youtube:before {
  position: absolute;
  content: '';
  background: #cd201f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-slack:before {
  position: absolute;
  content: '';
  background: #3aaf85;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-dribbble:before {
  position: absolute;
  content: '';
  background: #ea4c89;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-dropbox:before {
  position: absolute;
  content: '';
  background: #1e90ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-twitch:before {
  position: absolute;
  content: '';
  background: #4b367c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-paypal:before {
  position: absolute;
  content: '';
  background: #ecb32c;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-behance:before {
  position: absolute;
  content: '';
  background: #0057ff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-reddit:before {
  position: absolute;
  content: '';
  background: #e84422;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.overlay-github:before {
  position: absolute;
  content: '';
  background: #222222;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 0;
}

.section-image {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bg-secondary-alt {
  background-color: #acebfd;
}

.form-bg-image {
  background-repeat: no-repeat !important;
  background-position: top center !important;
}

.fill-soft {
  fill: #f5f8fb;
}

/**
* = Helper classes
*/
.overflow-visible {
  overflow: visible !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.fill-opacity-0 {
  fill-opacity: 0 !important;
}

.fill-opacity-1 {
  fill-opacity: 0.1 !important;
}

.fill-opacity-2 {
  fill-opacity: 0.2 !important;
}

.fill-opacity-3 {
  fill-opacity: 0.3 !important;
}

.fill-opacity-4 {
  fill-opacity: 0.4 !important;
}

.fill-opacity-5 {
  fill-opacity: 0.5 !important;
}

.fill-opacity-6 {
  fill-opacity: 0.6 !important;
}

.fill-opacity-7 {
  fill-opacity: 0.7 !important;
}

.fill-opacity-8 {
  fill-opacity: 0.8 !important;
}

.fill-opacity-9 {
  fill-opacity: 0.9 !important;
}

.z-0 {
  position: relative;
  z-index: 0 !important;
}

.z-1 {
  position: relative;
  z-index: 1 !important;
}

.z-2 {
  position: relative;
  z-index: 2 !important;
}

.z-3 {
  position: relative;
  z-index: 3 !important;
}

.z-4 {
  position: relative;
  z-index: 4 !important;
}

.z-5 {
  position: relative;
  z-index: 5 !important;
}

.z-6 {
  position: relative;
  z-index: 6 !important;
}

.z-7 {
  position: relative;
  z-index: 7 !important;
}

.z-8 {
  position: relative;
  z-index: 8 !important;
}

.z-9 {
  position: relative;
  z-index: 9 !important;
}

.w-auto {
  width: auto !important;
}

.bw-md {
  border-width: 0.125rem !important;
}

.bw-lg {
  border-width: 0.25rem !important;
}

.bw-xl {
  border-width: 0.375rem !important;
}

.border-top-left-radius-0 {
  border-top-left-radius: 0 !important;
}

.border-top-right-radius-0 {
  border-top-end-radius: 0 !important;
}

.border-bottom-left-radius-0 {
  border-bottom-start-radius: 0 !important;
}

.border-bottom-right-radius-0 {
  border-bottom-end-radius: 0 !important;
}

.rounded-xl {
  border-radius: 0.875rem !important;
}

/**
 * = Spacing
 */
.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1 {
  top: 0.25rem;
}

.right-1 {
  right: 0.25rem;
}

.bottom-1 {
  bottom: 0.25rem;
}

.left-1 {
  left: 0.25rem;
}

.top-2 {
  top: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}

.bottom-2 {
  bottom: 0.5rem;
}

.left-2 {
  left: 0.5rem;
}

.top-3 {
  top: 1rem;
}

.right-3 {
  right: 1rem;
}

.bottom-3 {
  bottom: 1rem;
}

.left-3 {
  left: 1rem;
}

.top-4 {
  top: 1.5rem;
}

.right-4 {
  right: 1.5rem;
}

.bottom-4 {
  bottom: 1.5rem;
}

.left-4 {
  left: 1.5rem;
}

.top-5 {
  top: 3rem;
}

.right-5 {
  right: 3rem;
}

.bottom-5 {
  bottom: 3rem;
}

.left-5 {
  left: 3rem;
}

.top-6 {
  top: 5rem;
}

.right-6 {
  right: 5rem;
}

.bottom-6 {
  bottom: 5rem;
}

.left-6 {
  left: 5rem;
}

.top-7 {
  top: 8rem;
}

.right-7 {
  right: 8rem;
}

.bottom-7 {
  bottom: 8rem;
}

.left-7 {
  left: 8rem;
}

.top-8 {
  top: 10rem;
}

.right-8 {
  right: 10rem;
}

.bottom-8 {
  bottom: 10rem;
}

.left-8 {
  left: 10rem;
}

.top-9 {
  top: 11rem;
}

.right-9 {
  right: 11rem;
}

.bottom-9 {
  bottom: 11rem;
}

.left-9 {
  left: 11rem;
}

.top-10 {
  top: 14rem;
}

.right-10 {
  right: 14rem;
}

.bottom-10 {
  bottom: 14rem;
}

.left-10 {
  left: 14rem;
}

.top-11 {
  top: 16rem;
}

.right-11 {
  right: 16rem;
}

.bottom-11 {
  bottom: 16rem;
}

.left-11 {
  left: 16rem;
}

.top-12 {
  top: 20rem;
}

.right-12 {
  right: 20rem;
}

.bottom-12 {
  bottom: 20rem;
}

.left-12 {
  left: 20rem;
}

.top-sm {
  top: 1rem;
}

.right-sm {
  right: 1rem;
}

.bottom-sm {
  bottom: 1rem;
}

.left-sm {
  left: 1rem;
}

.top-md {
  top: 2rem;
}

.right-md {
  right: 2rem;
}

.bottom-md {
  bottom: 2rem;
}

.left-md {
  left: 2rem;
}

.top-lg {
  top: 4rem;
}

.right-lg {
  right: 4rem;
}

.bottom-lg {
  bottom: 4rem;
}

.left-lg {
  left: 4rem;
}

.top-xl {
  top: 8rem;
}

.right-xl {
  right: 8rem;
}

.bottom-xl {
  bottom: 8rem;
}

.left-xl {
  left: 8rem;
}

.center-vertical-absolute,
.center-horizontal-absolute {
  position: absolute;
}

.center-vertical-absolute {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center-horizontal-absolute {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/**
 * = Sizing
 */
.h-100vh {
  height: 100vh !important;
}

@media (min-width: 992px) {
  .vh-lg-100 {
    height: 100vh !important;
  }
}

.fh-50 {
  height: 50px !important;
}

.fh-100 {
  height: 100px !important;
}

.fh-150 {
  height: 150px !important;
}

.fh-200 {
  height: 200px !important;
}

.fh-250 {
  height: 250px !important;
}

.fh-300 {
  height: 300px !important;
}

.fh-350 {
  height: 350px !important;
}

.fh-400 {
  height: 400px !important;
}

.fh-450 {
  height: 450px !important;
}

.fh-500 {
  height: 500px !important;
}

.fh-550 {
  height: 550px !important;
}

.fh-600 {
  height: 600px !important;
}

.fh-650 {
  height: 650px !important;
}

.fh-700 {
  height: 700px !important;
}

.fh-750 {
  height: 750px !important;
}

.fh-800 {
  height: 800px !important;
}

.fh-850 {
  height: 850px !important;
}

.fh-900 {
  height: 900px !important;
}

.fh-950 {
  height: 950px !important;
}

.fh-1000 {
  height: 1000px !important;
}

.fh-1050 {
  height: 1050px !important;
}

.fh-1100 {
  height: 1100px !important;
}

.fh-1150 {
  height: 1150px !important;
}

.fh-1200 {
  height: 1200px !important;
}

.fh-1250 {
  height: 1250px !important;
}

.fh-1300 {
  height: 1300px !important;
}

.fh-1350 {
  height: 1350px !important;
}

.fh-1400 {
  height: 1400px !important;
}

.fh-1450 {
  height: 1450px !important;
}

.fh-1500 {
  height: 1500px !important;
}

.fh-1550 {
  height: 1550px !important;
}

.fh-1600 {
  height: 1600px !important;
}

.fh-1650 {
  height: 1650px !important;
}

.fh-1700 {
  height: 1700px !important;
}

.fh-1750 {
  height: 1750px !important;
}

.fh-1800 {
  height: 1800px !important;
}

.fh-1850 {
  height: 1850px !important;
}

.fh-1900 {
  height: 1900px !important;
}

.fh-1950 {
  height: 1950px !important;
}

.fw-50 {
  width: 50px !important;
}

.fw-100 {
  width: 100px !important;
}

.fw-150 {
  width: 150px !important;
}

.fw-200 {
  width: 200px !important;
}

.fw-250 {
  width: 250px !important;
}

.fw-300 {
  width: 300px !important;
}

.fw-350 {
  width: 350px !important;
}

.fw-400 {
  width: 400px !important;
}

.fw-450 {
  width: 450px !important;
}

.fw-500 {
  width: 500px !important;
}

.fw-550 {
  width: 550px !important;
}

.fw-600 {
  width: 600px !important;
}

.fw-650 {
  width: 650px !important;
}

.fw-700 {
  width: 700px !important;
}

.fw-750 {
  width: 750px !important;
}

.fw-800 {
  width: 800px !important;
}

.fw-850 {
  width: 850px !important;
}

.fw-900 {
  width: 900px !important;
}

.fw-950 {
  width: 950px !important;
}

.fw-1000 {
  width: 1000px !important;
}

.fw-1050 {
  width: 1050px !important;
}

.fw-1100 {
  width: 1100px !important;
}

.fw-1150 {
  width: 1150px !important;
}

.fw-1200 {
  width: 1200px !important;
}

.fw-1250 {
  width: 1250px !important;
}

.fw-1300 {
  width: 1300px !important;
}

.fw-1350 {
  width: 1350px !important;
}

.fw-1400 {
  width: 1400px !important;
}

.fw-1450 {
  width: 1450px !important;
}

.fw-1500 {
  width: 1500px !important;
}

.fw-1550 {
  width: 1550px !important;
}

.fw-1600 {
  width: 1600px !important;
}

.fw-1650 {
  width: 1650px !important;
}

.fw-1700 {
  width: 1700px !important;
}

.fw-1750 {
  width: 1750px !important;
}

.fw-1800 {
  width: 1800px !important;
}

.fw-1850 {
  width: 1850px !important;
}

.fw-1900 {
  width: 1900px !important;
}

.fw-1950 {
  width: 1950px !important;
}

.fmh-50 {
  min-height: 50px !important;
}

.fmh-100 {
  min-height: 100px !important;
}

.fmh-150 {
  min-height: 150px !important;
}

.fmh-200 {
  min-height: 200px !important;
}

.fmh-250 {
  min-height: 250px !important;
}

.fmh-300 {
  min-height: 300px !important;
}

.fmh-350 {
  min-height: 350px !important;
}

.fmh-400 {
  min-height: 400px !important;
}

.fmh-450 {
  min-height: 450px !important;
}

.fmh-500 {
  min-height: 500px !important;
}

.fmh-550 {
  min-height: 550px !important;
}

.fmh-600 {
  min-height: 600px !important;
}

.fmh-650 {
  min-height: 650px !important;
}

.fmh-700 {
  min-height: 700px !important;
}

.fmh-750 {
  min-height: 750px !important;
}

.fmh-800 {
  min-height: 800px !important;
}

.fmh-850 {
  min-height: 850px !important;
}

.fmh-900 {
  min-height: 900px !important;
}

.fmh-950 {
  min-height: 950px !important;
}

.fmh-1000 {
  min-height: 1000px !important;
}

.fmh-1050 {
  min-height: 1050px !important;
}

.fmh-1100 {
  min-height: 1100px !important;
}

.fmh-1150 {
  min-height: 1150px !important;
}

.fmh-1200 {
  min-height: 1200px !important;
}

.fmh-1250 {
  min-height: 1250px !important;
}

.fmh-1300 {
  min-height: 1300px !important;
}

.fmh-1350 {
  min-height: 1350px !important;
}

.fmh-1400 {
  min-height: 1400px !important;
}

.fmh-1450 {
  min-height: 1450px !important;
}

.fmh-1500 {
  min-height: 1500px !important;
}

.fmh-1550 {
  min-height: 1550px !important;
}

.fmh-1600 {
  min-height: 1600px !important;
}

.fmh-1650 {
  min-height: 1650px !important;
}

.fmh-1700 {
  min-height: 1700px !important;
}

.fmh-1750 {
  min-height: 1750px !important;
}

.fmh-1800 {
  min-height: 1800px !important;
}

.fmh-1850 {
  min-height: 1850px !important;
}

.fmh-1900 {
  min-height: 1900px !important;
}

.fmh-1950 {
  min-height: 1950px !important;
}

.fmw-50 {
  min-width: 50px !important;
}

.fmw-100 {
  min-width: 100px !important;
}

.fmw-150 {
  min-width: 150px !important;
}

.fmw-200 {
  min-width: 200px !important;
}

.fmw-250 {
  min-width: 250px !important;
}

.fmw-300 {
  min-width: 300px !important;
}

.fmw-350 {
  min-width: 350px !important;
}

.fmw-400 {
  min-width: 400px !important;
}

.fmw-450 {
  min-width: 450px !important;
}

.fmw-500 {
  min-width: 500px !important;
}

.fmw-550 {
  min-width: 550px !important;
}

.fmw-600 {
  min-width: 600px !important;
}

.fmw-650 {
  min-width: 650px !important;
}

.fmw-700 {
  min-width: 700px !important;
}

.fmw-750 {
  min-width: 750px !important;
}

.fmw-800 {
  min-width: 800px !important;
}

.fmw-850 {
  min-width: 850px !important;
}

.fmw-900 {
  min-width: 900px !important;
}

.fmw-950 {
  min-width: 950px !important;
}

.fmw-1000 {
  min-width: 1000px !important;
}

.fmw-1050 {
  min-width: 1050px !important;
}

.fmw-1100 {
  min-width: 1100px !important;
}

.fmw-1150 {
  min-width: 1150px !important;
}

.fmw-1200 {
  min-width: 1200px !important;
}

.fmw-1250 {
  min-width: 1250px !important;
}

.fmw-1300 {
  min-width: 1300px !important;
}

.fmw-1350 {
  min-width: 1350px !important;
}

.fmw-1400 {
  min-width: 1400px !important;
}

.fmw-1450 {
  min-width: 1450px !important;
}

.fmw-1500 {
  min-width: 1500px !important;
}

.fmw-1550 {
  min-width: 1550px !important;
}

.fmw-1600 {
  min-width: 1600px !important;
}

.fmw-1650 {
  min-width: 1650px !important;
}

.fmw-1700 {
  min-width: 1700px !important;
}

.fmw-1750 {
  min-width: 1750px !important;
}

.fmw-1800 {
  min-width: 1800px !important;
}

.fmw-1850 {
  min-width: 1850px !important;
}

.fmw-1900 {
  min-width: 1900px !important;
}

.fmw-1950 {
  min-width: 1950px !important;
}

.fmxh-50 {
  max-height: 50px !important;
}

.fmxh-100 {
  max-height: 100px !important;
}

.fmxh-150 {
  max-height: 150px !important;
}

.fmxh-200 {
  max-height: 200px !important;
}

.fmxh-250 {
  max-height: 250px !important;
}

.fmxh-300 {
  max-height: 300px !important;
}

.fmxh-350 {
  max-height: 350px !important;
}

.fmxh-400 {
  max-height: 400px !important;
}

.fmxh-450 {
  max-height: 450px !important;
}

.fmxh-500 {
  max-height: 500px !important;
}

.fmxh-550 {
  max-height: 550px !important;
}

.fmxh-600 {
  max-height: 600px !important;
}

.fmxh-650 {
  max-height: 650px !important;
}

.fmxh-700 {
  max-height: 700px !important;
}

.fmxh-750 {
  max-height: 750px !important;
}

.fmxh-800 {
  max-height: 800px !important;
}

.fmxh-850 {
  max-height: 850px !important;
}

.fmxh-900 {
  max-height: 900px !important;
}

.fmxh-950 {
  max-height: 950px !important;
}

.fmxh-1000 {
  max-height: 1000px !important;
}

.fmxh-1050 {
  max-height: 1050px !important;
}

.fmxh-1100 {
  max-height: 1100px !important;
}

.fmxh-1150 {
  max-height: 1150px !important;
}

.fmxh-1200 {
  max-height: 1200px !important;
}

.fmxh-1250 {
  max-height: 1250px !important;
}

.fmxh-1300 {
  max-height: 1300px !important;
}

.fmxh-1350 {
  max-height: 1350px !important;
}

.fmxh-1400 {
  max-height: 1400px !important;
}

.fmxh-1450 {
  max-height: 1450px !important;
}

.fmxh-1500 {
  max-height: 1500px !important;
}

.fmxh-1550 {
  max-height: 1550px !important;
}

.fmxh-1600 {
  max-height: 1600px !important;
}

.fmxh-1650 {
  max-height: 1650px !important;
}

.fmxh-1700 {
  max-height: 1700px !important;
}

.fmxh-1750 {
  max-height: 1750px !important;
}

.fmxh-1800 {
  max-height: 1800px !important;
}

.fmxh-1850 {
  max-height: 1850px !important;
}

.fmxh-1900 {
  max-height: 1900px !important;
}

.fmxh-1950 {
  max-height: 1950px !important;
}

.fmxw-50 {
  max-width: 50px !important;
}

.fmxw-100 {
  max-width: 100px !important;
}

.fmxw-150 {
  max-width: 150px !important;
}

.fmxw-200 {
  max-width: 200px !important;
}

.fmxw-250 {
  max-width: 250px !important;
}

.fmxw-300 {
  max-width: 300px !important;
}

.fmxw-350 {
  max-width: 350px !important;
}

.fmxw-400 {
  max-width: 400px !important;
}

.fmxw-450 {
  max-width: 450px !important;
}

.fmxw-500 {
  max-width: 500px !important;
}

.fmxw-550 {
  max-width: 550px !important;
}

.fmxw-600 {
  max-width: 600px !important;
}

.fmxw-650 {
  max-width: 650px !important;
}

.fmxw-700 {
  max-width: 700px !important;
}

.fmxw-750 {
  max-width: 750px !important;
}

.fmxw-800 {
  max-width: 800px !important;
}

.fmxw-850 {
  max-width: 850px !important;
}

.fmxw-900 {
  max-width: 900px !important;
}

.fmxw-950 {
  max-width: 950px !important;
}

.fmxw-1000 {
  max-width: 1000px !important;
}

.fmxw-1050 {
  max-width: 1050px !important;
}

.fmxw-1100 {
  max-width: 1100px !important;
}

.fmxw-1150 {
  max-width: 1150px !important;
}

.fmxw-1200 {
  max-width: 1200px !important;
}

.fmxw-1250 {
  max-width: 1250px !important;
}

.fmxw-1300 {
  max-width: 1300px !important;
}

.fmxw-1350 {
  max-width: 1350px !important;
}

.fmxw-1400 {
  max-width: 1400px !important;
}

.fmxw-1450 {
  max-width: 1450px !important;
}

.fmxw-1500 {
  max-width: 1500px !important;
}

.fmxw-1550 {
  max-width: 1550px !important;
}

.fmxw-1600 {
  max-width: 1600px !important;
}

.fmxw-1650 {
  max-width: 1650px !important;
}

.fmxw-1700 {
  max-width: 1700px !important;
}

.fmxw-1750 {
  max-width: 1750px !important;
}

.fmxw-1800 {
  max-width: 1800px !important;
}

.fmxw-1850 {
  max-width: 1850px !important;
}

.fmxw-1900 {
  max-width: 1900px !important;
}

.fmxw-1950 {
  max-width: 1950px !important;
}

/**
 * = Shadows
 */
[class*='shadow'] {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.shadow-soft {
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
}

.shadow-hover:hover {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15) !important;
}

.shadow-sm-hover:hover {
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02) !important;
}

.shadow-lg-hover:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175) !important;
}

.shadow-none-hover:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/**
 * = Text utility classes
 */
.text-underline {
  text-decoration: underline !important;
}

.text-through {
  text-decoration: line-through !important;
}

.display-1-xs {
  font-size: 5rem;
}

.display-2-xs {
  font-size: 3.5rem;
}

.display-3-xs {
  font-size: 2.5rem;
}

.display-4-xs {
  font-size: 1.875rem;
}

@media (min-width: 576px) {
  .display-1-sm {
    font-size: 5rem;
  }

  .display-2-sm {
    font-size: 3.5rem;
  }

  .display-3-sm {
    font-size: 2.5rem;
  }

  .display-4-sm {
    font-size: 1.875rem;
  }
}

@media (min-width: 768px) {
  .display-1-md {
    font-size: 5rem;
  }

  .display-2-md {
    font-size: 3.5rem;
  }

  .display-3-md {
    font-size: 2.5rem;
  }

  .display-4-md {
    font-size: 1.875rem;
  }
}

@media (min-width: 992px) {
  .display-1-lg {
    font-size: 5rem;
  }

  .display-2-lg {
    font-size: 3.5rem;
  }

  .display-3-lg {
    font-size: 2.5rem;
  }

  .display-4-lg {
    font-size: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .display-1-xl {
    font-size: 5rem;
  }

  .display-2-xl {
    font-size: 3.5rem;
  }

  .display-3-xl {
    font-size: 2.5rem;
  }

  .display-4-xl {
    font-size: 1.875rem;
  }
}

@media (min-width: 1400px) {
  .display-1-xxl {
    font-size: 5rem;
  }

  .display-2-xxl {
    font-size: 3.5rem;
  }

  .display-3-xxl {
    font-size: 2.5rem;
  }

  .display-4-xxl {
    font-size: 1.875rem;
  }
}

.lh-100 {
  line-height: 1;
}

.lh-110 {
  line-height: 1.1;
}

.lh-120 {
  line-height: 1.2;
}

.lh-130 {
  line-height: 1.3;
}

.lh-140 {
  line-height: 1.4;
}

.lh-150 {
  line-height: 1.5;
}

.lh-160 {
  line-height: 1.6;
}

.lh-170 {
  line-height: 1.7;
}

.lh-180 {
  line-height: 1.8;
}

.lh-190 {
  line-height: 1.9;
}

.lh-200 {
  line-height: 2;
}

.lh-210 {
  line-height: 2.1;
}

.lh-220 {
  line-height: 2.2;
}

.lh-230 {
  line-height: 2.3;
}

.lh-240 {
  line-height: 2.4;
}

.lh-250 {
  line-height: 2.5;
}

.lh-260 {
  line-height: 2.6;
}

.lh-270 {
  line-height: 2.7;
}

.lh-280 {
  line-height: 2.8;
}

.lh-290 {
  line-height: 2.9;
}

.lh-300 {
  line-height: 3;
}

.ls-1 {
  letter-spacing: 0.0625rem;
}

.ls-2 {
  letter-spacing: 0.09375rem;
}

.ls-3 {
  letter-spacing: 0.125rem;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

.text-body {
  color: #4a5073 !important;
}

.text-black-50 {
  color: rgba(46, 54, 80, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.list-style-none {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

/**
 * = Transform
 */
@media (min-width: 992px) {
  .transform-perspective-right {
    -webkit-transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
    transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
  }

  .transform-perspective-left {
    -webkit-transform: scale(1) perspective(900px) rotateY(27deg) rotateX(2deg) rotate(-2deg);
    transform: scale(1) perspective(900px) rotateY(27deg) rotateX(2deg) rotate(-2deg);
  }
}

.t-none {
  -webkit-transform: none !important;
  transform: none !important;
}

/**
 * = Animations
 */
.animate-up-1,
.animate-right-1,
.animate-down-1,
.animate-left-1,
.scale-up-1,
.scale-down-1 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-1:hover {
  -webkit-transform: scale(0.55556);
  transform: scale(0.55556);
}

.scale-up-hover-1:hover .scale {
  -webkit-transform: scale(0.55556);
  transform: scale(0.55556);
}

.scale-down-1:hover {
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
}

.animate-up-1:hover,
.animate-hover:hover .animate-up-1 {
  -webkit-transform: translate(0, -1px);
  transform: translate(0, -1px);
}

.animate-right-1:hover,
.animate-hover:hover .animate-right-1 {
  -webkit-transform: translate(1px, 0);
  transform: translate(1px, 0);
}

.animate-down-1:hover,
.animate-hover:hover .animate-down-1 {
  -webkit-transform: translate(0, 1px);
  transform: translate(0, 1px);
}

.animate-left-1:hover,
.animate-hover:hover .animate-left-1 {
  -webkit-transform: translate(-1px, 0);
  transform: translate(-1px, 0);
}

.animate-up-2,
.animate-right-2,
.animate-down-2,
.animate-left-2,
.scale-up-2,
.scale-down-2 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-2:hover {
  -webkit-transform: scale(1.11111);
  transform: scale(1.11111);
}

.scale-up-hover-2:hover .scale {
  -webkit-transform: scale(1.11111);
  transform: scale(1.11111);
}

.scale-down-2:hover {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.animate-up-2:hover,
.animate-hover:hover .animate-up-2 {
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.animate-right-2:hover,
.animate-hover:hover .animate-right-2 {
  -webkit-transform: translate(2px, 0);
  transform: translate(2px, 0);
}

.animate-down-2:hover,
.animate-hover:hover .animate-down-2 {
  -webkit-transform: translate(0, 2px);
  transform: translate(0, 2px);
}

.animate-left-2:hover,
.animate-hover:hover .animate-left-2 {
  -webkit-transform: translate(-2px, 0);
  transform: translate(-2px, 0);
}

.animate-up-3,
.animate-right-3,
.animate-down-3,
.animate-left-3,
.scale-up-3,
.scale-down-3 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-3:hover {
  -webkit-transform: scale(1.66667);
  transform: scale(1.66667);
}

.scale-up-hover-3:hover .scale {
  -webkit-transform: scale(1.66667);
  transform: scale(1.66667);
}

.scale-down-3:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.animate-up-3:hover,
.animate-hover:hover .animate-up-3 {
  -webkit-transform: translate(0, -3px);
  transform: translate(0, -3px);
}

.animate-right-3:hover,
.animate-hover:hover .animate-right-3 {
  -webkit-transform: translate(3px, 0);
  transform: translate(3px, 0);
}

.animate-down-3:hover,
.animate-hover:hover .animate-down-3 {
  -webkit-transform: translate(0, 3px);
  transform: translate(0, 3px);
}

.animate-left-3:hover,
.animate-hover:hover .animate-left-3 {
  -webkit-transform: translate(-3px, 0);
  transform: translate(-3px, 0);
}

.animate-up-4,
.animate-right-4,
.animate-down-4,
.animate-left-4,
.scale-up-4,
.scale-down-4 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-4:hover {
  -webkit-transform: scale(2.22222);
  transform: scale(2.22222);
}

.scale-up-hover-4:hover .scale {
  -webkit-transform: scale(2.22222);
  transform: scale(2.22222);
}

.scale-down-4:hover {
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
}

.animate-up-4:hover,
.animate-hover:hover .animate-up-4 {
  -webkit-transform: translate(0, -4px);
  transform: translate(0, -4px);
}

.animate-right-4:hover,
.animate-hover:hover .animate-right-4 {
  -webkit-transform: translate(4px, 0);
  transform: translate(4px, 0);
}

.animate-down-4:hover,
.animate-hover:hover .animate-down-4 {
  -webkit-transform: translate(0, 4px);
  transform: translate(0, 4px);
}

.animate-left-4:hover,
.animate-hover:hover .animate-left-4 {
  -webkit-transform: translate(-4px, 0);
  transform: translate(-4px, 0);
}

.animate-up-5,
.animate-right-5,
.animate-down-5,
.animate-left-5,
.scale-up-5,
.scale-down-5 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-5:hover {
  -webkit-transform: scale(2.77778);
  transform: scale(2.77778);
}

.scale-up-hover-5:hover .scale {
  -webkit-transform: scale(2.77778);
  transform: scale(2.77778);
}

.scale-down-5:hover {
  -webkit-transform: scale(2);
  transform: scale(2);
}

.animate-up-5:hover,
.animate-hover:hover .animate-up-5 {
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
}

.animate-right-5:hover,
.animate-hover:hover .animate-right-5 {
  -webkit-transform: translate(5px, 0);
  transform: translate(5px, 0);
}

.animate-down-5:hover,
.animate-hover:hover .animate-down-5 {
  -webkit-transform: translate(0, 5px);
  transform: translate(0, 5px);
}

.animate-left-5:hover,
.animate-hover:hover .animate-left-5 {
  -webkit-transform: translate(-5px, 0);
  transform: translate(-5px, 0);
}

.animate-up-6,
.animate-right-6,
.animate-down-6,
.animate-left-6,
.scale-up-6,
.scale-down-6 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-6:hover {
  -webkit-transform: scale(3.33333);
  transform: scale(3.33333);
}

.scale-up-hover-6:hover .scale {
  -webkit-transform: scale(3.33333);
  transform: scale(3.33333);
}

.scale-down-6:hover {
  -webkit-transform: scale(2.4);
  transform: scale(2.4);
}

.animate-up-6:hover,
.animate-hover:hover .animate-up-6 {
  -webkit-transform: translate(0, -6px);
  transform: translate(0, -6px);
}

.animate-right-6:hover,
.animate-hover:hover .animate-right-6 {
  -webkit-transform: translate(6px, 0);
  transform: translate(6px, 0);
}

.animate-down-6:hover,
.animate-hover:hover .animate-down-6 {
  -webkit-transform: translate(0, 6px);
  transform: translate(0, 6px);
}

.animate-left-6:hover,
.animate-hover:hover .animate-left-6 {
  -webkit-transform: translate(-6px, 0);
  transform: translate(-6px, 0);
}

.animate-up-7,
.animate-right-7,
.animate-down-7,
.animate-left-7,
.scale-up-7,
.scale-down-7 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-7:hover {
  -webkit-transform: scale(3.88889);
  transform: scale(3.88889);
}

.scale-up-hover-7:hover .scale {
  -webkit-transform: scale(3.88889);
  transform: scale(3.88889);
}

.scale-down-7:hover {
  -webkit-transform: scale(2.8);
  transform: scale(2.8);
}

.animate-up-7:hover,
.animate-hover:hover .animate-up-7 {
  -webkit-transform: translate(0, -7px);
  transform: translate(0, -7px);
}

.animate-right-7:hover,
.animate-hover:hover .animate-right-7 {
  -webkit-transform: translate(7px, 0);
  transform: translate(7px, 0);
}

.animate-down-7:hover,
.animate-hover:hover .animate-down-7 {
  -webkit-transform: translate(0, 7px);
  transform: translate(0, 7px);
}

.animate-left-7:hover,
.animate-hover:hover .animate-left-7 {
  -webkit-transform: translate(-7px, 0);
  transform: translate(-7px, 0);
}

.animate-up-8,
.animate-right-8,
.animate-down-8,
.animate-left-8,
.scale-up-8,
.scale-down-8 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-8:hover {
  -webkit-transform: scale(4.44444);
  transform: scale(4.44444);
}

.scale-up-hover-8:hover .scale {
  -webkit-transform: scale(4.44444);
  transform: scale(4.44444);
}

.scale-down-8:hover {
  -webkit-transform: scale(3.2);
  transform: scale(3.2);
}

.animate-up-8:hover,
.animate-hover:hover .animate-up-8 {
  -webkit-transform: translate(0, -8px);
  transform: translate(0, -8px);
}

.animate-right-8:hover,
.animate-hover:hover .animate-right-8 {
  -webkit-transform: translate(8px, 0);
  transform: translate(8px, 0);
}

.animate-down-8:hover,
.animate-hover:hover .animate-down-8 {
  -webkit-transform: translate(0, 8px);
  transform: translate(0, 8px);
}

.animate-left-8:hover,
.animate-hover:hover .animate-left-8 {
  -webkit-transform: translate(-8px, 0);
  transform: translate(-8px, 0);
}

.animate-up-9,
.animate-right-9,
.animate-down-9,
.animate-left-9,
.scale-up-9,
.scale-down-9 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.scale-up-9:hover {
  -webkit-transform: scale(5);
  transform: scale(5);
}

.scale-up-hover-9:hover .scale {
  -webkit-transform: scale(5);
  transform: scale(5);
}

.scale-down-9:hover {
  -webkit-transform: scale(3.6);
  transform: scale(3.6);
}

.animate-up-9:hover,
.animate-hover:hover .animate-up-9 {
  -webkit-transform: translate(0, -9px);
  transform: translate(0, -9px);
}

.animate-right-9:hover,
.animate-hover:hover .animate-right-9 {
  -webkit-transform: translate(9px, 0);
  transform: translate(9px, 0);
}

.animate-down-9:hover,
.animate-hover:hover .animate-down-9 {
  -webkit-transform: translate(0, 9px);
  transform: translate(0, 9px);
}

.animate-left-9:hover,
.animate-hover:hover .animate-left-9 {
  -webkit-transform: translate(-9px, 0);
  transform: translate(-9px, 0);
}

@-webkit-keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

@-webkit-keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    -webkit-transition:
      visibility 0.45s,
      opacity 0.45s,
      -webkit-transform 0.45s;
    transition:
      visibility 0.45s,
      opacity 0.45s,
      -webkit-transform 0.45s;
    transition:
      visibility 0.45s,
      opacity 0.45s,
      transform 0.45s;
    transition:
      visibility 0.45s,
      opacity 0.45s,
      transform 0.45s,
      -webkit-transform 0.45s;
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    -webkit-transition:
      visibility 0.45s,
      opacity 0.45s,
      -webkit-transform 0.45s;
    transition:
      visibility 0.45s,
      opacity 0.45s,
      -webkit-transform 0.45s;
    transition:
      visibility 0.45s,
      opacity 0.45s,
      transform 0.45s;
    transition:
      visibility 0.45s,
      opacity 0.45s,
      transform 0.45s,
      -webkit-transform 0.45s;
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
}

@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
}

@-webkit-keyframes show-dropdown {
  0% {
    opacity: 0;
    -webkit-transform-origin: perspective(200px) rotateX(-2deg);
    transform-origin: perspective(200px) rotateX(-2deg);
    -webkit-transition:
      visibility 0.45s,
      opacity 0.5s;
    transition:
      visibility 0.45s,
      opacity 0.5s;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show-dropdown {
  0% {
    opacity: 0;
    -webkit-transform-origin: perspective(200px) rotateX(-2deg);
    transform-origin: perspective(200px) rotateX(-2deg);
    -webkit-transition:
      visibility 0.45s,
      opacity 0.5s;
    transition:
      visibility 0.45s,
      opacity 0.5s;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes hide-dropdown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
}

@keyframes hide-dropdown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
}

.bell-shake.shaking {
  -webkit-animation: bellshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: bellshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: top right;
  transform-origin: top right;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}

@-webkit-keyframes bellshake {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  15% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  30% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  45% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  75% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  85% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  92% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes bellshake {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  15% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  30% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  45% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }

  75% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  85% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  92% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}

/**
 * = Navigation bars
 */
.navbar-main {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

.navbar-main .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar .navbar-nav .nav-link {
  -webkit-transition: all 0.8s all 0.2s ease;
  transition: all 0.8s all 0.2s ease;
}

@media (max-width: 767.98px) {
  .navbar .navbar-nav .nav-link {
    font-size: 1.2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.navbar .navbar-nav .nav-item .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

.navbar .navbar-nav .nav-item:focus,
.navbar .navbar-nav .nav-item .nav-link:focus {
  outline: none !important;
}

.navbar .navbar-nav .dropdown .dropdown-menu {
  top: 100%;
}

.navbar .navbar-nav .dropdown .dropdown-menu span {
  width: 30px;
}

.navbar .navbar-nav .dropdown .dropdown-item {
  font-size: 0.875rem;
}

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-megamenu,
  .navbar .navbar-nav .dropdown-megamenu-sm,
  .navbar .navbar-nav .dropdown-megamenu-md {
    min-width: 580px;
  }
}

.navbar .navbar-nav .dropdown-megamenu .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-md .megamenu-link {
  color: #4a5073;
  font-weight: 400;
  font-size: 0.875rem;
}

.navbar .navbar-nav .dropdown-megamenu .megamenu-item,
.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-item,
.navbar .navbar-nav .dropdown-megamenu-md .megamenu-item {
  position: relative;
}

.navbar .navbar-nav .dropdown-megamenu .megamenu-item:hover .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-item:hover .megamenu-link,
.navbar .navbar-nav .dropdown-megamenu-md .megamenu-item:hover .megamenu-link {
  color: #262b40;
}

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-megamenu-sm {
    min-width: 320px;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-megamenu-md {
    min-width: 960px;
  }
}

@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-menu .dropdown-toggle .nav-link-arrow {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .navbar .navbar-nav .dropdown.show > .dropdown-toggle .nav-link-arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .navbar .navbar-nav .dropdown-toggle:hover .nav-link-arrow {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.navbar .navbar-nav .dropdown-submenu {
  position: relative;
}

.navbar .navbar-nav .dropdown-submenu .dropdown-menu {
  top: 0;
  left: calc(100% - 2px);
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  opacity: 0;
  display: none;
}

.navbar .navbar-nav .dropdown-submenu .dropdown-menu.show {
  opacity: 1;
  pointer-events: all;
  display: block;
}

.navbar .navbar-nav .nav-link-arrow {
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition:
    transform 0.2s ease,
    -webkit-transform 0.2s ease;
}

.navbar-brand .brand-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #61dafb;
}

.navbar-brand img {
  height: 27px;
}

.navbar-dark .navbar-brand-dark {
  display: none;
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}

.navbar-dark > .nav-item:hover > .nav-link {
  color: #262b40;
}

.navbar-light:not(.headroom--not-top) .btn-outline-soft {
  border-color: #262b40;
  color: #262b40;
}

.navbar-light:not(.headroom--not-top) .btn-outline-soft:hover {
  background: #262b40;
  color: #ffffff;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(46, 54, 80, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-light .navbar-brand-light {
  display: none;
}

.navbar-light .navbar-brand {
  color: #506690;
}

.navbar-theme-primary:not(.headroom) {
  background-color: #262b40;
}

.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-primary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-primary .navbar-nav .dropdown-item.active,
.navbar-theme-primary .navbar-nav .dropdown-item:hover,
.navbar-theme-primary .navbar-nav .list-group-item.active,
.navbar-theme-primary .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-primary .navbar-nav .nav-link,
  .navbar-theme-primary .navbar-nav .nav-link:focus,
  .navbar-theme-primary .navbar-nav .nav-link.active,
  .navbar-theme-primary .navbar-nav .nav-link:hover,
  .navbar-theme-primary .navbar-nav .show > .nav-link,
  .navbar-theme-primary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary .navbar-nav .dropdown-item,
  .navbar-theme-primary .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary .navbar-nav .dropdown-item.active,
  .navbar-theme-primary .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary .navbar-nav .list-group-item,
  .navbar-theme-primary .navbar-nav .list-group-item:focus,
  .navbar-theme-primary .navbar-nav .list-group-item.active,
  .navbar-theme-primary .navbar-nav .list-group-item:hover {
    color: #262b40;
    background: transparent;
  }

  .navbar-theme-primary .navbar-nav .nav-link.disabled,
  .navbar-theme-primary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-primary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-primary .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-primary .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-primary .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-primary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-secondary:not(.headroom) {
  background-color: #61dafb;
}

.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-secondary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-secondary .navbar-nav .dropdown-item.active,
.navbar-theme-secondary .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary .navbar-nav .list-group-item.active,
.navbar-theme-secondary .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-secondary .navbar-nav .nav-link,
  .navbar-theme-secondary .navbar-nav .nav-link:focus,
  .navbar-theme-secondary .navbar-nav .nav-link.active,
  .navbar-theme-secondary .navbar-nav .nav-link:hover,
  .navbar-theme-secondary .navbar-nav .show > .nav-link,
  .navbar-theme-secondary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary .navbar-nav .dropdown-item,
  .navbar-theme-secondary .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary .navbar-nav .list-group-item,
  .navbar-theme-secondary .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary .navbar-nav .list-group-item.active,
  .navbar-theme-secondary .navbar-nav .list-group-item:hover {
    color: #61dafb;
    background: transparent;
  }

  .navbar-theme-secondary .navbar-nav .nav-link.disabled,
  .navbar-theme-secondary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-secondary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-secondary .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-secondary .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-secondary .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-secondary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-tertiary:not(.headroom) {
  background-color: #1b998b;
}

.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-tertiary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-tertiary .navbar-nav .dropdown-item.active,
.navbar-theme-tertiary .navbar-nav .dropdown-item:hover,
.navbar-theme-tertiary .navbar-nav .list-group-item.active,
.navbar-theme-tertiary .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-tertiary .navbar-nav .nav-link,
  .navbar-theme-tertiary .navbar-nav .nav-link:focus,
  .navbar-theme-tertiary .navbar-nav .nav-link.active,
  .navbar-theme-tertiary .navbar-nav .nav-link:hover,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link.active,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-tertiary .navbar-nav .dropdown-item,
  .navbar-theme-tertiary .navbar-nav .dropdown-item:focus,
  .navbar-theme-tertiary .navbar-nav .dropdown-item.active,
  .navbar-theme-tertiary .navbar-nav .dropdown-item:hover,
  .navbar-theme-tertiary .navbar-nav .list-group-item,
  .navbar-theme-tertiary .navbar-nav .list-group-item:focus,
  .navbar-theme-tertiary .navbar-nav .list-group-item.active,
  .navbar-theme-tertiary .navbar-nav .list-group-item:hover {
    color: #1b998b;
    background: transparent;
  }

  .navbar-theme-tertiary .navbar-nav .nav-link.disabled,
  .navbar-theme-tertiary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-tertiary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-tertiary .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-tertiary .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-tertiary .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-tertiary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-quaternary:not(.headroom) {
  background-color: #c96480;
}

.navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-quaternary.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-quaternary .navbar-nav .dropdown-item.active,
.navbar-theme-quaternary .navbar-nav .dropdown-item:hover,
.navbar-theme-quaternary .navbar-nav .list-group-item.active,
.navbar-theme-quaternary .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-quaternary .navbar-nav .nav-link,
  .navbar-theme-quaternary .navbar-nav .nav-link:focus,
  .navbar-theme-quaternary .navbar-nav .nav-link.active,
  .navbar-theme-quaternary .navbar-nav .nav-link:hover,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link:focus,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link.active,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link:hover,
  .navbar-theme-quaternary .navbar-nav .dropdown-item,
  .navbar-theme-quaternary .navbar-nav .dropdown-item:focus,
  .navbar-theme-quaternary .navbar-nav .dropdown-item.active,
  .navbar-theme-quaternary .navbar-nav .dropdown-item:hover,
  .navbar-theme-quaternary .navbar-nav .list-group-item,
  .navbar-theme-quaternary .navbar-nav .list-group-item:focus,
  .navbar-theme-quaternary .navbar-nav .list-group-item.active,
  .navbar-theme-quaternary .navbar-nav .list-group-item:hover {
    color: #c96480;
    background: transparent;
  }

  .navbar-theme-quaternary .navbar-nav .nav-link.disabled,
  .navbar-theme-quaternary .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-quaternary .navbar-nav .dropdown-item.disabled,
  .navbar-theme-quaternary .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-quaternary .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-quaternary .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-quaternary .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-primary-app:not(.headroom) {
  background-color: #ebf4f6;
}

.navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-primary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-primary-app .navbar-nav .dropdown-item.active,
.navbar-theme-primary-app .navbar-nav .dropdown-item:hover,
.navbar-theme-primary-app .navbar-nav .list-group-item.active,
.navbar-theme-primary-app .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-primary-app .navbar-nav .nav-link,
  .navbar-theme-primary-app .navbar-nav .nav-link:focus,
  .navbar-theme-primary-app .navbar-nav .nav-link.active,
  .navbar-theme-primary-app .navbar-nav .nav-link:hover,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link:focus,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link.active,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link:hover,
  .navbar-theme-primary-app .navbar-nav .dropdown-item,
  .navbar-theme-primary-app .navbar-nav .dropdown-item:focus,
  .navbar-theme-primary-app .navbar-nav .dropdown-item.active,
  .navbar-theme-primary-app .navbar-nav .dropdown-item:hover,
  .navbar-theme-primary-app .navbar-nav .list-group-item,
  .navbar-theme-primary-app .navbar-nav .list-group-item:focus,
  .navbar-theme-primary-app .navbar-nav .list-group-item.active,
  .navbar-theme-primary-app .navbar-nav .list-group-item:hover {
    color: #ebf4f6;
    background: transparent;
  }

  .navbar-theme-primary-app .navbar-nav .nav-link.disabled,
  .navbar-theme-primary-app .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-primary-app .navbar-nav .dropdown-item.disabled,
  .navbar-theme-primary-app .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-primary-app .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-primary-app .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-primary-app .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-secondary-app:not(.headroom) {
  background-color: #424aa0;
}

.navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-secondary-app.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-secondary-app .navbar-nav .dropdown-item.active,
.navbar-theme-secondary-app .navbar-nav .dropdown-item:hover,
.navbar-theme-secondary-app .navbar-nav .list-group-item.active,
.navbar-theme-secondary-app .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-secondary-app .navbar-nav .nav-link,
  .navbar-theme-secondary-app .navbar-nav .nav-link:focus,
  .navbar-theme-secondary-app .navbar-nav .nav-link.active,
  .navbar-theme-secondary-app .navbar-nav .nav-link:hover,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link:focus,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link.active,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link:hover,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item:focus,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item.active,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item:hover,
  .navbar-theme-secondary-app .navbar-nav .list-group-item,
  .navbar-theme-secondary-app .navbar-nav .list-group-item:focus,
  .navbar-theme-secondary-app .navbar-nav .list-group-item.active,
  .navbar-theme-secondary-app .navbar-nav .list-group-item:hover {
    color: #424aa0;
    background: transparent;
  }

  .navbar-theme-secondary-app .navbar-nav .nav-link.disabled,
  .navbar-theme-secondary-app .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-secondary-app .navbar-nav .dropdown-item.disabled,
  .navbar-theme-secondary-app .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-secondary-app .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-secondary-app .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-secondary-app .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-light:not(.headroom) {
  background-color: #eaedf2;
}

.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-light.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-light .navbar-nav .dropdown-item.active,
.navbar-theme-light .navbar-nav .dropdown-item:hover,
.navbar-theme-light .navbar-nav .list-group-item.active,
.navbar-theme-light .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-light .navbar-nav .nav-link,
  .navbar-theme-light .navbar-nav .nav-link:focus,
  .navbar-theme-light .navbar-nav .nav-link.active,
  .navbar-theme-light .navbar-nav .nav-link:hover,
  .navbar-theme-light .navbar-nav .show > .nav-link,
  .navbar-theme-light .navbar-nav .show > .nav-link:focus,
  .navbar-theme-light .navbar-nav .show > .nav-link.active,
  .navbar-theme-light .navbar-nav .show > .nav-link:hover,
  .navbar-theme-light .navbar-nav .dropdown-item,
  .navbar-theme-light .navbar-nav .dropdown-item:focus,
  .navbar-theme-light .navbar-nav .dropdown-item.active,
  .navbar-theme-light .navbar-nav .dropdown-item:hover,
  .navbar-theme-light .navbar-nav .list-group-item,
  .navbar-theme-light .navbar-nav .list-group-item:focus,
  .navbar-theme-light .navbar-nav .list-group-item.active,
  .navbar-theme-light .navbar-nav .list-group-item:hover {
    color: #eaedf2;
    background: transparent;
  }

  .navbar-theme-light .navbar-nav .nav-link.disabled,
  .navbar-theme-light .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-light .navbar-nav .dropdown-item.disabled,
  .navbar-theme-light .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-light .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-light .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-light .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-lighten:not(.headroom) {
  background-color: #f0f3f6;
}

.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-lighten.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-lighten .navbar-nav .dropdown-item.active,
.navbar-theme-lighten .navbar-nav .dropdown-item:hover,
.navbar-theme-lighten .navbar-nav .list-group-item.active,
.navbar-theme-lighten .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-lighten .navbar-nav .nav-link,
  .navbar-theme-lighten .navbar-nav .nav-link:focus,
  .navbar-theme-lighten .navbar-nav .nav-link.active,
  .navbar-theme-lighten .navbar-nav .nav-link:hover,
  .navbar-theme-lighten .navbar-nav .show > .nav-link,
  .navbar-theme-lighten .navbar-nav .show > .nav-link:focus,
  .navbar-theme-lighten .navbar-nav .show > .nav-link.active,
  .navbar-theme-lighten .navbar-nav .show > .nav-link:hover,
  .navbar-theme-lighten .navbar-nav .dropdown-item,
  .navbar-theme-lighten .navbar-nav .dropdown-item:focus,
  .navbar-theme-lighten .navbar-nav .dropdown-item.active,
  .navbar-theme-lighten .navbar-nav .dropdown-item:hover,
  .navbar-theme-lighten .navbar-nav .list-group-item,
  .navbar-theme-lighten .navbar-nav .list-group-item:focus,
  .navbar-theme-lighten .navbar-nav .list-group-item.active,
  .navbar-theme-lighten .navbar-nav .list-group-item:hover {
    color: #f0f3f6;
    background: transparent;
  }

  .navbar-theme-lighten .navbar-nav .nav-link.disabled,
  .navbar-theme-lighten .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-lighten .navbar-nav .dropdown-item.disabled,
  .navbar-theme-lighten .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-lighten .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-lighten .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-lighten .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-success:not(.headroom) {
  background-color: #05a677;
}

.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-success.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-success .navbar-nav .dropdown-item.active,
.navbar-theme-success .navbar-nav .dropdown-item:hover,
.navbar-theme-success .navbar-nav .list-group-item.active,
.navbar-theme-success .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-success .navbar-nav .nav-link,
  .navbar-theme-success .navbar-nav .nav-link:focus,
  .navbar-theme-success .navbar-nav .nav-link.active,
  .navbar-theme-success .navbar-nav .nav-link:hover,
  .navbar-theme-success .navbar-nav .show > .nav-link,
  .navbar-theme-success .navbar-nav .show > .nav-link:focus,
  .navbar-theme-success .navbar-nav .show > .nav-link.active,
  .navbar-theme-success .navbar-nav .show > .nav-link:hover,
  .navbar-theme-success .navbar-nav .dropdown-item,
  .navbar-theme-success .navbar-nav .dropdown-item:focus,
  .navbar-theme-success .navbar-nav .dropdown-item.active,
  .navbar-theme-success .navbar-nav .dropdown-item:hover,
  .navbar-theme-success .navbar-nav .list-group-item,
  .navbar-theme-success .navbar-nav .list-group-item:focus,
  .navbar-theme-success .navbar-nav .list-group-item.active,
  .navbar-theme-success .navbar-nav .list-group-item:hover {
    color: #05a677;
    background: transparent;
  }

  .navbar-theme-success .navbar-nav .nav-link.disabled,
  .navbar-theme-success .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-success .navbar-nav .dropdown-item.disabled,
  .navbar-theme-success .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-success .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-success .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-success .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-info:not(.headroom) {
  background-color: #0948b3;
}

.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-info.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-info .navbar-nav .dropdown-item.active,
.navbar-theme-info .navbar-nav .dropdown-item:hover,
.navbar-theme-info .navbar-nav .list-group-item.active,
.navbar-theme-info .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-info .navbar-nav .nav-link,
  .navbar-theme-info .navbar-nav .nav-link:focus,
  .navbar-theme-info .navbar-nav .nav-link.active,
  .navbar-theme-info .navbar-nav .nav-link:hover,
  .navbar-theme-info .navbar-nav .show > .nav-link,
  .navbar-theme-info .navbar-nav .show > .nav-link:focus,
  .navbar-theme-info .navbar-nav .show > .nav-link.active,
  .navbar-theme-info .navbar-nav .show > .nav-link:hover,
  .navbar-theme-info .navbar-nav .dropdown-item,
  .navbar-theme-info .navbar-nav .dropdown-item:focus,
  .navbar-theme-info .navbar-nav .dropdown-item.active,
  .navbar-theme-info .navbar-nav .dropdown-item:hover,
  .navbar-theme-info .navbar-nav .list-group-item,
  .navbar-theme-info .navbar-nav .list-group-item:focus,
  .navbar-theme-info .navbar-nav .list-group-item.active,
  .navbar-theme-info .navbar-nav .list-group-item:hover {
    color: #0948b3;
    background: transparent;
  }

  .navbar-theme-info .navbar-nav .nav-link.disabled,
  .navbar-theme-info .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-info .navbar-nav .dropdown-item.disabled,
  .navbar-theme-info .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-info .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-info .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-info .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-warning:not(.headroom) {
  background-color: #f5b759;
}

.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-warning.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-warning .navbar-nav .dropdown-item.active,
.navbar-theme-warning .navbar-nav .dropdown-item:hover,
.navbar-theme-warning .navbar-nav .list-group-item.active,
.navbar-theme-warning .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-warning .navbar-nav .nav-link,
  .navbar-theme-warning .navbar-nav .nav-link:focus,
  .navbar-theme-warning .navbar-nav .nav-link.active,
  .navbar-theme-warning .navbar-nav .nav-link:hover,
  .navbar-theme-warning .navbar-nav .show > .nav-link,
  .navbar-theme-warning .navbar-nav .show > .nav-link:focus,
  .navbar-theme-warning .navbar-nav .show > .nav-link.active,
  .navbar-theme-warning .navbar-nav .show > .nav-link:hover,
  .navbar-theme-warning .navbar-nav .dropdown-item,
  .navbar-theme-warning .navbar-nav .dropdown-item:focus,
  .navbar-theme-warning .navbar-nav .dropdown-item.active,
  .navbar-theme-warning .navbar-nav .dropdown-item:hover,
  .navbar-theme-warning .navbar-nav .list-group-item,
  .navbar-theme-warning .navbar-nav .list-group-item:focus,
  .navbar-theme-warning .navbar-nav .list-group-item.active,
  .navbar-theme-warning .navbar-nav .list-group-item:hover {
    color: #f5b759;
    background: transparent;
  }

  .navbar-theme-warning .navbar-nav .nav-link.disabled,
  .navbar-theme-warning .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-warning .navbar-nav .dropdown-item.disabled,
  .navbar-theme-warning .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-warning .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-warning .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-warning .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-danger:not(.headroom) {
  background-color: #fa5252;
}

.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-danger.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-danger .navbar-nav .dropdown-item.active,
.navbar-theme-danger .navbar-nav .dropdown-item:hover,
.navbar-theme-danger .navbar-nav .list-group-item.active,
.navbar-theme-danger .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-danger .navbar-nav .nav-link,
  .navbar-theme-danger .navbar-nav .nav-link:focus,
  .navbar-theme-danger .navbar-nav .nav-link.active,
  .navbar-theme-danger .navbar-nav .nav-link:hover,
  .navbar-theme-danger .navbar-nav .show > .nav-link,
  .navbar-theme-danger .navbar-nav .show > .nav-link:focus,
  .navbar-theme-danger .navbar-nav .show > .nav-link.active,
  .navbar-theme-danger .navbar-nav .show > .nav-link:hover,
  .navbar-theme-danger .navbar-nav .dropdown-item,
  .navbar-theme-danger .navbar-nav .dropdown-item:focus,
  .navbar-theme-danger .navbar-nav .dropdown-item.active,
  .navbar-theme-danger .navbar-nav .dropdown-item:hover,
  .navbar-theme-danger .navbar-nav .list-group-item,
  .navbar-theme-danger .navbar-nav .list-group-item:focus,
  .navbar-theme-danger .navbar-nav .list-group-item.active,
  .navbar-theme-danger .navbar-nav .list-group-item:hover {
    color: #fa5252;
    background: transparent;
  }

  .navbar-theme-danger .navbar-nav .nav-link.disabled,
  .navbar-theme-danger .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-danger .navbar-nav .dropdown-item.disabled,
  .navbar-theme-danger .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-danger .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-danger .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-danger .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-white:not(.headroom) {
  background-color: #ffffff;
}

.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-white.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-white .navbar-nav .dropdown-item.active,
.navbar-theme-white .navbar-nav .dropdown-item:hover,
.navbar-theme-white .navbar-nav .list-group-item.active,
.navbar-theme-white .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-white .navbar-nav .nav-link,
  .navbar-theme-white .navbar-nav .nav-link:focus,
  .navbar-theme-white .navbar-nav .nav-link.active,
  .navbar-theme-white .navbar-nav .nav-link:hover,
  .navbar-theme-white .navbar-nav .show > .nav-link,
  .navbar-theme-white .navbar-nav .show > .nav-link:focus,
  .navbar-theme-white .navbar-nav .show > .nav-link.active,
  .navbar-theme-white .navbar-nav .show > .nav-link:hover,
  .navbar-theme-white .navbar-nav .dropdown-item,
  .navbar-theme-white .navbar-nav .dropdown-item:focus,
  .navbar-theme-white .navbar-nav .dropdown-item.active,
  .navbar-theme-white .navbar-nav .dropdown-item:hover,
  .navbar-theme-white .navbar-nav .list-group-item,
  .navbar-theme-white .navbar-nav .list-group-item:focus,
  .navbar-theme-white .navbar-nav .list-group-item.active,
  .navbar-theme-white .navbar-nav .list-group-item:hover {
    color: #ffffff;
    background: transparent;
  }

  .navbar-theme-white .navbar-nav .nav-link.disabled,
  .navbar-theme-white .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-white .navbar-nav .dropdown-item.disabled,
  .navbar-theme-white .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-white .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-white .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-white .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray:not(.headroom) {
  background-color: #4a5073;
}

.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray .navbar-nav .dropdown-item.active,
.navbar-theme-gray .navbar-nav .dropdown-item:hover,
.navbar-theme-gray .navbar-nav .list-group-item.active,
.navbar-theme-gray .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray .navbar-nav .nav-link,
  .navbar-theme-gray .navbar-nav .nav-link:focus,
  .navbar-theme-gray .navbar-nav .nav-link.active,
  .navbar-theme-gray .navbar-nav .nav-link:hover,
  .navbar-theme-gray .navbar-nav .show > .nav-link,
  .navbar-theme-gray .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray .navbar-nav .dropdown-item,
  .navbar-theme-gray .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray .navbar-nav .dropdown-item.active,
  .navbar-theme-gray .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray .navbar-nav .list-group-item,
  .navbar-theme-gray .navbar-nav .list-group-item:focus,
  .navbar-theme-gray .navbar-nav .list-group-item.active,
  .navbar-theme-gray .navbar-nav .list-group-item:hover {
    color: #4a5073;
    background: transparent;
  }

  .navbar-theme-gray .navbar-nav .nav-link.disabled,
  .navbar-theme-gray .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-indigo:not(.headroom) {
  background-color: #4c5680;
}

.navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-indigo .navbar-nav .dropdown-item.active,
.navbar-theme-indigo .navbar-nav .dropdown-item:hover,
.navbar-theme-indigo .navbar-nav .list-group-item.active,
.navbar-theme-indigo .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-indigo .navbar-nav .nav-link,
  .navbar-theme-indigo .navbar-nav .nav-link:focus,
  .navbar-theme-indigo .navbar-nav .nav-link.active,
  .navbar-theme-indigo .navbar-nav .nav-link:hover,
  .navbar-theme-indigo .navbar-nav .show > .nav-link,
  .navbar-theme-indigo .navbar-nav .show > .nav-link:focus,
  .navbar-theme-indigo .navbar-nav .show > .nav-link.active,
  .navbar-theme-indigo .navbar-nav .show > .nav-link:hover,
  .navbar-theme-indigo .navbar-nav .dropdown-item,
  .navbar-theme-indigo .navbar-nav .dropdown-item:focus,
  .navbar-theme-indigo .navbar-nav .dropdown-item.active,
  .navbar-theme-indigo .navbar-nav .dropdown-item:hover,
  .navbar-theme-indigo .navbar-nav .list-group-item,
  .navbar-theme-indigo .navbar-nav .list-group-item:focus,
  .navbar-theme-indigo .navbar-nav .list-group-item.active,
  .navbar-theme-indigo .navbar-nav .list-group-item:hover {
    color: #4c5680;
    background: transparent;
  }

  .navbar-theme-indigo .navbar-nav .nav-link.disabled,
  .navbar-theme-indigo .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-indigo .navbar-nav .dropdown-item.disabled,
  .navbar-theme-indigo .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-indigo .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-indigo .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-indigo .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-dark:not(.headroom) {
  background-color: #262b40;
}

.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dark.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-dark .navbar-nav .dropdown-item.active,
.navbar-theme-dark .navbar-nav .dropdown-item:hover,
.navbar-theme-dark .navbar-nav .list-group-item.active,
.navbar-theme-dark .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-dark .navbar-nav .nav-link,
  .navbar-theme-dark .navbar-nav .nav-link:focus,
  .navbar-theme-dark .navbar-nav .nav-link.active,
  .navbar-theme-dark .navbar-nav .nav-link:hover,
  .navbar-theme-dark .navbar-nav .show > .nav-link,
  .navbar-theme-dark .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dark .navbar-nav .show > .nav-link.active,
  .navbar-theme-dark .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dark .navbar-nav .dropdown-item,
  .navbar-theme-dark .navbar-nav .dropdown-item:focus,
  .navbar-theme-dark .navbar-nav .dropdown-item.active,
  .navbar-theme-dark .navbar-nav .dropdown-item:hover,
  .navbar-theme-dark .navbar-nav .list-group-item,
  .navbar-theme-dark .navbar-nav .list-group-item:focus,
  .navbar-theme-dark .navbar-nav .list-group-item.active,
  .navbar-theme-dark .navbar-nav .list-group-item:hover {
    color: #262b40;
    background: transparent;
  }

  .navbar-theme-dark .navbar-nav .nav-link.disabled,
  .navbar-theme-dark .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dark .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dark .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-dark .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-dark .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-dark .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-soft:not(.headroom) {
  background-color: #f5f8fb;
}

.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-soft.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-soft .navbar-nav .dropdown-item.active,
.navbar-theme-soft .navbar-nav .dropdown-item:hover,
.navbar-theme-soft .navbar-nav .list-group-item.active,
.navbar-theme-soft .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-soft .navbar-nav .nav-link,
  .navbar-theme-soft .navbar-nav .nav-link:focus,
  .navbar-theme-soft .navbar-nav .nav-link.active,
  .navbar-theme-soft .navbar-nav .nav-link:hover,
  .navbar-theme-soft .navbar-nav .show > .nav-link,
  .navbar-theme-soft .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft .navbar-nav .dropdown-item,
  .navbar-theme-soft .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft .navbar-nav .dropdown-item.active,
  .navbar-theme-soft .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft .navbar-nav .list-group-item,
  .navbar-theme-soft .navbar-nav .list-group-item:focus,
  .navbar-theme-soft .navbar-nav .list-group-item.active,
  .navbar-theme-soft .navbar-nav .list-group-item:hover {
    color: #f5f8fb;
    background: transparent;
  }

  .navbar-theme-soft .navbar-nav .nav-link.disabled,
  .navbar-theme-soft .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-soft .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-soft .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-soft .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-soft .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-black:not(.headroom) {
  background-color: #2e3650;
}

.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-black.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-black .navbar-nav .dropdown-item.active,
.navbar-theme-black .navbar-nav .dropdown-item:hover,
.navbar-theme-black .navbar-nav .list-group-item.active,
.navbar-theme-black .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-black .navbar-nav .nav-link,
  .navbar-theme-black .navbar-nav .nav-link:focus,
  .navbar-theme-black .navbar-nav .nav-link.active,
  .navbar-theme-black .navbar-nav .nav-link:hover,
  .navbar-theme-black .navbar-nav .show > .nav-link,
  .navbar-theme-black .navbar-nav .show > .nav-link:focus,
  .navbar-theme-black .navbar-nav .show > .nav-link.active,
  .navbar-theme-black .navbar-nav .show > .nav-link:hover,
  .navbar-theme-black .navbar-nav .dropdown-item,
  .navbar-theme-black .navbar-nav .dropdown-item:focus,
  .navbar-theme-black .navbar-nav .dropdown-item.active,
  .navbar-theme-black .navbar-nav .dropdown-item:hover,
  .navbar-theme-black .navbar-nav .list-group-item,
  .navbar-theme-black .navbar-nav .list-group-item:focus,
  .navbar-theme-black .navbar-nav .list-group-item.active,
  .navbar-theme-black .navbar-nav .list-group-item:hover {
    color: #2e3650;
    background: transparent;
  }

  .navbar-theme-black .navbar-nav .nav-link.disabled,
  .navbar-theme-black .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-black .navbar-nav .dropdown-item.disabled,
  .navbar-theme-black .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-black .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-black .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-black .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-blue:not(.headroom) {
  background-color: #0948b3;
}

.navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-blue.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-blue .navbar-nav .dropdown-item.active,
.navbar-theme-blue .navbar-nav .dropdown-item:hover,
.navbar-theme-blue .navbar-nav .list-group-item.active,
.navbar-theme-blue .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-blue .navbar-nav .nav-link,
  .navbar-theme-blue .navbar-nav .nav-link:focus,
  .navbar-theme-blue .navbar-nav .nav-link.active,
  .navbar-theme-blue .navbar-nav .nav-link:hover,
  .navbar-theme-blue .navbar-nav .show > .nav-link,
  .navbar-theme-blue .navbar-nav .show > .nav-link:focus,
  .navbar-theme-blue .navbar-nav .show > .nav-link.active,
  .navbar-theme-blue .navbar-nav .show > .nav-link:hover,
  .navbar-theme-blue .navbar-nav .dropdown-item,
  .navbar-theme-blue .navbar-nav .dropdown-item:focus,
  .navbar-theme-blue .navbar-nav .dropdown-item.active,
  .navbar-theme-blue .navbar-nav .dropdown-item:hover,
  .navbar-theme-blue .navbar-nav .list-group-item,
  .navbar-theme-blue .navbar-nav .list-group-item:focus,
  .navbar-theme-blue .navbar-nav .list-group-item.active,
  .navbar-theme-blue .navbar-nav .list-group-item:hover {
    color: #0948b3;
    background: transparent;
  }

  .navbar-theme-blue .navbar-nav .nav-link.disabled,
  .navbar-theme-blue .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-blue .navbar-nav .dropdown-item.disabled,
  .navbar-theme-blue .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-blue .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-blue .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-blue .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-pink:not(.headroom) {
  background-color: #c96480;
}

.navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-pink.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-pink .navbar-nav .dropdown-item.active,
.navbar-theme-pink .navbar-nav .dropdown-item:hover,
.navbar-theme-pink .navbar-nav .list-group-item.active,
.navbar-theme-pink .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-pink .navbar-nav .nav-link,
  .navbar-theme-pink .navbar-nav .nav-link:focus,
  .navbar-theme-pink .navbar-nav .nav-link.active,
  .navbar-theme-pink .navbar-nav .nav-link:hover,
  .navbar-theme-pink .navbar-nav .show > .nav-link,
  .navbar-theme-pink .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pink .navbar-nav .show > .nav-link.active,
  .navbar-theme-pink .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pink .navbar-nav .dropdown-item,
  .navbar-theme-pink .navbar-nav .dropdown-item:focus,
  .navbar-theme-pink .navbar-nav .dropdown-item.active,
  .navbar-theme-pink .navbar-nav .dropdown-item:hover,
  .navbar-theme-pink .navbar-nav .list-group-item,
  .navbar-theme-pink .navbar-nav .list-group-item:focus,
  .navbar-theme-pink .navbar-nav .list-group-item.active,
  .navbar-theme-pink .navbar-nav .list-group-item:hover {
    color: #c96480;
    background: transparent;
  }

  .navbar-theme-pink .navbar-nav .nav-link.disabled,
  .navbar-theme-pink .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-pink .navbar-nav .dropdown-item.disabled,
  .navbar-theme-pink .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-pink .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-pink .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-pink .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-cyan:not(.headroom) {
  background-color: #63b1bd;
}

.navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-cyan.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-cyan .navbar-nav .dropdown-item.active,
.navbar-theme-cyan .navbar-nav .dropdown-item:hover,
.navbar-theme-cyan .navbar-nav .list-group-item.active,
.navbar-theme-cyan .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-cyan .navbar-nav .nav-link,
  .navbar-theme-cyan .navbar-nav .nav-link:focus,
  .navbar-theme-cyan .navbar-nav .nav-link.active,
  .navbar-theme-cyan .navbar-nav .nav-link:hover,
  .navbar-theme-cyan .navbar-nav .show > .nav-link,
  .navbar-theme-cyan .navbar-nav .show > .nav-link:focus,
  .navbar-theme-cyan .navbar-nav .show > .nav-link.active,
  .navbar-theme-cyan .navbar-nav .show > .nav-link:hover,
  .navbar-theme-cyan .navbar-nav .dropdown-item,
  .navbar-theme-cyan .navbar-nav .dropdown-item:focus,
  .navbar-theme-cyan .navbar-nav .dropdown-item.active,
  .navbar-theme-cyan .navbar-nav .dropdown-item:hover,
  .navbar-theme-cyan .navbar-nav .list-group-item,
  .navbar-theme-cyan .navbar-nav .list-group-item:focus,
  .navbar-theme-cyan .navbar-nav .list-group-item.active,
  .navbar-theme-cyan .navbar-nav .list-group-item:hover {
    color: #63b1bd;
    background: transparent;
  }

  .navbar-theme-cyan .navbar-nav .nav-link.disabled,
  .navbar-theme-cyan .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-cyan .navbar-nav .dropdown-item.disabled,
  .navbar-theme-cyan .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-cyan .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-cyan .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-cyan .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-purple:not(.headroom) {
  background-color: #8965e0;
}

.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-purple.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-purple .navbar-nav .dropdown-item.active,
.navbar-theme-purple .navbar-nav .dropdown-item:hover,
.navbar-theme-purple .navbar-nav .list-group-item.active,
.navbar-theme-purple .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-purple .navbar-nav .nav-link,
  .navbar-theme-purple .navbar-nav .nav-link:focus,
  .navbar-theme-purple .navbar-nav .nav-link.active,
  .navbar-theme-purple .navbar-nav .nav-link:hover,
  .navbar-theme-purple .navbar-nav .show > .nav-link,
  .navbar-theme-purple .navbar-nav .show > .nav-link:focus,
  .navbar-theme-purple .navbar-nav .show > .nav-link.active,
  .navbar-theme-purple .navbar-nav .show > .nav-link:hover,
  .navbar-theme-purple .navbar-nav .dropdown-item,
  .navbar-theme-purple .navbar-nav .dropdown-item:focus,
  .navbar-theme-purple .navbar-nav .dropdown-item.active,
  .navbar-theme-purple .navbar-nav .dropdown-item:hover,
  .navbar-theme-purple .navbar-nav .list-group-item,
  .navbar-theme-purple .navbar-nav .list-group-item:focus,
  .navbar-theme-purple .navbar-nav .list-group-item.active,
  .navbar-theme-purple .navbar-nav .list-group-item:hover {
    color: #8965e0;
    background: transparent;
  }

  .navbar-theme-purple .navbar-nav .nav-link.disabled,
  .navbar-theme-purple .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-purple .navbar-nav .dropdown-item.disabled,
  .navbar-theme-purple .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-purple .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-purple .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-purple .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-soft-indigo:not(.headroom) {
  background-color: #f5e8ff;
}

.navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-soft-indigo.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-soft-indigo .navbar-nav .dropdown-item.active,
.navbar-theme-soft-indigo .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-indigo .navbar-nav .list-group-item.active,
.navbar-theme-soft-indigo .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-soft-indigo .navbar-nav .nav-link,
  .navbar-theme-soft-indigo .navbar-nav .nav-link:focus,
  .navbar-theme-soft-indigo .navbar-nav .nav-link.active,
  .navbar-theme-soft-indigo .navbar-nav .nav-link:hover,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item.active,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item:hover {
    color: #f5e8ff;
    background: transparent;
  }

  .navbar-theme-soft-indigo .navbar-nav .nav-link.disabled,
  .navbar-theme-soft-indigo .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-soft-indigo .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft-indigo .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-soft-indigo .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-soft-indigo .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-soft-indigo .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-soft-green:not(.headroom) {
  background-color: #2ca58d;
}

.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-soft-green.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-soft-green .navbar-nav .dropdown-item.active,
.navbar-theme-soft-green .navbar-nav .dropdown-item:hover,
.navbar-theme-soft-green .navbar-nav .list-group-item.active,
.navbar-theme-soft-green .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-soft-green .navbar-nav .nav-link,
  .navbar-theme-soft-green .navbar-nav .nav-link:focus,
  .navbar-theme-soft-green .navbar-nav .nav-link.active,
  .navbar-theme-soft-green .navbar-nav .nav-link:hover,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link:focus,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link.active,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link:hover,
  .navbar-theme-soft-green .navbar-nav .dropdown-item,
  .navbar-theme-soft-green .navbar-nav .dropdown-item:focus,
  .navbar-theme-soft-green .navbar-nav .dropdown-item.active,
  .navbar-theme-soft-green .navbar-nav .dropdown-item:hover,
  .navbar-theme-soft-green .navbar-nav .list-group-item,
  .navbar-theme-soft-green .navbar-nav .list-group-item:focus,
  .navbar-theme-soft-green .navbar-nav .list-group-item.active,
  .navbar-theme-soft-green .navbar-nav .list-group-item:hover {
    color: #2ca58d;
    background: transparent;
  }

  .navbar-theme-soft-green .navbar-nav .nav-link.disabled,
  .navbar-theme-soft-green .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-soft-green .navbar-nav .dropdown-item.disabled,
  .navbar-theme-soft-green .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-soft-green .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-soft-green .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-soft-green .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-100:not(.headroom) {
  background-color: #f3f7fa;
}

.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-100.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray-100 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-100 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-100 .navbar-nav .list-group-item.active,
.navbar-theme-gray-100 .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-100 .navbar-nav .nav-link,
  .navbar-theme-gray-100 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-100 .navbar-nav .nav-link.active,
  .navbar-theme-gray-100 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-100 .navbar-nav .list-group-item,
  .navbar-theme-gray-100 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-100 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-100 .navbar-nav .list-group-item:hover {
    color: #f3f7fa;
    background: transparent;
  }

  .navbar-theme-gray-100 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-100 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-100 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-100 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray-100 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray-100 .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray-100 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-200:not(.headroom) {
  background-color: #f5f8fb;
}

.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-200.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray-200 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-200 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-200 .navbar-nav .list-group-item.active,
.navbar-theme-gray-200 .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-200 .navbar-nav .nav-link,
  .navbar-theme-gray-200 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-200 .navbar-nav .nav-link.active,
  .navbar-theme-gray-200 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-200 .navbar-nav .list-group-item,
  .navbar-theme-gray-200 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-200 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-200 .navbar-nav .list-group-item:hover {
    color: #f5f8fb;
    background: transparent;
  }

  .navbar-theme-gray-200 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-200 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-200 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-200 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray-200 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray-200 .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray-200 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-300:not(.headroom) {
  background-color: #f0f3f6;
}

.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-300.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray-300 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-300 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-300 .navbar-nav .list-group-item.active,
.navbar-theme-gray-300 .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-300 .navbar-nav .nav-link,
  .navbar-theme-gray-300 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-300 .navbar-nav .nav-link.active,
  .navbar-theme-gray-300 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-300 .navbar-nav .list-group-item,
  .navbar-theme-gray-300 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-300 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-300 .navbar-nav .list-group-item:hover {
    color: #f0f3f6;
    background: transparent;
  }

  .navbar-theme-gray-300 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-300 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-300 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-300 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray-300 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray-300 .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray-300 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-400:not(.headroom) {
  background-color: #eaedf2;
}

.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-400.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray-400 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-400 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-400 .navbar-nav .list-group-item.active,
.navbar-theme-gray-400 .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-400 .navbar-nav .nav-link,
  .navbar-theme-gray-400 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-400 .navbar-nav .nav-link.active,
  .navbar-theme-gray-400 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-400 .navbar-nav .list-group-item,
  .navbar-theme-gray-400 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-400 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-400 .navbar-nav .list-group-item:hover {
    color: #eaedf2;
    background: transparent;
  }

  .navbar-theme-gray-400 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-400 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-400 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-400 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray-400 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray-400 .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray-400 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-500:not(.headroom) {
  background-color: #d1d7e0;
}

.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-500.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray-500 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-500 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-500 .navbar-nav .list-group-item.active,
.navbar-theme-gray-500 .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-500 .navbar-nav .nav-link,
  .navbar-theme-gray-500 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-500 .navbar-nav .nav-link.active,
  .navbar-theme-gray-500 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-500 .navbar-nav .list-group-item,
  .navbar-theme-gray-500 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-500 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-500 .navbar-nav .list-group-item:hover {
    color: #d1d7e0;
    background: transparent;
  }

  .navbar-theme-gray-500 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-500 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-500 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-500 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray-500 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray-500 .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray-500 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-600:not(.headroom) {
  background-color: #93a5be;
}

.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-600.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray-600 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-600 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-600 .navbar-nav .list-group-item.active,
.navbar-theme-gray-600 .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-600 .navbar-nav .nav-link,
  .navbar-theme-gray-600 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-600 .navbar-nav .nav-link.active,
  .navbar-theme-gray-600 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-600 .navbar-nav .list-group-item,
  .navbar-theme-gray-600 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-600 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-600 .navbar-nav .list-group-item:hover {
    color: #93a5be;
    background: transparent;
  }

  .navbar-theme-gray-600 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-600 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-600 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-600 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray-600 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray-600 .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray-600 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-700:not(.headroom) {
  background-color: #66799e;
}

.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-700.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray-700 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-700 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-700 .navbar-nav .list-group-item.active,
.navbar-theme-gray-700 .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-700 .navbar-nav .nav-link,
  .navbar-theme-gray-700 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-700 .navbar-nav .nav-link.active,
  .navbar-theme-gray-700 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-700 .navbar-nav .list-group-item,
  .navbar-theme-gray-700 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-700 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-700 .navbar-nav .list-group-item:hover {
    color: #66799e;
    background: transparent;
  }

  .navbar-theme-gray-700 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-700 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-700 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-700 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray-700 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray-700 .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray-700 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-gray-800:not(.headroom) {
  background-color: #506690;
}

.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-gray-800.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-gray-800 .navbar-nav .dropdown-item.active,
.navbar-theme-gray-800 .navbar-nav .dropdown-item:hover,
.navbar-theme-gray-800 .navbar-nav .list-group-item.active,
.navbar-theme-gray-800 .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-gray-800 .navbar-nav .nav-link,
  .navbar-theme-gray-800 .navbar-nav .nav-link:focus,
  .navbar-theme-gray-800 .navbar-nav .nav-link.active,
  .navbar-theme-gray-800 .navbar-nav .nav-link:hover,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link:focus,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link.active,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link:hover,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item:focus,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item.active,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item:hover,
  .navbar-theme-gray-800 .navbar-nav .list-group-item,
  .navbar-theme-gray-800 .navbar-nav .list-group-item:focus,
  .navbar-theme-gray-800 .navbar-nav .list-group-item.active,
  .navbar-theme-gray-800 .navbar-nav .list-group-item:hover {
    color: #506690;
    background: transparent;
  }

  .navbar-theme-gray-800 .navbar-nav .nav-link.disabled,
  .navbar-theme-gray-800 .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-gray-800 .navbar-nav .dropdown-item.disabled,
  .navbar-theme-gray-800 .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-gray-800 .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-gray-800 .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-gray-800 .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-facebook:not(.headroom) {
  background-color: #3b5999;
}

.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-facebook.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-facebook .navbar-nav .dropdown-item.active,
.navbar-theme-facebook .navbar-nav .dropdown-item:hover,
.navbar-theme-facebook .navbar-nav .list-group-item.active,
.navbar-theme-facebook .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-facebook .navbar-nav .nav-link,
  .navbar-theme-facebook .navbar-nav .nav-link:focus,
  .navbar-theme-facebook .navbar-nav .nav-link.active,
  .navbar-theme-facebook .navbar-nav .nav-link:hover,
  .navbar-theme-facebook .navbar-nav .show > .nav-link,
  .navbar-theme-facebook .navbar-nav .show > .nav-link:focus,
  .navbar-theme-facebook .navbar-nav .show > .nav-link.active,
  .navbar-theme-facebook .navbar-nav .show > .nav-link:hover,
  .navbar-theme-facebook .navbar-nav .dropdown-item,
  .navbar-theme-facebook .navbar-nav .dropdown-item:focus,
  .navbar-theme-facebook .navbar-nav .dropdown-item.active,
  .navbar-theme-facebook .navbar-nav .dropdown-item:hover,
  .navbar-theme-facebook .navbar-nav .list-group-item,
  .navbar-theme-facebook .navbar-nav .list-group-item:focus,
  .navbar-theme-facebook .navbar-nav .list-group-item.active,
  .navbar-theme-facebook .navbar-nav .list-group-item:hover {
    color: #3b5999;
    background: transparent;
  }

  .navbar-theme-facebook .navbar-nav .nav-link.disabled,
  .navbar-theme-facebook .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-facebook .navbar-nav .dropdown-item.disabled,
  .navbar-theme-facebook .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-facebook .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-facebook .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-facebook .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-twitter:not(.headroom) {
  background-color: #1da1f2;
}

.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-twitter.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-twitter .navbar-nav .dropdown-item.active,
.navbar-theme-twitter .navbar-nav .dropdown-item:hover,
.navbar-theme-twitter .navbar-nav .list-group-item.active,
.navbar-theme-twitter .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-twitter .navbar-nav .nav-link,
  .navbar-theme-twitter .navbar-nav .nav-link:focus,
  .navbar-theme-twitter .navbar-nav .nav-link.active,
  .navbar-theme-twitter .navbar-nav .nav-link:hover,
  .navbar-theme-twitter .navbar-nav .show > .nav-link,
  .navbar-theme-twitter .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitter .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitter .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitter .navbar-nav .dropdown-item,
  .navbar-theme-twitter .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitter .navbar-nav .dropdown-item.active,
  .navbar-theme-twitter .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitter .navbar-nav .list-group-item,
  .navbar-theme-twitter .navbar-nav .list-group-item:focus,
  .navbar-theme-twitter .navbar-nav .list-group-item.active,
  .navbar-theme-twitter .navbar-nav .list-group-item:hover {
    color: #1da1f2;
    background: transparent;
  }

  .navbar-theme-twitter .navbar-nav .nav-link.disabled,
  .navbar-theme-twitter .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-twitter .navbar-nav .dropdown-item.disabled,
  .navbar-theme-twitter .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-twitter .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-twitter .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-twitter .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-google:not(.headroom) {
  background-color: #db4337;
}

.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-google.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-google .navbar-nav .dropdown-item.active,
.navbar-theme-google .navbar-nav .dropdown-item:hover,
.navbar-theme-google .navbar-nav .list-group-item.active,
.navbar-theme-google .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-google .navbar-nav .nav-link,
  .navbar-theme-google .navbar-nav .nav-link:focus,
  .navbar-theme-google .navbar-nav .nav-link.active,
  .navbar-theme-google .navbar-nav .nav-link:hover,
  .navbar-theme-google .navbar-nav .show > .nav-link,
  .navbar-theme-google .navbar-nav .show > .nav-link:focus,
  .navbar-theme-google .navbar-nav .show > .nav-link.active,
  .navbar-theme-google .navbar-nav .show > .nav-link:hover,
  .navbar-theme-google .navbar-nav .dropdown-item,
  .navbar-theme-google .navbar-nav .dropdown-item:focus,
  .navbar-theme-google .navbar-nav .dropdown-item.active,
  .navbar-theme-google .navbar-nav .dropdown-item:hover,
  .navbar-theme-google .navbar-nav .list-group-item,
  .navbar-theme-google .navbar-nav .list-group-item:focus,
  .navbar-theme-google .navbar-nav .list-group-item.active,
  .navbar-theme-google .navbar-nav .list-group-item:hover {
    color: #db4337;
    background: transparent;
  }

  .navbar-theme-google .navbar-nav .nav-link.disabled,
  .navbar-theme-google .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-google .navbar-nav .dropdown-item.disabled,
  .navbar-theme-google .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-google .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-google .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-google .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-instagram:not(.headroom) {
  background-color: #e4405f;
}

.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-instagram.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-instagram .navbar-nav .dropdown-item.active,
.navbar-theme-instagram .navbar-nav .dropdown-item:hover,
.navbar-theme-instagram .navbar-nav .list-group-item.active,
.navbar-theme-instagram .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-instagram .navbar-nav .nav-link,
  .navbar-theme-instagram .navbar-nav .nav-link:focus,
  .navbar-theme-instagram .navbar-nav .nav-link.active,
  .navbar-theme-instagram .navbar-nav .nav-link:hover,
  .navbar-theme-instagram .navbar-nav .show > .nav-link,
  .navbar-theme-instagram .navbar-nav .show > .nav-link:focus,
  .navbar-theme-instagram .navbar-nav .show > .nav-link.active,
  .navbar-theme-instagram .navbar-nav .show > .nav-link:hover,
  .navbar-theme-instagram .navbar-nav .dropdown-item,
  .navbar-theme-instagram .navbar-nav .dropdown-item:focus,
  .navbar-theme-instagram .navbar-nav .dropdown-item.active,
  .navbar-theme-instagram .navbar-nav .dropdown-item:hover,
  .navbar-theme-instagram .navbar-nav .list-group-item,
  .navbar-theme-instagram .navbar-nav .list-group-item:focus,
  .navbar-theme-instagram .navbar-nav .list-group-item.active,
  .navbar-theme-instagram .navbar-nav .list-group-item:hover {
    color: #e4405f;
    background: transparent;
  }

  .navbar-theme-instagram .navbar-nav .nav-link.disabled,
  .navbar-theme-instagram .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-instagram .navbar-nav .dropdown-item.disabled,
  .navbar-theme-instagram .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-instagram .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-instagram .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-instagram .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-pinterest:not(.headroom) {
  background-color: #bd081c;
}

.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-pinterest.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-pinterest .navbar-nav .dropdown-item.active,
.navbar-theme-pinterest .navbar-nav .dropdown-item:hover,
.navbar-theme-pinterest .navbar-nav .list-group-item.active,
.navbar-theme-pinterest .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-pinterest .navbar-nav .nav-link,
  .navbar-theme-pinterest .navbar-nav .nav-link:focus,
  .navbar-theme-pinterest .navbar-nav .nav-link.active,
  .navbar-theme-pinterest .navbar-nav .nav-link:hover,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link:focus,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link.active,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link:hover,
  .navbar-theme-pinterest .navbar-nav .dropdown-item,
  .navbar-theme-pinterest .navbar-nav .dropdown-item:focus,
  .navbar-theme-pinterest .navbar-nav .dropdown-item.active,
  .navbar-theme-pinterest .navbar-nav .dropdown-item:hover,
  .navbar-theme-pinterest .navbar-nav .list-group-item,
  .navbar-theme-pinterest .navbar-nav .list-group-item:focus,
  .navbar-theme-pinterest .navbar-nav .list-group-item.active,
  .navbar-theme-pinterest .navbar-nav .list-group-item:hover {
    color: #bd081c;
    background: transparent;
  }

  .navbar-theme-pinterest .navbar-nav .nav-link.disabled,
  .navbar-theme-pinterest .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-pinterest .navbar-nav .dropdown-item.disabled,
  .navbar-theme-pinterest .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-pinterest .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-pinterest .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-pinterest .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-youtube:not(.headroom) {
  background-color: #cd201f;
}

.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-youtube.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-youtube .navbar-nav .dropdown-item.active,
.navbar-theme-youtube .navbar-nav .dropdown-item:hover,
.navbar-theme-youtube .navbar-nav .list-group-item.active,
.navbar-theme-youtube .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-youtube .navbar-nav .nav-link,
  .navbar-theme-youtube .navbar-nav .nav-link:focus,
  .navbar-theme-youtube .navbar-nav .nav-link.active,
  .navbar-theme-youtube .navbar-nav .nav-link:hover,
  .navbar-theme-youtube .navbar-nav .show > .nav-link,
  .navbar-theme-youtube .navbar-nav .show > .nav-link:focus,
  .navbar-theme-youtube .navbar-nav .show > .nav-link.active,
  .navbar-theme-youtube .navbar-nav .show > .nav-link:hover,
  .navbar-theme-youtube .navbar-nav .dropdown-item,
  .navbar-theme-youtube .navbar-nav .dropdown-item:focus,
  .navbar-theme-youtube .navbar-nav .dropdown-item.active,
  .navbar-theme-youtube .navbar-nav .dropdown-item:hover,
  .navbar-theme-youtube .navbar-nav .list-group-item,
  .navbar-theme-youtube .navbar-nav .list-group-item:focus,
  .navbar-theme-youtube .navbar-nav .list-group-item.active,
  .navbar-theme-youtube .navbar-nav .list-group-item:hover {
    color: #cd201f;
    background: transparent;
  }

  .navbar-theme-youtube .navbar-nav .nav-link.disabled,
  .navbar-theme-youtube .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-youtube .navbar-nav .dropdown-item.disabled,
  .navbar-theme-youtube .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-youtube .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-youtube .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-youtube .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-slack:not(.headroom) {
  background-color: #3aaf85;
}

.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-slack.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-slack .navbar-nav .dropdown-item.active,
.navbar-theme-slack .navbar-nav .dropdown-item:hover,
.navbar-theme-slack .navbar-nav .list-group-item.active,
.navbar-theme-slack .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-slack .navbar-nav .nav-link,
  .navbar-theme-slack .navbar-nav .nav-link:focus,
  .navbar-theme-slack .navbar-nav .nav-link.active,
  .navbar-theme-slack .navbar-nav .nav-link:hover,
  .navbar-theme-slack .navbar-nav .show > .nav-link,
  .navbar-theme-slack .navbar-nav .show > .nav-link:focus,
  .navbar-theme-slack .navbar-nav .show > .nav-link.active,
  .navbar-theme-slack .navbar-nav .show > .nav-link:hover,
  .navbar-theme-slack .navbar-nav .dropdown-item,
  .navbar-theme-slack .navbar-nav .dropdown-item:focus,
  .navbar-theme-slack .navbar-nav .dropdown-item.active,
  .navbar-theme-slack .navbar-nav .dropdown-item:hover,
  .navbar-theme-slack .navbar-nav .list-group-item,
  .navbar-theme-slack .navbar-nav .list-group-item:focus,
  .navbar-theme-slack .navbar-nav .list-group-item.active,
  .navbar-theme-slack .navbar-nav .list-group-item:hover {
    color: #3aaf85;
    background: transparent;
  }

  .navbar-theme-slack .navbar-nav .nav-link.disabled,
  .navbar-theme-slack .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-slack .navbar-nav .dropdown-item.disabled,
  .navbar-theme-slack .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-slack .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-slack .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-slack .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-dribbble:not(.headroom) {
  background-color: #ea4c89;
}

.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dribbble.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-dribbble .navbar-nav .dropdown-item.active,
.navbar-theme-dribbble .navbar-nav .dropdown-item:hover,
.navbar-theme-dribbble .navbar-nav .list-group-item.active,
.navbar-theme-dribbble .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-dribbble .navbar-nav .nav-link,
  .navbar-theme-dribbble .navbar-nav .nav-link:focus,
  .navbar-theme-dribbble .navbar-nav .nav-link.active,
  .navbar-theme-dribbble .navbar-nav .nav-link:hover,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link.active,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dribbble .navbar-nav .dropdown-item,
  .navbar-theme-dribbble .navbar-nav .dropdown-item:focus,
  .navbar-theme-dribbble .navbar-nav .dropdown-item.active,
  .navbar-theme-dribbble .navbar-nav .dropdown-item:hover,
  .navbar-theme-dribbble .navbar-nav .list-group-item,
  .navbar-theme-dribbble .navbar-nav .list-group-item:focus,
  .navbar-theme-dribbble .navbar-nav .list-group-item.active,
  .navbar-theme-dribbble .navbar-nav .list-group-item:hover {
    color: #ea4c89;
    background: transparent;
  }

  .navbar-theme-dribbble .navbar-nav .nav-link.disabled,
  .navbar-theme-dribbble .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dribbble .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dribbble .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-dribbble .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-dribbble .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-dribbble .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-dropbox:not(.headroom) {
  background-color: #1e90ff;
}

.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-dropbox.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-dropbox .navbar-nav .dropdown-item.active,
.navbar-theme-dropbox .navbar-nav .dropdown-item:hover,
.navbar-theme-dropbox .navbar-nav .list-group-item.active,
.navbar-theme-dropbox .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-dropbox .navbar-nav .nav-link,
  .navbar-theme-dropbox .navbar-nav .nav-link:focus,
  .navbar-theme-dropbox .navbar-nav .nav-link.active,
  .navbar-theme-dropbox .navbar-nav .nav-link:hover,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link:focus,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link.active,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link:hover,
  .navbar-theme-dropbox .navbar-nav .dropdown-item,
  .navbar-theme-dropbox .navbar-nav .dropdown-item:focus,
  .navbar-theme-dropbox .navbar-nav .dropdown-item.active,
  .navbar-theme-dropbox .navbar-nav .dropdown-item:hover,
  .navbar-theme-dropbox .navbar-nav .list-group-item,
  .navbar-theme-dropbox .navbar-nav .list-group-item:focus,
  .navbar-theme-dropbox .navbar-nav .list-group-item.active,
  .navbar-theme-dropbox .navbar-nav .list-group-item:hover {
    color: #1e90ff;
    background: transparent;
  }

  .navbar-theme-dropbox .navbar-nav .nav-link.disabled,
  .navbar-theme-dropbox .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-dropbox .navbar-nav .dropdown-item.disabled,
  .navbar-theme-dropbox .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-dropbox .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-dropbox .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-dropbox .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-twitch:not(.headroom) {
  background-color: #4b367c;
}

.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-twitch.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-twitch .navbar-nav .dropdown-item.active,
.navbar-theme-twitch .navbar-nav .dropdown-item:hover,
.navbar-theme-twitch .navbar-nav .list-group-item.active,
.navbar-theme-twitch .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-twitch .navbar-nav .nav-link,
  .navbar-theme-twitch .navbar-nav .nav-link:focus,
  .navbar-theme-twitch .navbar-nav .nav-link.active,
  .navbar-theme-twitch .navbar-nav .nav-link:hover,
  .navbar-theme-twitch .navbar-nav .show > .nav-link,
  .navbar-theme-twitch .navbar-nav .show > .nav-link:focus,
  .navbar-theme-twitch .navbar-nav .show > .nav-link.active,
  .navbar-theme-twitch .navbar-nav .show > .nav-link:hover,
  .navbar-theme-twitch .navbar-nav .dropdown-item,
  .navbar-theme-twitch .navbar-nav .dropdown-item:focus,
  .navbar-theme-twitch .navbar-nav .dropdown-item.active,
  .navbar-theme-twitch .navbar-nav .dropdown-item:hover,
  .navbar-theme-twitch .navbar-nav .list-group-item,
  .navbar-theme-twitch .navbar-nav .list-group-item:focus,
  .navbar-theme-twitch .navbar-nav .list-group-item.active,
  .navbar-theme-twitch .navbar-nav .list-group-item:hover {
    color: #4b367c;
    background: transparent;
  }

  .navbar-theme-twitch .navbar-nav .nav-link.disabled,
  .navbar-theme-twitch .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-twitch .navbar-nav .dropdown-item.disabled,
  .navbar-theme-twitch .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-twitch .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-twitch .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-twitch .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-paypal:not(.headroom) {
  background-color: #ecb32c;
}

.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-paypal.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-paypal .navbar-nav .dropdown-item.active,
.navbar-theme-paypal .navbar-nav .dropdown-item:hover,
.navbar-theme-paypal .navbar-nav .list-group-item.active,
.navbar-theme-paypal .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-paypal .navbar-nav .nav-link,
  .navbar-theme-paypal .navbar-nav .nav-link:focus,
  .navbar-theme-paypal .navbar-nav .nav-link.active,
  .navbar-theme-paypal .navbar-nav .nav-link:hover,
  .navbar-theme-paypal .navbar-nav .show > .nav-link,
  .navbar-theme-paypal .navbar-nav .show > .nav-link:focus,
  .navbar-theme-paypal .navbar-nav .show > .nav-link.active,
  .navbar-theme-paypal .navbar-nav .show > .nav-link:hover,
  .navbar-theme-paypal .navbar-nav .dropdown-item,
  .navbar-theme-paypal .navbar-nav .dropdown-item:focus,
  .navbar-theme-paypal .navbar-nav .dropdown-item.active,
  .navbar-theme-paypal .navbar-nav .dropdown-item:hover,
  .navbar-theme-paypal .navbar-nav .list-group-item,
  .navbar-theme-paypal .navbar-nav .list-group-item:focus,
  .navbar-theme-paypal .navbar-nav .list-group-item.active,
  .navbar-theme-paypal .navbar-nav .list-group-item:hover {
    color: #ecb32c;
    background: transparent;
  }

  .navbar-theme-paypal .navbar-nav .nav-link.disabled,
  .navbar-theme-paypal .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-paypal .navbar-nav .dropdown-item.disabled,
  .navbar-theme-paypal .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-paypal .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-paypal .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-paypal .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-behance:not(.headroom) {
  background-color: #0057ff;
}

.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-behance.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-behance .navbar-nav .dropdown-item.active,
.navbar-theme-behance .navbar-nav .dropdown-item:hover,
.navbar-theme-behance .navbar-nav .list-group-item.active,
.navbar-theme-behance .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-behance .navbar-nav .nav-link,
  .navbar-theme-behance .navbar-nav .nav-link:focus,
  .navbar-theme-behance .navbar-nav .nav-link.active,
  .navbar-theme-behance .navbar-nav .nav-link:hover,
  .navbar-theme-behance .navbar-nav .show > .nav-link,
  .navbar-theme-behance .navbar-nav .show > .nav-link:focus,
  .navbar-theme-behance .navbar-nav .show > .nav-link.active,
  .navbar-theme-behance .navbar-nav .show > .nav-link:hover,
  .navbar-theme-behance .navbar-nav .dropdown-item,
  .navbar-theme-behance .navbar-nav .dropdown-item:focus,
  .navbar-theme-behance .navbar-nav .dropdown-item.active,
  .navbar-theme-behance .navbar-nav .dropdown-item:hover,
  .navbar-theme-behance .navbar-nav .list-group-item,
  .navbar-theme-behance .navbar-nav .list-group-item:focus,
  .navbar-theme-behance .navbar-nav .list-group-item.active,
  .navbar-theme-behance .navbar-nav .list-group-item:hover {
    color: #0057ff;
    background: transparent;
  }

  .navbar-theme-behance .navbar-nav .nav-link.disabled,
  .navbar-theme-behance .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-behance .navbar-nav .dropdown-item.disabled,
  .navbar-theme-behance .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-behance .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-behance .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-behance .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-reddit:not(.headroom) {
  background-color: #e84422;
}

.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-reddit.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-reddit .navbar-nav .dropdown-item.active,
.navbar-theme-reddit .navbar-nav .dropdown-item:hover,
.navbar-theme-reddit .navbar-nav .list-group-item.active,
.navbar-theme-reddit .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-reddit .navbar-nav .nav-link,
  .navbar-theme-reddit .navbar-nav .nav-link:focus,
  .navbar-theme-reddit .navbar-nav .nav-link.active,
  .navbar-theme-reddit .navbar-nav .nav-link:hover,
  .navbar-theme-reddit .navbar-nav .show > .nav-link,
  .navbar-theme-reddit .navbar-nav .show > .nav-link:focus,
  .navbar-theme-reddit .navbar-nav .show > .nav-link.active,
  .navbar-theme-reddit .navbar-nav .show > .nav-link:hover,
  .navbar-theme-reddit .navbar-nav .dropdown-item,
  .navbar-theme-reddit .navbar-nav .dropdown-item:focus,
  .navbar-theme-reddit .navbar-nav .dropdown-item.active,
  .navbar-theme-reddit .navbar-nav .dropdown-item:hover,
  .navbar-theme-reddit .navbar-nav .list-group-item,
  .navbar-theme-reddit .navbar-nav .list-group-item:focus,
  .navbar-theme-reddit .navbar-nav .list-group-item.active,
  .navbar-theme-reddit .navbar-nav .list-group-item:hover {
    color: #e84422;
    background: transparent;
  }

  .navbar-theme-reddit .navbar-nav .nav-link.disabled,
  .navbar-theme-reddit .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-reddit .navbar-nav .dropdown-item.disabled,
  .navbar-theme-reddit .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-reddit .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-reddit .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-reddit .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.navbar-theme-github:not(.headroom) {
  background-color: #222222;
}

.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
.navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .nav-link:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .show > .nav-link:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .dropdown-item:hover,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:focus,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item.active,
  .navbar-theme-github.navbar-light:not(.headroom) .navbar-nav .list-group-item:hover {
    color: #262b40;
  }
}

.navbar-theme-github .navbar-nav .dropdown-item.active,
.navbar-theme-github .navbar-nav .dropdown-item:hover,
.navbar-theme-github .navbar-nav .list-group-item.active,
.navbar-theme-github .navbar-nav .list-group-item:hover {
  color: #262b40;
}

@media (max-width: 767.98px) {
  .navbar-theme-github .navbar-nav .nav-link,
  .navbar-theme-github .navbar-nav .nav-link:focus,
  .navbar-theme-github .navbar-nav .nav-link.active,
  .navbar-theme-github .navbar-nav .nav-link:hover,
  .navbar-theme-github .navbar-nav .show > .nav-link,
  .navbar-theme-github .navbar-nav .show > .nav-link:focus,
  .navbar-theme-github .navbar-nav .show > .nav-link.active,
  .navbar-theme-github .navbar-nav .show > .nav-link:hover,
  .navbar-theme-github .navbar-nav .dropdown-item,
  .navbar-theme-github .navbar-nav .dropdown-item:focus,
  .navbar-theme-github .navbar-nav .dropdown-item.active,
  .navbar-theme-github .navbar-nav .dropdown-item:hover,
  .navbar-theme-github .navbar-nav .list-group-item,
  .navbar-theme-github .navbar-nav .list-group-item:focus,
  .navbar-theme-github .navbar-nav .list-group-item.active,
  .navbar-theme-github .navbar-nav .list-group-item:hover {
    color: #222222;
    background: transparent;
  }

  .navbar-theme-github .navbar-nav .nav-link.disabled,
  .navbar-theme-github .navbar-nav .show > .nav-link.disabled,
  .navbar-theme-github .navbar-nav .dropdown-item.disabled,
  .navbar-theme-github .navbar-nav .list-group-item.disabled {
    color: rgba(46, 54, 80, 0.9);
  }

  .navbar-theme-github .navbar-nav .dropdown .dropdown-menu {
    padding: 0;
  }

  .navbar-theme-github .navbar-nav .dropdown-item {
    padding-right: 0;
  }

  .navbar-theme-github .navbar-nav .dropdown:not(.mega-dropdown) .dropdown-item {
    padding-left: 0;
  }
}

.dropdown-menu {
  top: 100%;
}

.dropdown-menu span {
  width: 30px;
}

.navbar-transparent {
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (min-width: 992px) {
  .navbar-nav .nav-item [data-toggle='dropdown']::after {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .navbar-nav .nav-item.show [data-toggle='dropdown']::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .navbar-nav .nav-link i {
    margin-right: 0.3rem;
    font-size: 0.75rem;
  }

  .navbar-nav .nav-link-icon {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1rem;
  }

  .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }

  .navbar-nav .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    margin: 0;
    border-radius: 0.5rem;
  }

  .navbar-nav .dropdown-menu-right:before {
    right: 20px;
    left: auto;
  }

  .navbar-nav .dropdown-menu-center {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .navbar-nav .dropdown-menu.show,
  .navbar-nav .dropdown-megamenu.show,
  .navbar-nav .dropdown-megamenu-md.show {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-nav .dropdown-menu.close,
  .navbar-nav .dropdown-megamenu.close,
  .navbar-nav .dropdown-megamenu-md.close {
    display: block;
  }

  .navbar-nav .dropdown-submenu .dropdown-menu {
    -webkit-transform: none;
    transform: none;
  }

  .navbar-nav .dropdown-menu {
    position: relative;
    display: block;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .navbar-nav .dropdown.show > .dropdown-menu,
  .navbar-nav .dropdown-submenu.show > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-nav .dropdown-menu-inner {
    position: relative;
    padding: 1rem;
  }

  .navbar-transparent .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.9);
  }

  .navbar-transparent .navbar-brand {
    color: rgba(255, 255, 255, 0.9);
  }
}

.navbar-collapse-header {
  display: none;
}

.navbar-collapse {
  width: auto;
}

@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
  }

  .navbar-nav .dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }

  .navbar-nav .dropdown-menu .media svg {
    width: 30px;
  }

  .dropdown-menu-center {
    right: 0;
    left: auto;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  .navbar-collapse {
    width: calc(100% - 30px);
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 1050;
    overflow-y: auto;
    height: calc(100vh - 30px) !important;
    opacity: 0;
  }

  .navbar-collapse .navbar-toggler {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
  }

  .navbar-collapse .navbar-toggler span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    opacity: 1;
    background: #283448;
  }

  .navbar-collapse .navbar-collapse-header {
    display: block;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .navbar-collapse .collapse-brand img {
    height: 35px;
  }

  .navbar-collapse .collapse-close {
    text-align: right;
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: #ffffff;
    -webkit-animation: show-navbar-collapse 0.2s ease forwards;
    animation: show-navbar-collapse 0.2s ease forwards;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .navbar-collapse.collapsing-out {
    -webkit-animation: hide-navbar-collapse 0.2s ease forwards;
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}

.navbar-dashboard {
  border-radius: 0.5rem;
}

.icon-notifications .unread-notifications {
  position: absolute;
  right: -5px;
  top: -5px;
}

/**
 * = Sections
 */
.section {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section-header {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 3rem;
}

@media (min-width: 576px) {
  .section {
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .section-header {
    position: relative;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .section-header.section-sm {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  .section-xl {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .section-lg {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .section-sm {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .section {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .section-header {
    position: relative;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .section-header.section-sm {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  .section-xl {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .section-lg {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .section-sm {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 768px) {
  .section-hero {
    height: 100vh;
  }
}

.line-bottom::after {
  content: '';
  display: block;
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -40%;
  height: 1px;
  background: radial-gradient(ellipse at center, #eaedf2 0, rgba(255, 255, 255, 0) 80%);
}

.section-profile-cover {
  height: 580px;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 767.98px) {
  .section-profile-cover {
    height: 400px;
  }
}

.components-section > .form-control + .form-control {
  margin-top: 0.5rem;
}

.components-section > .nav + .nav,
.components-section > .alert + .alert,
.components-section > .navbar + .navbar,
.components-section > .progress + .progress,
.components-section > .progress + .btn,
.components-section .badge,
.components-section .btn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.components-section .btn-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.components-section .btn-group .btn {
  margin: 0;
}

.components-section .alert {
  margin: 0;
}

.components-section .alert + .alert {
  margin-top: 1.25rem;
}

.components-section .badge {
  margin-right: 0.5rem;
}

.components-section .modal-footer .btn {
  margin: 0;
}

.presentation-box {
  position: relative;
}

.presentation-box .gadget {
  height: 500px;
  max-width: 100%;
}

.presentation-box .pricing-card,
.presentation-box .dropdown-btn-img,
.presentation-box .blog-card,
.presentation-box .profile-card,
.presentation-box .navbar-img,
.presentation-box .modal-img,
.presentation-box .social-btn-img {
  position: absolute;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  border-radius: 0.5rem;
}

.presentation-box .profile-card {
  left: 67%;
  top: 19%;
  height: 100px;
}

.presentation-box .navbar-img {
  left: 50%;
  top: 56%;
  height: 65px;
}

.presentation-box .modal-img {
  left: 38%;
  top: 80%;
  height: 100px;
}

.presentation-box .blog-card {
  left: 2%;
  top: 20%;
  height: 60px;
}

.presentation-box .pricing-card {
  left: 15%;
  top: 47%;
  height: 125px;
}

.presentation-box .social-btn-img {
  left: 51%;
  top: 78%;
  height: 25px;
}

.presentation-box .dropdown-btn-img {
  left: 25%;
  top: 10%;
  height: 25px;
}

@media (min-width: 768px) {
  .presentation-box .gadget {
    height: 600px;
    max-width: 100%;
  }

  .presentation-box .pricing-card,
  .presentation-box .dropdown-btn-img,
  .presentation-box .blog-card,
  .presentation-box .profile-card,
  .presentation-box .navbar-img,
  .presentation-box .modal-img,
  .presentation-box .social-btn-img {
    position: absolute;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    border-radius: 0.5rem;
  }

  .presentation-box .profile-card {
    left: 65%;
    top: 12%;
    height: 150px;
  }

  .presentation-box .navbar-img {
    left: 51%;
    top: 55%;
    height: 80px;
  }

  .presentation-box .modal-img {
    left: 36%;
    top: 80%;
    height: 100px;
  }

  .presentation-box .blog-card {
    left: 7%;
    top: 20%;
    height: 80px;
  }

  .presentation-box .pricing-card {
    left: 18%;
    top: 40%;
    height: 150px;
  }

  .presentation-box .social-btn-img {
    left: 60%;
    top: 80%;
    height: 25px;
  }

  .presentation-box .dropdown-btn-img {
    left: 30%;
    top: 9%;
    height: 25px;
  }
}

@media (min-width: 992px) {
  .presentation-box {
    width: 650px;
  }

  .presentation-box .gadget {
    height: auto;
  }

  .presentation-box .pricing-card,
  .presentation-box .dropdown-btn-img,
  .presentation-box .blog-card,
  .presentation-box .profile-card,
  .presentation-box .navbar-img,
  .presentation-box .modal-img,
  .presentation-box .social-btn-img {
    position: absolute;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    border-radius: 0.5rem;
  }

  .presentation-box .profile-card {
    left: 75%;
    top: 3%;
    height: 200px;
  }

  .presentation-box .navbar-img {
    left: 55%;
    top: 65%;
    height: 120px;
  }

  .presentation-box .modal-img {
    left: 19%;
    top: 67%;
    height: 170px;
  }

  .presentation-box .blog-card {
    left: 7%;
    top: 3%;
    height: 100px;
  }

  .presentation-box .pricing-card {
    left: 0%;
    top: 30%;
    height: 165px;
  }

  .presentation-box .social-btn-img {
    left: 2%;
    top: 78%;
    height: 25px;
  }

  .presentation-box .dropdown-btn-img {
    left: 30%;
    top: -12%;
    height: 25px;
  }
}

.card-box {
  -webkit-perspective: 900px;
  -o-perspective: 900px;
  perspective: 900px;
  width: 250px;
  position: relative;
}

.card-box .card-component {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  height: 300px;
}

@media (min-width: 992px) {
  .card-box .card-component {
    height: 450px;
  }
}

.card-box .card-component.card-component-lg {
  height: auto;
  max-height: 800px;
}

@media (min-width: 768px) {
  .card-box .card-component.card-component-lg {
    max-height: 410px;
  }
}

.card-box .card-component.card-component-xs {
  height: auto;
  max-height: 230px;
}

.card-box .card-component .front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  -webkit-box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175);
  box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175);
  border-radius: 0.5rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotateY(24deg);
  transform: rotateY(24deg);
}

.card-box .card-component .front:hover {
  cursor: pointer;
}

.card-box .page-card {
  -webkit-box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175);
  box-shadow: 0 1rem 3rem rgba(46, 54, 80, 0.175);
  border-radius: 0.5rem;
}

.card-box .page-card:hover {
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .card-box {
    width: 200px;
  }
}

@media (min-width: 992px) {
  .card-box {
    width: 260px;
  }
}

.copy-docs {
  position: absolute;
  top: 5px;
  right: 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.copy-docs.copied {
  background: #05a677;
}

.copy-docs:hover {
  cursor: pointer;
}

.index-icon {
  position: absolute;
  font-size: 38px;
  color: #dcdcdc;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.index-icon-javascript {
  bottom: 80px;
  left: 40px;
}

.index-icon-javascript:hover {
  color: #f1da1c;
}

.index-icon-bootstrap {
  bottom: -150px;
  right: -7%;
}

.index-icon-bootstrap:hover {
  color: #553d7c;
}

.icon-sass:hover {
  color: #cd6799;
}

.index-icon-code {
  bottom: 180px;
  left: -80px;
}

.index-icon-code:hover {
  color: #ff7f66;
}

.index-icon-gulp:hover {
  color: #e34a4f;
}

.index-icon-gulp {
  left: 250px;
  bottom: -10px;
}

.index-icon-html5 {
  right: 30px;
  bottom: 150px;
}

.index-icon-html5:hover {
  color: #e54b20;
}

.index-icon-css3 {
  right: 40px;
  bottom: -20px;
}

.index-icon-css3:hover {
  color: #e54b20;
}

.index-icon-npm {
  right: 180px;
  bottom: 20px;
}

.index-icon-npm:hover {
  color: #cc3f3d;
}

.index-icon-fontawesome {
  right: 340px;
  bottom: -40px;
}

.index-icon-fontawesome:hover {
  color: #3499ef;
}

.index-icon-illustrations {
  left: 20px;
  bottom: -40px;
}

.index-icon-illustrations:hover {
  color: #61dafb;
}

/**
 * = Footers
 */
.footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.footer ul li {
  display: inline-block;
}

.footer ul li a {
  text-decoration: none;
  position: relative;
  display: block;
  color: #66799e;
}

.footer ul li a:hover {
  color: #262b40;
}

.footer ul li .btn {
  margin: 0;
}

.footer ul.links-horizontal:first-child a {
  padding-left: 0;
}

.footer ul.links-horizontal:last-child a {
  padding-right: 0;
}

.footer ul.links-vertical li {
  display: block;
  margin-left: -5px;
  margin-right: -5px;
}

.footer ul.links-vertical li a {
  padding: 5px;
}

.footer ul.links-vertical li a:hover {
  color: inherit !important;
}

.footer ul.icon-box i {
  line-height: 1.7;
}

.footer .social-buttons a,
.footer .social-buttons .btn {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0.125rem 0.375rem;
}

.footer .footer-brand {
  font-size: 1.25rem;
  font-weight: 600;
}

.footer .footer-brand img.common {
  height: 40px;
}

.footer .footer-brand img {
  height: 40px;
  width: 40px;
}

.footer .footer-brand:hover,
.footer .footer-brand:focus {
  color: #2e3650;
}

.footer .footer-language-link {
  font-size: 0.875rem;
}

.footer .footer-language-link i {
  font-size: 0.75rem;
}

.footer .copyright {
  font-size: 0.875rem;
}

.footer .pull-center {
  display: inline-block;
  float: none;
}

/**
 * = Sidebars
 */
#doc-index:not(.collapse.show),
.doc-sidebar {
  display: none;
}

@media (min-width: 992px) {
  #doc-index {
    display: block;
  }

  .doc-sidebar {
    display: block;
    height: calc(100vh - 2rem);
    overflow-y: scroll;
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;
  }

  .doc-sidebar .nav-link[data-toggle='collapse'] > .icon {
    -webkit-transform: rotateZ(-90deg);
    transform: rotateZ(-90deg);
    position: relative;
    right: 0.25rem;
  }

  .doc-sidebar .nav .nav-item {
    font-size: 0.875rem;
  }
}

/*
 * Sidebar
*/
.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  -webkit-transition: max-width 0.3s;
  transition: max-width 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar {
    -webkit-transition: none;
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar {
    width: 100%;
    max-width: 260px;
  }
}

.sidebar .nav {
  white-space: nowrap;
}

.sidebar .nav-item {
  border: 0.0625rem solid transparent;
  margin-bottom: 0.2rem;
}

.sidebar .nav-item.active > .nav-link {
  color: #eaedf2;
  background-color: #2e3650;
  border: 0.0625rem solid #4c5680;
}

.sidebar .nav-item .nav-link {
  color: #eaedf2;
  border: 0.0625rem solid #262b40;
}

.sidebar .nav-item .nav-link:hover {
  color: #eaedf2;
  background-color: #2e3650;
}

.sidebar .nav-item .nav-link.active {
  color: #ffffff;
}

.sidebar .nav-link + .multi-level {
  margin-top: 0.2rem;
}

.sidebar .nav-link {
  font-size: 1rem;
  vertical-align: middle;
  padding: 0.55rem 0.75rem;
  border-radius: 0.5rem;
}

.sidebar .nav-link .sidebar-icon {
  margin-right: 0.5rem;
  color: #ffffff;
}

.sidebar .nav-link .sidebar-icon span {
  min-width: 22px;
}

.sidebar .nav-link .sidebar-icon svg {
  min-width: 22px;
}

.sidebar .nav-link .sidebar-icon.svg-icon {
  margin-right: 14px;
}

.sidebar .nav-link .link-arrow {
  font-size: 0.875rem;
}

.sidebar .nav-link:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0.1rem rgba(76, 86, 128, 0.2);
  box-shadow: 0 0 0 0.1rem rgba(76, 86, 128, 0.2);
}

.sidebar .nav-link.active {
  color: #262b40;
}

.sidebar .multi-level .nav-link {
  padding-left: 45px;
}

.sidebar .sidebar-text,
.sidebar .link-arrow,
.sidebar .badge,
.sidebar .notification-count {
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .sidebar-text,
  .sidebar .link-arrow,
  .sidebar .badge,
  .sidebar .notification-count {
    -webkit-transition: none;
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar .sidebar-text-contracted {
    display: none;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar .sidebar-text-contracted {
    -webkit-transition: none;
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar.contracted {
    max-width: 95px;
  }

  .sidebar.contracted .sidebar-text,
  .sidebar.contracted .link-arrow,
  .sidebar.contracted .badge {
    opacity: 0;
  }

  .sidebar.contracted .notification-count {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 35px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 0.7rem;
    padding: 0.2rem;
  }

  .sidebar.contracted .nav-item {
    position: relative;
    width: 47px;
  }

  .sidebar.contracted .nav-item.active > .nav-link {
    background-color: #262b40;
  }

  .sidebar.contracted .nav-item .nav-link:hover {
    background-color: #262b40;
  }

  .sidebar.contracted .sidebar-text-contracted {
    display: inline;
  }

  .sidebar.contracted .multi-level .nav-link {
    padding-left: 17px;
  }

  .sidebar.contracted .nav-item {
    white-space: nowrap;
  }

  .sidebar.contracted .sidebar-icon {
    text-align: center;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .sidebar.contracted + .content {
    margin-left: 95px;
  }
}

.sidebar-inner {
  position: relative;
  overflow-y: hidden;
}

.sidebar-inner .accordion-button::after {
  background-image: none;
  content: '\f054';
  font-family: 'Font Awesome 5 Free';
  font-size: 0.875rem;
  font-weight: 900;
  height: 1.25rem;
  width: 0.5rem;
}

.sidebar-inner .accordion-button:not(.collapsed) {
  background-color: #2e3650;
}

.sidebar-inner .accordion-button:not(.collapsed)::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.sidebar-inner .accordion {
  padding-top: 0.25rem;
}

.sidebar-inner .accordion-body {
  padding: 0.5rem 0 0 0;
}

.sidebar-inner .accordion-collapse {
  border: 0;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.user-card {
  border-bottom: 0.0625rem solid #2e3650;
}

@media (max-width: 575.98px) {
  .sidebar {
    width: 100%;
  }
}

.content {
  overflow: visible;
  padding: 0 1rem 0 1rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 80vh;
}

@media (prefers-reduced-motion: reduce) {
  .content {
    -webkit-transition: none;
    transition: none;
  }
}

@media (min-width: 768px) {
  .content {
    margin-left: 260px;
  }
}

.sidebar-toggle {
  font-size: 1.3rem;
}

.sidebar-toggle:hover,
.sidebar-toggle:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #eaedf2;
}

.sidebar-transition-enter {
  opacity: 0;
}

.sidebar-transition-enter-active {
  opacity: 1;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
}

.sidebar-transition-exit {
  opacity: 1;
}

.sidebar-transition-exit-active {
  opacity: 0;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
}

/**
 * = Accordions
 */
.accordion-button {
  text-align: left;
}

.accordion-button .btn-check:focus + .btn,
.accordion-button .btn:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-button:hover {
  background-color: #f5f8fb;
}

.accordion-flush .accordion-button {
  background-color: transparent;
}

.accordion-flush .accordion-button:not(.collapsed) {
  color: #61dafb;
}

.accordion-flush .accordion-button:focus {
  border-color: #f0f3f6;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-flush .accordion-collapse {
  border: 0;
}

.alert-heading {
  font-weight: 600;
}

.alert-icon {
  margin-bottom: 0.5rem;
}

.alert-icon span {
  font-size: 2rem;
}

/**
 * = Avatars
 */
.avatar + .avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}

.avatar-link img {
  width: 4rem;
  height: 4rem;
}

.author-image img {
  width: 3rem;
  height: 3rem;
}

.user-avatar {
  height: 2.5rem;
  width: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  border-radius: 50%;
}

.user-avatar.xs-avatar {
  height: 1.5rem;
  width: 1.5rem;
}

.user-avatar.xs-avatar img {
  height: 1.5rem;
  width: 1.5rem;
}

.user-avatar .md-avatar {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
}

.user-avatar.lg-avatar {
  height: 3.5rem;
  width: 3.5rem;
  min-height: 3.5rem;
  min-width: 3.5rem;
  font-size: 0.75rem;
}

@media (max-width: 991.98px) {
  .user-avatar.lg-avatar {
    height: 2rem;
    width: 2rem;
    min-height: 2rem;
    min-width: 2rem;
  }
}

.user-avatar.xl-avatar {
  height: 4.5rem;
  width: 4.5rem;
  min-height: 4.5rem;
  min-width: 4.5rem;
}

.user-avatar.large-avatar {
  border: 2px solid #ffffff;
  height: 10rem;
  width: 10rem;
  min-height: 10rem;
  min-width: 10rem;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #ffffff;
}

.avatar-group .avatar:hover {
  z-index: 3;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}

/**
 * = Badges
 */
.badge {
  font-size: 0.75em;
  font-weight: 700;
}

.badge a {
  color: #ffffff;
}

.badge.super-badge {
  vertical-align: super;
}

.badge-pill {
  padding-right: 0.875em;
  padding-left: 0.875em;
}

.badge-inline {
  margin-right: 0.625rem;
}

.badge-inline + span {
  top: 2px;
  position: relative;
}

.badge-inline + span > a {
  text-decoration: underline;
}

.badge-md {
  padding: 0.25rem 0.4rem;
}

.badge-lg {
  padding: 0.35rem 0.85rem;
}

.badge-xl {
  font-size: 1.25rem;
  padding: 0.35rem 0.85rem;
}

.btn .badge-corner {
  position: absolute;
  top: -50%;
  right: 0.5rem;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
  margin: 0;
  border: 3px solid;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.wi-tags a {
  display: inline-block;
  padding: 0.125rem 0.875rem;
  margin: 0.25rem;
  line-height: 2;
  font-size: 0.875rem;
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  background-color: #ffffff;
}

.pixel-pro-badge {
  position: relative;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
  right: -20px;
  padding: 5px 14px;
  top: -46px;
  background: #ffffff;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}

@media (max-width: 575.98px) {
  .pixel-pro-badge {
    font-size: 1rem;
    right: -13px;
    padding: 5px 10px;
    top: -23px;
  }
}

.subscription-badge {
  top: -19px;
  right: -12px;
  font-size: 0.75rem;
}

/*
* = Buttons
*/
.btn.btn-circle {
  border-radius: 50%;
}

.btn.btn-md {
  padding: 0.65rem 1.25rem;
}

.btn.btn-xs {
  padding: 0.175rem 0.45rem;
  font-size: 0.7rem;
}

.btn-icon-only {
  width: 2.575rem;
  height: 2.575rem;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-pack: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-icon-only.btn-xs {
  width: 1.7rem;
  height: 1.7rem;
}

.btn-icon-only.btn-sm,
.btn-group-sm > .btn-icon-only.btn {
  width: 2rem;
  height: 2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.copy-code-button {
  position: absolute;
  top: 22px;
  right: 20px;
  background-color: transparent;
  color: #93a5be;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.copy-code-button:hover {
  background-color: #262b40;
  color: #ffffff;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.copy-code-text {
  position: absolute;
  top: 24px;
  right: 90px;
}

/**
 * = Breadcrumbs
 */
.breadcrumb-item {
  font-size: 0.75rem;
}

.breadcrumb-item,
.breadcrumb-item a {
  color: #66799e;
  font-weight: 400;
  font-size: 0.75rem;
}

@media (min-width: 576px) {
  .breadcrumb-item,
  .breadcrumb-item a {
    font-size: 0.875rem;
  }
}

.breadcrumb-item.active {
  font-weight: 400;
}

.breadcrumb-item + .breadcrumb-item:before {
  content: '\f101';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #66799e;
}

.breadcrumb-transparent {
  background: transparent;
  padding: 0;
}

.breadcrumb-primary {
  background: #262b40;
}

.breadcrumb-primary .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-primary.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-primary.breadcrumb-transparent .breadcrumb-item.active {
  color: #262b40;
}

.breadcrumb-secondary {
  background: #61dafb;
}

.breadcrumb-secondary .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-secondary.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-secondary.breadcrumb-transparent .breadcrumb-item.active {
  color: #61dafb;
}

.breadcrumb-tertiary {
  background: #1b998b;
}

.breadcrumb-tertiary .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-tertiary.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-tertiary.breadcrumb-transparent .breadcrumb-item.active {
  color: #1b998b;
}

.breadcrumb-quaternary {
  background: #c96480;
}

.breadcrumb-quaternary .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-quaternary.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-quaternary.breadcrumb-transparent .breadcrumb-item.active {
  color: #c96480;
}

.breadcrumb-primary-app {
  background: #ebf4f6;
}

.breadcrumb-primary-app .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-primary-app.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-primary-app.breadcrumb-transparent .breadcrumb-item.active {
  color: #ebf4f6;
}

.breadcrumb-secondary-app {
  background: #424aa0;
}

.breadcrumb-secondary-app .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-secondary-app.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-secondary-app.breadcrumb-transparent .breadcrumb-item.active {
  color: #424aa0;
}

.breadcrumb-light {
  background: #eaedf2;
}

.breadcrumb-light .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-light.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-light.breadcrumb-transparent .breadcrumb-item.active {
  color: #eaedf2;
}

.breadcrumb-lighten {
  background: #f0f3f6;
}

.breadcrumb-lighten .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-lighten.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-lighten.breadcrumb-transparent .breadcrumb-item.active {
  color: #f0f3f6;
}

.breadcrumb-success {
  background: #05a677;
}

.breadcrumb-success .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-success.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-success.breadcrumb-transparent .breadcrumb-item.active {
  color: #05a677;
}

.breadcrumb-info {
  background: #0948b3;
}

.breadcrumb-info .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-info.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-info.breadcrumb-transparent .breadcrumb-item.active {
  color: #0948b3;
}

.breadcrumb-warning {
  background: #f5b759;
}

.breadcrumb-warning .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-warning.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-warning.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5b759;
}

.breadcrumb-danger {
  background: #fa5252;
}

.breadcrumb-danger .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-danger.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-danger.breadcrumb-transparent .breadcrumb-item.active {
  color: #fa5252;
}

.breadcrumb-white {
  background: #ffffff;
}

.breadcrumb-white .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-white.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-white.breadcrumb-transparent .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray {
  background: #4a5073;
}

.breadcrumb-gray .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray.breadcrumb-transparent .breadcrumb-item.active {
  color: #4a5073;
}

.breadcrumb-indigo {
  background: #4c5680;
}

.breadcrumb-indigo .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-indigo.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-indigo.breadcrumb-transparent .breadcrumb-item.active {
  color: #4c5680;
}

.breadcrumb-dark {
  background: #262b40;
}

.breadcrumb-dark .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-dark.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-dark.breadcrumb-transparent .breadcrumb-item.active {
  color: #262b40;
}

.breadcrumb-soft {
  background: #f5f8fb;
}

.breadcrumb-soft .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-soft.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-soft.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5f8fb;
}

.breadcrumb-black {
  background: #2e3650;
}

.breadcrumb-black .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-black.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-black.breadcrumb-transparent .breadcrumb-item.active {
  color: #2e3650;
}

.breadcrumb-blue {
  background: #0948b3;
}

.breadcrumb-blue .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-blue.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-blue.breadcrumb-transparent .breadcrumb-item.active {
  color: #0948b3;
}

.breadcrumb-pink {
  background: #c96480;
}

.breadcrumb-pink .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-pink.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-pink.breadcrumb-transparent .breadcrumb-item.active {
  color: #c96480;
}

.breadcrumb-cyan {
  background: #63b1bd;
}

.breadcrumb-cyan .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-cyan.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-cyan.breadcrumb-transparent .breadcrumb-item.active {
  color: #63b1bd;
}

.breadcrumb-purple {
  background: #8965e0;
}

.breadcrumb-purple .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-purple.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-purple.breadcrumb-transparent .breadcrumb-item.active {
  color: #8965e0;
}

.breadcrumb-soft-indigo {
  background: #f5e8ff;
}

.breadcrumb-soft-indigo .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-soft-indigo.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-soft-indigo.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5e8ff;
}

.breadcrumb-soft-green {
  background: #2ca58d;
}

.breadcrumb-soft-green .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-soft-green.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-soft-green.breadcrumb-transparent .breadcrumb-item.active {
  color: #2ca58d;
}

.breadcrumb-gray-100 {
  background: #f3f7fa;
}

.breadcrumb-gray-100 .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray-100.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray-100.breadcrumb-transparent .breadcrumb-item.active {
  color: #f3f7fa;
}

.breadcrumb-gray-200 {
  background: #f5f8fb;
}

.breadcrumb-gray-200 .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray-200.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray-200.breadcrumb-transparent .breadcrumb-item.active {
  color: #f5f8fb;
}

.breadcrumb-gray-300 {
  background: #f0f3f6;
}

.breadcrumb-gray-300 .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray-300.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray-300.breadcrumb-transparent .breadcrumb-item.active {
  color: #f0f3f6;
}

.breadcrumb-gray-400 {
  background: #eaedf2;
}

.breadcrumb-gray-400 .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray-400.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray-400.breadcrumb-transparent .breadcrumb-item.active {
  color: #eaedf2;
}

.breadcrumb-gray-500 {
  background: #d1d7e0;
}

.breadcrumb-gray-500 .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray-500.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray-500.breadcrumb-transparent .breadcrumb-item.active {
  color: #d1d7e0;
}

.breadcrumb-gray-600 {
  background: #93a5be;
}

.breadcrumb-gray-600 .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray-600.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray-600.breadcrumb-transparent .breadcrumb-item.active {
  color: #93a5be;
}

.breadcrumb-gray-700 {
  background: #66799e;
}

.breadcrumb-gray-700 .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray-700.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray-700.breadcrumb-transparent .breadcrumb-item.active {
  color: #66799e;
}

.breadcrumb-gray-800 {
  background: #506690;
}

.breadcrumb-gray-800 .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-gray-800.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-gray-800.breadcrumb-transparent .breadcrumb-item.active {
  color: #506690;
}

.breadcrumb-facebook {
  background: #3b5999;
}

.breadcrumb-facebook .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-facebook.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-facebook.breadcrumb-transparent .breadcrumb-item.active {
  color: #3b5999;
}

.breadcrumb-twitter {
  background: #1da1f2;
}

.breadcrumb-twitter .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-twitter.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-twitter.breadcrumb-transparent .breadcrumb-item.active {
  color: #1da1f2;
}

.breadcrumb-google {
  background: #db4337;
}

.breadcrumb-google .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-google.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-google.breadcrumb-transparent .breadcrumb-item.active {
  color: #db4337;
}

.breadcrumb-instagram {
  background: #e4405f;
}

.breadcrumb-instagram .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-instagram.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-instagram.breadcrumb-transparent .breadcrumb-item.active {
  color: #e4405f;
}

.breadcrumb-pinterest {
  background: #bd081c;
}

.breadcrumb-pinterest .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-pinterest.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-pinterest.breadcrumb-transparent .breadcrumb-item.active {
  color: #bd081c;
}

.breadcrumb-youtube {
  background: #cd201f;
}

.breadcrumb-youtube .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-youtube.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-youtube.breadcrumb-transparent .breadcrumb-item.active {
  color: #cd201f;
}

.breadcrumb-slack {
  background: #3aaf85;
}

.breadcrumb-slack .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-slack.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-slack.breadcrumb-transparent .breadcrumb-item.active {
  color: #3aaf85;
}

.breadcrumb-dribbble {
  background: #ea4c89;
}

.breadcrumb-dribbble .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-dribbble.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-dribbble.breadcrumb-transparent .breadcrumb-item.active {
  color: #ea4c89;
}

.breadcrumb-dropbox {
  background: #1e90ff;
}

.breadcrumb-dropbox .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-dropbox.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-dropbox.breadcrumb-transparent .breadcrumb-item.active {
  color: #1e90ff;
}

.breadcrumb-twitch {
  background: #4b367c;
}

.breadcrumb-twitch .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-twitch.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-twitch.breadcrumb-transparent .breadcrumb-item.active {
  color: #4b367c;
}

.breadcrumb-paypal {
  background: #ecb32c;
}

.breadcrumb-paypal .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-paypal.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-paypal.breadcrumb-transparent .breadcrumb-item.active {
  color: #ecb32c;
}

.breadcrumb-behance {
  background: #0057ff;
}

.breadcrumb-behance .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-behance.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-behance.breadcrumb-transparent .breadcrumb-item.active {
  color: #0057ff;
}

.breadcrumb-reddit {
  background: #e84422;
}

.breadcrumb-reddit .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-reddit.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-reddit.breadcrumb-transparent .breadcrumb-item.active {
  color: #e84422;
}

.breadcrumb-github {
  background: #222222;
}

.breadcrumb-github .breadcrumb-item.active {
  color: #ffffff;
}

.breadcrumb-github.breadcrumb-transparent {
  background: transparent;
}

.breadcrumb-github.breadcrumb-transparent .breadcrumb-item.active {
  color: #222222;
}

.breadcrumb-text-light .breadcrumb-item,
.breadcrumb-text-light .breadcrumb-item a {
  color: #ffffff;
}

.breadcrumb-text-light .breadcrumb-item:before {
  color: #ffffff;
}

/**
 * = Blog cards
 */
.card-img-overlay {
  padding: 0;
}

.card-img-overlay .card-footer,
.card-img-overlay .card-header {
  background: transparent;
  border-color: #66799e;
}

/**
 * = Cards
 */
.card {
  position: relative;
}

.card .card-header {
  background: transparent;
}

.card.hover-state:hover {
  background-color: #f5f8fb;
}

.card .profile-cover {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: 200px;
}

@media (min-width: 992px) {
  .card.timeline-card:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: 0.75rem;
    border: 8px solid #2e3650;
    border-color: transparent #e0e6ec #e0e6ec transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  }

  .card.left-timeline-card:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: -5px;
    border: 8px solid #2e3650;
    border-color: transparent #e0e6ec #e0e6ec transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
    box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  }
}

.message-wrapper .card,
.task-wrapper .card {
  border: 0;
}

.customer-testimonial .content-wrapper:before {
  content: '';
  position: absolute;
  top: 25px;
  left: -1px;
  margin-left: 0.75rem;
  border: 8px solid #2e3650;
  border-color: transparent #e0e6ec #e0e6ec transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}

.profile-page .card-profile {
  margin-top: -150px;
}

.profile-page .card-profile .card-profile-image {
  position: relative;
}

.profile-page .card-profile .card-profile-image img {
  max-width: 180px;
  border-radius: 0.5rem;
  -webkit-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  position: absolute;
  left: 50%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.profile-page .card-profile .card-profile-image img:hover {
  -webkit-transform: translate(-50%, -33%);
  transform: translate(-50%, -33%);
}

.profile-page .card-profile .card-profile-stats {
  padding: 1rem 0;
}

.profile-page .card-profile .card-profile-stats > div {
  text-align: center;
  margin-right: 1rem;
  padding: 0.875rem;
}

.profile-page .card-profile .card-profile-stats > div:last-child {
  margin-right: 0;
}

.profile-page .card-profile .card-profile-stats > div .heading {
  display: block;
  font-size: 1.1rem;
  font-weight: bold;
}

.profile-page .card-profile .card-profile-stats > div .description {
  font-size: 0.875rem;
  color: #d1d7e0;
}

.profile-page .card-profile .card-profile-actions {
  padding: 0.875rem;
}

.profile-page .card-profile .card-profile-actions {
  margin-top: 110px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .profile-page .card-profile .card-profile-stats {
    margin-top: 30px;
  }
}

.card-footer {
  background-color: transparent;
}

.card-stats {
  padding-left: 1.9rem;
}

.card .card-blockquote {
  position: relative;
  padding: 2rem;
}

.card .card-blockquote .svg-bg {
  display: block;
  position: absolute;
  width: 100%;
  height: 95px;
  top: -94px;
  left: 0;
}

.card-lift-hover:hover {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .card-lift-hover:hover {
    -webkit-transition: none;
    transition: none;
  }
}

.page-preview {
  display: block;
  position: relative;
}

.page-preview .show-on-hover {
  position: absolute;
  bottom: -25px;
  background: rgba(38, 43, 64, 0.85);
  padding: 10px 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  width: calc(100% + 28px);
  left: -14px;
  opacity: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

@media (prefers-reduced-motion: reduce) {
  .page-preview .show-on-hover {
    -webkit-transition: none;
    transition: none;
  }
}

.page-preview.page-preview-lg .show-on-hover {
  left: -30px;
  width: calc(100% + 60px);
  bottom: -20px;
}

.page-preview:hover .show-on-hover {
  z-index: 99;
  opacity: 1;
}

.live-editor textarea,
.live-editor pre {
  font-size: 1.2em !important;
  padding: 0 !important;
}

.live-editor textarea:focus {
  outline: none !important;
}

.prism-code {
  padding: 0 !important;
}

.theme-settings {
  z-index: 99;
  position: fixed;
  right: 15px;
  bottom: 0;
}

.theme-settings,
.theme-settings .card-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-settings-close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 0.65rem;
}

.theme-settings-expand {
  right: 15px;
  bottom: 0;
}

.theme-settings-expand:hover {
  background: #f5f8fb;
  cursor: pointer;
}

/**
 * = Bootstrap carousels
 */
.carousel-caption,
.carousel-caption h5,
.carousel-caption .h5 {
  color: #ffffff;
}

.carousel-indicators li {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid #ffffff;
}

.carousel-indicators .active {
  opacity: 1;
  background: #ffffff;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: auto;
  height: auto;
  font-weight: 900;
}

.carousel-control-prev-icon {
  background-image: none;
}

.carousel-control-prev-icon::before {
  content: '\f060';
  font-family: 'Font Awesome 5 Free';
  font-size: 2rem;
}

.carousel-control-next-icon {
  background-image: none;
}

.carousel-control-next-icon:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f061';
  font-size: 2rem;
}

/**
 * = Close
 */
.close {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.close > span:not(.sr-only) {
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  background-color: transparent;
  color: #2e3650;
  line-height: 17px;
  border-radius: 50%;
  font-size: 1.25rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.close:hover,
.close:focus {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  outline: none;
}

.close:hover span:not(.sr-only),
.close:focus span:not(.sr-only) {
  background-color: transparent;
}

/**
 * = Counters
 */
.counter-alternate::after {
  content: '\f067';
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  font-size: 2.25rem;
  color: #262b40;
  font-weight: 900;
}

/**
 * = Custom forms
 */
.custom-control-label:before {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.custom-control-label span {
  position: relative;
  top: 2px;
}

.custom-control-label {
  margin-bottom: 0;
}

.custom-control-input:active ~ .custom-control-label::before {
  border-color: #262b40;
}

.custom-file-label {
  background-color: #f3f3f5;
}

.custom-file-label::after {
  background-color: #f3f3f5;
}

.custom-select {
  font-size: 1rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-select.custom-select-shadow {
  -webkit-box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  -webkit-transition: -webkit-box-shadow 0.15s ease;
  transition: -webkit-box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
  transition:
    box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}

.custom-select.custom-select-shadow:focus {
  -webkit-box-shadow: 0.1rem 0.1rem 0 rgba(245, 248, 251, 0.5);
  box-shadow: 0.1rem 0.1rem 0 rgba(245, 248, 251, 0.5);
}

.custom-select:hover {
  cursor: pointer;
}

.rating-star.star-lg label {
  margin-bottom: 0.5rem;
}

.rating-star.star-lg label::before {
  font-size: 1.5rem;
}

.rating-star label {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  color: #eaedf2;
}

.rating-star label:before {
  content: '\f005';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 1.0625rem;
}

.rating-star input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.rating-star > input:checked ~ label {
  color: #f5b759;
}

.rating-star > label:hover {
  color: #f5b759;
}

.rating-star > label:hover ~ label {
  color: #f5b759;
}

.ct-bar {
  stroke-linecap: round;
  stroke-width: 10px;
}

.ct-label {
  fill: #4a5073;
  color: #4a5073;
  font-size: 0.75rem;
  margin-top: 6px;
  font-weight: 600;
}

.ct-slice-pie {
  stroke: #ffffff;
  stroke-width: 3px;
}

.ct-chart-donut .ct-series {
  stroke: #ffffff;
  stroke-width: 3px;
}

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  font-size: 0.75rem;
  font-weight: 600;
}

.small-chart {
  margin-left: -1rem;
}

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 1rem;
  font-weight: 600;
}

.ct-series-g .ct-grid {
  stroke: #17a5ce;
  stroke-width: 2px;
  stroke-dasharray: 2px;
}

.ct-series-g .ct-label.ct-horizontal.ct-end {
  margin-left: -9px;
  margin-top: 10px;
  color: #262b40;
}

/**
 * = Dropdowns
 */
.dropdown-menu {
  min-width: 12rem;
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.dropdown-menu .dropdown-header {
  color: #4a5073;
  font-weight: 600;
}

.dropdown-menu .dropdown-item {
  color: #66799e;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-weight: 300;
}

.dropdown-menu .dropdown-itema:hover {
  color: #4a5073;
}

.show .dropdown-menu {
  -webkit-animation: show-dropdown 0.2s ease forwards;
  animation: show-dropdown 0.2s ease forwards;
}

.dropdown-menu.dropdown-menu-xs {
  min-width: 120px;
  max-width: 120px;
  border: 0.0625rem solid #eaedf2;
  -webkit-box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
}

.dropdown-divider {
  margin: 0.375rem 0;
  border-color: #eaedf2;
}

[data-toggle]:hover {
  cursor: pointer;
}

.dropdown-toggle:after,
.dropright .dropdown-toggle:after,
.dropleft .dropdown-toggle:before,
.dropup .dropdown-toggle:after {
  display: none;
}

.dropdown-menu-sm {
  min-width: 100px;
  border: 0.3rem;
}

.left-auto {
  left: auto !important;
}

.dropdown-menu-md {
  min-width: 180px;
  border: 0.3rem;
}

.dropdown-menu-lg {
  min-width: 350px;
  border-radius: 0.3rem;
}

@media (max-width: 991.98px) {
  .dropdown-menu-lg {
    min-width: 285px;
    -webkit-transform: translateX(15%);
    transform: translateX(15%);
  }
}

.dropdown-menu-xl {
  min-width: 450px;
  border-radius: 0.3rem;
}

@media (max-width: 1199.98px) {
  .dropdown-menu-xl {
    min-width: 285px;
    -webkit-transform: translateX(15%);
    transform: translateX(15%);
  }
}

.user-dropdown.dropdown-menu.show {
  right: 0 !important;
  left: auto !important;
}

@media (max-width: 991.98px) {
  .notifications-dropdown[style] {
    left: auto !important;
    right: 0 !important;
  }
}

.dropzone {
  min-height: 150px;
  border: 2px dashed #93a5be;
  background: #ffffff;
  cursor: pointer;
}

.dropzone:hover {
  background-color: #f5f8fb;
}

.dropzone .dz-message .dz-button {
  font-size: 1.25rem;
  font-weight: 600;
}

.dropzone-files {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 1.5rem 0 1.5rem 0;
}

.dropzone-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  min-height: 100px;
}

.dropzone-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone-filename {
  white-space: normal;
  padding: 0 0.4em;
  margin: 5px 0 5px 0;
  border-radius: 3px;
}

.dropzone-filename:not(:hover) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (min-width: 992px) {
  .form-control.form-control-lg {
    padding: 1rem 0.75rem;
  }
}

.form-check-input.round-check[type='checkbox'] {
  border-radius: 50%;
}

.search-bar .form-control {
  width: 280px;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}

@media (max-width: 575.98px) {
  .search-bar .form-control {
    width: 120px;
  }

  .search-bar .form-control:focus {
    width: 150px;
  }
}

.input-group .form-control:focus {
  border-color: #d1d7e0;
}

.input-group .form-control:focus + .input-group-text {
  border-color: #d1d7e0;
}

.focused .form-control {
  border-color: #566190;
  background-color: #ffffff;
}

.form-switch {
  padding-left: 2.75em;
}

.form-switch .form-check-input {
  height: 1.275em;
}

.form-select .form-select-lg {
  padding: 0.55rem 1.75rem 0.55rem 0.75rem;
}

.file-field input[type='file'] {
  max-width: 230px;
  position: absolute;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  padding-bottom: 30px;
}

.file-field span {
  cursor: pointer;
}

.datepicker-dropdown {
  z-index: 9999;
}

/**
 * = Icon boxes
 */
.icon-box {
  padding: 1rem;
  position: relative;
}

.icon-box .icon-box-body {
  position: relative;
}

.icon {
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
  justify-content: center;
  -ms-flex-pack: center;
}

.icon span,
.icon svg {
  font-size: 2rem;
}

.icon.icon-small span,
.icon.icon-small svg {
  font-size: 0.675rem;
}

.icon.icon-xs span,
.icon.icon-xs svg {
  font-size: 0.875rem;
}

.icon.icon-sm span,
.icon.icon-sm svg {
  font-size: 1.25rem;
}

.icon.icon-lg span,
.icon.icon-lg svg {
  font-size: 2.75rem;
}

.icon.icon-lg svg {
  height: 2rem;
}

.icon.icon-xl span {
  font-size: 4.5rem;
}

.icon.icon-xl svg {
  height: 3.5rem;
}

.icon.w-20 {
  width: 20px;
}

.icon.w-30 {
  width: 30px;
}

.icon.rounded-circle .icon-bordered {
  border-radius: 50%;
}

.icon-shape {
  width: 4.5rem;
  height: 4.5rem;
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.icon-shape span,
.icon-shape svg {
  font-size: 2rem;
}

.icon-shape.icon-xs {
  width: 2rem;
  height: 2rem;
}

.icon-shape.icon-xs span,
.icon-shape.icon-xs svg {
  font-size: 0.875rem;
}

.icon-shape.icon-sm {
  width: 3rem;
  height: 3rem;
}

.icon-shape.icon-sm span,
.icon-shape.icon-sm svg {
  font-size: 1.25rem;
}

.icon-shape.icon-lg {
  width: 5.5rem;
  height: 5.5rem;
}

.icon-shape.icon-lg span,
.icon-shape.icon-lg svg {
  font-size: 1.875rem;
}

.image-shape {
  width: 5.5rem;
  height: 5.5rem;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.image-shape span {
  font-size: 2rem;
  z-index: 1;
}

.image-shape.icon-parallax {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
  background-repeat: no-repeat;
}

.list-icon {
  width: 45px;
}

.shape-xs {
  width: 10px;
  height: 10px;
}

.icon-badge {
  display: inline-block;
  position: absolute;
  top: -11px;
  right: 6px;
  height: 7px;
  width: 7px;
  background-color: #fa5252;
}

.github-big-icon {
  position: absolute;
  right: 200px;
  top: 165px;
}

.github-big-icon span {
  font-size: 800px;
  opacity: 0.1;
}

.github-big-icon svg {
  font-size: 800px;
  opacity: 0.1;
}

.react-big-icon {
  position: absolute;
  opacity: 0.05;
}

@media (min-width: 992px) {
  .react-big-icon {
    right: -35%;
    bottom: -30%;
    font-size: 800px;
  }
}

@media (min-width: 1200px) {
  .react-big-icon {
    right: -15%;
    bottom: -35%;
    font-size: 800px;
  }
}

/**
 * = Images
 */
.image-xl {
  height: 20rem;
}

.image-xl img {
  height: 20rem;
}

.image-lg {
  height: 12rem;
}

.image-lg img {
  height: 12rem;
}

.image-md {
  height: 5.5rem;
}

.image-md img {
  height: 5.5rem;
}

.image-sm {
  height: 3rem;
}

.image-sm img {
  height: 3rem;
}

.image-xs {
  height: 1.5rem;
}

.image-xs img {
  height: 1.5rem;
}

.image-small {
  height: 1rem;
}

.image-small img {
  height: 1rem;
}

.img-thumbnail {
  border-width: 0.125rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.full-image {
  height: 100%;
}

.gallery-feed img {
  width: 20%;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
}

.language-flag {
  width: auto;
  height: 1rem;
  margin-right: 0.4rem;
  position: relative;
  top: -2px;
}

@media (min-width: 576px) {
  .effect-img-2 {
    position: absolute;
    right: 5rem;
    top: 19%;
    z-index: 2;
    margin: 0;
  }

  .effect-img-1,
  .effect-img-2 {
    margin: 0 0 3rem;
    width: 350px;
    height: auto;
  }
}

@media (max-width: 767.98px) {
  .effect-img-2 {
    right: 0.425rem;
    top: 0;
  }
}

/**
 * = Input groups
 */
.input-group {
  border-radius: 0.5rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.input-group .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-group .form-control:not(:first-child) {
  border-left: 0;
  padding-left: 0;
}

.input-group .form-control:not(:last-child) {
  border-right: 0;
  padding-right: 0;
}

.input-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-group-text {
  font-size: 0.875rem;
  -webkit-transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  border-right: 0;
}

.input-group-prepend {
  margin-right: -2px;
}

.focused .input-group-text {
  color: #66799e;
  background-color: #ffffff;
}

.focused .input-group-shadow {
  -webkit-box-shadow: 0.1rem 0.1rem 0 rgba(245, 248, 251, 0.5);
  box-shadow: 0.1rem 0.1rem 0 rgba(245, 248, 251, 0.5);
}

/**
 * = List groups
 */
.list-group-space .list-group-item {
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
}

.list-group-item {
  border: 0;
}

.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.list-group-item i {
  width: 1rem;
}

.list-group-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  vertical-align: top;
  margin: -0.1rem 1.2rem 0 -0.2rem;
}

.list-group-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

.list-group-content > p {
  color: #d1d7e0;
  line-height: 1.5;
  margin: 0.2rem 0 0;
}

.list-group-heading {
  font-size: 1rem;
  color: #506690;
}

.list-group-heading > small,
.list-group-heading > .small {
  float: right;
  color: #d1d7e0;
  font-weight: 500;
}

.list-group.simple-list .list-group-item {
  background: transparent;
  border: none;
  padding: 0.375rem 0.125rem;
}

.list-group.simple-list .list-group-item i {
  vertical-align: middle;
  width: 35px;
  display: inline-block;
}

.news-list .row > [class*='col'] {
  padding: 0 0.25rem;
}

.news-list > li:not(:last-child) {
  margin-bottom: 0.75rem;
}

.news-list img {
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/**
 * = Modals
 */
.modal.static-example {
  position: relative;
  display: block;
}

.modal-content {
  border: 0;
  border-radius: 0.3rem;
}

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-fluid .modal-content {
  border-radius: 0;
}

/**
 * = Navs
 */
.nav-link {
  color: #506690;
}

.nav-link:hover,
.nav-link.active {
  color: #262b40;
}

.nav-link:hover img,
.nav-link.active img {
  opacity: inherit;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.nav-link span {
  position: relative;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-link {
  border: 0;
  padding: 1rem 1rem;
}

.nav-tabs .nav-link.active {
  color: #ffffff;
  background-color: #262b40;
}

.nav-tabs.nav-dark .nav-link.active {
  color: #ffffff;
  background-color: #12358a;
}

.nav-tabs.nav-light .nav-link.active {
  color: #2e3650;
  background-color: #eaedf2;
}

.nav-tabs.nav-light .nav-link:hover {
  color: #2e3650;
}

.nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem;
}

.nav-pills .nav-link {
  padding: 0.75rem 0.85rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #eaedf2;
}

.nav-pills .nav-link.avatar-link {
  border: 0;
}

.nav-pills .nav-link:hover {
  color: #262b40;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #262b40;
  background-color: #f8f8f8;
  border-color: #262b40;
}

.nav-pills.rounded .nav-link {
  border-radius: 30px;
}

.nav-pills.bordered-pill-md .nav-link {
  border: 0.125rem solid #f5f8fb;
  font-weight: 600;
}

.nav-pills.vertical-tab .nav-link {
  margin-bottom: 0.625rem;
}

.nav-pills.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}

.nav-pills .nav-item {
  margin-bottom: 1rem;
}

@media (max-width: 575.98px) {
  .nav-pills:not(.nav-pills-circle) .nav-item {
    padding-right: 0;
  }
}

.nav-pill-circle .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  height: 80px;
  width: 80px;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-pill-circle .nav-link.avatar-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.nav-pill-circle.vertical-tab .nav-link-icon i,
.nav-pill-circle.vertical-tab .nav-link-icon svg {
  font-size: 1.5rem;
}

.nav-pill-circle.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}

.nav-pill-circle .nav-link-icon i,
.nav-pill-circle .nav-link-icon svg {
  font-size: 1.25rem;
  margin: 0;
  display: block;
}

.nav-pill-square .nav-link {
  text-align: center;
  min-width: 80px;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-pill-square.vertical-tab .nav-link {
  margin-bottom: 0.625rem;
  min-width: 100px;
}

.nav-pill-square.vertical-tab .nav-item:not(:last-child) {
  padding-right: 0;
}

.nav-pill-square .nav-link-icon i,
.nav-pill-square .nav-link-icon svg {
  font-size: 1.25rem;
  margin: 0;
  display: block;
  line-height: 50px;
}

.nav-wrapper {
  padding: 1rem 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.nav-wrapper + .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .tab-pane pre {
  padding: 0;
  margin: 0;
}

.tab-content > .active {
  display: block;
}

/**
 * = Paginations
 */
.circle-pagination .page-link,
.circle-pagination span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  padding: 0;
  border-radius: 50%;
}

/**
 * = Pricing cards
 */
.pricing-card .card-header {
  background: transparent;
  z-index: 2;
}

.pricing-card .card-body {
  z-index: 2;
}

.pricing-card .pricing-value {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-width: 0.375rem;
  border-style: double;
  margin: 0 auto;
}

.pricing-card .pricing-value span {
  line-height: 140px;
}

.pricing-card .list-group-item {
  background: transparent;
  border: none;
  padding: 0.375rem 0.125rem;
}

.pricing-card .list-group-item i {
  display: inline-block;
  vertical-align: middle;
  width: 35px;
}

@media (max-width: 767.98px) {
  .pricing-card {
    margin-top: 1rem;
  }
}

/**
 * = Popovers
 */
.popover {
  border: 0;
}

.popover-header {
  font-weight: 600;
}

.popover-primary {
  background-color: #262b40;
}

.popover-primary .popover-header {
  background-color: #262b40;
  color: color-yiq(#262b40);
}

.popover-primary .popover-body {
  color: color-yiq(#262b40);
}

.popover-primary .popover-header {
  border-color: rgba(38, 43, 64, 0.2);
}

.popover-primary.bs-popover-top .arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #262b40;
}

.popover-primary.bs-popover-right .arrow::after {
  border-right-color: #262b40;
}

.popover-primary.bs-popover-bottom .arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #262b40;
}

.popover-primary.bs-popover-left .arrow::after {
  border-left-color: #262b40;
}

.popover-secondary {
  background-color: #61dafb;
}

.popover-secondary .popover-header {
  background-color: #61dafb;
  color: color-yiq(#61dafb);
}

.popover-secondary .popover-body {
  color: color-yiq(#61dafb);
}

.popover-secondary .popover-header {
  border-color: rgba(97, 218, 251, 0.2);
}

.popover-secondary.bs-popover-top .arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #61dafb;
}

.popover-secondary.bs-popover-right .arrow::after {
  border-right-color: #61dafb;
}

.popover-secondary.bs-popover-bottom .arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #61dafb;
}

.popover-secondary.bs-popover-left .arrow::after {
  border-left-color: #61dafb;
}

.popover-tertiary {
  background-color: #1b998b;
}

.popover-tertiary .popover-header {
  background-color: #1b998b;
  color: color-yiq(#1b998b);
}

.popover-tertiary .popover-body {
  color: color-yiq(#1b998b);
}

.popover-tertiary .popover-header {
  border-color: rgba(27, 153, 139, 0.2);
}

.popover-tertiary.bs-popover-top .arrow::after,
.popover-tertiary.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #1b998b;
}

.popover-tertiary.bs-popover-right .arrow::after {
  border-right-color: #1b998b;
}

.popover-tertiary.bs-popover-bottom .arrow::after,
.popover-tertiary.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #1b998b;
}

.popover-tertiary.bs-popover-left .arrow::after {
  border-left-color: #1b998b;
}

.popover-quaternary {
  background-color: #c96480;
}

.popover-quaternary .popover-header {
  background-color: #c96480;
  color: color-yiq(#c96480);
}

.popover-quaternary .popover-body {
  color: color-yiq(#c96480);
}

.popover-quaternary .popover-header {
  border-color: rgba(201, 100, 128, 0.2);
}

.popover-quaternary.bs-popover-top .arrow::after,
.popover-quaternary.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #c96480;
}

.popover-quaternary.bs-popover-right .arrow::after {
  border-right-color: #c96480;
}

.popover-quaternary.bs-popover-bottom .arrow::after,
.popover-quaternary.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #c96480;
}

.popover-quaternary.bs-popover-left .arrow::after {
  border-left-color: #c96480;
}

.popover-primary-app {
  background-color: #ebf4f6;
}

.popover-primary-app .popover-header {
  background-color: #ebf4f6;
  color: color-yiq(#ebf4f6);
}

.popover-primary-app .popover-body {
  color: color-yiq(#ebf4f6);
}

.popover-primary-app .popover-header {
  border-color: rgba(235, 244, 246, 0.2);
}

.popover-primary-app.bs-popover-top .arrow::after,
.popover-primary-app.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #ebf4f6;
}

.popover-primary-app.bs-popover-right .arrow::after {
  border-right-color: #ebf4f6;
}

.popover-primary-app.bs-popover-bottom .arrow::after,
.popover-primary-app.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #ebf4f6;
}

.popover-primary-app.bs-popover-left .arrow::after {
  border-left-color: #ebf4f6;
}

.popover-secondary-app {
  background-color: #424aa0;
}

.popover-secondary-app .popover-header {
  background-color: #424aa0;
  color: color-yiq(#424aa0);
}

.popover-secondary-app .popover-body {
  color: color-yiq(#424aa0);
}

.popover-secondary-app .popover-header {
  border-color: rgba(66, 74, 160, 0.2);
}

.popover-secondary-app.bs-popover-top .arrow::after,
.popover-secondary-app.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #424aa0;
}

.popover-secondary-app.bs-popover-right .arrow::after {
  border-right-color: #424aa0;
}

.popover-secondary-app.bs-popover-bottom .arrow::after,
.popover-secondary-app.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #424aa0;
}

.popover-secondary-app.bs-popover-left .arrow::after {
  border-left-color: #424aa0;
}

.popover-light {
  background-color: #eaedf2;
}

.popover-light .popover-header {
  background-color: #eaedf2;
  color: color-yiq(#eaedf2);
}

.popover-light .popover-body {
  color: color-yiq(#eaedf2);
}

.popover-light .popover-header {
  border-color: rgba(234, 237, 242, 0.2);
}

.popover-light.bs-popover-top .arrow::after,
.popover-light.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #eaedf2;
}

.popover-light.bs-popover-right .arrow::after {
  border-right-color: #eaedf2;
}

.popover-light.bs-popover-bottom .arrow::after,
.popover-light.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #eaedf2;
}

.popover-light.bs-popover-left .arrow::after {
  border-left-color: #eaedf2;
}

.popover-lighten {
  background-color: #f0f3f6;
}

.popover-lighten .popover-header {
  background-color: #f0f3f6;
  color: color-yiq(#f0f3f6);
}

.popover-lighten .popover-body {
  color: color-yiq(#f0f3f6);
}

.popover-lighten .popover-header {
  border-color: rgba(240, 243, 246, 0.2);
}

.popover-lighten.bs-popover-top .arrow::after,
.popover-lighten.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #f0f3f6;
}

.popover-lighten.bs-popover-right .arrow::after {
  border-right-color: #f0f3f6;
}

.popover-lighten.bs-popover-bottom .arrow::after,
.popover-lighten.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #f0f3f6;
}

.popover-lighten.bs-popover-left .arrow::after {
  border-left-color: #f0f3f6;
}

.popover-success {
  background-color: #05a677;
}

.popover-success .popover-header {
  background-color: #05a677;
  color: color-yiq(#05a677);
}

.popover-success .popover-body {
  color: color-yiq(#05a677);
}

.popover-success .popover-header {
  border-color: rgba(5, 166, 119, 0.2);
}

.popover-success.bs-popover-top .arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #05a677;
}

.popover-success.bs-popover-right .arrow::after {
  border-right-color: #05a677;
}

.popover-success.bs-popover-bottom .arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #05a677;
}

.popover-success.bs-popover-left .arrow::after {
  border-left-color: #05a677;
}

.popover-info {
  background-color: #0948b3;
}

.popover-info .popover-header {
  background-color: #0948b3;
  color: color-yiq(#0948b3);
}

.popover-info .popover-body {
  color: color-yiq(#0948b3);
}

.popover-info .popover-header {
  border-color: rgba(9, 72, 179, 0.2);
}

.popover-info.bs-popover-top .arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #0948b3;
}

.popover-info.bs-popover-right .arrow::after {
  border-right-color: #0948b3;
}

.popover-info.bs-popover-bottom .arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #0948b3;
}

.popover-info.bs-popover-left .arrow::after {
  border-left-color: #0948b3;
}

.popover-warning {
  background-color: #f5b759;
}

.popover-warning .popover-header {
  background-color: #f5b759;
  color: color-yiq(#f5b759);
}

.popover-warning .popover-body {
  color: color-yiq(#f5b759);
}

.popover-warning .popover-header {
  border-color: rgba(245, 183, 89, 0.2);
}

.popover-warning.bs-popover-top .arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #f5b759;
}

.popover-warning.bs-popover-right .arrow::after {
  border-right-color: #f5b759;
}

.popover-warning.bs-popover-bottom .arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #f5b759;
}

.popover-warning.bs-popover-left .arrow::after {
  border-left-color: #f5b759;
}

.popover-danger {
  background-color: #fa5252;
}

.popover-danger .popover-header {
  background-color: #fa5252;
  color: color-yiq(#fa5252);
}

.popover-danger .popover-body {
  color: color-yiq(#fa5252);
}

.popover-danger .popover-header {
  border-color: rgba(250, 82, 82, 0.2);
}

.popover-danger.bs-popover-top .arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #fa5252;
}

.popover-danger.bs-popover-right .arrow::after {
  border-right-color: #fa5252;
}

.popover-danger.bs-popover-bottom .arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #fa5252;
}

.popover-danger.bs-popover-left .arrow::after {
  border-left-color: #fa5252;
}

.popover-white {
  background-color: #ffffff;
}

.popover-white .popover-header {
  background-color: #ffffff;
  color: color-yiq(#ffffff);
}

.popover-white .popover-body {
  color: color-yiq(#ffffff);
}

.popover-white .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}

.popover-white.bs-popover-top .arrow::after,
.popover-white.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #ffffff;
}

.popover-white.bs-popover-right .arrow::after {
  border-right-color: #ffffff;
}

.popover-white.bs-popover-bottom .arrow::after,
.popover-white.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #ffffff;
}

.popover-white.bs-popover-left .arrow::after {
  border-left-color: #ffffff;
}

.popover-gray {
  background-color: #4a5073;
}

.popover-gray .popover-header {
  background-color: #4a5073;
  color: color-yiq(#4a5073);
}

.popover-gray .popover-body {
  color: color-yiq(#4a5073);
}

.popover-gray .popover-header {
  border-color: rgba(74, 80, 115, 0.2);
}

.popover-gray.bs-popover-top .arrow::after,
.popover-gray.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #4a5073;
}

.popover-gray.bs-popover-right .arrow::after {
  border-right-color: #4a5073;
}

.popover-gray.bs-popover-bottom .arrow::after,
.popover-gray.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #4a5073;
}

.popover-gray.bs-popover-left .arrow::after {
  border-left-color: #4a5073;
}

.popover-indigo {
  background-color: #4c5680;
}

.popover-indigo .popover-header {
  background-color: #4c5680;
  color: color-yiq(#4c5680);
}

.popover-indigo .popover-body {
  color: color-yiq(#4c5680);
}

.popover-indigo .popover-header {
  border-color: rgba(76, 86, 128, 0.2);
}

.popover-indigo.bs-popover-top .arrow::after,
.popover-indigo.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #4c5680;
}

.popover-indigo.bs-popover-right .arrow::after {
  border-right-color: #4c5680;
}

.popover-indigo.bs-popover-bottom .arrow::after,
.popover-indigo.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #4c5680;
}

.popover-indigo.bs-popover-left .arrow::after {
  border-left-color: #4c5680;
}

.popover-dark {
  background-color: #262b40;
}

.popover-dark .popover-header {
  background-color: #262b40;
  color: color-yiq(#262b40);
}

.popover-dark .popover-body {
  color: color-yiq(#262b40);
}

.popover-dark .popover-header {
  border-color: rgba(38, 43, 64, 0.2);
}

.popover-dark.bs-popover-top .arrow::after,
.popover-dark.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #262b40;
}

.popover-dark.bs-popover-right .arrow::after {
  border-right-color: #262b40;
}

.popover-dark.bs-popover-bottom .arrow::after,
.popover-dark.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #262b40;
}

.popover-dark.bs-popover-left .arrow::after {
  border-left-color: #262b40;
}

.popover-soft {
  background-color: #f5f8fb;
}

.popover-soft .popover-header {
  background-color: #f5f8fb;
  color: color-yiq(#f5f8fb);
}

.popover-soft .popover-body {
  color: color-yiq(#f5f8fb);
}

.popover-soft .popover-header {
  border-color: rgba(245, 248, 251, 0.2);
}

.popover-soft.bs-popover-top .arrow::after,
.popover-soft.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #f5f8fb;
}

.popover-soft.bs-popover-right .arrow::after {
  border-right-color: #f5f8fb;
}

.popover-soft.bs-popover-bottom .arrow::after,
.popover-soft.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #f5f8fb;
}

.popover-soft.bs-popover-left .arrow::after {
  border-left-color: #f5f8fb;
}

.popover-black {
  background-color: #2e3650;
}

.popover-black .popover-header {
  background-color: #2e3650;
  color: color-yiq(#2e3650);
}

.popover-black .popover-body {
  color: color-yiq(#2e3650);
}

.popover-black .popover-header {
  border-color: rgba(46, 54, 80, 0.2);
}

.popover-black.bs-popover-top .arrow::after,
.popover-black.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #2e3650;
}

.popover-black.bs-popover-right .arrow::after {
  border-right-color: #2e3650;
}

.popover-black.bs-popover-bottom .arrow::after,
.popover-black.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #2e3650;
}

.popover-black.bs-popover-left .arrow::after {
  border-left-color: #2e3650;
}

.popover-blue {
  background-color: #0948b3;
}

.popover-blue .popover-header {
  background-color: #0948b3;
  color: color-yiq(#0948b3);
}

.popover-blue .popover-body {
  color: color-yiq(#0948b3);
}

.popover-blue .popover-header {
  border-color: rgba(9, 72, 179, 0.2);
}

.popover-blue.bs-popover-top .arrow::after,
.popover-blue.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #0948b3;
}

.popover-blue.bs-popover-right .arrow::after {
  border-right-color: #0948b3;
}

.popover-blue.bs-popover-bottom .arrow::after,
.popover-blue.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #0948b3;
}

.popover-blue.bs-popover-left .arrow::after {
  border-left-color: #0948b3;
}

.popover-pink {
  background-color: #c96480;
}

.popover-pink .popover-header {
  background-color: #c96480;
  color: color-yiq(#c96480);
}

.popover-pink .popover-body {
  color: color-yiq(#c96480);
}

.popover-pink .popover-header {
  border-color: rgba(201, 100, 128, 0.2);
}

.popover-pink.bs-popover-top .arrow::after,
.popover-pink.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #c96480;
}

.popover-pink.bs-popover-right .arrow::after {
  border-right-color: #c96480;
}

.popover-pink.bs-popover-bottom .arrow::after,
.popover-pink.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #c96480;
}

.popover-pink.bs-popover-left .arrow::after {
  border-left-color: #c96480;
}

.popover-cyan {
  background-color: #63b1bd;
}

.popover-cyan .popover-header {
  background-color: #63b1bd;
  color: color-yiq(#63b1bd);
}

.popover-cyan .popover-body {
  color: color-yiq(#63b1bd);
}

.popover-cyan .popover-header {
  border-color: rgba(99, 177, 189, 0.2);
}

.popover-cyan.bs-popover-top .arrow::after,
.popover-cyan.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #63b1bd;
}

.popover-cyan.bs-popover-right .arrow::after {
  border-right-color: #63b1bd;
}

.popover-cyan.bs-popover-bottom .arrow::after,
.popover-cyan.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #63b1bd;
}

.popover-cyan.bs-popover-left .arrow::after {
  border-left-color: #63b1bd;
}

.popover-purple {
  background-color: #8965e0;
}

.popover-purple .popover-header {
  background-color: #8965e0;
  color: color-yiq(#8965e0);
}

.popover-purple .popover-body {
  color: color-yiq(#8965e0);
}

.popover-purple .popover-header {
  border-color: rgba(137, 101, 224, 0.2);
}

.popover-purple.bs-popover-top .arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #8965e0;
}

.popover-purple.bs-popover-right .arrow::after {
  border-right-color: #8965e0;
}

.popover-purple.bs-popover-bottom .arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #8965e0;
}

.popover-purple.bs-popover-left .arrow::after {
  border-left-color: #8965e0;
}

.popover-soft-indigo {
  background-color: #f5e8ff;
}

.popover-soft-indigo .popover-header {
  background-color: #f5e8ff;
  color: color-yiq(#f5e8ff);
}

.popover-soft-indigo .popover-body {
  color: color-yiq(#f5e8ff);
}

.popover-soft-indigo .popover-header {
  border-color: rgba(245, 232, 255, 0.2);
}

.popover-soft-indigo.bs-popover-top .arrow::after,
.popover-soft-indigo.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #f5e8ff;
}

.popover-soft-indigo.bs-popover-right .arrow::after {
  border-right-color: #f5e8ff;
}

.popover-soft-indigo.bs-popover-bottom .arrow::after,
.popover-soft-indigo.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #f5e8ff;
}

.popover-soft-indigo.bs-popover-left .arrow::after {
  border-left-color: #f5e8ff;
}

.popover-soft-green {
  background-color: #2ca58d;
}

.popover-soft-green .popover-header {
  background-color: #2ca58d;
  color: color-yiq(#2ca58d);
}

.popover-soft-green .popover-body {
  color: color-yiq(#2ca58d);
}

.popover-soft-green .popover-header {
  border-color: rgba(44, 165, 141, 0.2);
}

.popover-soft-green.bs-popover-top .arrow::after,
.popover-soft-green.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #2ca58d;
}

.popover-soft-green.bs-popover-right .arrow::after {
  border-right-color: #2ca58d;
}

.popover-soft-green.bs-popover-bottom .arrow::after,
.popover-soft-green.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #2ca58d;
}

.popover-soft-green.bs-popover-left .arrow::after {
  border-left-color: #2ca58d;
}

.popover-gray-100 {
  background-color: #f3f7fa;
}

.popover-gray-100 .popover-header {
  background-color: #f3f7fa;
  color: color-yiq(#f3f7fa);
}

.popover-gray-100 .popover-body {
  color: color-yiq(#f3f7fa);
}

.popover-gray-100 .popover-header {
  border-color: rgba(243, 247, 250, 0.2);
}

.popover-gray-100.bs-popover-top .arrow::after,
.popover-gray-100.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #f3f7fa;
}

.popover-gray-100.bs-popover-right .arrow::after {
  border-right-color: #f3f7fa;
}

.popover-gray-100.bs-popover-bottom .arrow::after,
.popover-gray-100.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #f3f7fa;
}

.popover-gray-100.bs-popover-left .arrow::after {
  border-left-color: #f3f7fa;
}

.popover-gray-200 {
  background-color: #f5f8fb;
}

.popover-gray-200 .popover-header {
  background-color: #f5f8fb;
  color: color-yiq(#f5f8fb);
}

.popover-gray-200 .popover-body {
  color: color-yiq(#f5f8fb);
}

.popover-gray-200 .popover-header {
  border-color: rgba(245, 248, 251, 0.2);
}

.popover-gray-200.bs-popover-top .arrow::after,
.popover-gray-200.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #f5f8fb;
}

.popover-gray-200.bs-popover-right .arrow::after {
  border-right-color: #f5f8fb;
}

.popover-gray-200.bs-popover-bottom .arrow::after,
.popover-gray-200.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #f5f8fb;
}

.popover-gray-200.bs-popover-left .arrow::after {
  border-left-color: #f5f8fb;
}

.popover-gray-300 {
  background-color: #f0f3f6;
}

.popover-gray-300 .popover-header {
  background-color: #f0f3f6;
  color: color-yiq(#f0f3f6);
}

.popover-gray-300 .popover-body {
  color: color-yiq(#f0f3f6);
}

.popover-gray-300 .popover-header {
  border-color: rgba(240, 243, 246, 0.2);
}

.popover-gray-300.bs-popover-top .arrow::after,
.popover-gray-300.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #f0f3f6;
}

.popover-gray-300.bs-popover-right .arrow::after {
  border-right-color: #f0f3f6;
}

.popover-gray-300.bs-popover-bottom .arrow::after,
.popover-gray-300.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #f0f3f6;
}

.popover-gray-300.bs-popover-left .arrow::after {
  border-left-color: #f0f3f6;
}

.popover-gray-400 {
  background-color: #eaedf2;
}

.popover-gray-400 .popover-header {
  background-color: #eaedf2;
  color: color-yiq(#eaedf2);
}

.popover-gray-400 .popover-body {
  color: color-yiq(#eaedf2);
}

.popover-gray-400 .popover-header {
  border-color: rgba(234, 237, 242, 0.2);
}

.popover-gray-400.bs-popover-top .arrow::after,
.popover-gray-400.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #eaedf2;
}

.popover-gray-400.bs-popover-right .arrow::after {
  border-right-color: #eaedf2;
}

.popover-gray-400.bs-popover-bottom .arrow::after,
.popover-gray-400.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #eaedf2;
}

.popover-gray-400.bs-popover-left .arrow::after {
  border-left-color: #eaedf2;
}

.popover-gray-500 {
  background-color: #d1d7e0;
}

.popover-gray-500 .popover-header {
  background-color: #d1d7e0;
  color: color-yiq(#d1d7e0);
}

.popover-gray-500 .popover-body {
  color: color-yiq(#d1d7e0);
}

.popover-gray-500 .popover-header {
  border-color: rgba(209, 215, 224, 0.2);
}

.popover-gray-500.bs-popover-top .arrow::after,
.popover-gray-500.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #d1d7e0;
}

.popover-gray-500.bs-popover-right .arrow::after {
  border-right-color: #d1d7e0;
}

.popover-gray-500.bs-popover-bottom .arrow::after,
.popover-gray-500.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #d1d7e0;
}

.popover-gray-500.bs-popover-left .arrow::after {
  border-left-color: #d1d7e0;
}

.popover-gray-600 {
  background-color: #93a5be;
}

.popover-gray-600 .popover-header {
  background-color: #93a5be;
  color: color-yiq(#93a5be);
}

.popover-gray-600 .popover-body {
  color: color-yiq(#93a5be);
}

.popover-gray-600 .popover-header {
  border-color: rgba(147, 165, 190, 0.2);
}

.popover-gray-600.bs-popover-top .arrow::after,
.popover-gray-600.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #93a5be;
}

.popover-gray-600.bs-popover-right .arrow::after {
  border-right-color: #93a5be;
}

.popover-gray-600.bs-popover-bottom .arrow::after,
.popover-gray-600.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #93a5be;
}

.popover-gray-600.bs-popover-left .arrow::after {
  border-left-color: #93a5be;
}

.popover-gray-700 {
  background-color: #66799e;
}

.popover-gray-700 .popover-header {
  background-color: #66799e;
  color: color-yiq(#66799e);
}

.popover-gray-700 .popover-body {
  color: color-yiq(#66799e);
}

.popover-gray-700 .popover-header {
  border-color: rgba(102, 121, 158, 0.2);
}

.popover-gray-700.bs-popover-top .arrow::after,
.popover-gray-700.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #66799e;
}

.popover-gray-700.bs-popover-right .arrow::after {
  border-right-color: #66799e;
}

.popover-gray-700.bs-popover-bottom .arrow::after,
.popover-gray-700.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #66799e;
}

.popover-gray-700.bs-popover-left .arrow::after {
  border-left-color: #66799e;
}

.popover-gray-800 {
  background-color: #506690;
}

.popover-gray-800 .popover-header {
  background-color: #506690;
  color: color-yiq(#506690);
}

.popover-gray-800 .popover-body {
  color: color-yiq(#506690);
}

.popover-gray-800 .popover-header {
  border-color: rgba(80, 102, 144, 0.2);
}

.popover-gray-800.bs-popover-top .arrow::after,
.popover-gray-800.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #506690;
}

.popover-gray-800.bs-popover-right .arrow::after {
  border-right-color: #506690;
}

.popover-gray-800.bs-popover-bottom .arrow::after,
.popover-gray-800.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #506690;
}

.popover-gray-800.bs-popover-left .arrow::after {
  border-left-color: #506690;
}

.popover-facebook {
  background-color: #3b5999;
}

.popover-facebook .popover-header {
  background-color: #3b5999;
  color: color-yiq(#3b5999);
}

.popover-facebook .popover-body {
  color: color-yiq(#3b5999);
}

.popover-facebook .popover-header {
  border-color: rgba(59, 89, 153, 0.2);
}

.popover-facebook.bs-popover-top .arrow::after,
.popover-facebook.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #3b5999;
}

.popover-facebook.bs-popover-right .arrow::after {
  border-right-color: #3b5999;
}

.popover-facebook.bs-popover-bottom .arrow::after,
.popover-facebook.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #3b5999;
}

.popover-facebook.bs-popover-left .arrow::after {
  border-left-color: #3b5999;
}

.popover-twitter {
  background-color: #1da1f2;
}

.popover-twitter .popover-header {
  background-color: #1da1f2;
  color: color-yiq(#1da1f2);
}

.popover-twitter .popover-body {
  color: color-yiq(#1da1f2);
}

.popover-twitter .popover-header {
  border-color: rgba(29, 161, 242, 0.2);
}

.popover-twitter.bs-popover-top .arrow::after,
.popover-twitter.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #1da1f2;
}

.popover-twitter.bs-popover-right .arrow::after {
  border-right-color: #1da1f2;
}

.popover-twitter.bs-popover-bottom .arrow::after,
.popover-twitter.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #1da1f2;
}

.popover-twitter.bs-popover-left .arrow::after {
  border-left-color: #1da1f2;
}

.popover-google {
  background-color: #db4337;
}

.popover-google .popover-header {
  background-color: #db4337;
  color: color-yiq(#db4337);
}

.popover-google .popover-body {
  color: color-yiq(#db4337);
}

.popover-google .popover-header {
  border-color: rgba(219, 67, 55, 0.2);
}

.popover-google.bs-popover-top .arrow::after,
.popover-google.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #db4337;
}

.popover-google.bs-popover-right .arrow::after {
  border-right-color: #db4337;
}

.popover-google.bs-popover-bottom .arrow::after,
.popover-google.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #db4337;
}

.popover-google.bs-popover-left .arrow::after {
  border-left-color: #db4337;
}

.popover-instagram {
  background-color: #e4405f;
}

.popover-instagram .popover-header {
  background-color: #e4405f;
  color: color-yiq(#e4405f);
}

.popover-instagram .popover-body {
  color: color-yiq(#e4405f);
}

.popover-instagram .popover-header {
  border-color: rgba(228, 64, 95, 0.2);
}

.popover-instagram.bs-popover-top .arrow::after,
.popover-instagram.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #e4405f;
}

.popover-instagram.bs-popover-right .arrow::after {
  border-right-color: #e4405f;
}

.popover-instagram.bs-popover-bottom .arrow::after,
.popover-instagram.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #e4405f;
}

.popover-instagram.bs-popover-left .arrow::after {
  border-left-color: #e4405f;
}

.popover-pinterest {
  background-color: #bd081c;
}

.popover-pinterest .popover-header {
  background-color: #bd081c;
  color: color-yiq(#bd081c);
}

.popover-pinterest .popover-body {
  color: color-yiq(#bd081c);
}

.popover-pinterest .popover-header {
  border-color: rgba(189, 8, 28, 0.2);
}

.popover-pinterest.bs-popover-top .arrow::after,
.popover-pinterest.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #bd081c;
}

.popover-pinterest.bs-popover-right .arrow::after {
  border-right-color: #bd081c;
}

.popover-pinterest.bs-popover-bottom .arrow::after,
.popover-pinterest.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #bd081c;
}

.popover-pinterest.bs-popover-left .arrow::after {
  border-left-color: #bd081c;
}

.popover-youtube {
  background-color: #cd201f;
}

.popover-youtube .popover-header {
  background-color: #cd201f;
  color: color-yiq(#cd201f);
}

.popover-youtube .popover-body {
  color: color-yiq(#cd201f);
}

.popover-youtube .popover-header {
  border-color: rgba(205, 32, 31, 0.2);
}

.popover-youtube.bs-popover-top .arrow::after,
.popover-youtube.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #cd201f;
}

.popover-youtube.bs-popover-right .arrow::after {
  border-right-color: #cd201f;
}

.popover-youtube.bs-popover-bottom .arrow::after,
.popover-youtube.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #cd201f;
}

.popover-youtube.bs-popover-left .arrow::after {
  border-left-color: #cd201f;
}

.popover-slack {
  background-color: #3aaf85;
}

.popover-slack .popover-header {
  background-color: #3aaf85;
  color: color-yiq(#3aaf85);
}

.popover-slack .popover-body {
  color: color-yiq(#3aaf85);
}

.popover-slack .popover-header {
  border-color: rgba(58, 175, 133, 0.2);
}

.popover-slack.bs-popover-top .arrow::after,
.popover-slack.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #3aaf85;
}

.popover-slack.bs-popover-right .arrow::after {
  border-right-color: #3aaf85;
}

.popover-slack.bs-popover-bottom .arrow::after,
.popover-slack.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #3aaf85;
}

.popover-slack.bs-popover-left .arrow::after {
  border-left-color: #3aaf85;
}

.popover-dribbble {
  background-color: #ea4c89;
}

.popover-dribbble .popover-header {
  background-color: #ea4c89;
  color: color-yiq(#ea4c89);
}

.popover-dribbble .popover-body {
  color: color-yiq(#ea4c89);
}

.popover-dribbble .popover-header {
  border-color: rgba(234, 76, 137, 0.2);
}

.popover-dribbble.bs-popover-top .arrow::after,
.popover-dribbble.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #ea4c89;
}

.popover-dribbble.bs-popover-right .arrow::after {
  border-right-color: #ea4c89;
}

.popover-dribbble.bs-popover-bottom .arrow::after,
.popover-dribbble.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #ea4c89;
}

.popover-dribbble.bs-popover-left .arrow::after {
  border-left-color: #ea4c89;
}

.popover-dropbox {
  background-color: #1e90ff;
}

.popover-dropbox .popover-header {
  background-color: #1e90ff;
  color: color-yiq(#1e90ff);
}

.popover-dropbox .popover-body {
  color: color-yiq(#1e90ff);
}

.popover-dropbox .popover-header {
  border-color: rgba(30, 144, 255, 0.2);
}

.popover-dropbox.bs-popover-top .arrow::after,
.popover-dropbox.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #1e90ff;
}

.popover-dropbox.bs-popover-right .arrow::after {
  border-right-color: #1e90ff;
}

.popover-dropbox.bs-popover-bottom .arrow::after,
.popover-dropbox.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #1e90ff;
}

.popover-dropbox.bs-popover-left .arrow::after {
  border-left-color: #1e90ff;
}

.popover-twitch {
  background-color: #4b367c;
}

.popover-twitch .popover-header {
  background-color: #4b367c;
  color: color-yiq(#4b367c);
}

.popover-twitch .popover-body {
  color: color-yiq(#4b367c);
}

.popover-twitch .popover-header {
  border-color: rgba(75, 54, 124, 0.2);
}

.popover-twitch.bs-popover-top .arrow::after,
.popover-twitch.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #4b367c;
}

.popover-twitch.bs-popover-right .arrow::after {
  border-right-color: #4b367c;
}

.popover-twitch.bs-popover-bottom .arrow::after,
.popover-twitch.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #4b367c;
}

.popover-twitch.bs-popover-left .arrow::after {
  border-left-color: #4b367c;
}

.popover-paypal {
  background-color: #ecb32c;
}

.popover-paypal .popover-header {
  background-color: #ecb32c;
  color: color-yiq(#ecb32c);
}

.popover-paypal .popover-body {
  color: color-yiq(#ecb32c);
}

.popover-paypal .popover-header {
  border-color: rgba(236, 179, 44, 0.2);
}

.popover-paypal.bs-popover-top .arrow::after,
.popover-paypal.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #ecb32c;
}

.popover-paypal.bs-popover-right .arrow::after {
  border-right-color: #ecb32c;
}

.popover-paypal.bs-popover-bottom .arrow::after,
.popover-paypal.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #ecb32c;
}

.popover-paypal.bs-popover-left .arrow::after {
  border-left-color: #ecb32c;
}

.popover-behance {
  background-color: #0057ff;
}

.popover-behance .popover-header {
  background-color: #0057ff;
  color: color-yiq(#0057ff);
}

.popover-behance .popover-body {
  color: color-yiq(#0057ff);
}

.popover-behance .popover-header {
  border-color: rgba(0, 87, 255, 0.2);
}

.popover-behance.bs-popover-top .arrow::after,
.popover-behance.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #0057ff;
}

.popover-behance.bs-popover-right .arrow::after {
  border-right-color: #0057ff;
}

.popover-behance.bs-popover-bottom .arrow::after,
.popover-behance.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #0057ff;
}

.popover-behance.bs-popover-left .arrow::after {
  border-left-color: #0057ff;
}

.popover-reddit {
  background-color: #e84422;
}

.popover-reddit .popover-header {
  background-color: #e84422;
  color: color-yiq(#e84422);
}

.popover-reddit .popover-body {
  color: color-yiq(#e84422);
}

.popover-reddit .popover-header {
  border-color: rgba(232, 68, 34, 0.2);
}

.popover-reddit.bs-popover-top .arrow::after,
.popover-reddit.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #e84422;
}

.popover-reddit.bs-popover-right .arrow::after {
  border-right-color: #e84422;
}

.popover-reddit.bs-popover-bottom .arrow::after,
.popover-reddit.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #e84422;
}

.popover-reddit.bs-popover-left .arrow::after {
  border-left-color: #e84422;
}

.popover-github {
  background-color: #222222;
}

.popover-github .popover-header {
  background-color: #222222;
  color: color-yiq(#222222);
}

.popover-github .popover-body {
  color: color-yiq(#222222);
}

.popover-github .popover-header {
  border-color: rgba(34, 34, 34, 0.2);
}

.popover-github.bs-popover-top .arrow::after,
.popover-github.bs-popover-auto[data-popper-placement^='top'] .arrow::after {
  border-top-color: #222222;
}

.popover-github.bs-popover-right .arrow::after {
  border-right-color: #222222;
}

.popover-github.bs-popover-bottom .arrow::after,
.popover-github.bs-popover-auto[data-popper-placement^='bottom'] .arrow::after {
  border-bottom-color: #222222;
}

.popover-github.bs-popover-left .arrow::after {
  border-left-color: #222222;
}

/**
 * = Progress bars
 */
.progress-wrapper {
  position: relative;
}

.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  height: auto;
}

.progress {
  height: 4px;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 0.1rem;
  background-color: #f5f8fb;
  font-size: 0.75rem;
  font-weight: 600;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-xl {
  height: 0.8rem;
}

.progress-lg {
  height: 0.6rem;
}

.progress-sm {
  height: 0.2rem;
}

.progress-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-bottom: 0.5rem;
}

.progress-info .progress-label span {
  display: inline-block;
  color: #262b40;
  font-size: 0.875rem;
  font-weight: 600;
}

.progress-info .progress-percentage {
  text-align: right;
}

.progress-info .progress-percentage span {
  display: inline-block;
  color: #66799e;
  font-size: 0.75rem;
  font-weight: 600;
}

.info-xl .progress-label span,
.info-xl .progress-percentage span {
  font-size: 0.875rem;
}

.info-xl .progress-percentage {
  text-align: right;
}

.progress-tooltip {
  background: #262b40;
  color: #ffffff;
  font-weight: 600;
  padding: 0.25rem 0.375rem;
  line-height: 1;
  font-size: 0.75rem;
  position: relative;
  border-radius: 0.1rem;
}

.progress-tooltip:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 10px;
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  border-top-color: #4a5073;
  border-width: 6px;
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}

@keyframes animate-positive {
  0% {
    width: 0%;
  }
}

.progress-info .bg-primary::after {
  border-top-color: #262b40;
}

.progress-info .bg-secondary::after {
  border-top-color: #61dafb;
}

.progress-info .bg-tertiary::after {
  border-top-color: #1b998b;
}

.progress-info .bg-quaternary::after {
  border-top-color: #c96480;
}

.progress-info .bg-primary-app::after {
  border-top-color: #ebf4f6;
}

.progress-info .bg-secondary-app::after {
  border-top-color: #424aa0;
}

.progress-info .bg-light::after {
  border-top-color: #eaedf2;
}

.progress-info .bg-lighten::after {
  border-top-color: #f0f3f6;
}

.progress-info .bg-success::after {
  border-top-color: #05a677;
}

.progress-info .bg-info::after {
  border-top-color: #0948b3;
}

.progress-info .bg-warning::after {
  border-top-color: #f5b759;
}

.progress-info .bg-danger::after {
  border-top-color: #fa5252;
}

.progress-info .bg-white::after {
  border-top-color: #ffffff;
}

.progress-info .bg-gray::after {
  border-top-color: #4a5073;
}

.progress-info .bg-indigo::after {
  border-top-color: #4c5680;
}

.progress-info .bg-dark::after {
  border-top-color: #262b40;
}

.progress-info .bg-soft::after {
  border-top-color: #f5f8fb;
}

.progress-info .bg-black::after {
  border-top-color: #2e3650;
}

.progress-info .bg-blue::after {
  border-top-color: #0948b3;
}

.progress-info .bg-pink::after {
  border-top-color: #c96480;
}

.progress-info .bg-cyan::after {
  border-top-color: #63b1bd;
}

.progress-info .bg-purple::after {
  border-top-color: #8965e0;
}

.progress-info .bg-soft-indigo::after {
  border-top-color: #f5e8ff;
}

.progress-info .bg-soft-green::after {
  border-top-color: #2ca58d;
}

.progress-info .bg-gray-100::after {
  border-top-color: #f3f7fa;
}

.progress-info .bg-gray-200::after {
  border-top-color: #f5f8fb;
}

.progress-info .bg-gray-300::after {
  border-top-color: #f0f3f6;
}

.progress-info .bg-gray-400::after {
  border-top-color: #eaedf2;
}

.progress-info .bg-gray-500::after {
  border-top-color: #d1d7e0;
}

.progress-info .bg-gray-600::after {
  border-top-color: #93a5be;
}

.progress-info .bg-gray-700::after {
  border-top-color: #66799e;
}

.progress-info .bg-gray-800::after {
  border-top-color: #506690;
}

.progress-info .bg-facebook::after {
  border-top-color: #3b5999;
}

.progress-info .bg-twitter::after {
  border-top-color: #1da1f2;
}

.progress-info .bg-google::after {
  border-top-color: #db4337;
}

.progress-info .bg-instagram::after {
  border-top-color: #e4405f;
}

.progress-info .bg-pinterest::after {
  border-top-color: #bd081c;
}

.progress-info .bg-youtube::after {
  border-top-color: #cd201f;
}

.progress-info .bg-slack::after {
  border-top-color: #3aaf85;
}

.progress-info .bg-dribbble::after {
  border-top-color: #ea4c89;
}

.progress-info .bg-dropbox::after {
  border-top-color: #1e90ff;
}

.progress-info .bg-twitch::after {
  border-top-color: #4b367c;
}

.progress-info .bg-paypal::after {
  border-top-color: #ecb32c;
}

.progress-info .bg-behance::after {
  border-top-color: #0057ff;
}

.progress-info .bg-reddit::after {
  border-top-color: #e84422;
}

.progress-info .bg-github::after {
  border-top-color: #222222;
}

/**
 * = Shapes
 */
.avatar-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.avatar-md {
  width: 2rem;
  height: 2rem;
}

.avatar-lg {
  width: 3rem;
  height: 3rem;
}

.table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  border-top: 0;
}

.table.comparison-table thead th {
  font-size: 1.25rem;
  color: #262b40;
}

.table.comparison-table td {
  font-size: 1rem;
}

.table th {
  font-weight: 600;
  padding: 1rem;
}

.table td .progress {
  height: 3px;
  width: 120px;
  margin: 0;
}

.table td,
.table th {
  font-size: 0.875rem;
  white-space: nowrap;
}

.table.user-table thead th {
  border-top: 0;
}

.table.align-items-center td,
.table.align-items-center th {
  vertical-align: middle;
}

.table .thead-dark th {
  background-color: #262b40;
  color: #ffffff;
}

.table .thead-dark th a {
  color: #262b40;
}

.table .thead-light th {
  background-color: #f5f8fb;
  color: #262b40;
}

.table .thead-light th a {
  color: #262b40;
}

.table-hover tr {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@media (prefers-reduced-motion: reduce) {
  .table-hover tr {
    -webkit-transition: none;
    transition: none;
  }
}

.table-flush td,
.table-flush th {
  border-left: 0;
  border-right: 0;
}

.table-flush tbody tr:first-child td,
.table-flush tbody tr:first-child th {
  border-top: 0;
}

.table-flush tbody tr:last-child td,
.table-flush tbody tr:last-child th {
  border-bottom: 0;
}

.card .table {
  margin-bottom: 0;
}

.card .table td,
.card .table th {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.table [data-sort] {
  cursor: pointer;
}

.table .custom-toggle {
  display: block;
}

.table .thead-dark [data-sort]::after {
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%23262B40'/></svg>");
  margin-left: 0.25rem;
}

.table .thead-light [data-sort]::after {
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%23262B40'/></svg>");
  margin-left: 0.25rem;
}

.dataTables_wrapper .dataTables_info {
  padding-left: 1.5rem;
}

.dataTables_wrapper .dataTables_paginate {
  padding-right: 1.5rem;
}

.dataTables_wrapper .dataTables_length label {
  padding-left: 1.5rem;
}

.dataTables_wrapper .dataTables_filter {
  padding-right: 1.5rem;
}

.dataTable-top,
.dataTable-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 767.98px) {
  .dataTable-top,
  .dataTable-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .dataTable-top .dataTable-info,
  .dataTable-bottom .dataTable-info {
    margin-bottom: 1rem;
  }
}

.dataTable-top {
  padding: 0 1.5rem 1rem 1.5rem;
}

.dataTable-bottom {
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.dataTable-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.dataTable-pagination a {
  padding: 0.375rem 0.75rem;
}

.dataTable-pagination li:first-child a {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.dataTable-pagination li:last-child a {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.dataTable-pagination > li:not(:first-child) a {
  margin-left: -0.0625rem;
}

.dataTable-pagination > li.active a {
  z-index: 3;
  color: #ffffff;
  background-color: #262b40;
  border-color: #262b40;
}

.dataTable-pagination > li.disabled a {
  color: #93a5be;
  pointer-events: none;
  background-color: #ffffff;
  border-color: #f0f3f6;
}

.dataTable-pagination > li a {
  position: relative;
  display: block;
  color: #262b40;
  background-color: #ffffff;
  border: 0.0625rem solid #eaedf2;
}

.dataTable-pagination > li a:hover {
  z-index: 2;
  color: #0a0b10;
  background-color: #f5f8fb;
  border-color: #f0f3f6;
}

.dataTable-pagination > li a:focus {
  z-index: 3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.dataTable-selector {
  display: inline-block;
  width: auto;
  height: calc(1.5em + 1.225rem);
  padding: 0.55rem 1.75rem 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  vertical-align: middle;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23506690' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 0.5rem;
  -webkit-box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dataTable-selector:focus {
  border-color: #566190;
  outline: 0;
  -webkit-box-shadow:
    inset 0 1px 2px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow:
    inset 0 1px 2px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.dataTable-selector:focus::-ms-value {
  color: #66799e;
  background-color: #ffffff;
}

.dataTable-selector[multiple],
.dataTable-selector[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.dataTable-selector:disabled {
  color: #93a5be;
  background-color: #f5f8fb;
}

.dataTable-selector:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #66799e;
}

.dataTable-input {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 1.225rem);
  padding: 0.55rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #66799e;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0.0625rem solid #d1d7e0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  -webkit-box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  box-shadow: inset 0 1px 2px rgba(46, 54, 80, 0.075);
  -webkit-transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dataTable-input {
    -webkit-transition: none;
    transition: none;
  }
}

.dataTable-input:focus {
  color: #66799e;
  background-color: #ffffff;
  border-color: #566190;
  outline: 0;
  -webkit-box-shadow:
    inset 0 1px 2px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
  box-shadow:
    inset 0 1px 2px rgba(46, 54, 80, 0.075),
    0 0 0 0.2rem rgba(38, 43, 64, 0.25);
}

.dataTable-input::-webkit-input-placeholder {
  color: #506690;
  opacity: 1;
}

.dataTable-input:-ms-input-placeholder {
  color: #506690;
  opacity: 1;
}

.dataTable-input::-ms-input-placeholder {
  color: #506690;
  opacity: 1;
}

.dataTable-input::placeholder {
  color: #506690;
  opacity: 1;
}

.dataTable-input:disabled,
.dataTable-input[readonly] {
  background-color: #f5f8fb;
  opacity: 1;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}

.search-label {
  width: 100%;
}

/**
 * = Typography
 */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
}

p,
ol li,
ul li,
.p {
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

a,
.text-action {
  color: #262b40;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:hover,
.text-action:hover {
  color: #0a0b10;
  cursor: pointer;
}

.lead + .btn-wrapper {
  margin-top: 3rem;
}

@media (max-width: 767.98px) {
  .lead {
    font-size: 1rem;
  }
}

.line-through {
  -webkit-text-decoration: line-through;
  text-decoration: line-through;
}

.text-italic {
  font-style: italic !important;
}

.description {
  font-size: 0.875rem;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .display-2 {
    font-size: 1.875rem;
  }
}

@media (max-width: 991.98px) {
  .display-3 {
    font-size: 1.875rem;
  }
}

@media (max-width: 575.98px) {
  .display-4 {
    font-size: 1.5rem;
  }
}

.blockquote {
  font-style: italic;
}

@media (max-width: 767.98px) {
  .blockquote.blockquote-large {
    font-size: 1.25rem;
  }
}

.blockquote .blockquote-footer {
  font-size: 0.875rem;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .blockquote .blockquote-footer {
    font-size: 1.25rem;
  }
}

.font-small {
  font-size: 0.875rem;
  font-weight: 300;
}

.font-base {
  font-size: 1rem;
  font-weight: 300;
}

.font-medium {
  font-size: 1.25rem;
  font-weight: 300;
}

code[class*='language-'],
pre[class*='language-'] {
  text-shadow: none;
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
}

.text-primary,
.text-primary:hover {
  color: #262b40 !important;
}

.text-primary a:not(.btn),
.text-primary h1,
.text-primary .h1,
.text-primary h2,
.text-primary .h2,
.text-primary h3,
.text-primary .h3,
.text-primary h4,
.text-primary .h4,
.text-primary h5,
.text-primary .h5,
.text-primary h6,
.text-primary .h6,
.text-primary .h1,
.text-primary .h2,
.text-primary .h3,
.text-primary .h4,
.text-primary .h5,
.text-primary .h6,
.text-primary .display-1,
.text-primary .display-2,
.text-primary .display-3,
.text-primary .display-4 {
  color: #262b40;
}

.text-primary .text-muted {
  color: rgba(38, 43, 64, 0.7) !important;
}

.text-secondary,
.text-secondary:hover {
  color: #61dafb !important;
}

.text-secondary a:not(.btn),
.text-secondary h1,
.text-secondary .h1,
.text-secondary h2,
.text-secondary .h2,
.text-secondary h3,
.text-secondary .h3,
.text-secondary h4,
.text-secondary .h4,
.text-secondary h5,
.text-secondary .h5,
.text-secondary h6,
.text-secondary .h6,
.text-secondary .h1,
.text-secondary .h2,
.text-secondary .h3,
.text-secondary .h4,
.text-secondary .h5,
.text-secondary .h6,
.text-secondary .display-1,
.text-secondary .display-2,
.text-secondary .display-3,
.text-secondary .display-4 {
  color: #61dafb;
}

.text-secondary .text-muted {
  color: rgba(97, 218, 251, 0.7) !important;
}

.text-tertiary,
.text-tertiary:hover {
  color: #1b998b !important;
}

.text-tertiary a:not(.btn),
.text-tertiary h1,
.text-tertiary .h1,
.text-tertiary h2,
.text-tertiary .h2,
.text-tertiary h3,
.text-tertiary .h3,
.text-tertiary h4,
.text-tertiary .h4,
.text-tertiary h5,
.text-tertiary .h5,
.text-tertiary h6,
.text-tertiary .h6,
.text-tertiary .h1,
.text-tertiary .h2,
.text-tertiary .h3,
.text-tertiary .h4,
.text-tertiary .h5,
.text-tertiary .h6,
.text-tertiary .display-1,
.text-tertiary .display-2,
.text-tertiary .display-3,
.text-tertiary .display-4 {
  color: #1b998b;
}

.text-tertiary .text-muted {
  color: rgba(27, 153, 139, 0.7) !important;
}

.text-quaternary,
.text-quaternary:hover {
  color: #c96480 !important;
}

.text-quaternary a:not(.btn),
.text-quaternary h1,
.text-quaternary .h1,
.text-quaternary h2,
.text-quaternary .h2,
.text-quaternary h3,
.text-quaternary .h3,
.text-quaternary h4,
.text-quaternary .h4,
.text-quaternary h5,
.text-quaternary .h5,
.text-quaternary h6,
.text-quaternary .h6,
.text-quaternary .h1,
.text-quaternary .h2,
.text-quaternary .h3,
.text-quaternary .h4,
.text-quaternary .h5,
.text-quaternary .h6,
.text-quaternary .display-1,
.text-quaternary .display-2,
.text-quaternary .display-3,
.text-quaternary .display-4 {
  color: #c96480;
}

.text-quaternary .text-muted {
  color: rgba(201, 100, 128, 0.7) !important;
}

.text-primary-app,
.text-primary-app:hover {
  color: #ebf4f6 !important;
}

.text-primary-app a:not(.btn),
.text-primary-app h1,
.text-primary-app .h1,
.text-primary-app h2,
.text-primary-app .h2,
.text-primary-app h3,
.text-primary-app .h3,
.text-primary-app h4,
.text-primary-app .h4,
.text-primary-app h5,
.text-primary-app .h5,
.text-primary-app h6,
.text-primary-app .h6,
.text-primary-app .h1,
.text-primary-app .h2,
.text-primary-app .h3,
.text-primary-app .h4,
.text-primary-app .h5,
.text-primary-app .h6,
.text-primary-app .display-1,
.text-primary-app .display-2,
.text-primary-app .display-3,
.text-primary-app .display-4 {
  color: #ebf4f6;
}

.text-primary-app .text-muted {
  color: rgba(235, 244, 246, 0.7) !important;
}

.text-secondary-app,
.text-secondary-app:hover {
  color: #424aa0 !important;
}

.text-secondary-app a:not(.btn),
.text-secondary-app h1,
.text-secondary-app .h1,
.text-secondary-app h2,
.text-secondary-app .h2,
.text-secondary-app h3,
.text-secondary-app .h3,
.text-secondary-app h4,
.text-secondary-app .h4,
.text-secondary-app h5,
.text-secondary-app .h5,
.text-secondary-app h6,
.text-secondary-app .h6,
.text-secondary-app .h1,
.text-secondary-app .h2,
.text-secondary-app .h3,
.text-secondary-app .h4,
.text-secondary-app .h5,
.text-secondary-app .h6,
.text-secondary-app .display-1,
.text-secondary-app .display-2,
.text-secondary-app .display-3,
.text-secondary-app .display-4 {
  color: #424aa0;
}

.text-secondary-app .text-muted {
  color: rgba(66, 74, 160, 0.7) !important;
}

.text-light,
.text-light:hover {
  color: #eaedf2 !important;
}

.text-light a:not(.btn),
.text-light h1,
.text-light .h1,
.text-light h2,
.text-light .h2,
.text-light h3,
.text-light .h3,
.text-light h4,
.text-light .h4,
.text-light h5,
.text-light .h5,
.text-light h6,
.text-light .h6,
.text-light .h1,
.text-light .h2,
.text-light .h3,
.text-light .h4,
.text-light .h5,
.text-light .h6,
.text-light .display-1,
.text-light .display-2,
.text-light .display-3,
.text-light .display-4 {
  color: #eaedf2;
}

.text-light .text-muted {
  color: rgba(234, 237, 242, 0.7) !important;
}

.text-lighten,
.text-lighten:hover {
  color: #f0f3f6 !important;
}

.text-lighten a:not(.btn),
.text-lighten h1,
.text-lighten .h1,
.text-lighten h2,
.text-lighten .h2,
.text-lighten h3,
.text-lighten .h3,
.text-lighten h4,
.text-lighten .h4,
.text-lighten h5,
.text-lighten .h5,
.text-lighten h6,
.text-lighten .h6,
.text-lighten .h1,
.text-lighten .h2,
.text-lighten .h3,
.text-lighten .h4,
.text-lighten .h5,
.text-lighten .h6,
.text-lighten .display-1,
.text-lighten .display-2,
.text-lighten .display-3,
.text-lighten .display-4 {
  color: #f0f3f6;
}

.text-lighten .text-muted {
  color: rgba(240, 243, 246, 0.7) !important;
}

.text-success,
.text-success:hover {
  color: #05a677 !important;
}

.text-success a:not(.btn),
.text-success h1,
.text-success .h1,
.text-success h2,
.text-success .h2,
.text-success h3,
.text-success .h3,
.text-success h4,
.text-success .h4,
.text-success h5,
.text-success .h5,
.text-success h6,
.text-success .h6,
.text-success .h1,
.text-success .h2,
.text-success .h3,
.text-success .h4,
.text-success .h5,
.text-success .h6,
.text-success .display-1,
.text-success .display-2,
.text-success .display-3,
.text-success .display-4 {
  color: #05a677;
}

.text-success .text-muted {
  color: rgba(5, 166, 119, 0.7) !important;
}

.text-info,
.text-info:hover {
  color: #0948b3 !important;
}

.text-info a:not(.btn),
.text-info h1,
.text-info .h1,
.text-info h2,
.text-info .h2,
.text-info h3,
.text-info .h3,
.text-info h4,
.text-info .h4,
.text-info h5,
.text-info .h5,
.text-info h6,
.text-info .h6,
.text-info .h1,
.text-info .h2,
.text-info .h3,
.text-info .h4,
.text-info .h5,
.text-info .h6,
.text-info .display-1,
.text-info .display-2,
.text-info .display-3,
.text-info .display-4 {
  color: #0948b3;
}

.text-info .text-muted {
  color: rgba(9, 72, 179, 0.7) !important;
}

.text-warning,
.text-warning:hover {
  color: #f5b759 !important;
}

.text-warning a:not(.btn),
.text-warning h1,
.text-warning .h1,
.text-warning h2,
.text-warning .h2,
.text-warning h3,
.text-warning .h3,
.text-warning h4,
.text-warning .h4,
.text-warning h5,
.text-warning .h5,
.text-warning h6,
.text-warning .h6,
.text-warning .h1,
.text-warning .h2,
.text-warning .h3,
.text-warning .h4,
.text-warning .h5,
.text-warning .h6,
.text-warning .display-1,
.text-warning .display-2,
.text-warning .display-3,
.text-warning .display-4 {
  color: #f5b759;
}

.text-warning .text-muted {
  color: rgba(245, 183, 89, 0.7) !important;
}

.text-danger,
.text-danger:hover {
  color: #fa5252 !important;
}

.text-danger a:not(.btn),
.text-danger h1,
.text-danger .h1,
.text-danger h2,
.text-danger .h2,
.text-danger h3,
.text-danger .h3,
.text-danger h4,
.text-danger .h4,
.text-danger h5,
.text-danger .h5,
.text-danger h6,
.text-danger .h6,
.text-danger .h1,
.text-danger .h2,
.text-danger .h3,
.text-danger .h4,
.text-danger .h5,
.text-danger .h6,
.text-danger .display-1,
.text-danger .display-2,
.text-danger .display-3,
.text-danger .display-4 {
  color: #fa5252;
}

.text-danger .text-muted {
  color: rgba(250, 82, 82, 0.7) !important;
}

.text-white,
.text-white:hover {
  color: #ffffff !important;
}

.text-white a:not(.btn),
.text-white h1,
.text-white .h1,
.text-white h2,
.text-white .h2,
.text-white h3,
.text-white .h3,
.text-white h4,
.text-white .h4,
.text-white h5,
.text-white .h5,
.text-white h6,
.text-white .h6,
.text-white .h1,
.text-white .h2,
.text-white .h3,
.text-white .h4,
.text-white .h5,
.text-white .h6,
.text-white .display-1,
.text-white .display-2,
.text-white .display-3,
.text-white .display-4 {
  color: #ffffff;
}

.text-white .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-gray,
.text-gray:hover {
  color: #4a5073 !important;
}

.text-gray a:not(.btn),
.text-gray h1,
.text-gray .h1,
.text-gray h2,
.text-gray .h2,
.text-gray h3,
.text-gray .h3,
.text-gray h4,
.text-gray .h4,
.text-gray h5,
.text-gray .h5,
.text-gray h6,
.text-gray .h6,
.text-gray .h1,
.text-gray .h2,
.text-gray .h3,
.text-gray .h4,
.text-gray .h5,
.text-gray .h6,
.text-gray .display-1,
.text-gray .display-2,
.text-gray .display-3,
.text-gray .display-4 {
  color: #4a5073;
}

.text-gray .text-muted {
  color: rgba(74, 80, 115, 0.7) !important;
}

.text-indigo,
.text-indigo:hover {
  color: #4c5680 !important;
}

.text-indigo a:not(.btn),
.text-indigo h1,
.text-indigo .h1,
.text-indigo h2,
.text-indigo .h2,
.text-indigo h3,
.text-indigo .h3,
.text-indigo h4,
.text-indigo .h4,
.text-indigo h5,
.text-indigo .h5,
.text-indigo h6,
.text-indigo .h6,
.text-indigo .h1,
.text-indigo .h2,
.text-indigo .h3,
.text-indigo .h4,
.text-indigo .h5,
.text-indigo .h6,
.text-indigo .display-1,
.text-indigo .display-2,
.text-indigo .display-3,
.text-indigo .display-4 {
  color: #4c5680;
}

.text-indigo .text-muted {
  color: rgba(76, 86, 128, 0.7) !important;
}

.text-dark,
.text-dark:hover {
  color: #262b40 !important;
}

.text-dark a:not(.btn),
.text-dark h1,
.text-dark .h1,
.text-dark h2,
.text-dark .h2,
.text-dark h3,
.text-dark .h3,
.text-dark h4,
.text-dark .h4,
.text-dark h5,
.text-dark .h5,
.text-dark h6,
.text-dark .h6,
.text-dark .h1,
.text-dark .h2,
.text-dark .h3,
.text-dark .h4,
.text-dark .h5,
.text-dark .h6,
.text-dark .display-1,
.text-dark .display-2,
.text-dark .display-3,
.text-dark .display-4 {
  color: #262b40;
}

.text-dark .text-muted {
  color: rgba(38, 43, 64, 0.7) !important;
}

.text-soft,
.text-soft:hover {
  color: #f5f8fb !important;
}

.text-soft a:not(.btn),
.text-soft h1,
.text-soft .h1,
.text-soft h2,
.text-soft .h2,
.text-soft h3,
.text-soft .h3,
.text-soft h4,
.text-soft .h4,
.text-soft h5,
.text-soft .h5,
.text-soft h6,
.text-soft .h6,
.text-soft .h1,
.text-soft .h2,
.text-soft .h3,
.text-soft .h4,
.text-soft .h5,
.text-soft .h6,
.text-soft .display-1,
.text-soft .display-2,
.text-soft .display-3,
.text-soft .display-4 {
  color: #f5f8fb;
}

.text-soft .text-muted {
  color: rgba(245, 248, 251, 0.7) !important;
}

.text-black,
.text-black:hover {
  color: #2e3650 !important;
}

.text-black a:not(.btn),
.text-black h1,
.text-black .h1,
.text-black h2,
.text-black .h2,
.text-black h3,
.text-black .h3,
.text-black h4,
.text-black .h4,
.text-black h5,
.text-black .h5,
.text-black h6,
.text-black .h6,
.text-black .h1,
.text-black .h2,
.text-black .h3,
.text-black .h4,
.text-black .h5,
.text-black .h6,
.text-black .display-1,
.text-black .display-2,
.text-black .display-3,
.text-black .display-4 {
  color: #2e3650;
}

.text-black .text-muted {
  color: rgba(46, 54, 80, 0.7) !important;
}

.text-blue,
.text-blue:hover {
  color: #0948b3 !important;
}

.text-blue a:not(.btn),
.text-blue h1,
.text-blue .h1,
.text-blue h2,
.text-blue .h2,
.text-blue h3,
.text-blue .h3,
.text-blue h4,
.text-blue .h4,
.text-blue h5,
.text-blue .h5,
.text-blue h6,
.text-blue .h6,
.text-blue .h1,
.text-blue .h2,
.text-blue .h3,
.text-blue .h4,
.text-blue .h5,
.text-blue .h6,
.text-blue .display-1,
.text-blue .display-2,
.text-blue .display-3,
.text-blue .display-4 {
  color: #0948b3;
}

.text-blue .text-muted {
  color: rgba(9, 72, 179, 0.7) !important;
}

.text-pink,
.text-pink:hover {
  color: #c96480 !important;
}

.text-pink a:not(.btn),
.text-pink h1,
.text-pink .h1,
.text-pink h2,
.text-pink .h2,
.text-pink h3,
.text-pink .h3,
.text-pink h4,
.text-pink .h4,
.text-pink h5,
.text-pink .h5,
.text-pink h6,
.text-pink .h6,
.text-pink .h1,
.text-pink .h2,
.text-pink .h3,
.text-pink .h4,
.text-pink .h5,
.text-pink .h6,
.text-pink .display-1,
.text-pink .display-2,
.text-pink .display-3,
.text-pink .display-4 {
  color: #c96480;
}

.text-pink .text-muted {
  color: rgba(201, 100, 128, 0.7) !important;
}

.text-cyan,
.text-cyan:hover {
  color: #63b1bd !important;
}

.text-cyan a:not(.btn),
.text-cyan h1,
.text-cyan .h1,
.text-cyan h2,
.text-cyan .h2,
.text-cyan h3,
.text-cyan .h3,
.text-cyan h4,
.text-cyan .h4,
.text-cyan h5,
.text-cyan .h5,
.text-cyan h6,
.text-cyan .h6,
.text-cyan .h1,
.text-cyan .h2,
.text-cyan .h3,
.text-cyan .h4,
.text-cyan .h5,
.text-cyan .h6,
.text-cyan .display-1,
.text-cyan .display-2,
.text-cyan .display-3,
.text-cyan .display-4 {
  color: #63b1bd;
}

.text-cyan .text-muted {
  color: rgba(99, 177, 189, 0.7) !important;
}

.text-purple,
.text-purple:hover {
  color: #8965e0 !important;
}

.text-purple a:not(.btn),
.text-purple h1,
.text-purple .h1,
.text-purple h2,
.text-purple .h2,
.text-purple h3,
.text-purple .h3,
.text-purple h4,
.text-purple .h4,
.text-purple h5,
.text-purple .h5,
.text-purple h6,
.text-purple .h6,
.text-purple .h1,
.text-purple .h2,
.text-purple .h3,
.text-purple .h4,
.text-purple .h5,
.text-purple .h6,
.text-purple .display-1,
.text-purple .display-2,
.text-purple .display-3,
.text-purple .display-4 {
  color: #8965e0;
}

.text-purple .text-muted {
  color: rgba(137, 101, 224, 0.7) !important;
}

.text-soft-indigo,
.text-soft-indigo:hover {
  color: #f5e8ff !important;
}

.text-soft-indigo a:not(.btn),
.text-soft-indigo h1,
.text-soft-indigo .h1,
.text-soft-indigo h2,
.text-soft-indigo .h2,
.text-soft-indigo h3,
.text-soft-indigo .h3,
.text-soft-indigo h4,
.text-soft-indigo .h4,
.text-soft-indigo h5,
.text-soft-indigo .h5,
.text-soft-indigo h6,
.text-soft-indigo .h6,
.text-soft-indigo .h1,
.text-soft-indigo .h2,
.text-soft-indigo .h3,
.text-soft-indigo .h4,
.text-soft-indigo .h5,
.text-soft-indigo .h6,
.text-soft-indigo .display-1,
.text-soft-indigo .display-2,
.text-soft-indigo .display-3,
.text-soft-indigo .display-4 {
  color: #f5e8ff;
}

.text-soft-indigo .text-muted {
  color: rgba(245, 232, 255, 0.7) !important;
}

.text-soft-green,
.text-soft-green:hover {
  color: #2ca58d !important;
}

.text-soft-green a:not(.btn),
.text-soft-green h1,
.text-soft-green .h1,
.text-soft-green h2,
.text-soft-green .h2,
.text-soft-green h3,
.text-soft-green .h3,
.text-soft-green h4,
.text-soft-green .h4,
.text-soft-green h5,
.text-soft-green .h5,
.text-soft-green h6,
.text-soft-green .h6,
.text-soft-green .h1,
.text-soft-green .h2,
.text-soft-green .h3,
.text-soft-green .h4,
.text-soft-green .h5,
.text-soft-green .h6,
.text-soft-green .display-1,
.text-soft-green .display-2,
.text-soft-green .display-3,
.text-soft-green .display-4 {
  color: #2ca58d;
}

.text-soft-green .text-muted {
  color: rgba(44, 165, 141, 0.7) !important;
}

.text-gray-100,
.text-gray-100:hover {
  color: #f3f7fa !important;
}

.text-gray-100 a:not(.btn),
.text-gray-100 h1,
.text-gray-100 .h1,
.text-gray-100 h2,
.text-gray-100 .h2,
.text-gray-100 h3,
.text-gray-100 .h3,
.text-gray-100 h4,
.text-gray-100 .h4,
.text-gray-100 h5,
.text-gray-100 .h5,
.text-gray-100 h6,
.text-gray-100 .h6,
.text-gray-100 .h1,
.text-gray-100 .h2,
.text-gray-100 .h3,
.text-gray-100 .h4,
.text-gray-100 .h5,
.text-gray-100 .h6,
.text-gray-100 .display-1,
.text-gray-100 .display-2,
.text-gray-100 .display-3,
.text-gray-100 .display-4 {
  color: #f3f7fa;
}

.text-gray-100 .text-muted {
  color: rgba(243, 247, 250, 0.7) !important;
}

.text-gray-200,
.text-gray-200:hover {
  color: #f5f8fb !important;
}

.text-gray-200 a:not(.btn),
.text-gray-200 h1,
.text-gray-200 .h1,
.text-gray-200 h2,
.text-gray-200 .h2,
.text-gray-200 h3,
.text-gray-200 .h3,
.text-gray-200 h4,
.text-gray-200 .h4,
.text-gray-200 h5,
.text-gray-200 .h5,
.text-gray-200 h6,
.text-gray-200 .h6,
.text-gray-200 .h1,
.text-gray-200 .h2,
.text-gray-200 .h3,
.text-gray-200 .h4,
.text-gray-200 .h5,
.text-gray-200 .h6,
.text-gray-200 .display-1,
.text-gray-200 .display-2,
.text-gray-200 .display-3,
.text-gray-200 .display-4 {
  color: #f5f8fb;
}

.text-gray-200 .text-muted {
  color: rgba(245, 248, 251, 0.7) !important;
}

.text-gray-300,
.text-gray-300:hover {
  color: #f0f3f6 !important;
}

.text-gray-300 a:not(.btn),
.text-gray-300 h1,
.text-gray-300 .h1,
.text-gray-300 h2,
.text-gray-300 .h2,
.text-gray-300 h3,
.text-gray-300 .h3,
.text-gray-300 h4,
.text-gray-300 .h4,
.text-gray-300 h5,
.text-gray-300 .h5,
.text-gray-300 h6,
.text-gray-300 .h6,
.text-gray-300 .h1,
.text-gray-300 .h2,
.text-gray-300 .h3,
.text-gray-300 .h4,
.text-gray-300 .h5,
.text-gray-300 .h6,
.text-gray-300 .display-1,
.text-gray-300 .display-2,
.text-gray-300 .display-3,
.text-gray-300 .display-4 {
  color: #f0f3f6;
}

.text-gray-300 .text-muted {
  color: rgba(240, 243, 246, 0.7) !important;
}

.text-gray-400,
.text-gray-400:hover {
  color: #eaedf2 !important;
}

.text-gray-400 a:not(.btn),
.text-gray-400 h1,
.text-gray-400 .h1,
.text-gray-400 h2,
.text-gray-400 .h2,
.text-gray-400 h3,
.text-gray-400 .h3,
.text-gray-400 h4,
.text-gray-400 .h4,
.text-gray-400 h5,
.text-gray-400 .h5,
.text-gray-400 h6,
.text-gray-400 .h6,
.text-gray-400 .h1,
.text-gray-400 .h2,
.text-gray-400 .h3,
.text-gray-400 .h4,
.text-gray-400 .h5,
.text-gray-400 .h6,
.text-gray-400 .display-1,
.text-gray-400 .display-2,
.text-gray-400 .display-3,
.text-gray-400 .display-4 {
  color: #eaedf2;
}

.text-gray-400 .text-muted {
  color: rgba(234, 237, 242, 0.7) !important;
}

.text-gray-500,
.text-gray-500:hover {
  color: #d1d7e0 !important;
}

.text-gray-500 a:not(.btn),
.text-gray-500 h1,
.text-gray-500 .h1,
.text-gray-500 h2,
.text-gray-500 .h2,
.text-gray-500 h3,
.text-gray-500 .h3,
.text-gray-500 h4,
.text-gray-500 .h4,
.text-gray-500 h5,
.text-gray-500 .h5,
.text-gray-500 h6,
.text-gray-500 .h6,
.text-gray-500 .h1,
.text-gray-500 .h2,
.text-gray-500 .h3,
.text-gray-500 .h4,
.text-gray-500 .h5,
.text-gray-500 .h6,
.text-gray-500 .display-1,
.text-gray-500 .display-2,
.text-gray-500 .display-3,
.text-gray-500 .display-4 {
  color: #d1d7e0;
}

.text-gray-500 .text-muted {
  color: rgba(209, 215, 224, 0.7) !important;
}

.text-gray-600,
.text-gray-600:hover {
  color: #93a5be !important;
}

.text-gray-600 a:not(.btn),
.text-gray-600 h1,
.text-gray-600 .h1,
.text-gray-600 h2,
.text-gray-600 .h2,
.text-gray-600 h3,
.text-gray-600 .h3,
.text-gray-600 h4,
.text-gray-600 .h4,
.text-gray-600 h5,
.text-gray-600 .h5,
.text-gray-600 h6,
.text-gray-600 .h6,
.text-gray-600 .h1,
.text-gray-600 .h2,
.text-gray-600 .h3,
.text-gray-600 .h4,
.text-gray-600 .h5,
.text-gray-600 .h6,
.text-gray-600 .display-1,
.text-gray-600 .display-2,
.text-gray-600 .display-3,
.text-gray-600 .display-4 {
  color: #93a5be;
}

.text-gray-600 .text-muted {
  color: rgba(147, 165, 190, 0.7) !important;
}

.text-gray-700,
.text-gray-700:hover {
  color: #66799e !important;
}

.text-gray-700 a:not(.btn),
.text-gray-700 h1,
.text-gray-700 .h1,
.text-gray-700 h2,
.text-gray-700 .h2,
.text-gray-700 h3,
.text-gray-700 .h3,
.text-gray-700 h4,
.text-gray-700 .h4,
.text-gray-700 h5,
.text-gray-700 .h5,
.text-gray-700 h6,
.text-gray-700 .h6,
.text-gray-700 .h1,
.text-gray-700 .h2,
.text-gray-700 .h3,
.text-gray-700 .h4,
.text-gray-700 .h5,
.text-gray-700 .h6,
.text-gray-700 .display-1,
.text-gray-700 .display-2,
.text-gray-700 .display-3,
.text-gray-700 .display-4 {
  color: #66799e;
}

.text-gray-700 .text-muted {
  color: rgba(102, 121, 158, 0.7) !important;
}

.text-gray-800,
.text-gray-800:hover {
  color: #506690 !important;
}

.text-gray-800 a:not(.btn),
.text-gray-800 h1,
.text-gray-800 .h1,
.text-gray-800 h2,
.text-gray-800 .h2,
.text-gray-800 h3,
.text-gray-800 .h3,
.text-gray-800 h4,
.text-gray-800 .h4,
.text-gray-800 h5,
.text-gray-800 .h5,
.text-gray-800 h6,
.text-gray-800 .h6,
.text-gray-800 .h1,
.text-gray-800 .h2,
.text-gray-800 .h3,
.text-gray-800 .h4,
.text-gray-800 .h5,
.text-gray-800 .h6,
.text-gray-800 .display-1,
.text-gray-800 .display-2,
.text-gray-800 .display-3,
.text-gray-800 .display-4 {
  color: #506690;
}

.text-gray-800 .text-muted {
  color: rgba(80, 102, 144, 0.7) !important;
}

.text-facebook,
.text-facebook:hover {
  color: #3b5999 !important;
}

.text-facebook a:not(.btn),
.text-facebook h1,
.text-facebook .h1,
.text-facebook h2,
.text-facebook .h2,
.text-facebook h3,
.text-facebook .h3,
.text-facebook h4,
.text-facebook .h4,
.text-facebook h5,
.text-facebook .h5,
.text-facebook h6,
.text-facebook .h6,
.text-facebook .h1,
.text-facebook .h2,
.text-facebook .h3,
.text-facebook .h4,
.text-facebook .h5,
.text-facebook .h6,
.text-facebook .display-1,
.text-facebook .display-2,
.text-facebook .display-3,
.text-facebook .display-4 {
  color: #3b5999;
}

.text-facebook .text-muted {
  color: rgba(59, 89, 153, 0.7) !important;
}

.text-twitter,
.text-twitter:hover {
  color: #1da1f2 !important;
}

.text-twitter a:not(.btn),
.text-twitter h1,
.text-twitter .h1,
.text-twitter h2,
.text-twitter .h2,
.text-twitter h3,
.text-twitter .h3,
.text-twitter h4,
.text-twitter .h4,
.text-twitter h5,
.text-twitter .h5,
.text-twitter h6,
.text-twitter .h6,
.text-twitter .h1,
.text-twitter .h2,
.text-twitter .h3,
.text-twitter .h4,
.text-twitter .h5,
.text-twitter .h6,
.text-twitter .display-1,
.text-twitter .display-2,
.text-twitter .display-3,
.text-twitter .display-4 {
  color: #1da1f2;
}

.text-twitter .text-muted {
  color: rgba(29, 161, 242, 0.7) !important;
}

.text-google,
.text-google:hover {
  color: #db4337 !important;
}

.text-google a:not(.btn),
.text-google h1,
.text-google .h1,
.text-google h2,
.text-google .h2,
.text-google h3,
.text-google .h3,
.text-google h4,
.text-google .h4,
.text-google h5,
.text-google .h5,
.text-google h6,
.text-google .h6,
.text-google .h1,
.text-google .h2,
.text-google .h3,
.text-google .h4,
.text-google .h5,
.text-google .h6,
.text-google .display-1,
.text-google .display-2,
.text-google .display-3,
.text-google .display-4 {
  color: #db4337;
}

.text-google .text-muted {
  color: rgba(219, 67, 55, 0.7) !important;
}

.text-instagram,
.text-instagram:hover {
  color: #e4405f !important;
}

.text-instagram a:not(.btn),
.text-instagram h1,
.text-instagram .h1,
.text-instagram h2,
.text-instagram .h2,
.text-instagram h3,
.text-instagram .h3,
.text-instagram h4,
.text-instagram .h4,
.text-instagram h5,
.text-instagram .h5,
.text-instagram h6,
.text-instagram .h6,
.text-instagram .h1,
.text-instagram .h2,
.text-instagram .h3,
.text-instagram .h4,
.text-instagram .h5,
.text-instagram .h6,
.text-instagram .display-1,
.text-instagram .display-2,
.text-instagram .display-3,
.text-instagram .display-4 {
  color: #e4405f;
}

.text-instagram .text-muted {
  color: rgba(228, 64, 95, 0.7) !important;
}

.text-pinterest,
.text-pinterest:hover {
  color: #bd081c !important;
}

.text-pinterest a:not(.btn),
.text-pinterest h1,
.text-pinterest .h1,
.text-pinterest h2,
.text-pinterest .h2,
.text-pinterest h3,
.text-pinterest .h3,
.text-pinterest h4,
.text-pinterest .h4,
.text-pinterest h5,
.text-pinterest .h5,
.text-pinterest h6,
.text-pinterest .h6,
.text-pinterest .h1,
.text-pinterest .h2,
.text-pinterest .h3,
.text-pinterest .h4,
.text-pinterest .h5,
.text-pinterest .h6,
.text-pinterest .display-1,
.text-pinterest .display-2,
.text-pinterest .display-3,
.text-pinterest .display-4 {
  color: #bd081c;
}

.text-pinterest .text-muted {
  color: rgba(189, 8, 28, 0.7) !important;
}

.text-youtube,
.text-youtube:hover {
  color: #cd201f !important;
}

.text-youtube a:not(.btn),
.text-youtube h1,
.text-youtube .h1,
.text-youtube h2,
.text-youtube .h2,
.text-youtube h3,
.text-youtube .h3,
.text-youtube h4,
.text-youtube .h4,
.text-youtube h5,
.text-youtube .h5,
.text-youtube h6,
.text-youtube .h6,
.text-youtube .h1,
.text-youtube .h2,
.text-youtube .h3,
.text-youtube .h4,
.text-youtube .h5,
.text-youtube .h6,
.text-youtube .display-1,
.text-youtube .display-2,
.text-youtube .display-3,
.text-youtube .display-4 {
  color: #cd201f;
}

.text-youtube .text-muted {
  color: rgba(205, 32, 31, 0.7) !important;
}

.text-slack,
.text-slack:hover {
  color: #3aaf85 !important;
}

.text-slack a:not(.btn),
.text-slack h1,
.text-slack .h1,
.text-slack h2,
.text-slack .h2,
.text-slack h3,
.text-slack .h3,
.text-slack h4,
.text-slack .h4,
.text-slack h5,
.text-slack .h5,
.text-slack h6,
.text-slack .h6,
.text-slack .h1,
.text-slack .h2,
.text-slack .h3,
.text-slack .h4,
.text-slack .h5,
.text-slack .h6,
.text-slack .display-1,
.text-slack .display-2,
.text-slack .display-3,
.text-slack .display-4 {
  color: #3aaf85;
}

.text-slack .text-muted {
  color: rgba(58, 175, 133, 0.7) !important;
}

.text-dribbble,
.text-dribbble:hover {
  color: #ea4c89 !important;
}

.text-dribbble a:not(.btn),
.text-dribbble h1,
.text-dribbble .h1,
.text-dribbble h2,
.text-dribbble .h2,
.text-dribbble h3,
.text-dribbble .h3,
.text-dribbble h4,
.text-dribbble .h4,
.text-dribbble h5,
.text-dribbble .h5,
.text-dribbble h6,
.text-dribbble .h6,
.text-dribbble .h1,
.text-dribbble .h2,
.text-dribbble .h3,
.text-dribbble .h4,
.text-dribbble .h5,
.text-dribbble .h6,
.text-dribbble .display-1,
.text-dribbble .display-2,
.text-dribbble .display-3,
.text-dribbble .display-4 {
  color: #ea4c89;
}

.text-dribbble .text-muted {
  color: rgba(234, 76, 137, 0.7) !important;
}

.text-dropbox,
.text-dropbox:hover {
  color: #1e90ff !important;
}

.text-dropbox a:not(.btn),
.text-dropbox h1,
.text-dropbox .h1,
.text-dropbox h2,
.text-dropbox .h2,
.text-dropbox h3,
.text-dropbox .h3,
.text-dropbox h4,
.text-dropbox .h4,
.text-dropbox h5,
.text-dropbox .h5,
.text-dropbox h6,
.text-dropbox .h6,
.text-dropbox .h1,
.text-dropbox .h2,
.text-dropbox .h3,
.text-dropbox .h4,
.text-dropbox .h5,
.text-dropbox .h6,
.text-dropbox .display-1,
.text-dropbox .display-2,
.text-dropbox .display-3,
.text-dropbox .display-4 {
  color: #1e90ff;
}

.text-dropbox .text-muted {
  color: rgba(30, 144, 255, 0.7) !important;
}

.text-twitch,
.text-twitch:hover {
  color: #4b367c !important;
}

.text-twitch a:not(.btn),
.text-twitch h1,
.text-twitch .h1,
.text-twitch h2,
.text-twitch .h2,
.text-twitch h3,
.text-twitch .h3,
.text-twitch h4,
.text-twitch .h4,
.text-twitch h5,
.text-twitch .h5,
.text-twitch h6,
.text-twitch .h6,
.text-twitch .h1,
.text-twitch .h2,
.text-twitch .h3,
.text-twitch .h4,
.text-twitch .h5,
.text-twitch .h6,
.text-twitch .display-1,
.text-twitch .display-2,
.text-twitch .display-3,
.text-twitch .display-4 {
  color: #4b367c;
}

.text-twitch .text-muted {
  color: rgba(75, 54, 124, 0.7) !important;
}

.text-paypal,
.text-paypal:hover {
  color: #ecb32c !important;
}

.text-paypal a:not(.btn),
.text-paypal h1,
.text-paypal .h1,
.text-paypal h2,
.text-paypal .h2,
.text-paypal h3,
.text-paypal .h3,
.text-paypal h4,
.text-paypal .h4,
.text-paypal h5,
.text-paypal .h5,
.text-paypal h6,
.text-paypal .h6,
.text-paypal .h1,
.text-paypal .h2,
.text-paypal .h3,
.text-paypal .h4,
.text-paypal .h5,
.text-paypal .h6,
.text-paypal .display-1,
.text-paypal .display-2,
.text-paypal .display-3,
.text-paypal .display-4 {
  color: #ecb32c;
}

.text-paypal .text-muted {
  color: rgba(236, 179, 44, 0.7) !important;
}

.text-behance,
.text-behance:hover {
  color: #0057ff !important;
}

.text-behance a:not(.btn),
.text-behance h1,
.text-behance .h1,
.text-behance h2,
.text-behance .h2,
.text-behance h3,
.text-behance .h3,
.text-behance h4,
.text-behance .h4,
.text-behance h5,
.text-behance .h5,
.text-behance h6,
.text-behance .h6,
.text-behance .h1,
.text-behance .h2,
.text-behance .h3,
.text-behance .h4,
.text-behance .h5,
.text-behance .h6,
.text-behance .display-1,
.text-behance .display-2,
.text-behance .display-3,
.text-behance .display-4 {
  color: #0057ff;
}

.text-behance .text-muted {
  color: rgba(0, 87, 255, 0.7) !important;
}

.text-reddit,
.text-reddit:hover {
  color: #e84422 !important;
}

.text-reddit a:not(.btn),
.text-reddit h1,
.text-reddit .h1,
.text-reddit h2,
.text-reddit .h2,
.text-reddit h3,
.text-reddit .h3,
.text-reddit h4,
.text-reddit .h4,
.text-reddit h5,
.text-reddit .h5,
.text-reddit h6,
.text-reddit .h6,
.text-reddit .h1,
.text-reddit .h2,
.text-reddit .h3,
.text-reddit .h4,
.text-reddit .h5,
.text-reddit .h6,
.text-reddit .display-1,
.text-reddit .display-2,
.text-reddit .display-3,
.text-reddit .display-4 {
  color: #e84422;
}

.text-reddit .text-muted {
  color: rgba(232, 68, 34, 0.7) !important;
}

.text-github,
.text-github:hover {
  color: #222222 !important;
}

.text-github a:not(.btn),
.text-github h1,
.text-github .h1,
.text-github h2,
.text-github .h2,
.text-github h3,
.text-github .h3,
.text-github h4,
.text-github .h4,
.text-github h5,
.text-github .h5,
.text-github h6,
.text-github .h6,
.text-github .h1,
.text-github .h2,
.text-github .h3,
.text-github .h4,
.text-github .h5,
.text-github .h6,
.text-github .display-1,
.text-github .display-2,
.text-github .display-3,
.text-github .display-4 {
  color: #222222;
}

.text-github .text-muted {
  color: rgba(34, 34, 34, 0.7) !important;
}

article p,
article ul,
article ol,
article blockquote {
  margin-bottom: 10px;
}

article p,
article ul li,
article ol li {
  font-size: 1.1rem;
}

article h1,
article .h1,
article h2,
article .h2,
article h3,
article .h3,
article h4,
article .h4,
article h5,
article .h5,
article h6,
article .h6,
article .h1,
article .h2,
article .h3,
article .h4,
article .h5,
article .h6 {
  margin-bottom: 10px;
}

.folder-structure li {
  font-size: 1.2rem;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hero-title {
  font-size: 3rem;
}

@media (min-width: 992px) {
  .hero-title {
    font-size: 4.275rem;
  }
}

p a,
.docs-list li a {
  color: #0948b3;
  text-decoration: underline;
}

p a:hover,
.docs-list li a:hover {
  color: #0948b3;
  text-decoration: none;
}

.code-editor-error pre {
  margin-bottom: 0;
}

/**
 * = Timelines
 */
.list-group-timeline .list-group-item {
  position: relative;
}

.list-group-timeline .list-group-item .col-auto {
  z-index: 999;
}

.list-group-timeline .list-group-item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 1rem;
  left: 2.5rem;
  height: 100%;
  border-left: 1px solid #eaedf2;
}

.tooltip {
  margin-bottom: 0.375rem;
}

.tooltip-inner {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(46, 54, 80, 0.15);
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #f5f8fb;
  border: 1px solid #eaedf2;
  border-radius: 0.5rem;
  color: #262b40;
  font-weight: 600;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.chartist-tooltip:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -15px;
  border: 15px solid transparent;
  border-top-color: #f5f8fb;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

.preloader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .preloader {
    -webkit-transition: none;
    transition: none;
  }
}

.preloader .loader-element {
  -webkit-transition: spin 0.3s linear infinite;
  transition: spin 0.3s linear infinite;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .preloader .loader-element {
    -webkit-transition: none;
    transition: none;
  }
}

.preloader.show {
  height: 0;
}

.preloader.show .loader-element {
  display: none !important;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

[data-simplebar] {
  position: fixed;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  -webkit-transition: opacity 0.2s 0.5s linear;
  transition: opacity 0.2s 0.5s linear;
}

.simplebar-scrolling .simplebar-scrollbar:before,
.simplebar-hover .simplebar-scrollbar:before,
.simplebar-mouse-entered .simplebar-scrollbar:before {
  opacity: 0.5;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.simplebar-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: scrollbar !important;
}

.simplebar-dummy-scrollbar-size > div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-scrollbar::before {
  background-color: #131620;
}

.demo-editor {
  border: 1px solid rgba(209, 157, 157, 0.185);
  border-top: 1px solid #fff;
  height: 353px !important;
  overflow: scroll;
  overflow-x: hidden;
  background-color: #fff;
  padding-left: 12px;
}

.demo-editor::-webkit-scrollbar {
  width: 4px;
}

.demo-editor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.demo-editor::-webkit-scrollbar-thumb {
  background-color: slategrey;
  outline: 1px solid slategrey;
}

.toolbar-class {
  border: 1px solid #ccc;
}

/* Chat Css */

.chat-container {
  display: flex;

  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.sidebar-1 {
  flex: 1;
  background-color: #252b40;
  color: #fff;
  padding: 20px;
  min-height: 70vh;
  height: 100%;
  overflow-y: auto;
}

.user-profile img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: #00ab79;
  border-bottom: 1px solid black;
}

.contact-list {
  list-style: none;
  padding: 0;
}

.contact {
  padding: 0.5rem 0 0.5rem 1.1rem;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.chat {
  flex: 3;
  background-color: #f4f4f4;
  position: relative;
}

.chat-header {
  background-color: black;
}

.chat-header h3 {
  color: white;
  padding: 0.7rem;
  margin-bottom: 0%;
}

.chat-header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-messages {
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px;
}

.message {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sent {
  background-color: #dcf8c6;
  align-self: flex-end;
  border-radius: 5px;
}

.sent-pdf {
  background-color: #dcf8c6;
  align-self: flex-end;
  border-radius: 5px;
  padding: 1rem;
}

.received-pdf {
  padding: 1rem;
  border-radius: 5px;
  background-color: #e5f2ff;
  align-self: flex-start;
}

.sent-p {
  margin-bottom: 0;
}

.received {
  border-radius: 5px;
  background-color: #e5f2ff;
  align-self: flex-start;
}

.received-p {
  margin-bottom: 0;
}

.message-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
}

.custom-input {
  flex: 1;
  width: 100%;
  position: relative;

  border-radius: 6px;
  margin-top: 1rem;
}

.custom-input input:focus {
  outline: none;
  border: none;
}

.attachment {
}

.attachment-preview {
  display: flex;

  align-items: center;

  height: 50px;
}

.replay-preview {
  display: flex;

  align-items: center;

  height: 50px;
}

.message-input input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  border: none;
}

.message-input button {
  background-color: #128c7e;
  border-left: none;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.no-data-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.cursor-pointer-class {
  cursor: pointer !important;
  background-color: #0000003d !important;
  border-radius: 20px !important;
  padding: 5px 7px !important;
}

.cursor-pointer-default {
  cursor: pointer !important;
}

.progress-bar {
  color: black !important;
}
