
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!


.demo-editor{
    border: 1px solid rgba(209, 157, 157, 0.185);
    border-top: 1px solid #fff;
        height:353px !important;
        overflow: scroll;
        overflow-x: hidden;
        background-color: #fff;
        padding-left: 12px;
    }
    
    .demo-editor::-webkit-scrollbar {
        width: 4px;
      }
       
      .demo-editor::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
       
      .demo-editor::-webkit-scrollbar-thumb {
        background-color: slategrey;
        outline: 1px solid slategrey;
      }
    
    
      .toolbar-class {
        border: 1px solid #ccc;
      }
    
    